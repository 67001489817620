import 'moment/locale/it';

// eslint-disable-next-line no-restricted-imports
import _i18n, { TOptions as _TOptions } from 'i18next';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import * as reactI18next from 'react-i18next';
import { SetRequired } from 'type-fest';

import { Leaves } from '../util';
import { de } from './locales/de';
import { en } from './locales/en';
import { es } from './locales/es';
import { fr } from './locales/fr';
import { it } from './locales/it';

export type SupportedLanguage = 'de' | 'en' | 'es' | 'fr' | 'it';

export const locales = { de, en, es, fr, it };
export * from './I18nExample';

const i18nextNamespace = 'translation';

export const i18nInit = (
  props: SetRequired<Partial<Record<SupportedLanguage, Record<string, unknown>>>, 'en'>,
  language: string,
  supportedLanguages: Array<string>
) => {
  // eslint-disable-next-line import/no-named-as-default-member
  _i18n.use(reactI18next.initReactI18next).init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: language,
    ns: i18nextNamespace,
    resources: {
      de: { [i18nextNamespace]: _.merge(de, props.de) },
      en: { [i18nextNamespace]: _.merge(en, props.en) },
      es: { [i18nextNamespace]: _.merge(es, props.es) },
      fr: { [i18nextNamespace]: _.merge(fr, props.fr) },
      it: { [i18nextNamespace]: _.merge(it, props.it) },
    },
    returnEmptyString: false,
    supportedLngs: supportedLanguages,
  });
};

interface TOptions extends _TOptions {
  overrideValue?: string;
}

// interface TProps<U> {
//   id: U;
//   options?: TOptions;
// }

export const i18nBuild = <K extends string = never>() => {
  const _t = <U extends string = never>(key: U, options?: TOptions) =>
    // eslint-disable-next-line import/no-named-as-default-member
    options?.overrideValue ? options.overrideValue : _i18n.t<string, U>(key, options);

  // const _useI18n = <U extends string = never>() => {
  //   const { i18n: i18nHook } = reactI18next.useTranslation();

  //   const t = (key: U, options?: TOptions) => _t<U>(key, options);

  //   const changeLanguage = (language: SupportedLanguage) => i18nHook.changeLanguage(language);

  //   const addResource = (language: SupportedLanguage, key: U, value: string) =>
  //     i18nHook.addResource(language, i18nextNamespace, key, value);

  //   return {
  //     addResource,
  //     changeLanguage,
  //     language: i18nHook.language as SupportedLanguage,
  //     t,
  //   };
  // };

  const _addResource = <U extends string = never>(language: SupportedLanguage, key: U, value: string) =>
    _i18n.addResource(language, i18nextNamespace, key, value);

  // const _T = <U extends string = never>({ id, options }: TProps<U>) => {
  //   const { t } = _useI18n<U>();
  //   return (
  //     <span className="i18n" data-id={id}>
  //       {t(id, options)}
  //     </span>
  //   );
  // };

  // const useI18n = () => _useI18n<K>();
  const t = (key: K, options?: TOptions) => _t<K>(key, options);
  // eslint-disable-next-line import/no-named-as-default-member
  const changeLanguage = (language: SupportedLanguage) => _i18n.changeLanguage(language);
  const addResource = (language: SupportedLanguage, key: K, value: string) => _addResource(language, key, value);
  const language = _i18n.language as SupportedLanguage;
  // const i18n = { addResource, changeLanguage, t };
  // eslint-disable-next-line react/jsx-pascal-case
  // const T = (props: TProps<K>) => <_T<K> {...props} />;
  return { addResource, changeLanguage, language, t };
};

// export type Resource = PartialDeep<typeof en>;
export type Resource = typeof en;
export type ResourceKeys = Leaves<typeof en>;

export const { addResource, changeLanguage, language, t } = i18nBuild<ResourceKeys>();

// Strategia alternativa per "aumentare" le chiavi delle traduzioni da quelle comuni di b2x-react a quelle aggiuntive dedicate del singolo store.
// Sfrutto il "Declaration Merging".
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html#merging-interfaces
// Dichiaro quindi in maniera esplicita tutte le interfacce e faccio in seguito un overload delle funzioni di traduzione
// tramite un file 'b2x-react.d.ts' posto nella root dei sorgenti del singolo store.

// export interface I18nStatic {
//   addResource(language: SupportedLanguages, id: ResourceKeys, value: string): void;
//   changeLanguage(language: SupportedLanguages): void;
//   language: SupportedLanguages;
//   t(id: ResourceKeys): string;
// }

// export const i18n: I18nStatic = {
//   addResource: (language, id, value) => _i18n.addResource(language, i18nextNamespace, id, value),
//   changeLanguage: (language) => _i18n.changeLanguage(language),
//   language: _i18n.language as SupportedLanguages,
//   t: (id) => _i18n.t(id),
// };

// export interface UseTranslation {
//   addResource(language: SupportedLanguages, id: ResourceKeys, value: string): void;
//   changeLanguage(language: SupportedLanguages): void;
//   language: SupportedLanguages;
//   t(id: ResourceKeys): string;
// }

// export const useTranslation = (): UseTranslation => {
//   const { i18n: _i18nHook, t: _t } = reactI18next.useTranslation();

//   return {
//     addResource: (language, id, value) => _i18nHook.addResource(language, i18nextNamespace, id, value),
//     changeLanguage: (language) => _i18nHook.changeLanguage(language),
//     language: _i18nHook.language as SupportedLanguages,
//     t: (id) => _t(id),
//   };
// };

// interface TProps {
//   id: ResourceKeys;
// }
// export interface TInterface {
//   (props: TProps): React.ReactElement;
// }
// export const T: TInterface = (props) => {
//   const { id } = props;
//   //   return <span className="i18n">{t(id)}</span>;
// };
