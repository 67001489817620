import { CtaContentSection } from './contentTypes';
import { Cta, CtaProps } from './Cta';

interface CtaFromContentProps extends CtaContentSection {
  ctaProps?: CtaProps<string, string, number>;
}

export const CtaFromContent = ({ ctaProps, icon, label, to, variant }: CtaFromContentProps) => {
  return (
    <>
      {label && (
        <Cta
          action={to?.action}
          button={{
            ...ctaProps?.button,
            iconStart: icon ? { ...ctaProps?.button?.iconStart, name: icon } : undefined,
            label: label,
            variant: variant,
          }}
          code={to?.code}
          href={to?.href}
          link={{ ...ctaProps?.link }}
        />
      )}
    </>
  );
};
