import { ConsentsForm } from '../../../form/ConsentsForm';
import { useCustomer } from '../../../useCustomer';
import { AccountPrivacySubpageProps } from '../AccountPrivacySubpage';
import { AccountSubpage } from '../AccountSubpage';
import { Box } from './Box';

export const AccountPrivacySubpageA = (props: AccountPrivacySubpageProps) => {
  const customer = useCustomer();
  return (
    <AccountSubpage>
      <Box>{customer && <ConsentsForm customer={customer} />}</Box>
    </AccountSubpage>
  );
};
