import { Fragment } from 'react';

import { useOrdersApi } from './api/useOrdersApi';
import { Col } from './Col';
import { EmptyLink } from './EmptyLink';
import { RestartPaymentForm } from './form/RestartPaymentForm';
import { Div, P } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { InvoiceSummary } from './InvoiceSummary';
import { OrderDetailsProps } from './OrderDetails';
import { Link } from './router/Link';
import { Row } from './Row';
import { Table } from './Table';
import { TableBody } from './TableBody';
import { TableFoot } from './TableFoot';
import { TableHead } from './TableHead';
import { downloadFileFromBlob, formatCurrency, renderUI } from './util';

export const OrderDetailsA = ({ order }: OrderDetailsProps) => {
  const { getInvoiceFile } = useOrdersApi();
  console.log(order.warehouseOrders);

  return (
    <div className="order-details-a">
      {order.warehouseOrders?.map((warehouseOrder) =>
        renderUI({
          bs5: (
            <Fragment key={warehouseOrder.id}>
              <Table borderless className="small m-0 d-none d-md-table border-bottom" colNumber={12}>
                <TableHead>
                  <tr>
                    <th className="text-uppercase" colSpan={8}>
                      <b>{t('account.orders.details.product')}</b>
                    </th>
                    <th className="text-uppercase" colSpan={2}>
                      <b>{t('account.orders.details.price')}</b>
                    </th>
                    <th className="text-uppercase text-end" colSpan={2}>
                      <b>{t('account.orders.details.total')}</b>
                    </th>
                  </tr>
                </TableHead>
                <TableBody>
                  {warehouseOrder.orderedSkus?.map((orderSku) => (
                    <tr key={`${warehouseOrder.id}-${orderSku.id}`}>
                      <td colSpan={8}>
                        <div className="d-flex">
                          <div className="col-product-img">
                            {orderSku.sku?.product?.image && (
                              <Image fluid format={144} src={orderSku.sku.product.image.src} />
                            )}
                          </div>
                          <div className="col-product-detail ps-3">
                            <p className="m-0">{orderSku.sku?.product?.name}</p>
                            <p className="text-dark">
                              {orderSku.sku?.product?.attributes?.map(
                                (attribute) =>
                                  attribute.typeCode === 'EQS_COLOR' && (
                                    <span key={attribute.typeCode}>
                                      {t('account.orders.details.product')} {attribute.value}
                                    </span>
                                  )
                              )}
                              <br />
                              {t('account.orders.details.size')} {orderSku.sku?.name}
                              <br />
                              {t('account.orders.details.quantity')} {orderSku.quantity}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="mobile-data-title" colSpan={2} data-title={t('account.orders.details.unitPrice')}>
                        {formatCurrency(orderSku.price)}
                      </td>
                      <td
                        className="mobile-data-title text-end"
                        colSpan={2}
                        data-title={t('account.orders.details.total')}
                      >
                        {/* TODO Far arrivare già il totale dalle API */}
                        <b>{formatCurrency(orderSku.price * orderSku.quantity)}</b>
                      </td>
                    </tr>
                  ))}
                </TableBody>

                <TableFoot className="border-top my-md-3" yAlignment="top">
                  <tr>
                    <td className="pt-4 py-md-4" colSpan={4}>
                      <p className="fw-semi-bold">{t('account.orders.details.shippingAddress')}</p>
                      <p>
                        {order.shippingAddress?.name} {order.shippingAddress?.surname},
                        <br />
                        {order.shippingAddress?.addressLine1} {order.shippingAddress?.civicNumber}
                        <br />
                        {order.shippingAddress?.zipCode} {order.shippingAddress?.city}
                        <br />
                      </p>
                      <p>
                        {warehouseOrder.shippingProfileDescription}
                        <br />
                      </p>
                      {warehouseOrder.carrierTracking?.carrierTrackingUrl && (
                        <p>
                          <div>
                            <Link
                              className="order-tracking-url"
                              target={'_blank'}
                              to={warehouseOrder.carrierTracking.carrierTrackingUrl}
                            >
                              {t('account.orders.details.trackingCode')}
                            </Link>
                          </div>
                        </p>
                      )}
                    </td>
                    <td className="py-md-4" colSpan={4}>
                      <p className="fw-semi-bold">{t('account.orders.details.paymentAddress')}</p>
                      <p>
                        {order.billingAddress?.name} {order.billingAddress?.surname},
                        <br />
                        {order.billingAddress?.addressLine1} {order.billingAddress?.civicNumber}
                        <br />
                        {order.billingAddress?.zipCode} {order.billingAddress?.city}
                        <br />
                      </p>
                      <p>{order.paymentMethod?.name}</p>
                      <p className="m-0">
                        {order.invoice && order.billingAddress ? (
                          <div>
                            <div>{t('checkout.invoice.requested')}</div>
                            <Div marginTop={2}>
                              <InvoiceSummary address={order.billingAddress} />
                              <div>
                                {order.invoiceFileReady && (
                                  <EmptyLink
                                    className="order-invoice-download"
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onClick={() => {
                                      getInvoiceFile(order.code).then((response) => {
                                        downloadFileFromBlob(response.data, `${order.code}.pdf`, 'application/pdf');
                                      });
                                    }}
                                  >
                                    {t('account.orders.details.invoiceDownload')}
                                  </EmptyLink>
                                )}
                              </div>
                            </Div>
                          </div>
                        ) : (
                          <div>{t('checkout.invoice.notRequested')}</div>
                        )}
                      </p>
                    </td>
                    <td className="py-md-4" colSpan={4}>
                      <p className="fw-semi-bold">{t('account.orders.details.review')}</p>
                      <div className="row justify-content-between">
                        <div className="col-auto">{t('account.orders.details.totalProducts')}</div>
                        <div className="col-auto">{formatCurrency(order.productsCost)}</div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-auto">{t('account.orders.details.totalShipping')}</div>
                        <div className="col-auto">{formatCurrency(order.shippingCost)}</div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="col-auto fw-semi-bold">{t('account.orders.details.totalAmount')}</div>
                        <div className="col-auto fw-semi-bold">{formatCurrency(order.totalCost)}</div>
                      </div>
                      {!order.paid && !order.unrepayable && (
                        <Div marginTop={3}>
                          <RestartPaymentForm order={order} />
                        </Div>
                      )}
                    </td>
                  </tr>
                </TableFoot>
              </Table>
              <Div display={{ md: 'none' }}>
                {warehouseOrder.orderedSkus?.map((orderSku) => (
                  <Div key={`${warehouseOrder.id}-${orderSku.id}`} marginTop={3}>
                    <Row gap={3}>
                      <Col size={4}>
                        {orderSku.sku?.product?.image && (
                          <Image fluid format={144} src={orderSku.sku.product.image.src} />
                        )}
                      </Col>
                      <Col size={8}>
                        <P className="small" fontWeight={700} margin={0}>
                          {orderSku.sku?.product?.name}
                        </P>
                        <P className="small text-dark" margin={0}>
                          {orderSku.sku?.product?.attributes?.map(
                            (attribute) =>
                              attribute.typeCode === 'EQS_COLOR' && (
                                <span key={attribute.typeCode}>
                                  {t('account.orders.details.product')} {attribute.value}
                                </span>
                              )
                          )}
                        </P>
                        <P className="small text-dark" margin={0}>
                          {t('account.orders.details.size')} {orderSku.sku?.name}
                        </P>
                        <P className="small text-dark" margin={0}>
                          {t('account.orders.details.quantity')} {orderSku.quantity}
                        </P>
                      </Col>
                    </Row>
                    <P fontWeight={700} margin={0} marginTop={1} textAlign={'end'}>
                      {formatCurrency(orderSku.price * orderSku.quantity)}
                    </P>
                  </Div>
                ))}
                <Div marginTop={3}>
                  <P className="small" fontWeight={700} margin={0}>
                    {t('account.orders.details.shippingAddress')}
                  </P>
                  <P className="small text-dark" margin={0} marginTop={1}>
                    {order.shippingAddress?.name} {order.shippingAddress?.surname}
                  </P>
                  <P className="small text-dark" margin={0}>
                    {order.shippingAddress?.addressLine1} {order.shippingAddress?.civicNumber}
                  </P>
                  <P className="small text-dark" margin={0}>
                    {order.shippingAddress?.zipCode} {order.shippingAddress?.city}
                  </P>
                  <P className="small" margin={0}>
                    {warehouseOrder.shippingProfileDescription}
                    <br />
                    {warehouseOrder.carrierTracking?.carrierTrackingUrl && (
                      <div className="mt-2">
                        <Link
                          className="order-tracking-url"
                          target={'_blank'}
                          to={warehouseOrder.carrierTracking.carrierTrackingUrl}
                        >
                          {t('account.orders.details.trackingCode')}
                        </Link>
                      </div>
                    )}
                  </P>
                </Div>
                <Div marginTop={3}>
                  <P className="small" fontWeight={700} margin={0}>
                    {t('account.orders.details.paymentAddress')}
                  </P>
                  <P className="small text-dark" margin={0} marginTop={1}>
                    {order.billingAddress?.name} {order.billingAddress?.surname}
                  </P>
                  <P className="small text-dark" margin={0}>
                    {order.billingAddress?.addressLine1} {order.billingAddress?.civicNumber}
                  </P>
                  <P className="small text-dark" margin={0}>
                    {order.billingAddress?.zipCode} {order.billingAddress?.city}
                  </P>
                  <P className="small" margin={0}>
                    {order.paymentMethod?.name}
                  </P>
                </Div>
                <Div marginTop={3}>
                  <P className="small" fontWeight={700} margin={0}>
                    {t('account.orders.details.review')}
                  </P>
                  <Row>
                    <Col size="">
                      <P className="small" margin={0} marginTop={1}>
                        {t('account.orders.details.totalProducts')}
                      </P>
                    </Col>
                    <Col size="auto">
                      <P className="small" margin={0} marginTop={1}>
                        {formatCurrency(order.productsCost)}
                      </P>
                    </Col>
                  </Row>
                  <Row>
                    <Col size="">
                      <P className="small" margin={0}>
                        {t('account.orders.details.totalShipping')}
                      </P>
                    </Col>
                    <Col size="auto">
                      <P className="small" margin={0}>
                        {formatCurrency(order.shippingCost)}
                      </P>
                    </Col>
                  </Row>
                  <Row>
                    <Col size="">
                      <P className="small" fontWeight={700} margin={0}>
                        {t('account.orders.details.totalAmount')}
                      </P>
                    </Col>
                    <Col size="auto">
                      <P className="small" fontWeight={700} margin={0}>
                        {formatCurrency(order.totalCost)}
                      </P>
                    </Col>
                  </Row>
                  {!order.paid && !order.unrepayable && (
                    <Div marginTop={3}>
                      <RestartPaymentForm order={order} />
                    </Div>
                  )}
                </Div>
              </Div>
            </Fragment>
          ),
        })
      )}
    </div>
  );
};
