import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountInfoSubpageA } from './accountSubpagesA/AccountInfoSubpageA';

export interface AccountInfoSubpageProps {}

export type AccountInfoSubpageVariants = 'A';

const AccountInfoSubpageController = (props: PropsWithCustomComponent<AccountInfoSubpageProps>) => (
  <VariantsController<AccountInfoSubpageProps, AccountInfoSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountInfoSubpageA },
      name: 'AccountInfoSubpage',
    }}
  />
);
export { AccountInfoSubpageController as AccountInfoSubpage };
