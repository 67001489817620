import React from 'react';

interface MagnoliaAppContextInterface {}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [MagnoliaAppContextProvider, useMagnoliaAppContext] =
  createContext<MagnoliaAppContextInterface>('MagnoliaAppContext');

interface UseMagnoliaAppContextInitializerProps {}

const useMagnoliaAppContextInitializer = (props: UseMagnoliaAppContextInitializerProps) => {
  const magnoliaMagnoliaAppContext: MagnoliaAppContextInterface = {};

  return {
    MagnoliaAppContextProvider,
    magnoliaMagnoliaAppContext,
  };
};

export interface MagnoliaAppContextProps extends UseMagnoliaAppContextInitializerProps {
  children: React.ReactNode | ((magnoliaMagnoliaAppContext: MagnoliaAppContextInterface) => React.ReactNode);
}

export const MagnoliaAppContext = ({ children, ...otherProps }: MagnoliaAppContextProps) => {
  const magnoliaMagnoliaAppContextInitializer = useMagnoliaAppContextInitializer(otherProps);

  return (
    <magnoliaMagnoliaAppContextInitializer.MagnoliaAppContextProvider
      value={magnoliaMagnoliaAppContextInitializer.magnoliaMagnoliaAppContext}
    >
      {typeof children === 'function'
        ? children(magnoliaMagnoliaAppContextInitializer.magnoliaMagnoliaAppContext)
        : children}
    </magnoliaMagnoliaAppContextInitializer.MagnoliaAppContextProvider>
  );
};
