import { ContentApiDto } from '@b2x/storefront-api-js-client/src/';
import { GetContentOptions } from '@b2x/storefront-api-js-client/src/contents';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useContentsApi } from './api/useContentsApi';
import { appConfig } from './config';
import { storage } from './storage';
import { useStable } from './util';

export interface UseContentOptions extends GetContentOptions {}

export const useContent = <T,>(code?: string, options?: UseContentOptions, config?: ApiRequestConfig) => {
  const [content, setContent] = React.useState<ContentApiDto<T> | undefined>(
    code ? storage.getObject<ContentApiDto<T>>(code) : undefined
  );

  const { getContentByCode } = useContentsApi();

  options = useStable(options);
  config = useStable(config);

  React.useEffect(() => {
    if (!content && code) {
      getContentByCode<T>(code, options, config)
        .then((response) => {
          setContent(response.data);
          if (appConfig.saveResourcesInSession) {
            storage.setObject(code, response.data);
          }
        })
        .catch(() => {
          // pazienza
        });
    }
  }, [code, config, content, getContentByCode, options]);

  return content;
};
