import { useCartSkuBadges, UseCartSkuBadgesProps, UseCartSkuBadgesResult } from './useCartSkuBadges';

export interface CartSkuBadgesProps<ThemeColor> extends UseCartSkuBadgesProps {
  children(props: UseCartSkuBadgesResult<ThemeColor>): React.ReactNode;
}

export const CartSkuBadges = <ThemeColor extends string>({
  children,
  ...otherProps
}: CartSkuBadgesProps<ThemeColor>) => {
  const useCartSkuBadgesResult = useCartSkuBadges<ThemeColor>(otherProps);

  return <>{children(useCartSkuBadgesResult)}</>;
};
