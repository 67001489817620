import classnames from 'classnames';
import React from 'react';

import { renderUI } from './util';

interface StickyProps {
  children: ((props: { isSticky: boolean }) => React.ReactNode) | React.ReactNode;
  className?: string;
  offset?: number;
  position?: 'top' | 'bottom';
  zIndex?: number;
}

export const Sticky = ({ children, className, offset = 0, position = 'top', zIndex }: StickyProps) => {
  const [isSticky, setIsSticky] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(entry.intersectionRatio < 1);
      },
      {
        threshold: [1],
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  const hack = 1;

  return renderUI({
    bs5: (
      <div
        className={classnames(className, `sticky-${position}`, { sticky: isSticky })}
        ref={ref}
        style={{
          marginTop: -hack,
          paddingTop: position === 'top' ? hack : undefined,
          top: position === 'top' ? offset - hack : undefined,
          zIndex: zIndex,
        }}
      >
        {typeof children === 'function' ? children({ isSticky }) : children}
      </div>
    ),
  });
};
