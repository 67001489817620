import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { NavigateOptions as _NavigateOptions, To, useLocation, useNavigate as _useNavigate } from 'react-router-dom-6';

import { useAppStaticContext } from '../AppContext';
import { scrollTop as _scrollTop } from '../util';

interface NavigateOptions extends _NavigateOptions {
  preferFrom?: boolean;
  scrollTop?: boolean;
  silent?: boolean;
}

export const useNavigate = () => {
  const navigate = _useNavigate();
  const location = useLocation();

  /*
    Rendo stabile la useNavigate().
    In caso di problemi togliere questa cosa ma ricordasi di togliere useNavigate() in useApiRequest()
    (redirect in home se le API mi rispondono con api.error.usernotlogged)
  */
  const navigateRef = React.useRef(navigate);
  navigateRef.current = navigate;
  const navigateStable = React.useCallback((to: To, options?: NavigateOptions) => navigateRef.current(to, options), []);

  const { setPageReady } = useAppStaticContext();

  const navigateFunction = React.useCallback(
    (to: To, options?: NavigateOptions) => {
      const { preferFrom, scrollTop = true, silent } = options ?? {};

      const navigateTo = preferFrom && location.state?.from ? location.state.from : to;
      const navigateOptions: _NavigateOptions = {
        state: { from: location.pathname + location.search, ...options?.state },
        ...options,
      };
      navigateStable(navigateTo, navigateOptions);

      !silent && setPageReady(false);
      scrollTop && _scrollTop();
    },
    [navigateStable, setPageReady, location]
  );

  return navigateFunction;
};
