import { ImageApiDto } from '@b2x/storefront-api-js-client/src';

import { Image } from './Image';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface CustomRadioCheckProps {
  color?: string;
  image?: ImageApiDto;
  label?: string;
}

const CustomRadioCheck = ({ color, image, label }: CustomRadioCheckProps) => {
  return (
    <div className="custom-radio-check">
      {image && <Image {...image} />} {label}
    </div>
  );
};

export type CustomRadioCheckVariants = '';

const CustomRadioCheckController = (props: PropsWithCustomComponent<CustomRadioCheckProps>) => (
  <VariantsController<CustomRadioCheckProps, CustomRadioCheckVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CustomRadioCheck,
      name: 'CustomRadioCheck',
    }}
  />
);
export { CustomRadioCheckController as CustomRadioCheck };
