import React from 'react';

export const useRerenderer = () => {
  const [count, setCount] = React.useState<number>(0);

  const rerender = React.useCallback(() => {
    setCount((prevState) => prevState + 1);
  }, []);

  return { count, rerender };
};
