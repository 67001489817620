import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Button } from '../Button';
import { Col } from '../Col';
import { appConfig } from '../config';
import { Dropdown } from '../Dropdown';
import { t } from '../i18n/i18n';
import { UseFormModalProps } from '../Modal';
import { Row } from '../Row';
import { SpeechRecognition } from '../SpeechRecognition';
import { useGooglePlacesAutocomplete } from '../useGooglePlacesAutocomplete';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { formatDateForInput, formatDateTimeForInput, prettyStringify } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { FieldArray, FieldArrayProps } from './FieldArray';
import {
  DateInput,
  DateInputProps,
  DateTimeInput,
  DateTimeInputProps,
  FileInput,
  FileInputProps,
  FileUploaderInput,
  FileUploaderInputProps,
  ImageUploaderInput,
  ImageUploaderInputProps,
  NumberInput,
  NumberInputProps,
  PasswordInput,
  PasswordInputProps,
  TextInput,
  TextInputProps,
  TimeInput,
  TimeInputProps,
} from './fields/Input';
import { Checkboxes, CheckboxesProps, Radios, RadiosProps } from './fields/RadioCheck';
import { Select, SelectProps } from './fields/Select';
import { TextArea, TextAreaProps } from './fields/TextArea';
import {
  FormButtonProps,
  FormDropdownProps,
  formikBoolean,
  formikDate,
  formikDateTime,
  formikFile,
  formikNumber,
  formikString,
  getInitialBoolean,
  getInitialDate,
  getInitialDateTime,
  getInitialNumber,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FakeFormGroupForVerticalSpacing, FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { InputGroup, InputGroupProps } from './InputGroup';
import { InputGroupText, InputGroupTextProps } from './InputGroupText';

export interface ExampleFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {}

interface FormValues {
  businessEmailDomain: formikString;
  businessEmailName: formikString;
  checkbox1: formikBoolean;
  checkbox2: formikBoolean;
  checkbox3: formikBoolean;
  checkbox4: formikBoolean;
  checkbox5: formikBoolean;
  checkbox6: formikBoolean;
  checkboxes: Array<formikString>;
  confirmNewPassword: formikString;
  currentPassword: formikString;
  date: formikDate;
  dateTime: formikDateTime;
  email: formikString;
  file: formikFile;
  fileUploader: formikString;
  fixedBusinessEmail: formikString;
  googlePlacesAutocomplete: formikString;
  imageUploader: formikString;
  newPassword: formikString;
  number: formikNumber;
  persons: Array<PersonFormValues>;
  radios: formikString;
  radiosInline: formikString;
  salary: formikNumber;
  select: formikString;
  speechRecognition: formikString;
  text: formikString;
  textarea: formikString;
  texts: Array<formikString>;
  time: formikString;
  url: formikString;
}

interface PersonFormValues {
  name: formikString;
  surname: formikString;
}

type ValidationSchema = {
  businessEmailDomain: yup.StringSchema;
  businessEmailName: yup.StringSchema;
  checkbox1: yup.BooleanSchema;
  checkbox2: yup.BooleanSchema;
  checkbox3: yup.BooleanSchema;
  checkbox4: yup.BooleanSchema;
  checkbox5: yup.BooleanSchema;
  checkbox6: yup.BooleanSchema;
  checkboxes: yup.ArraySchema<yup.StringSchema>;
  confirmNewPassword: yup.StringSchema;
  currentPassword: yup.StringSchema;
  date: yup.DateSchema;
  dateTime: yup.DateSchema;
  email: yup.StringSchema;
  file: yup.StringSchema;
  fileUploader: yup.StringSchema;
  fixedBusinessEmail: yup.StringSchema;
  googlePlacesAutocomplete: yup.StringSchema;
  imageUploader: yup.StringSchema;
  newPassword: yup.StringSchema;
  number: yup.NumberSchema;
  persons: yup.ArraySchema<
    yup.ObjectSchema<{
      name: yup.StringSchema;
      surname: yup.StringSchema;
    }>
  >;
  radios: yup.StringSchema;
  radiosInline: yup.StringSchema;
  salary: yup.NumberSchema;
  select: yup.StringSchema;
  speechRecognition: yup.StringSchema;
  text: yup.StringSchema;
  textarea: yup.StringSchema;
  texts: yup.ArraySchema<yup.StringSchema>;
  time: yup.StringSchema;
  url: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  businessEmail: {
    domain: {
      textInput: TextInputProps;
    };
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    inputGroupText: InputGroupTextProps;
    name: {
      textInput: TextInputProps;
    };
  };
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  checkboxes: { checkboxes: CheckboxesProps; formGroup: FormGroupProps };
  checkboxesArray: { checkboxes: CheckboxesProps; formGroup: FormGroupProps };
  checkboxesInline: { checkboxes: CheckboxesProps; formGroup: FormGroupProps };
  confirmNewPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  currentPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  date: { dateInput: DateInputProps; formGroup: FormGroupProps };
  dateTime: { dateTimeInput: DateTimeInputProps; formGroup: FormGroupProps };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
  file: { fileInput: FileInputProps; formGroup: FormGroupProps };
  fileUploader: { fileUploaderInput: FileUploaderInputProps; formGroup: FormGroupProps };
  fixedBusinessEmail: {
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    inputGroupText: InputGroupTextProps;
    textInput: TextInputProps;
  };
  googlePlacesAutocomplete: { formGroup: FormGroupProps; textInput: TextInputProps };
  imageUploader: { formGroup: FormGroupProps; imageUploaderInput: ImageUploaderInputProps };
  newPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  number: { formGroup: FormGroupProps; numberInput: NumberInputProps };
  persons: {
    addButton: FormButtonProps;
    fieldArray: Omit<FieldArrayProps<PersonFormValues>, 'children'>;
    fields: Array<{
      name: { formGroup: FormGroupProps; textInput: TextInputProps };
      removeButton: FormButtonProps;
      surname: { formGroup: FormGroupProps; textInput: TextInputProps };
    }>;
    formGroup: FormGroupProps;
  };
  radios: { formGroup: FormGroupProps; radios: RadiosProps };
  radiosInline: { formGroup: FormGroupProps; radios: RadiosProps };
  salary: {
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    inputGroupText1: InputGroupTextProps;
    inputGroupText2: InputGroupTextProps;
    numberInput: NumberInputProps;
  };
  select: { formGroup: FormGroupProps; select: SelectProps };
  selectDefault: { formGroup: FormGroupProps; select: SelectProps };
  selectLarge: { formGroup: FormGroupProps; select: SelectProps };
  selectSmall: { formGroup: FormGroupProps; select: SelectProps };
  speechRecognition: {
    button: FormButtonProps;
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    textInput: TextInputProps;
  };
  text: { formGroup: FormGroupProps; textInput: TextInputProps };
  textDefault: { formGroup: FormGroupProps; textInput: TextInputProps };
  textLarge: { formGroup: FormGroupProps; textInput: TextInputProps };
  textSmall: { formGroup: FormGroupProps; textInput: TextInputProps };
  textWithDropdown: {
    dropdown: FormDropdownProps;
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    textInput: TextInputProps;
  };
  textWithLeftAndRightButtons: {
    button1: FormButtonProps;
    button2: FormButtonProps;
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    textInput: TextInputProps;
  };
  textWithLeftButton: {
    button: FormButtonProps;
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    textInput: TextInputProps;
  };
  textWithRightButton: {
    button: FormButtonProps;
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    textInput: TextInputProps;
  };
  textarea: { formGroup: FormGroupProps; textArea: TextAreaProps };
  texts: {
    addButton: FormButtonProps;
    fieldArray: Omit<FieldArrayProps<formikString>, 'children'>;
    fields: Array<{
      formGroup: FormGroupProps;
      removeButton: FormButtonProps;
      textInput: TextInputProps;
    }>;
    formGroup: FormGroupProps;
  };
  time: { formGroup: FormGroupProps; timeInput: TimeInputProps };
  url: {
    formGroup: FormGroupProps;
    inputGroup: InputGroupProps;
    inputGroupText: InputGroupTextProps;
    textInput: TextInputProps;
  };
}

export const ExampleFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: ExampleFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      businessEmailDomain: getInitialString(),
      businessEmailName: getInitialString(),
      checkbox1: getInitialBoolean(),
      checkbox2: getInitialBoolean(),
      checkbox3: getInitialBoolean(),
      checkbox4: getInitialBoolean(),
      checkbox5: getInitialBoolean(),
      checkbox6: getInitialBoolean(),
      checkboxes: [],
      confirmNewPassword: getInitialString(),
      currentPassword: getInitialString(),
      date: getInitialDate(),
      dateTime: getInitialDateTime(),
      email: getInitialString(),
      file: getInitialString(),
      fileUploader: getInitialString(),
      fixedBusinessEmail: getInitialString(),
      googlePlacesAutocomplete: getInitialString(),
      imageUploader: getInitialString(),
      newPassword: getInitialString(),
      number: getInitialNumber(),
      persons: [
        {
          name: getInitialString(),
          surname: getInitialString(),
        },
      ],
      radios: getInitialString(),
      radiosInline: getInitialString(),
      salary: getInitialNumber(),
      select: getInitialString(),
      speechRecognition: getInitialString(),
      text: getInitialString(),
      textarea: getInitialString(),
      texts: [getInitialString()],
      time: getInitialString(),
      url: getInitialString(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      businessEmailDomain: yup.string().required(),
      businessEmailName: yup.string().required(),
      checkbox1: yup.boolean().required().oneOf([true]),
      checkbox2: yup.boolean().required(),
      checkbox3: yup.boolean().required(),
      checkbox4: yup.boolean().required().oneOf([true]),
      checkbox5: yup.boolean().required(),
      checkbox6: yup.boolean().required(),
      checkboxes: yup.array().min(1).of(yup.string()),
      confirmNewPassword: yup
        .string()
        .required()
        .oneOf([yup.ref('newPassword'), null]),
      currentPassword: yup.string().required(),
      date: yup.date().required(),
      dateTime: yup.date().required(),
      email: yup.string().required().email(),
      file: yup.string().required(),
      fileUploader: yup.string().required(),
      fixedBusinessEmail: yup.string().required(),
      googlePlacesAutocomplete: yup.string().required(),
      imageUploader: yup.string().required(),
      newPassword: yup
        .string()
        .required()
        .notOneOf([yup.ref('currentPassword'), null]),
      number: yup.number().required().min(50),
      persons: yup
        .array()
        .max(10)
        .of(
          yup.object({
            name: yup.string().required(),
            surname: yup.string().required(),
          })
        ),
      radios: yup.string().required(),
      radiosInline: yup.string().required(),
      salary: yup.number().required(),
      select: yup.string().required(),
      speechRecognition: yup.string().required(),
      text: yup.string().required(),
      textarea: yup.string().required(),
      texts: yup.array().max(10).of(yup.string().required()),
      time: yup.string().required(),
      url: yup.string().required(),
    }),
    []
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      console.log(values);
      alert(JSON.stringify(values, null, 2));
      return Promise.resolve().then(() => {
        onSuccess && onSuccess();
      });
    },
    [onSuccess]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();
  const { showModal } = useModals();

  const [googlePlacesAutocompleteRef] = useGooglePlacesAutocomplete({
    componentRestrictions: { country: 'it' },
    fields: ['geometry', 'formatted_address'],
    onPlaceChanged: (placeResult) => {
      if (placeResult.geometry?.location) {
        showModal({ children: prettyStringify(placeResult), title: 'Google places autocomplete' });
      }
    },
  });

  return (
    <HelpedForm<FormValues>
      className={classnames('ExampleForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          businessEmail: {
            domain: {
              textInput: { name: 'businessEmailDomain' },
            },
            formGroup: {
              label: 'Business email',
              names: ['businessEmailName', 'businessEmailDomain'],
              omitForAttribute: true,
            },
            inputGroup: { names: ['businessEmailName', 'businessEmailDomain'] },
            inputGroupText: { children: '@' },
            name: {
              textInput: { name: 'businessEmailName' },
            },
          },
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: 'Reset',
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: 'Submit',
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          checkboxes: {
            checkboxes: {
              checkboxes: [
                {
                  id: 'checkbox1',
                  label: 'Checkbox 1 (required)',
                  name: 'checkbox1',
                },
                { id: 'checkbox2', label: 'Checkbox 2', name: 'checkbox2' },
                { disabled: true, id: 'checkbox3', label: 'Checkbox 3', name: 'checkbox3' },
              ],
            },
            formGroup: { label: 'Checkboxes', names: [], omitForAttribute: true },
          },
          checkboxesArray: {
            checkboxes: {
              checkboxes: [
                { id: 'checkboxes1', label: 'Checkbox 1', name: 'checkboxes', value: 'checkboxes1' },
                { id: 'checkboxes2', label: 'Checkbox 2', name: 'checkboxes', value: 'checkboxes2' },
                { disabled: true, id: 'checkboxes3', label: 'Checkbox 3', name: 'checkboxes', value: 'checkboxes3' },
              ],
            },
            formGroup: { label: 'Checkboxes (array)', names: ['checkboxes'], omitForAttribute: true },
          },
          checkboxesInline: {
            checkboxes: {
              checkboxes: [
                { id: 'checkbox4', inline: true, label: 'Checkbox 4 (required)', name: 'checkbox4' },
                { id: 'checkbox5', inline: true, label: 'Checkbox 5', name: 'checkbox5' },
                { disabled: true, id: 'checkbox6', inline: true, label: 'Checkbox 6', name: 'checkbox6' },
              ],
            },
            formGroup: { label: 'Checkboxes (inline)', names: [], omitForAttribute: true },
          },
          confirmNewPassword: {
            formGroup: { label: 'Confirm new password', names: ['confirmNewPassword'] },
            passwordInput: { autoComplete: 'new-password', name: 'confirmNewPassword' },
          },
          currentPassword: {
            formGroup: { label: 'Current password', names: ['currentPassword'] },
            passwordInput: { autoComplete: 'current-password', name: 'currentPassword' },
          },
          date: {
            dateInput: { min: formatDateForInput(new Date()), name: 'date', step: 2 },
            formGroup: { label: 'Date', names: ['date'] },
          },
          dateTime: {
            dateTimeInput: { min: formatDateTimeForInput(new Date()), name: 'dateTime', step: 2 },
            formGroup: { label: 'Date time', names: ['dateTime'] },
          },
          email: {
            formGroup: { label: 'Email', names: ['email'] },
            textInput: { name: 'email', type: 'email' },
          },
          file: {
            fileInput: {
              name: 'file',
            },
            formGroup: { label: 'File', names: ['file'] },
          },
          fileUploader: {
            fileUploaderInput: {
              name: 'fileUploader',
            },
            formGroup: { label: 'File uploader', names: ['fileUploader'] },
          },
          fixedBusinessEmail: {
            formGroup: { label: 'Fixed Business email', names: ['fixedBusinessEmail'] },
            inputGroup: { names: ['fixedBusinessEmail'] },
            inputGroupText: { children: '@b2x.it' },
            textInput: { name: 'fixedBusinessEmail' },
          },
          googlePlacesAutocomplete: {
            formGroup: { label: 'Google places autocomplete', names: ['googlePlacesAutocomplete'] },
            textInput: {
              innerRef: googlePlacesAutocompleteRef,
              name: 'googlePlacesAutocomplete',
            },
          },
          imageUploader: {
            formGroup: { label: 'Image uploader', names: ['imageUploader'] },
            imageUploaderInput: {
              name: 'imageUploader',
            },
          },
          newPassword: {
            formGroup: { label: 'New password', names: ['newPassword'] },
            passwordInput: { autoComplete: 'new-password', name: 'newPassword' },
          },
          number: {
            formGroup: { label: 'Number (min 50)', names: ['number'] },
            numberInput: {
              max: 100,
              min: 0,
              name: 'number',
              step: 10,
            },
          },
          persons: {
            addButton: { label: 'Add person' },
            fieldArray: {
              items: formik.values.persons,
              max: 5,
              min: 1,
              name: 'persons',
            },
            fields: formik.values.persons.map((person, index) => ({
              name: {
                formGroup: { label: 'Name', name: `persons.${index}.name`, names: [`persons.${index}.name`] },
                textInput: { name: `persons.${index}.name` },
              },
              removeButton: { label: 'Remove person' },
              surname: {
                formGroup: { label: 'Surname', names: [`persons.${index}.surname`] },
                textInput: { name: `persons.${index}.surname` },
              },
            })),
            formGroup: {
              label: `Persons (min: ${1}, max: ${5}, current: ${formik.values.persons.length})`,
              names: ['persons'],
            },
          },
          radios: {
            formGroup: { label: 'Radios', names: ['radios'], omitForAttribute: true },
            radios: {
              radios: [
                { id: 'radio1', label: 'Radio 1', name: 'radios', value: 'radio1' },
                { id: 'radio2', label: 'Radio 2', name: 'radios', value: 'radio2' },
                { disabled: true, id: 'radio3', label: 'Radio 3', name: 'radios', value: 'radio3' },
              ],
            },
          },
          radiosInline: {
            formGroup: { label: 'Radios (inline)', names: ['radiosInline'], omitForAttribute: true },
            radios: {
              radios: [
                { id: 'radio4', inline: true, label: 'Radio 4', name: 'radiosInline', value: 'radio4' },
                { id: 'radio5', inline: true, label: 'Radio 5', name: 'radiosInline', value: 'radio5' },
                { disabled: true, id: 'radio6', inline: true, label: 'Radio 6', name: 'radiosInline', value: 'radio6' },
              ],
            },
          },
          salary: {
            formGroup: { label: 'Salary', names: ['salary'] },
            inputGroup: { names: ['salary'] },
            inputGroupText1: { children: '€' },
            inputGroupText2: { children: '.00' },
            numberInput: { name: 'salary', step: 100 },
          },
          select: {
            formGroup: { label: 'Select', names: ['select'] },
            select: {
              name: 'select',
              options: [
                { label: 'Opzione 1', value: 'opzione1' },
                { value: 'opzione2' },
                { label: 'Opzione 3', value: 'opzione3' },
              ],
              placeholder: 'Seleziona una delle opzioni',
            },
          },
          selectDefault: {
            formGroup: { names: ['select'] },
            select: {
              name: 'select',
              options: [
                { label: 'Opzione 1', value: 'opzione1' },
                { value: 'opzione2' },
                { label: 'Opzione 3', value: 'opzione3' },
              ],
              placeholder: 'Seleziona una delle opzioni',
            },
          },
          selectLarge: {
            formGroup: { names: ['select'] },
            select: {
              name: 'select',
              options: [
                { label: 'Opzione 1', value: 'opzione1' },
                { value: 'opzione2' },
                { label: 'Opzione 3', value: 'opzione3' },
              ],
              placeholder: 'Seleziona una delle opzioni',
              size: 'large',
            },
          },
          selectSmall: {
            formGroup: { label: 'Select sizes', names: ['select'] },
            select: {
              name: 'select',
              options: [
                { label: 'Opzione 1', value: 'opzione1' },
                { value: 'opzione2' },
                { label: 'Opzione 3', value: 'opzione3' },
              ],
              placeholder: 'Seleziona una delle opzioni',
              size: 'small',
            },
          },
          speechRecognition: {
            button: {
              type: 'button',
            },
            formGroup: { label: 'Speech recognition', names: ['speechRecognition'] },
            inputGroup: { names: ['speechRecognition'] },
            textInput: { name: 'speechRecognition' },
          },
          text: {
            formGroup: { label: 'Text', names: ['text'] },
            textInput: {
              name: 'text',
            },
          },
          textDefault: {
            formGroup: { names: ['text'] },
            textInput: { name: 'text', placeholder: 'default' },
          },
          textLarge: {
            formGroup: { names: ['text'] },
            textInput: { name: 'text', placeholder: 'large', size: 'large' },
          },
          textSmall: {
            formGroup: { label: 'Input sizes', names: ['text'] },
            textInput: { name: 'text', placeholder: 'small', size: 'small' },
          },
          textWithDropdown: {
            dropdown: { items: [{ children: 'Action 1' }, { children: 'Action 2' }], label: 'Button' },
            formGroup: { label: 'Text with dropdown', names: ['text'] },
            inputGroup: { names: ['text'] },
            textInput: { name: 'text' },
          },
          textWithLeftAndRightButtons: {
            button1: { label: 'Button', type: 'button' },
            button2: { label: 'Button', type: 'button' },
            formGroup: { label: 'Text with left and right buttons', names: ['text'] },
            inputGroup: { names: ['text'] },
            textInput: { name: 'text' },
          },
          textWithLeftButton: {
            button: { label: 'Button', type: 'button', variant: 'light' },
            formGroup: { label: 'Text with left button', names: ['text'] },
            inputGroup: { names: ['text'] },
            textInput: { name: 'text' },
          },
          textWithRightButton: {
            button: { label: 'Button', type: 'button' },
            formGroup: { label: 'Text with right button', names: ['text'] },
            inputGroup: { names: ['text'] },
            textInput: { name: 'text' },
          },
          textarea: {
            formGroup: { label: 'Textarea', names: ['textarea'] },
            textArea: { name: 'textarea', rows: 3 },
          },
          texts: {
            addButton: { label: 'Add text' },
            fieldArray: {
              items: formik.values.texts,
              max: 5,
              min: 1,
              name: 'texts',
            },
            fields: formik.values.texts.map((text, index) => ({
              formGroup: { names: ['texts'] },
              removeButton: { label: 'Remove text' },
              textInput: { name: `texts.${index}` },
            })),
            formGroup: {
              label: `Texts (min: ${1}, max: ${5}, current: ${formik.values.texts.length})`,
              names: ['texts'],
            },
          },
          time: {
            formGroup: { label: 'Time', names: ['time'] },
            timeInput: { name: 'time', step: 2 },
          },
          url: {
            formGroup: { label: 'Url', names: ['url'] },
            inputGroup: { names: ['url'] },
            inputGroupText: { children: 'https://' },
            textInput: { name: 'url' },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <Row>
            <Col className="mb-3 mb-md-0" size={{ md: 6, xs: 12 }}>
              <Row>
                <Col size={{ md: 6, xs: 12 }}>
                  <FormGroup {...fieldsHelper.text.formGroup}>
                    <TextInput {...fieldsHelper.text.textInput} />
                  </FormGroup>
                </Col>
                <Col size={{ md: 6, xs: 12 }}>
                  <FormGroup {...fieldsHelper.email.formGroup}>
                    <TextInput {...fieldsHelper.email.textInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col size={{ md: 4, xs: 12 }}>
                  <FormGroup {...fieldsHelper.currentPassword.formGroup}>
                    <PasswordInput {...fieldsHelper.currentPassword.passwordInput} />
                  </FormGroup>
                </Col>
                <Col size={{ md: 4, xs: 12 }}>
                  <FormGroup {...fieldsHelper.newPassword.formGroup}>
                    <PasswordInput {...fieldsHelper.newPassword.passwordInput} />
                  </FormGroup>
                </Col>
                <Col size={{ md: 4, xs: 12 }}>
                  <FormGroup {...fieldsHelper.confirmNewPassword.formGroup}>
                    <PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col size={{ md: 4, xs: 12 }}>
                  <FormGroup {...fieldsHelper.number.formGroup}>
                    <NumberInput {...fieldsHelper.number.numberInput} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col size={{ md: 4, xs: 12 }}>
                  <FormGroup {...fieldsHelper.date.formGroup}>
                    <DateInput {...fieldsHelper.date.dateInput} />
                  </FormGroup>
                </Col>
                <Col size={{ md: 4, xs: 12 }}>
                  <FormGroup {...fieldsHelper.time.formGroup}>
                    <TimeInput {...fieldsHelper.time.timeInput} />
                  </FormGroup>
                </Col>
                <Col size={{ md: 4, xs: 12 }}>
                  <FormGroup {...fieldsHelper.dateTime.formGroup}>
                    <DateTimeInput {...fieldsHelper.dateTime.dateTimeInput} />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup {...fieldsHelper.select.formGroup}>
                <Select {...fieldsHelper.select.select} />
              </FormGroup>
              <FormGroup {...fieldsHelper.textarea.formGroup}>
                <TextArea {...fieldsHelper.textarea.textArea} />
              </FormGroup>
              <FormGroup {...fieldsHelper.file.formGroup}>
                <FileInput {...fieldsHelper.file.fileInput} />
              </FormGroup>
              <FormGroup {...fieldsHelper.fileUploader.formGroup}>
                <FileUploaderInput {...fieldsHelper.fileUploader.fileUploaderInput} />
              </FormGroup>
              <FormGroup {...fieldsHelper.imageUploader.formGroup}>
                <ImageUploaderInput {...fieldsHelper.imageUploader.imageUploaderInput} />
              </FormGroup>
              <FormGroup {...fieldsHelper.checkboxes.formGroup}>
                <Checkboxes {...fieldsHelper.checkboxes.checkboxes} />
              </FormGroup>
              <FormGroup {...fieldsHelper.checkboxesInline.formGroup}>
                <Checkboxes {...fieldsHelper.checkboxesInline.checkboxes} />
              </FormGroup>
              <FormGroup {...fieldsHelper.checkboxesArray.formGroup}>
                <Checkboxes {...fieldsHelper.checkboxesArray.checkboxes} />
              </FormGroup>
              <FormGroup {...fieldsHelper.radios.formGroup}>
                <Radios {...fieldsHelper.radios.radios} />
              </FormGroup>
              <FormGroup {...fieldsHelper.radiosInline.formGroup}>
                <Radios {...fieldsHelper.radiosInline.radios} />
              </FormGroup>
              <hr />
              <FormGroup {...fieldsHelper.url.formGroup}>
                <InputGroup {...fieldsHelper.url.inputGroup}>
                  <InputGroupText {...fieldsHelper.url.inputGroupText} />
                  <TextInput {...fieldsHelper.url.textInput} />
                </InputGroup>
              </FormGroup>
              <FormGroup {...fieldsHelper.salary.formGroup}>
                <InputGroup {...fieldsHelper.salary.inputGroup}>
                  <InputGroupText {...fieldsHelper.salary.inputGroupText1} />
                  <NumberInput {...fieldsHelper.salary.numberInput} />
                  <InputGroupText {...fieldsHelper.salary.inputGroupText1} />
                </InputGroup>
              </FormGroup>
              <FormGroup {...fieldsHelper.fixedBusinessEmail.formGroup}>
                <InputGroup {...fieldsHelper.fixedBusinessEmail.inputGroup}>
                  <TextInput {...fieldsHelper.fixedBusinessEmail.textInput} />
                  <InputGroupText {...fieldsHelper.fixedBusinessEmail.inputGroupText} />
                </InputGroup>
              </FormGroup>
              <FormGroup {...fieldsHelper.businessEmail.formGroup}>
                <InputGroup {...fieldsHelper.businessEmail.inputGroup}>
                  <TextInput {...fieldsHelper.businessEmail.name.textInput} />
                  <InputGroupText {...fieldsHelper.businessEmail.inputGroupText} />
                  <TextInput {...fieldsHelper.businessEmail.domain.textInput} />
                </InputGroup>
              </FormGroup>
              <FormGroup {...fieldsHelper.textWithLeftButton.formGroup}>
                <InputGroup {...fieldsHelper.textWithLeftButton.inputGroup}>
                  <Button {...fieldsHelper.textWithLeftButton.button} />
                  <TextInput {...fieldsHelper.textWithLeftButton.textInput} />
                </InputGroup>
              </FormGroup>
              <FormGroup {...fieldsHelper.textWithRightButton.formGroup}>
                <InputGroup {...fieldsHelper.textWithRightButton.inputGroup}>
                  <TextInput {...fieldsHelper.textWithRightButton.textInput} />
                  <Button {...fieldsHelper.textWithRightButton.button} />
                </InputGroup>
              </FormGroup>
              <FormGroup {...fieldsHelper.textWithLeftAndRightButtons.formGroup}>
                <InputGroup {...fieldsHelper.textWithLeftAndRightButtons.inputGroup}>
                  <Button {...fieldsHelper.textWithLeftAndRightButtons.button1} />
                  <TextInput {...fieldsHelper.textWithLeftAndRightButtons.textInput} />
                  <Button {...fieldsHelper.textWithLeftAndRightButtons.button2} />
                </InputGroup>
              </FormGroup>
              <FormGroup {...fieldsHelper.textWithDropdown.formGroup}>
                <InputGroup {...fieldsHelper.textWithDropdown.inputGroup}>
                  <TextInput {...fieldsHelper.textWithDropdown.textInput} />
                  <Dropdown {...fieldsHelper.textWithDropdown.dropdown} />
                </InputGroup>
              </FormGroup>
              <hr />
              <div className="row">
                <div className="col">
                  <FormGroup {...fieldsHelper.textSmall.formGroup}>
                    <TextInput {...fieldsHelper.textSmall.textInput} />
                  </FormGroup>
                  <FormGroup {...fieldsHelper.textDefault.formGroup}>
                    <TextInput {...fieldsHelper.textDefault.textInput} />
                  </FormGroup>
                  <FormGroup {...fieldsHelper.textLarge.formGroup}>
                    <TextInput {...fieldsHelper.textLarge.textInput} />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup {...fieldsHelper.selectSmall.formGroup}>
                    <Select {...fieldsHelper.selectSmall.select} />
                  </FormGroup>
                  <FormGroup {...fieldsHelper.selectDefault.formGroup}>
                    <Select {...fieldsHelper.selectDefault.select} />
                  </FormGroup>
                  <FormGroup {...fieldsHelper.selectLarge.formGroup}>
                    <Select {...fieldsHelper.selectLarge.select} />
                  </FormGroup>
                </div>
              </div>
              <hr />
              <FormGroup {...fieldsHelper.googlePlacesAutocomplete.formGroup}>
                <TextInput {...fieldsHelper.googlePlacesAutocomplete.textInput} />
              </FormGroup>
              <hr />
              <FormGroup {...fieldsHelper.speechRecognition.formGroup}>
                <InputGroup {...fieldsHelper.speechRecognition.inputGroup}>
                  <SpeechRecognition
                    // eslint-disable-next-line react/jsx-no-bind
                    onResult={(result) => {
                      formik.setFieldValue('speechRecognition', result);
                    }}
                  >
                    {({ listening, supported, toggle }) => (
                      <>
                        <TextInput {...fieldsHelper.speechRecognition.textInput} />
                        <Button
                          {...fieldsHelper.speechRecognition.button}
                          disabled={!supported}
                          label={listening ? 'Stop' : 'Start'}
                          onClick={toggle}
                        />
                      </>
                    )}
                  </SpeechRecognition>
                </InputGroup>
              </FormGroup>
              <hr />
              <FieldArray<string> {...fieldsHelper.texts.fieldArray}>
                {(arrayHelpers) => (
                  <FormGroup {...fieldsHelper.texts.formGroup}>
                    {fieldsHelper.texts.fields.map(({ formGroup, removeButton, textInput }, index) => (
                      <Row key={textInput.name}>
                        <Col>
                          <FormGroup {...formGroup}>
                            <TextInput {...textInput} />
                          </FormGroup>
                        </Col>
                        <Col>
                          <Button
                            {...removeButton}
                            disabled={arrayHelpers.removeDisabled}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          />
                        </Col>
                      </Row>
                    ))}
                    <p>
                      <Button
                        {...fieldsHelper.texts.addButton}
                        disabled={arrayHelpers.pushDisabled}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => {
                          arrayHelpers.push(getInitialString());
                        }}
                      />
                    </p>
                  </FormGroup>
                )}
              </FieldArray>
              <FieldArray<PersonFormValues> {...fieldsHelper.persons.fieldArray}>
                {(arrayHelpers) => (
                  <FormGroup {...fieldsHelper.persons.formGroup}>
                    {fieldsHelper.persons.fields.map(({ name, removeButton, surname }, index) => (
                      <Row key={name.textInput.name}>
                        <Col size={{ sm: 4, xs: 12 }}>
                          <FormGroup {...name.formGroup}>
                            <TextInput {...name.textInput} />
                          </FormGroup>
                        </Col>
                        <Col size={{ sm: 4, xs: 12 }}>
                          <FormGroup {...surname.formGroup}>
                            <TextInput {...surname.textInput} />
                          </FormGroup>
                        </Col>
                        <Col size={{ sm: 4, xs: 12 }}>
                          <FakeFormGroupForVerticalSpacing className="d-none d-sm-block">
                            <Button
                              {...removeButton}
                              disabled={arrayHelpers.removeDisabled}
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          </FakeFormGroupForVerticalSpacing>
                        </Col>
                      </Row>
                    ))}
                    <p>
                      <Button
                        {...fieldsHelper.persons.addButton}
                        disabled={arrayHelpers.pushDisabled}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => {
                          arrayHelpers.push({ name: getInitialString(), surname: getInitialString() });
                        }}
                      />
                    </p>
                  </FormGroup>
                )}
              </FieldArray>
              <Button {...fieldsHelper.buttons.reset} />
              <Button {...fieldsHelper.buttons.submit} />
            </Col>
            <Col size={{ md: 6, xs: 12 }}>
              <aside>
                <pre>
                  <code>{prettyStringify(formik, { collapsed: 1 })}</code>
                </pre>
              </aside>
            </Col>
          </Row>
        );
      }}
    </HelpedForm>
  );
};

export type ExampleFormVariants = '';

const ExampleFormController = (props: PropsWithCustomComponentWithoutChildren<ExampleFormProps>) => (
  <VariantsController<ExampleFormProps, ExampleFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ExampleFormHelper,
      name: 'ExampleForm',
    }}
  />
);
export { ExampleFormController as ExampleForm };

export const useExampleFormModal = (modalProps?: UseFormModalProps) => {
  const { showModal } = useModals();
  return React.useCallback(
    (formProps: ExampleFormProps) => {
      showModal({
        children: <ExampleFormController {...formProps} />,
        fullscreen: 'sm',
        size: 'extra-large',
        title: t('form.exampleForm.modal.title'),
        ...modalProps,
      });
    },
    [modalProps, showModal]
  );
};
