import { Col } from '../Col';
import { NumberInput } from '../form/fields/Input';
import { Checkbox } from '../form/fields/RadioCheck';
import {
  Form,
  formikBoolean,
  formikNumber,
  getFormikBooleanValue,
  getFormikNumberValue,
  getInitialBoolean,
  getInitialNumber,
} from '../form/Form';
import { FormGroup } from '../form/FormGroup';
import { Pagination } from '../Pagination';
import { Row } from '../Row';

export interface PaginationsProps {}

export const Paginations = (props: PaginationsProps) => {
  return (
    <Form<{
      currentPage: formikNumber;
      fullStepNavigation: formikBoolean;
      pageOffset: formikNumber;
      showDots: formikBoolean;
      showSummary: formikBoolean;
      sigleStepNavigation: formikBoolean;
      totalPages: formikNumber;
    }>
      initialValues={{
        currentPage: getInitialNumber(1),
        fullStepNavigation: getInitialBoolean(false),
        pageOffset: getInitialNumber(2),
        showDots: getInitialBoolean(true),
        showSummary: getInitialBoolean(false),
        sigleStepNavigation: getInitialBoolean(true),
        totalPages: getInitialNumber(20),
      }}
    >
      {({ formik }) => {
        if (formik.values.currentPage > formik.values.totalPages) {
          formik.setFieldValue('currentPage', formik.values.totalPages);
        }

        return (
          <Row>
            <Col className="mb-4" size={{ lg: 8, md: 7, sm: 6, xl: 9, xs: 12 }}>
              <Pagination
                currentPage={getFormikNumberValue(formik.values.currentPage) ?? 1}
                fullStepNavigation={getFormikBooleanValue(formik.values.fullStepNavigation)}
                pageOffset={getFormikNumberValue(formik.values.pageOffset) as 1 | 2 | 3 | 4 | 5}
                showDots={getFormikBooleanValue(formik.values.showDots)}
                showSummary={getFormikBooleanValue(formik.values.showSummary)}
                singleStepNavigation={getFormikBooleanValue(formik.values.sigleStepNavigation)}
                totalPages={getFormikNumberValue(formik.values.totalPages) ?? 10}
              />
            </Col>
            <Col size={{ lg: 4, md: 5, sm: 6, xl: 3, xs: 12 }}>
              <FormGroup label="Total pages" names={[]}>
                <NumberInput max={50} min={1} name="totalPages" />
              </FormGroup>
              <FormGroup label="Current page" names={[]}>
                <NumberInput max={formik.values.totalPages as number} min={1} name="currentPage" />
              </FormGroup>
              <FormGroup label="Page offset" names={[]}>
                <NumberInput max={5} min={1} name="pageOffset" />
              </FormGroup>
              <FormGroup names={[]} omitForAttribute>
                <Checkbox id="sigleStepNavigation" label="Sigle step navigation" name="sigleStepNavigation" />
                <Checkbox id="fullStepNavigation" label="Full step navigation" name="fullStepNavigation" />
                <Checkbox id="showDots" label="Show dots" name="showDots" />
                <Checkbox id="showSummary" label="Show summary" name="showSummary" />
              </FormGroup>
            </Col>
          </Row>
        );
      }}
    </Form>
  );
};
