import { PriceRangeApiDto, PriceSkuApiDto } from '@b2x/storefront-api-js-client/src/';
import React from 'react';

export interface UsePriceOptions {
  multiplier?: number; // Posso gestire correttamente il multiplier solo sui valori "unrounded" (non arrotondati).
}

export interface UsePriceResult {
  bestPriceDiscountPercentage?: number;
  bestPriceDiscountPercentageDecimal?: number;
  bestPriceValue?: number;
  crossedOutPrice?: number;
  discountPercentage?: number;
  discountPercentageDecimal?: number;
  isPriceInRange: boolean;
  perUnit?: {
    crossedOutPrice?: number;
    measurement?: string;
    price?: number;
  };
  price: number;
  vat?: number;
}

export const usePrice = (
  priceRangeApiDto?: PriceRangeApiDto,
  priceSkuApiDto?: PriceSkuApiDto,
  options?: UsePriceOptions
): UsePriceResult => {
  const { multiplier = 1 } = options ?? {};

  const isPriceInRange = React.useMemo(
    () => priceSkuApiDto === undefined && priceRangeApiDto?.from.value !== priceRangeApiDto?.to.value,
    [priceRangeApiDto?.from.value, priceRangeApiDto?.to.value, priceSkuApiDto]
  );

  const crossedOutPrice = React.useMemo(() => {
    const _price = priceRangeApiDto ? priceRangeApiDto.from : priceSkuApiDto ? priceSkuApiDto : undefined;
    if (_price?.crossedOutValue !== undefined && _price.crossedOutValueUnrounded !== undefined) {
      return multiplier > 1 ? _price.crossedOutValueUnrounded * multiplier : _price.crossedOutValue;
    } else {
      return undefined;
    }
  }, [multiplier, priceRangeApiDto, priceSkuApiDto]);

  const discountPercentage = React.useMemo(
    () => (priceSkuApiDto ? priceSkuApiDto.discountPercentage : priceRangeApiDto?.from.discountPercentage),
    [priceSkuApiDto, priceRangeApiDto?.from.discountPercentage]
  );

  const discountPercentageDecimal = React.useMemo(
    () => discountPercentage && Math.round(discountPercentage * 100),
    [discountPercentage]
  );

  const price = React.useMemo(() => {
    const _price = priceRangeApiDto ? priceRangeApiDto.from : priceSkuApiDto ? priceSkuApiDto : undefined;
    if (_price) {
      return multiplier > 1 ? _price.valueUnrounded * multiplier : _price.value;
    } else {
      return 0;
    }
  }, [multiplier, priceRangeApiDto, priceSkuApiDto]);

  const perUnit = React.useMemo(
    () =>
      priceSkuApiDto
        ? {
            crossedOutPrice: priceSkuApiDto.crossedOutValuePerUnit,
            measurement: priceSkuApiDto.measurementUnit,
            price: priceSkuApiDto.valuePerUnit,
          }
        : undefined,
    [priceSkuApiDto]
  );

  const vat = React.useMemo(() => {
    const _vat = priceSkuApiDto ? priceSkuApiDto.vat : priceRangeApiDto?.from.vat;
    // return _vat ? _vat * multiplier : 0;
    return _vat;
  }, [priceRangeApiDto?.from.vat, priceSkuApiDto]);

  const bestPriceValue = React.useMemo(() => {
    const _bestPriceValue = priceSkuApiDto?.bestPriceValue;
    // return _bestPriceValue ? _bestPriceValue * multiplier : undefined;
    return _bestPriceValue;
  }, [priceSkuApiDto?.bestPriceValue]);

  const bestPriceDiscountPercentage = React.useMemo(
    () => priceSkuApiDto?.bestPriceDiscountPercentage,
    [priceSkuApiDto?.bestPriceDiscountPercentage]
  );

  const bestPriceDiscountPercentageDecimal = React.useMemo(
    () => bestPriceDiscountPercentage && Math.round(bestPriceDiscountPercentage * 100),
    [bestPriceDiscountPercentage]
  );

  return {
    bestPriceDiscountPercentage,
    bestPriceDiscountPercentageDecimal,
    bestPriceValue,
    crossedOutPrice,
    discountPercentage,
    discountPercentageDecimal,
    isPriceInRange,
    perUnit,
    price,
    vat,
  };
};
