// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { FileUploadOptions, ImageUploadOptions } from '@b2x/storefront-api-js-client/src/file';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useFileApi = () => {
  const { apiRequest } = useApiRequest();

  const upload = React.useCallback(
    (file: File, options?: FileUploadOptions, config?: ApiRequestConfig) =>
      apiRequest(api.file.upload(file, options), { ...config }),
    [apiRequest]
  );

  const imageUpload = React.useCallback(
    (image: File, options?: ImageUploadOptions, config?: ApiRequestConfig) =>
      apiRequest(api.file.imageUpload(image, options), { ...config }),
    [apiRequest]
  );

  const getImage = React.useCallback(
    (id: string, config?: ApiRequestConfig) => apiRequest(api.file.getImage(id), { ...config }),
    [apiRequest]
  );

  return {
    getImage,
    imageUpload,
    upload,
  };
};
