import { GiftCardApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface GiftCardPopulate {
  balance?: Populate<GiftCardBalancePopulate>;
}

export interface GiftCardBalancePopulate {
  movements?: boolean;
}

const rootPath = '/v2/giftcards';

export interface GetGiftCardOptions extends BaseOptions {
  populate?: Populate<GiftCardPopulate>;
}

export const getByCode = async (code: string, options?: GetGiftCardOptions) =>
  apiRequest<GiftCardApiDto, void, GiftCardPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}/code/${code}`,
  });
