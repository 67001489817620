import classnames from 'classnames';
import React from 'react';

import { renderUI } from '../../../util';

export interface BoxProps {
  children?: React.ReactNode;
  fullHeight?: boolean;
}
export const Box = ({ children, fullHeight }: BoxProps) => {
  return renderUI({
    bs5: (
      <div
        className={classnames('box-content shadow-sm bg-white px-3 px-xl-5 py-3 py-xl-5 mb-4', { 'h-100': fullHeight })}
      >
        {children}
      </div>
    ),
  });
};
