import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { ServiceIndexSubpageA } from './serviceSubpagesA/ServiceIndexSubpageA';

export interface ServiceIndexSubpageProps {}

export type ServiceIndexSubpageVariants = 'A';

const ServiceIndexSubpageController = (props: PropsWithCustomComponent<ServiceIndexSubpageProps>) => (
  <VariantsController<ServiceIndexSubpageProps, ServiceIndexSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServiceIndexSubpageA },
      name: 'ServiceIndexSubpage',
    }}
  />
);
export { ServiceIndexSubpageController as ServiceIndexSubpage };
