import { b2x } from '@b2x/react/src';

import { LandingTemplateAPageContentType } from '../contentTypes';
import { ImageContentBox } from '../ImageContentBox';
import { PageHeader } from '../PageHeader';
import { Page } from './Page';

export interface LandingTemplateAPageProps {}

export const LandingTemplateAPage = (props: LandingTemplateAPageProps) => {
  const page = b2x.usePage<LandingTemplateAPageContentType>();
  return (
    <Page noPaddingTop>
      <PageHeader {...page?.content?.body.header} className="mb-3 mb-xl-5" />
      {page?.content?.body.imageContentBoxList && (
        <div className="container-fluid container-fluid-w-custom">
          {page.content.body.imageContentBoxList.map((item) => {
            return <ImageContentBox {...item.imageContentBoxSection} key={item.contentSectionId} />;
          })}
        </div>
      )}
    </Page>
  );
};
