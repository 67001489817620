import './SliderProducts.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import SwiperInterface from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Fetching } from '../Fetching';
import { ProductTile } from '../ProductTile';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';

export interface SliderProductsProps {
  products?: Array<b2x.ProductApiDto>;
  title?: string;
}

export const SliderProducts = ({ products, title }: SliderProductsProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
  const [swiper, setSwiper] = React.useState<SwiperInterface>();

  return (
    <div className="SliderProducts my-3 mb-lg-5">
      <div className="container-xxl">
        <div className="row">
          {title && (
            <div className="col-12 text-center mb-5">
              <div className="display-1">{title && b2x.formatHtml(title)}</div>
            </div>
          )}
        </div>
        <div className="row">
          <b2x.EqualHeight>
            <div className="col-12">
              <div className="slider-container position-relative px-5 d-flex flex-wrap">
                <Fetching item={products}>
                  <Slider
                    navigation
                    onSwiper={setSwiper}
                    responsive={{
                      lg: { slidesPerView: 3 },
                      md: { slidesPerView: 2 },
                      xl: { slidesPerView: 4 },
                      xs: { slidesPerView: 1 },
                    }}
                    sliderNextEl={sliderNextButton}
                    sliderPrevEl={sliderPrevButton}
                    slidesPerView={2}
                    spaceBetween={20}
                  >
                    {products?.map((product, index) => (
                      <SwiperSlide className="d-flex" key={product.id}>
                        <ProductTile enableExtraInfo product={product} />
                      </SwiperSlide>
                    ))}
                  </Slider>
                </Fetching>
                <SliderButton direction="left" innerRef={sliderPrevButton} onClick={swiper?.slidePrev} size="large" />
                <SliderButton direction="right" innerRef={sliderNextButton} onClick={swiper?.slideNext} size="large" />
              </div>
            </div>
          </b2x.EqualHeight>
        </div>
      </div>
    </div>
  );
};
