import React from 'react';

import { createContext } from './util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
export interface EqualHeightContextInterface {
  updateChecker: unknown;
  updateTrigger(): void;
}

export const [EqualHeightContextProvider, useEqualHeightContext, useEqualHeightContextStrict] =
  createContext<EqualHeightContextInterface>('EqualHeightContext');

// Proprietà necessarie per creare il contesto
interface UseEqualHeightContextInitializerProps {}

// Funzione per inizializzare il contesto
const useEqualHeightContextInitializer = (props: UseEqualHeightContextInitializerProps) => {
  const [counter, setCounter] = React.useState<number>(0);

  const updateTrigger = React.useCallback(() => {
    setCounter((prevState) => prevState + 1);
  }, []);

  // Crea l'oggetto equalHeightContext
  const equalHeightContext: EqualHeightContextInterface = React.useMemo(
    () => ({
      updateChecker: counter,
      updateTrigger,
    }),
    [counter, updateTrigger]
  );

  return {
    EqualHeightContextProvider,
    equalHeightContext,
  };
};

// Interfaccia componente contesto
export interface EqualHeightContextProps extends UseEqualHeightContextInitializerProps {
  children: React.ReactNode | ((equalHeightContext: EqualHeightContextInterface) => React.ReactNode);
}

// Componente Contesto
export const EqualHeightContext = ({ children, ...otherProps }: EqualHeightContextProps) => {
  const equalHeightContextInitializer = useEqualHeightContextInitializer(otherProps);
  return (
    <equalHeightContextInitializer.EqualHeightContextProvider value={equalHeightContextInitializer.equalHeightContext}>
      {typeof children === 'function' ? children(equalHeightContextInitializer.equalHeightContext) : children}
    </equalHeightContextInitializer.EqualHeightContextProvider>
  );
};
