import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { AccountHomePageA } from './AccountHomePageA';

export interface AccountHomePageProps {}

export type AccountHomePageVariants = 'A';

const AccountHomePageController = (props: PropsWithCustomComponent<AccountHomePageProps>) => (
  <VariantsController<AccountHomePageProps, AccountHomePageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountHomePageA },
      name: 'AccountHomePage',
    }}
  />
);
export { AccountHomePageController as AccountHomePage };
