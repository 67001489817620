import React from 'react';

export const useIntersectionObserver = <T extends HTMLElement = HTMLElement>(
  initialVisibility = false,
  rootMargin?: string
) => {
  const [visible, setVisible] = React.useState<boolean>(initialVisibility);

  const ref = React.useRef<T | null>(null);

  const observer = React.useMemo(
    () => new IntersectionObserver(([entry]) => setVisible(entry.isIntersecting), { rootMargin: rootMargin }),
    [rootMargin]
  );

  const setRef = React.useCallback((node: T | null) => {
    ref.current = node;
  }, []);

  const currentRef = ref.current;
  React.useEffect(() => {
    if (!currentRef) {
      return;
    }

    observer.observe(currentRef);

    return () => {
      observer.disconnect();
    };
  }, [currentRef, observer]);

  return [ref, setRef, visible] as const;
};
