import { ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { LocalBusiness } from 'schema-dts';

import { appConfig } from '../config';

export interface EntertainmentBusinessProps {
  shop: ShopApiDto;
}

export const EntertainmentBusiness = ({ shop }: EntertainmentBusinessProps) => {
  const images = [...(shop.image ? [shop.image] : []), ...(shop.alternativeImages ? shop.alternativeImages : [])];
  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp<LocalBusiness>(
            {
              '@context': 'https://schema.org',
              '@type': 'EntertainmentBusiness',
              address: {
                '@type': 'PostalAddress',
                addressCountry: shop.address?.country?.code,
                addressLocality: shop.address?.city,
                addressRegion: shop.address?.province?.code,
                postalCode: shop.address?.zipCode,
                streetAddress: shop.address?.addressLine1,
              },
              geo: {
                '@type': 'GeoCoordinates',
                latitude: shop.address?.latitude,
                longitude: shop.address?.longitude,
              },
              image: images.length > 0 ? images.map((image) => image.src) : undefined,
              name: appConfig.shopNameResolver ? appConfig.shopNameResolver(shop) : shop.name,
              telephone: shop.phoneNumber,
              url: window.location.href,
            },
            {
              space: 1,
            }
          ),
        ]}
      />
    </>
  );
};
