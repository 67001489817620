import React from 'react';

import { LinkProps } from './router/Link';
import { createContext } from './util';

interface PageWithSubpageContextInterface {
  mobileBackLink?: LinkProps;
  pageSubtitle?: string;
  pageTitle?: string;
  setMobileBackLink: React.Dispatch<React.SetStateAction<LinkProps | undefined>>;
  setPageSubtitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowMobileBackLink: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPageSubtitle: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPageTitle: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubpageTitle: React.Dispatch<React.SetStateAction<boolean>>;
  setSubpageTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  showMobileBackLink: boolean;
  showPageSubtitle: boolean;
  showPageTitle: boolean;
  showSubpageTitle: boolean;
  subpageTitle?: string;
}

export const [PageWithSubpageContextProvider, usePageWithSubpageContext, usePageWithSubpageContextStrict] =
  createContext<PageWithSubpageContextInterface>('PageWithSubpageContext');

interface UsePageWithSubpageContextInitializerProps {
  mobileBackLink?: LinkProps;
  pageSubtitle?: string;
  pageTitle?: string;
  showMobileBackLink?: boolean;
  showPageSubtitle?: boolean;
  showPageTitle?: boolean;
  showSubpageTitle?: boolean;
  subpageTitle?: string;
}

const usePageWithSubpageContextInitializer = (props: UsePageWithSubpageContextInitializerProps) => {
  const [pageTitle, setPageTitle] = React.useState<string | undefined>(props.pageTitle);
  const [showPageTitle, setShowPageTitle] = React.useState<boolean>(props.showPageTitle ?? false);
  const [pageSubtitle, setPageSubtitle] = React.useState<string | undefined>(props.pageSubtitle);
  const [showPageSubtitle, setShowPageSubtitle] = React.useState<boolean>(props.showPageSubtitle ?? false);
  const [subpageTitle, setSubpageTitle] = React.useState<string | undefined>(props.subpageTitle);
  const [showSubpageTitle, setShowSubpageTitle] = React.useState<boolean>(props.showSubpageTitle ?? false);
  const [mobileBackLink, setMobileBackLink] = React.useState<LinkProps | undefined>(props.mobileBackLink);
  const [showMobileBackLink, setShowMobileBackLink] = React.useState<boolean>(props.showMobileBackLink ?? false);

  const pageWithSubpageContext: PageWithSubpageContextInterface = React.useMemo(
    () => ({
      mobileBackLink,
      pageSubtitle,
      pageTitle,
      setMobileBackLink,
      setPageSubtitle,
      setPageTitle,
      setShowMobileBackLink,
      setShowPageSubtitle,
      setShowPageTitle,
      setShowSubpageTitle,
      setSubpageTitle,
      showMobileBackLink,
      showPageSubtitle,
      showPageTitle,
      showSubpageTitle,
      subpageTitle,
    }),
    [
      mobileBackLink,
      pageSubtitle,
      pageTitle,
      showMobileBackLink,
      showPageSubtitle,
      showPageTitle,
      showSubpageTitle,
      subpageTitle,
    ]
  );

  return {
    PageWithSubpageContextProvider,
    pageWithSubpageContext,
  };
};

export interface PageWithSubpageContextProps extends UsePageWithSubpageContextInitializerProps {
  children: React.ReactNode | ((pageWithSubpageContext: PageWithSubpageContextInterface) => React.ReactNode);
}

export const PageWithSubpageContext = ({ children, ...otherProps }: PageWithSubpageContextProps) => {
  const pageWithSubpageContextInitializer = usePageWithSubpageContextInitializer(otherProps);
  return (
    <pageWithSubpageContextInitializer.PageWithSubpageContextProvider
      value={pageWithSubpageContextInitializer.pageWithSubpageContext}
    >
      {typeof children === 'function' ? children(pageWithSubpageContextInitializer.pageWithSubpageContext) : children}
    </pageWithSubpageContextInitializer.PageWithSubpageContextProvider>
  );
};
