import { OfferApiDto } from '@b2x/storefront-api-js-client/src';

import { Div, Li, Ul } from './HTMLElement';
import { Icon, IconProps } from './Icon';

export interface OffersListProps {
  icon?: IconProps<string, number>;
  offers: Array<OfferApiDto>;
}

export const OffersList = ({ icon, offers }: OffersListProps) => {
  return (
    <>
      {offers.length > 0 && (
        <Div dynamicMargin={{ top: 2 }}>
          <Ul dynamicPadding={{ xs: 0 }} style={{ listStyle: 'none' }}>
            {offers.map((offer) => (
              <Li
                alignItems={'center'}
                alignSelf={'stretch'}
                className={'extra-small'}
                display={'flex'}
                flexDirection={'row'}
                fontWeight={500}
                gap={1}
                key={offer.name}
                uiClassName={{ bs5: 'text-offer' }}
              >
                {icon && `${(<Icon {...icon} />)} `}
                <span>{offer.name}</span>
              </Li>
            ))}
          </Ul>
        </Div>
      )}
    </>
  );
};
