import { b2x } from '@b2x/react/src';

import { OurValuesSection } from './contentTypes';
import { Icon, isIconName } from './Icon';

export interface OurValuesProps extends OurValuesSection {}

export const OurValues = ({ itemList, title }: OurValuesProps) => {
  return (
    <div className="OurValues my-5">
      {itemList && (
        <div className="container-xxl">
          <div className="row">
            <div className="col-12 text-center">
              <div className="display-1 mb-5">{title && b2x.formatHtml(title)}</div>
            </div>
          </div>
          <div className="row">
            {itemList.map((item, index) => {
              return (
                <div className="col-lg-4 mb-4 mb-lg-3" key={item.contentSectionId}>
                  <div className="text-center rounded-3 bg-white shadow h-100">
                    <div className="p-3">
                      {isIconName(item.icon) && <Icon className="text-primary" name={item.icon} size={120} />}
                      <div className="display-2 my-3">{b2x.formatHtml(item.title)}</div>
                      <div>{b2x.formatHtml(item.content)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
