import { apiRequest } from './utils/util';

const rootPath = '/v2/translations';

export const translateTexts = async (texts: Array<string>, targetLocale: string) =>
  apiRequest<Array<string>, void, void>({
    params: {
      texts: texts,
    },
    staleTime: Infinity,
    url: `${rootPath}/translate/${targetLocale}`,
  });
