import { LoremIpsum as _LoremIpsum } from 'lorem-ipsum';
import React from 'react';

import { formatHtml } from './util';

export interface LoremIpsumProps {
  format?: 'html' | 'plain';
  paragraphs?: number;
}

export const LoremIpsum = ({ format = 'plain', paragraphs = 1 }: LoremIpsumProps) => {
  const content = React.useMemo(() => {
    const lorem = new _LoremIpsum(
      {
        sentencesPerParagraph: {
          max: 8,
          min: 4,
        },
        wordsPerSentence: {
          max: 16,
          min: 4,
        },
      },
      format
    );

    return lorem.generateParagraphs(paragraphs);
  }, [format, paragraphs]);

  return <>{format === 'html' ? formatHtml(content) : content}</>;
};
