import React from 'react';
import * as yup from 'yup';

import { Button } from '../Button';
import { appConfig } from '../config';
import { DeprecatedCta } from '../DeprecatedCta';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { formatHtml, renderUI } from '../util';
import { FileUploaderInput, TextInput } from './fields/Input';
import { Checkbox } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';
import { TaxBenefitsFormHelper, TaxBenefitsFormProps } from './TaxBenefitsForm';

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesValidationSchema = yup.ObjectSchema<{}>;

export const TaxBenefitsFormA = (
  props: TaxBenefitsFormProps<CustomPropertiesFormValues, CustomPropertiesValidationSchema>
) => {
  const [hideRejectedMessage, setHideRejectedMessage] = React.useState<boolean>(false);
  const handleFileUploadOnChange = React.useCallback(() => setHideRejectedMessage(true), []);

  return renderUI({
    bs5: (
      <TaxBenefitsFormHelper {...props}>
        {({ fieldsHelper, formik }) => (
          <>
            <FormGroup {...fieldsHelper.taxCode.formGroup} className="mb-4" noMarginBottom>
              <TextInput {...fieldsHelper.taxCode.textInput} />
            </FormGroup>
            {fieldsHelper.filesToAttach.map(
              ({ fileUploader: { contentSection, fileUploaderInput, formGroup }, status }) => (
                <FormGroup
                  key={fileUploaderInput.name}
                  {...formGroup}
                  className="mb-4"
                  labelClassName="mb-0"
                  noMarginBottom
                >
                  {contentSection.description && <p>{formatHtml(contentSection.description)}</p>}
                  {contentSection.documentation?.file && (
                    <Div marginBottom={3}>
                      <DeprecatedCta
                        className="btn-documentation text-decoration-none"
                        cta={{ to: { href: contentSection.documentation.file } }}
                      >
                        {appConfig.icons?.download && (
                          <Icon className="me-1" name={appConfig.icons.download} size={32} />
                        )}
                        {contentSection.documentation.label
                          ? contentSection.documentation.label
                          : t('form.taxBenefitsForm.documentation.label')}
                      </DeprecatedCta>
                    </Div>
                  )}
                  {status === 'APPROVED' ? (
                    <p className="text-success">
                      <b>{t('form.taxBenefitsForm.documentValidation.approved')}</b>
                    </p>
                  ) : status === 'AWAITING_APPROVAL' ? (
                    <p className="text-warning">
                      <b>{t('form.taxBenefitsForm.documentValidation.awating')}</b>
                    </p>
                  ) : (
                    status === 'REJECTED' &&
                    !hideRejectedMessage && (
                      <p className="text-danger">
                        <b>{t('form.taxBenefitsForm.documentValidation.rejected')}</b>
                      </p>
                    )
                  )}
                  {(status === 'REJECTED' || status === undefined) && (
                    <FileUploaderInput onChange={handleFileUploadOnChange} {...fileUploaderInput} />
                  )}
                </FormGroup>
              )
            )}
            <FormGroup {...fieldsHelper.privacy.formGroup} className="mb-4">
              <Checkbox {...fieldsHelper.privacy.checkbox} />
            </FormGroup>
            <Div textAlign={'center'}>
              <Button {...fieldsHelper.buttons.submit} />
            </Div>
          </>
        )}
      </TaxBenefitsFormHelper>
    ),
  });
};
