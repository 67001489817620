import React from 'react';

export interface EmptyLinkProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {}

export const EmptyLink = ({ children, onClick, ...otherProps }: EmptyLinkProps) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (onClick) {
        event.preventDefault();
        onClick(event);
        return false;
      }
    },
    [onClick]
  );

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid, react/forbid-elements
    <a href="#" {...otherProps} onClick={handleClick}>
      {children}
    </a>
  );
};
