import { ImageContentSectionV1 } from './contentTypes';
import { ImageAsBackground, ImageAsBackgroundProps } from './ImageAsBackground';

export interface ImageAsBackgroundFromContentV1Props
  extends ImageContentSectionV1,
    Omit<ImageAsBackgroundProps, 'src'> {}

export const ImageAsBackgroundFromContentV1 = ({
  backgroundSize = 'cover',
  className,
  src,
  ...otherProps
}: ImageAsBackgroundFromContentV1Props) => {
  return (
    <>
      {src?.xs?.url && (
        <ImageAsBackground
          {...otherProps}
          backgroundSize={backgroundSize}
          className={className}
          src={{
            lg: src.lg?.url,
            md: src.md?.url,
            sm: src.sm?.url,
            xl: src.xl?.url,
            xs: src.xs.url,
            xxl: src.xxl?.url,
          }}
        />
      )}
    </>
  );
};
