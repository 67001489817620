import { CheckEmailResponseApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import _ from 'lodash';
import React from 'react';
import * as yup from 'yup';

import { useCartApi } from '../api/useCartApi';
import { useCustomerApi } from '../api/useCustomerApi';
import { useAppContext, useAppStaticContext } from '../AppContext';
import { Button } from '../Button';
import { useCheckoutContextStrict } from '../CheckoutContext';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { useNavigate } from '../router/useNavigate';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { formatHtml, validateEmail } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { Checkbox, CheckboxProps, Radios, RadiosProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBoolean,
  formikBooleanAsString,
  formikString,
  getFormikBooleanAsStringValue,
  getInitialBoolean,
  getInitialBooleanAsString,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { LoginForm } from './LoginForm';

export interface CartEmailFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  variant: 'whois' | 'checkout';
}

interface FormValues {
  email: formikString;
  newsletterConsent: formikBooleanAsString;
  privacy: formikBoolean;
}

type ValidationSchema = {
  email: yup.StringSchema;
  newsletterConsent: yup.StringSchema;
  privacy: yup.BooleanSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
  newsletterConsent?: { formGroup: FormGroupProps; radios: RadiosProps };
  privacy: { checkbox: CheckboxProps; formGroup: FormGroupProps };
}

export const CartEmailFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  variant,
  ...otherProps
}: CartEmailFormProps) => {
  const [emailCheckResponse, setEmailCheckResponse] = React.useState<CheckEmailResponseApiDto>();

  const { session } = useAppContext();

  const { getFixedPagePath } = useAppStaticContext();

  const { info } = useAppContext();

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      email: getInitialString(session?.cart?.email),
      newsletterConsent: getInitialBooleanAsString(),
      privacy: getInitialBoolean(),
      ...initialValues,
    }),
    [initialValues, session?.cart?.email]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      email: yup.string().required(),
      newsletterConsent: emailCheckResponse?.newsletter === false ? yup.string().required() : yup.string(),
      privacy: yup.boolean().required().oneOf([true]),
    }),
    [emailCheckResponse?.newsletter]
  );

  const { setEmail } = useCartApi();

  const navigate = useNavigate();

  const checkoutContext = useCheckoutContextStrict();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      setEmail({
        email: values.email,
        newsletterConsent:
          emailCheckResponse?.newsletter === false && getFormikBooleanAsStringValue(values.newsletterConsent),
      }).then(() => {
        onSuccess && onSuccess();
        checkoutContext ? checkoutContext.deleteForcedStep() : navigate(getFixedPagePath('checkout'));
      }),
    [checkoutContext, emailCheckResponse?.newsletter, getFixedPagePath, navigate, onSuccess, setEmail]
  );

  const ref = React.useRef<HTMLFormElement>(null);
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const { insideModal } = useInsideModalDetector();

  const closeModal = useModalCloser();

  const { checkEmail } = useCustomerApi();

  const lastCheckedEmail = React.useRef<string>();

  const { showModal } = useModals();

  const _handleEmailChange = React.useCallback(
    (email: string, silent: boolean) =>
      new Promise<CheckEmailResponseApiDto | undefined>((resolve) => {
        if (info?.guestOrderEmailCheckEnabled) {
          if (lastCheckedEmail.current !== email) {
            if (variant === 'whois') {
              if (validateEmail(email)) {
                checkEmail({ email }, { silent })
                  .then((response) => {
                    setEmailCheckResponse(response.data);
                    resolve(response.data);
                  })
                  .catch(() => {
                    setEmailCheckResponse(undefined);
                    resolve(undefined);
                  })
                  .finally(() => {
                    lastCheckedEmail.current = email;
                  });
              } else {
                setEmailCheckResponse(undefined);
                resolve(undefined);
              }
            } else {
              resolve(undefined);
            }
          } else {
            resolve(emailCheckResponse);
          }
        } else {
          resolve(undefined);
        }
      }),
    [checkEmail, emailCheckResponse, info?.guestOrderEmailCheckEnabled, variant]
  );

  const _handleEmailChangeDebounced = _.debounce(_handleEmailChange, 200);

  const handleEmailInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      _handleEmailChangeDebounced(event.target.value, true);
    },
    [_handleEmailChangeDebounced]
  );

  const handleEmailInputBlur = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement, Element>) => {
      const email = event.target.value;
      _handleEmailChange(email, false).then((response) => {
        if (response && response.registered === true) {
          showModal({
            children: (
              <>
                <p>{formatHtml(t('form.cartEmailForm.handlers.registeredEmail.body'))}</p>
                <LoginForm
                  // eslint-disable-next-line react/jsx-no-bind
                  onSuccess={() => {
                    navigate(getFixedPagePath('checkout'));
                  }}
                  username={email}
                />
              </>
            ),
            title: t('form.cartEmailForm.handlers.registeredEmail.title'),
          });
        }
      });
    },
    [_handleEmailChange, getFixedPagePath, navigate, showModal]
  );

  return (
    <HelpedForm<FormValues>
      className={classnames('CartEmailForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.cartEmailForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label:
                variant === 'whois'
                  ? t('form.cartEmailForm.buttons.submit.whoIs.label')
                  : t('form.cartEmailForm.buttons.submit.checkout.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          email: {
            formGroup: { label: t('form.cartEmailForm.fields.email.label'), names: ['email'] },
            textInput: {
              name: 'email',
              onBlur: handleEmailInputBlur,
              onChange: handleEmailInputChange,
              placeholder: t('form.cartEmailForm.fields.email.placeholder'),
            },
          },
          newsletterConsent:
            emailCheckResponse?.newsletter === false
              ? {
                  formGroup: {
                    label: formatHtml(consentsContent?.body.newsletter?.label),
                    names: ['newsletterConsent'],
                    omitForAttribute: true,
                  },
                  radios: {
                    radios: [
                      {
                        id: 'newsletterConsent-true',
                        inline: true,
                        label: t('form.cartEmailForm.fields.newsletterConsent.radios.true'),
                        name: 'newsletterConsent',
                        value: 'true',
                      },
                      {
                        id: 'newsletterConsent-false',
                        inline: true,
                        label: t('form.cartEmailForm.fields.newsletterConsent.radios.false'),
                        name: 'newsletterConsent',
                        value: 'false',
                      },
                    ],
                  },
                }
              : undefined,
          privacy: {
            checkbox: {
              id: 'privacy',
              label: formatHtml(consentsContent?.body.privacyGuestOrder),
              name: 'privacy',
            },
            formGroup: { names: ['privacy'] },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} />
            </FormGroup>
            {fieldsHelper.newsletterConsent && (
              <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
                <Radios {...fieldsHelper.newsletterConsent.radios} />
              </FormGroup>
            )}
            <FormGroup {...fieldsHelper.privacy.formGroup}>
              <Checkbox {...fieldsHelper.privacy.checkbox} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type CartEmailFormVariants = '';

const CartEmailFormController = (props: PropsWithCustomComponentWithoutChildren<CartEmailFormProps>) => (
  <VariantsController<CartEmailFormProps, CartEmailFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CartEmailFormHelper,
      name: 'CartEmailForm',
    }}
  />
);
export { CartEmailFormController as CartEmailForm };
