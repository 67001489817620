import { FileApiDto, ImageApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import React from 'react';

import { useFileApi } from '../../api/useFileApi';
import { Button } from '../../Button';
import { appConfig } from '../../config';
import { t } from '../../i18n/i18n';
import { Icon } from '../../Icon';
import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { CommonFieldProps, HTMLAutocomplete, useField, useFormikContext } from '../Form';
import { useFormGroupContext } from '../FormGroupContext';
import { InputGroup } from '../InputGroup';
import { InputGroupText } from '../InputGroupText';

interface InputProps extends Omit<CommonFieldProps<HTMLInputElement, string | number>, 'field'> {
  autoComplete?: HTMLAutocomplete;
  max?: number | string;
  maxLength?: number;
  min?: number | string;
  minLength?: number;
  placeholder?: string;
  step?: number;
  style?: React.CSSProperties;
  type: 'text' | 'number' | 'email' | 'tel' | 'password' | 'date' | 'time' | 'file' | 'datetime-local';
}

const Input = (props: InputProps) => {
  const { autoComplete, className, innerRef, onFormikChange, placeholder, size, type, ...otherProps } = props;
  const { b2xHelpers, field } = useField(props, otherProps);

  const { id, required, withLabel } = useFormGroupContext();

  return renderUI({
    bs5: (
      <>
        <input
          {...field}
          {...otherProps}
          autoComplete={type === 'email' ? 'username' : autoComplete}
          className={classnames(
            'form-control',
            { 'form-control-sm': size === 'small' },
            { 'form-control-lg': size === 'large' },
            { 'is-valid': b2xHelpers.isValid },
            { 'is-invalid': b2xHelpers.isInvalid },
            className
          )}
          id={id}
          onChange={b2xHelpers.handleChange}
          placeholder={!withLabel && required ? `${placeholder} *` : placeholder}
          ref={innerRef}
          type={type}
        />
        <Feedback name={props.name} />
      </>
    ),
  });
};

export interface TextInputProps extends CommonFieldProps<HTMLInputElement, string> {
  autoComplete?: HTMLAutocomplete;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  type?: 'text' | 'email' | 'tel';
}
export const TextInput = ({ type = 'text', ...otherProps }: TextInputProps): React.ReactElement => {
  return <Input {...otherProps} type={type} />;
};

export interface NumberInputProps extends CommonFieldProps<HTMLInputElement, number> {
  autoComplete?: HTMLAutocomplete;
  max?: number;
  min?: number;
  placeholder?: string;
  step?: number;
}
export const NumberInput = (props: NumberInputProps): React.ReactElement => <Input {...props} type="number" />;

export interface DateInputProps extends CommonFieldProps<HTMLInputElement, string> {
  autoComplete?: HTMLAutocomplete;
  max?: string;
  min?: string;
  placeholder?: string;
  step?: number;
}
export const DateInput = ({
  // name,
  // onBlur,
  // onFocus,
  // placeholder,
  ...otherProps
}: DateInputProps): React.ReactElement => {
  // const [type, setType] = React.useState<'date' | 'text'>(placeholder ? 'text' : 'date');
  // const { values } = useFormikContext();
  // const ref = React.useRef<HTMLInputElement>(null);

  // const handleFocus = React.useCallback(
  //   (event: React.FocusEvent<HTMLInputElement, Element>) => {
  //     setType('date');
  //     onFocus && onFocus(event);
  //     wait(100).then(() => {
  //       ref.current?.click();
  //     });
  //   },
  //   [onFocus]
  // );

  // const handleBlur = React.useCallback(
  //   (event: React.FocusEvent<HTMLInputElement, Element>) => {
  //     const value = getIn(values, name);
  //     if (placeholder !== undefined && (value === undefined || value === '')) {
  //       setType('text');
  //     }
  //     onBlur && onBlur(event);
  //   },
  //   [name, onBlur, placeholder, values]
  // );

  return (
    <Input
      {...otherProps}
      // innerRef={ref}
      // name={name}
      // onBlur={handleBlur}
      // onFocus={handleFocus}
      // placeholder={placeholder}
      type="date"
    />
  );
};

export interface TimeInputProps extends CommonFieldProps<HTMLInputElement, string> {
  autoComplete?: HTMLAutocomplete;
  max?: string;
  min?: string;
  placeholder?: string;
  step?: number;
}
export const TimeInput = (props: TimeInputProps): React.ReactElement => <Input {...props} type="time" />;

export interface DateTimeInputProps extends CommonFieldProps<HTMLInputElement, string> {
  autoComplete?: HTMLAutocomplete;
  max?: string;
  min?: string;
  placeholder?: string;
  step?: number;
}
export const DateTimeInput = (props: DateTimeInputProps): React.ReactElement => (
  <Input {...props} type="datetime-local" />
);

export interface FileInputProps extends Omit<CommonFieldProps<HTMLInputElement, string>, 'readOnly'> {
  accept?: string;
  // buttonVariant?: string; // da gestire
  capture?: 'user' | 'environment';
  // maxSize?: number; // default 10000 // da gestire
  // multiple?: boolean; // da gestire
  onUpload?(file: FileApiDto): void;
  placeholder?: string;
}

export const FileInput = (props: FileInputProps): React.ReactElement => <Input {...props} type="file" />;

export interface FileUploaderInputProps extends FileInputProps {
  folder?: string;
}

export const FileUploaderInput = (props: FileUploaderInputProps): React.ReactElement => {
  const [uploading, setUploading] = React.useState<boolean>(false);
  const { id } = useFormGroupContext();
  const { setFieldValue } = useFormikContext();
  const [file, setFile] = React.useState<FileApiDto>();

  const {
    className,
    disabled,
    folder,
    innerRef,
    name,
    onChange,
    onFormikChange,
    onUpload,
    placeholder,
    size,
    ...otherProps
  } = props;
  const { b2xHelpers } = useField(props, { ...otherProps, name: name });

  const { upload } = useFileApi();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      if (event.target.files && event.target.files.length > 0) {
        setFile(undefined);
        setFieldValue(name, '');
        setUploading(true);
        upload(event.target.files[0], { folder: folder })
          .then((response) => {
            onUpload && onUpload(response.data);
            setFile(response.data);
            setFieldValue(name, response.data.id);
          })
          .finally(() => {
            setUploading(false);
          });
      }
    },
    [folder, name, onChange, onUpload, setFieldValue, upload]
  );

  return renderUI({
    bs5: (
      <label
        className={classnames(className, 'd-block')}
        htmlFor={id}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <span
          className={classnames(
            'btn btn-light',
            { 'btn-sm': size === 'small' },
            { 'btn-lg': size === 'large' },
            { 'border-danger': b2xHelpers.isInvalid },
            { disabled: disabled }
          )}
        >
          {t('misc.fileInput.label')}
        </span>
        <span className="ps-2">
          {uploading
            ? t('misc.fileInput.uploading')
            : file
            ? file.title
            : placeholder || t('misc.fileInput.placeholder')}
        </span>
        <input
          {...otherProps}
          className={classnames({ 'is-valid': b2xHelpers.isValid }, { 'is-invalid': b2xHelpers.isInvalid }, className)}
          disabled={disabled || uploading}
          id={id}
          onChange={handleChange}
          ref={innerRef}
          style={{ display: 'none' }}
          type="file"
        />
        <Feedback name={name} />
      </label>
    ),
  });
};

export interface ImageUploaderInputProps extends FileInputProps {
  album?: string;
}

export const ImageUploaderInput = (props: ImageUploaderInputProps): React.ReactElement => {
  const [uploading, setUploading] = React.useState<boolean>(false);
  const { id } = useFormGroupContext();
  const { setFieldValue } = useFormikContext();
  const [image, setImage] = React.useState<ImageApiDto>();

  const {
    album,
    className,
    disabled,
    innerRef,
    name,
    onChange,
    onFormikChange,
    onUpload,
    placeholder,
    size,
    ...otherProps
  } = props;
  const { b2xHelpers } = useField(props, { ...otherProps, name: name });

  const { imageUpload } = useFileApi();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event);
      if (event.target.files && event.target.files.length > 0) {
        setImage(undefined);
        setFieldValue(name, '');
        setUploading(true);
        imageUpload(event.target.files[0], { album: album })
          .then((response) => {
            onUpload && onUpload(response.data);
            setImage(response.data);
            setFieldValue(name, response.data.id);
          })
          .finally(() => {
            setUploading(false);
          });
      }
    },
    [album, name, onChange, onUpload, setFieldValue, imageUpload]
  );

  return renderUI({
    bs5: (
      <label
        className={classnames(className, 'd-block')}
        htmlFor={id}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <span
          className={classnames(
            'btn btn-light',
            { 'btn-sm': size === 'small' },
            { 'btn-lg': size === 'large' },
            { 'border-danger': b2xHelpers.isInvalid },
            { disabled: disabled }
          )}
        >
          {t('misc.fileInput.label')}
        </span>
        <span className="ps-2">
          {uploading
            ? t('misc.fileInput.uploading')
            : image
            ? image.title
            : placeholder || t('misc.fileInput.placeholder')}
        </span>
        <input
          {...otherProps}
          className={classnames({ 'is-valid': b2xHelpers.isValid }, { 'is-invalid': b2xHelpers.isInvalid }, className)}
          disabled={disabled || uploading}
          id={id}
          onChange={handleChange}
          ref={innerRef}
          style={{ display: 'none' }}
          type="file"
        />
        <Feedback name={name} />
      </label>
    ),
  });
};

export interface PasswordInputProps extends CommonFieldProps<HTMLInputElement, string> {
  autoComplete: 'new-password' | 'current-password';
  placeholder?: string;
}
export const PasswordInput = ({ name, ...otherProps }: PasswordInputProps) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleButtonClick = React.useCallback(() => {
    setVisible((prevState) => !prevState);
  }, []);

  return (
    <InputGroup names={[name]}>
      <Input name={name} {...otherProps} style={{ borderRight: 0 }} type={visible ? 'text' : 'password'} />
      <InputGroupText style={{ background: 'none' }}>
        <Button onClick={handleButtonClick} variant="blank">
          {appConfig.icons?.passwordToggle ? (
            <Icon name={visible ? appConfig.icons.passwordToggle.visible : appConfig.icons.passwordToggle.hidden} />
          ) : (
            <strong className="extra-small">
              <u>{visible ? t('misc.passwordInput.hide') : t('misc.passwordInput.show')}</u>
            </strong>
          )}
        </Button>
      </InputGroupText>
    </InputGroup>
  );
};

export interface RatingsProps {}

export const Ratings = (props: RatingsProps) => {
  return renderUI({
    bs5: <></>,
  });
};
