import './RecipeProduct.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { t } from './i18n/i18n';

interface RecipeProductProps {
  className?: string;
  enableAddToCart?: boolean;
  imageFormat?: b2x.ImageFormat | undefined;
  product: b2x.ProductApiDto;
}

export const RecipeProduct = ({ className, enableAddToCart = true, imageFormat, product }: RecipeProductProps) => {
  return (
    <>
      <div className={classnames('recipe-product p-4', className)}>
        <b2x.AddToCartFormHelper product={product} scope="tile">
          {({ fieldsHelper, priceHelper, selectedProductVariant }) => (
            <>
              <div className="row">
                <div className="col-xl-4 col-md-5">
                  <div className="recipe-product-image-container">
                    <b2x.router.Link style={{ display: 'block' }} to={selectedProductVariant.url}>
                      <b2x.Image
                        alt={selectedProductVariant.name}
                        aspectRatio={b2x.appConfig.productImageAspectRatio}
                        fluid
                        format={imageFormat}
                        src={selectedProductVariant.image?.src}
                      />
                    </b2x.router.Link>
                    {priceHelper.discountPercentage && (
                      <div className="discount-percentage bg-promo text-white">{`-${b2x.formatFloatPercentage(
                        priceHelper.discountPercentage
                      )}%`}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-8 col-md-7">
                  <div className="mt-5 mt-md-3">
                    <h6 className="text-uppercase">
                      <b2x.router.Link className="text-decoration-none text-black" to={selectedProductVariant.url}>
                        {b2x.formatHtml(selectedProductVariant.name)}
                      </b2x.router.Link>
                    </h6>

                    <div className="recipe-product-price">
                      {priceHelper.crossedOutPrice && (
                        <span className="text-decoration-line-through me-2">
                          {b2x.t(priceHelper.isPriceInRange ? 'misc.fromPrice' : 'misc.price', {
                            value: b2x.formatCurrency(priceHelper.crossedOutPrice),
                          })}
                        </span>
                      )}
                      <strong className={classnames({ 'text-promo': priceHelper.crossedOutPrice })}>
                        {b2x.t(priceHelper.isPriceInRange ? 'misc.fromPrice' : 'misc.price', {
                          value: b2x.formatCurrency(priceHelper.price),
                        })}
                      </strong>
                    </div>
                  </div>

                  <div className="recipe-product-extra-info mt-3">
                    {fieldsHelper.productVariants.formFields.length > 1 && (
                      <div>
                        <b2x.FormGroup
                          {...fieldsHelper.productVariants.formGroup}
                          className="recipe-product-variant bg-gray-100 pt-2 px-2 d-inline-block"
                          label={undefined}
                        >
                          <div>
                            <div className="mb-1">{t('form.productForm.size')}</div>
                            <div className="d-flex flex-wrap lh-1">
                              {fieldsHelper.productVariants.formFields.map((formField, index) => (
                                <b2x.Radio
                                  key={formField.productVariant.id}
                                  {...formField.radio}
                                  className="m-0"
                                  inline
                                  labelClassName={classnames(
                                    { 'me-2': fieldsHelper.productVariants.formFields.length !== index + 1 },
                                    'mb-2 fw-bold shadow-sm px-2 py-1 bg-white'
                                  )}
                                >
                                  {formField.productVariant.skus?.map((sku, indexSku) => indexSku === 0 && sku.name)}
                                </b2x.Radio>
                              ))}
                            </div>
                          </div>
                        </b2x.FormGroup>
                      </div>
                    )}
                    <b2x.Button {...fieldsHelper.buttons.submit} />
                  </div>
                </div>
              </div>
            </>
          )}
        </b2x.AddToCartFormHelper>
      </div>
    </>
  );
};
