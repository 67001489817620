// eslint-disable-next-line no-restricted-imports
import { api, ContentApiDto } from '@b2x/storefront-api-js-client/src';

import { appConfig } from '../config';
import { GoogleAdsContentType } from '../contentTypes';
import { env } from '../env';
import { addScript, documentHeadHasScript } from '../util';
import { AnalyticsEventsProps, InitCommonProps } from './analytics';

let initialized = false;
let id: string | undefined = undefined;
let debug = false;
let content: ContentApiDto<GoogleAdsContentType> | undefined = undefined;

interface InitProps extends InitCommonProps {
  id?: string;
}
export type GoogleAdsInitProps = InitProps;

const init = (props?: InitProps): void => {
  const _id = props?.id ?? env.REACT_APP_GOOGLE_ADS_ID;
  debug = props?.debug || false;
  if (_id) {
    if (!initialized) {
      initialized = true;
      id = _id;

      const contentCode = 'GOOGLE_ADS_CONTENT';

      api.contents
        .getByCode<GoogleAdsContentType>(contentCode, { forceDefaultLanguage: true })
        .then((response) => {
          content = response.data;
          install();
        })
        .catch(() => {
          log(`Not installed because no content with code ${contentCode} found.`);
        });
    }
  }
};

const install = () => {
  if (!documentHeadHasScript('gtag')) {
    const queryParams = env.REACT_APP_GOOGLE_ADS_QUERY_PARAMS;

    addScript({
      async: true,
      id: 'gtag',
      src: `https://www.googletagmanager.com/gtag/js?id=${id}${queryParams}`,
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.dataLayer = window.dataLayer || [];

    // Inizio gestione valori di default per Google Consent Mode v2
    if (appConfig.consentMode) {
      gtag('consent', 'default', {
        ad_personalization: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'granted',
        wait_for_update: 500,
      });
      gtag('set', 'ads_data_redaction', true);
      gtag('set', 'url_passthrough', true);
    }
    // Fine gestione valori di default per Google consent Mode v2

    gtag('js', new Date());
  }
  gtag('config', id);
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, func-style
function gtag(...args: Array<unknown>) {
  if (initialized) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
    log(args);
  } else {
    log('Blocked because not initialized.', args);
  }
}

// const config = () => {
//   gtag('config', id);
// };

export interface GoogleAdsData {
  currency?: string;
  transaction_id?: string;
  value?: number;
}

const reportConversion = (name: keyof AnalyticsEventsProps, data: GoogleAdsData) => {
  if (initialized) {
    if (content === undefined) {
      log('Initialized but no content loaded.', name);
    } else {
      const code = content.body[name];
      if (code === undefined || code === '') {
        log('No mapping in content.', name);
      } else {
        gtag('event', 'conversion', {
          currency: data.currency ?? (data.value !== undefined ? 'EUR' : undefined),
          send_to: `${id}/${code}`,
          transaction_id: data.transaction_id,
          value: data.value,
        });
      }
    }
  } else {
    log('Blocked because not initialized.', name);
  }
};

const log = (message?: unknown, ...optionalParams: Array<unknown>) => {
  if (debug) {
    console.log('analytics', 'googleAds:', message, ...optionalParams);
  }
};

export const googleAds: {
  gtagReportConversion(event: keyof AnalyticsEventsProps, data: GoogleAdsData): void;
  init(props?: InitProps): void;
} = {
  gtagReportConversion: (event, data) => {
    reportConversion(event, data);
  },
  init: (props) => {
    init(props);
  },
};
