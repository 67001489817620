import React from 'react';

import { createContext } from './util';

export type LightboxItemsDimensions = Record<string, { height: number; width: number } | undefined>;

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface LightboxContextInterface {
  allDimensions: LightboxItemsDimensions;
  cursor: React.CSSProperties['cursor'];
  id: string;
  setAllDimensions: React.Dispatch<React.SetStateAction<LightboxItemsDimensions>>;
}

export const [LightboxContextProvider, useLightboxContext] = createContext<LightboxContextInterface>('LightboxContext');

// Proprietà necessarie per creare il contesto
interface UseLightboxContextInitializerProps {
  allDimensions: LightboxItemsDimensions;
  cursor: React.CSSProperties['cursor'];
  id: string;
  setAllDimensions: React.Dispatch<React.SetStateAction<LightboxItemsDimensions>>;
}

// Funzione per inizializzare il contesto
const useLightboxContextInitializer = (props: UseLightboxContextInitializerProps) => {
  // Crea l'oggetto lightboxContext
  const lightboxContext: LightboxContextInterface = { ...props };

  return {
    LightboxContextProvider: LightboxContextProvider,
    lightboxContext,
  };
};

// Interfaccia componente contesto
export interface LightboxContextProps extends UseLightboxContextInitializerProps {
  children: React.ReactNode | ((lightboxContext: LightboxContextInterface) => React.ReactNode);
}

// Componente Contesto
export const LightboxContext = ({ children, ...otherProps }: LightboxContextProps) => {
  const lightboxContextInitializer = useLightboxContextInitializer(otherProps);
  return (
    <lightboxContextInitializer.LightboxContextProvider value={lightboxContextInitializer.lightboxContext}>
      {typeof children === 'function' ? children(lightboxContextInitializer.lightboxContext) : children}
    </lightboxContextInitializer.LightboxContextProvider>
  );
};
