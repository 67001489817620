import { PageApiDto } from '@b2x/storefront-api-js-client/src';
import { GetPageOptions } from '@b2x/storefront-api-js-client/src/pages';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { usePagesApi } from './api/usePagesApi';
import { useStable } from './util';

export interface UsePageByCodeOptions extends GetPageOptions {}

export const usePageByCode = <T,>(code: string, options?: UsePageByCodeOptions, config?: ApiRequestConfig) => {
  const [page, setPage] = React.useState<PageApiDto<T>>();

  const { getPageByCode } = usePagesApi();

  options = useStable(options);
  config = useStable(config);

  React.useEffect(() => {
    getPageByCode<T>(code, options, config)
      .then((response) => {
        setPage(response.data);
      })
      .catch(() => {
        // pazienza
      });
  }, [code, config, getPageByCode, options]);

  return page;
};
