import { useRemounter, UseRemounterProps } from './useRemounter';

export interface RemounterProps extends UseRemounterProps {
  children?: React.ReactNode;
}

export const Remounter = ({ children, ...otherProps }: RemounterProps) => {
  const mount = useRemounter(otherProps);

  return <>{mount && children}</>;
};
