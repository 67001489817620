import React from 'react';

interface InputGroupContextInterface {}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [InputGroupContextProvider, , useInputGroupContextStrict] =
  createContext<InputGroupContextInterface>('InputGroupContext');

interface UseInputGroupContextInitializerProps {}

const useInputGroupContextInitializer = (props: UseInputGroupContextInitializerProps) => {
  const inputGroupContext: InputGroupContextInterface = {};

  return {
    InputGroupContextProvider,
    inputGroupContext,
  };
};

export interface InputGroupContextProps extends UseInputGroupContextInitializerProps {
  children: React.ReactNode | ((inputGroupContext: InputGroupContextInterface) => React.ReactNode);
}

export const InputGroupContext = ({ children, ...otherProps }: InputGroupContextProps) => {
  const inputGroupContextInitializer = useInputGroupContextInitializer(otherProps);
  return (
    <inputGroupContextInitializer.InputGroupContextProvider value={inputGroupContextInitializer.inputGroupContext}>
      {typeof children === 'function' ? children(inputGroupContextInitializer.inputGroupContext) : children}
    </inputGroupContextInitializer.InputGroupContextProvider>
  );
};
