// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { GetInfoOptions } from '@b2x/storefront-api-js-client/src/info';
import React from 'react';

// import { useAppStaticContext } from '../AppContext';
import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useInfoApi = () => {
  const { apiRequest } = useApiRequest();
  // const { setInfo } = useAppStaticContext();

  const getInfo = React.useCallback(
    (options?: GetInfoOptions, config?: ApiRequestConfig) =>
      apiRequest(api.info.get(options), { ...config }).then((response) => {
        // setInfo(response.data);
        return response;
      }),

    [apiRequest]
  );

  const getSampleShippingCountries = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.info.getSampleShippingCountries(), { ...config }),
    [apiRequest]
  );

  const getCountryProvinces = React.useCallback(
    (countryCode: string, config?: ApiRequestConfig) =>
      apiRequest(api.info.getCountryProvinces(countryCode), { suppressErrorModal: true, ...config }),
    [apiRequest]
  );

  const getProvinceZipCodes = React.useCallback(
    (provinceCode: string, config?: ApiRequestConfig) =>
      apiRequest(api.info.getProvinceZipCodes(provinceCode), { suppressErrorModal: true, ...config }),
    [apiRequest]
  );

  const getBankAccountDetails = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.info.getBankAccountDetails(), { ...config }),
    [apiRequest]
  );

  const checkComingSoon = React.useCallback(
    (previewCode: string, config?: ApiRequestConfig) =>
      apiRequest(api.info.checkComingSoon(previewCode), { silent: true, ...config }),
    [apiRequest]
  );

  return {
    checkComingSoon,
    getBankAccountDetails,
    getCountryProvinces,
    getInfo,
    getProvinceZipCodes,
    getSampleShippingCountries,
  };
};
