import { ImageThronContentSection } from './contentTypes';
import { ImageThron, ImageThronProps } from './ImageThron';

export interface ImageThronFromContentProps extends ImageThronContentSection, ImageThronProps {}

export const ImageThronFromContent = ({ lg, md, sm, xl, xs, xxl, ...otherProps }: ImageThronFromContentProps) => {
  const tokenizeAspectRatio = (input: string): number | undefined => {
    // Rimuove tutti gli spazi bianchi dall'input
    const cleanInput = input.replace(/\s/g, '');
    const parts = cleanInput.split(':');

    if (parts.length !== 2) {
      return;
    }

    const [numerator, denominator] = parts.map(parseFloat);

    if (isNaN(numerator) || isNaN(denominator) || denominator === 0) {
      return;
    }
    return numerator / denominator;
  };

  return (
    <>
      {xs?.url && (
        <ImageThron
          {...otherProps}
          aspectRatio={{
            lg: lg?.aspectRatio ? tokenizeAspectRatio(lg.aspectRatio) : undefined,
            md: md?.aspectRatio ? tokenizeAspectRatio(md.aspectRatio) : undefined,
            sm: sm?.aspectRatio ? tokenizeAspectRatio(sm.aspectRatio) : undefined,
            xl: xl?.aspectRatio ? tokenizeAspectRatio(xl.aspectRatio) : undefined,
            xs: xs.aspectRatio ? tokenizeAspectRatio(xs.aspectRatio) : undefined,
            xxl: xxl?.aspectRatio ? tokenizeAspectRatio(xxl.aspectRatio) : undefined,
          }}
          quality={{
            lg: lg?.quality,
            md: md?.quality,
            sm: sm?.quality,
            xl: xl?.quality,
            xs: xs.quality,
            xxl: xxl?.quality,
          }}
          src={{
            lg: lg?.url,
            md: md?.url,
            sm: sm?.url,
            xl: xl?.url,
            xs: xs.url,
            xxl: xxl?.url,
          }}
        />
      )}
    </>
  );
};
