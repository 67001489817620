import React from 'react';
import { Link as _Link, LinkProps as _LinkProps } from 'react-router-dom-5';

import { scrollTop as _scrollTop, wait } from '../util';
import { getBase } from './AppHelpers';

export interface LinkProps extends _LinkProps {
  scrollTop?: boolean;
}

export const Link = ({ onClick, scrollTop, to, ...otherProps }: LinkProps) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      onClick && onClick(event);
      scrollTop && wait(150).then(() => _scrollTop());
    },
    [onClick, scrollTop]
  );

  // eslint-disable-next-line react/jsx-pascal-case
  return <_Link {...otherProps} onClick={handleClick} to={getBase() + to} />;
};
