import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { structuredData } from './structuredData/structuredData';
import { createContext } from './util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface ProductContextInterface {
  selectedProductVariant: ProductApiDto;
  selectedSku?: SkuApiDto;
  setSelectedProductVariant: React.Dispatch<React.SetStateAction<ProductApiDto>>;
  setSelectedSku: React.Dispatch<React.SetStateAction<SkuApiDto | undefined>>;
}

export const [ProductContextProvider, useProductContext, useProductContextStrict] =
  createContext<ProductContextInterface>('ProductContext');

// Proprietà necessarie per creare il contesto
interface UseProductContextInitializerProps {
  product: ProductApiDto;
}

// Funzione per inizializzare il contesto
const useProductContextInitializer = ({ product }: UseProductContextInitializerProps) => {
  // Crea l'oggetto productContext
  const [selectedProductVariant, setSelectedProductVariant] = React.useState<ProductApiDto>(product);
  const [selectedSku, setSelectedSku] = React.useState<SkuApiDto>();

  const productContext: ProductContextInterface = React.useMemo(
    () => ({
      selectedProductVariant,
      selectedSku,
      setSelectedProductVariant,
      setSelectedSku,
    }),
    [selectedProductVariant, selectedSku]
  );

  return {
    ProductContextProvider,
    productContext,
  };
};

// Interfaccia componente contesto
export interface ProductContextProps extends UseProductContextInitializerProps {
  children: React.ReactNode | ((productContext: ProductContextInterface) => React.ReactNode);
}

// Componente Contesto
export const ProductContext = ({ children, ...otherProps }: ProductContextProps) => {
  const productContextInitializer = useProductContextInitializer(otherProps);
  return (
    <productContextInitializer.ProductContextProvider value={productContextInitializer.productContext}>
      <structuredData.Product
        product={productContextInitializer.productContext.selectedProductVariant}
        selectedSku={productContextInitializer.productContext.selectedSku}
      />
      {typeof children === 'function' ? children(productContextInitializer.productContext) : children}
    </productContextInitializer.ProductContextProvider>
  );
};
