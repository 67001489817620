import { CouponApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { b2x } from '..';
import { useCartApi } from '../api/useCartApi';
import { useAppContext } from '../AppContext';
import { t } from '../i18n/i18n';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Radio, RadioProps } from './fields/RadioCheck';
import { formikString, getInitialString } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface PersonalCouponsFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  appliedCoupon: CouponApiDto | undefined;
  disableRemoveCouponRadio?: boolean;
}

interface FormValues {
  code: formikString;
}

type ValidationSchema = {
  code: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  code: {
    formFields: Array<{ coupon?: b2x.CouponApiDto; radio: RadioProps }>;
    formGroup: FormGroupProps;
  };
}

export const PersonalCouponsFormHelper = ({
  appliedCoupon,
  children,
  className,
  disableRemoveCouponRadio = false,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: PersonalCouponsFormProps) => {
  const [coupons, setCoupons] = React.useState<Array<CouponApiDto>>([]);

  const { applyCoupon, getCoupons, removeCoupon } = useCartApi();

  const { session } = useAppContext();

  // Prendo i coupon al primo caricamento e quando cambia il customer in sessione (dopo una login)
  React.useEffect(() => {
    getCoupons().then((response) => {
      setCoupons(response.data);
    });
  }, [getCoupons, session?.customer]);

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      code: getInitialString(appliedCoupon?.code),
      ...initialValues,
    }),
    [appliedCoupon?.code, initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      code: yup.string(),
    }),
    []
  );

  const handleSuccess = React.useCallback(() => {
    onSuccess && onSuccess();
  }, [onSuccess]);

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      if (values.code === 'NO_COUPON') {
        return removeCoupon().then(() => {
          handleSuccess();
        });
      } else {
        return applyCoupon({ couponCode: values.code }).then(() => {
          handleSuccess();
        });
      }
    },
    [applyCoupon, handleSuccess, removeCoupon]
  );

  const ref = React.useRef<HTMLFormElement>(null);
  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('personal-coupons-form', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      submitOnChange
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          code: {
            formFields: [
              // Il coupon attualmente applicato, se non è tra quelli personali (che mostro nel ciclo successivo).
              ...(appliedCoupon && !coupons.some((coupon) => coupon.id === appliedCoupon.id)
                ? [
                    {
                      coupon: appliedCoupon,
                      radio: {
                        id: appliedCoupon.id,
                        label: appliedCoupon.code,
                        name: 'code',
                        value: appliedCoupon.code,
                      },
                    },
                  ]
                : []),
              // I coupon personali.
              ...coupons.map((coupon) => ({
                coupon: coupon,
                radio: {
                  id: coupon.id,
                  label: coupon.code,
                  name: 'code',
                  value: coupon.code,
                },
              })),

              // Se ho un coupon applicato, mostro il radio per toglierlo.
              ...(appliedCoupon && !disableRemoveCouponRadio
                ? [
                    {
                      radio: {
                        id: 'NO_COUPON',
                        label: t('form.cartPersonalCouponsForm.misc.noCoupon'),
                        name: 'code',
                        value: 'NO_COUPON',
                      },
                    },
                  ]
                : []),
            ],
            formGroup: { label: 'Personal coupons', names: ['code'], omitForAttribute: true },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <FormGroup {...fieldsHelper.code.formGroup}>
            {fieldsHelper.code.formFields.map(({ coupon, radio }) => (
              <Radio key={radio.id} {...radio} />
            ))}
          </FormGroup>
        );
      }}
    </HelpedForm>
  );
};

export type PersonalCouponsFormVariants = '';

const PersonalCouponsFormController = (props: PropsWithCustomComponentWithoutChildren<PersonalCouponsFormProps>) => (
  <VariantsController<PersonalCouponsFormProps, PersonalCouponsFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: PersonalCouponsFormHelper,
      name: 'PersonalCouponsForm',
    }}
  />
);
export { PersonalCouponsFormController as PersonalCouponsForm };
