import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import { ObjectShape } from 'yup/lib/object';

import { Form, FormProps } from './Form';

export interface HelpedFormProps<FormValues, ValidationSchema> extends FormProps<FormValues, ValidationSchema> {}

export const HelpedForm = <FormValues extends FormikValues, ValidationSchema extends ObjectShape = ObjectShape>({
  ...otherProps
}: HelpedFormProps<FormValues, ValidationSchema>): React.ReactElement => {
  return <Form<FormValues> {...otherProps} />;
};

// eslint-disable-next-line @typescript-eslint/ban-types
// export type HelpedFormChildren<FormValues, FieldsHelper, T = {}> = (
//   props: {
//     closeModal(): void;
//     fieldsHelper: FieldsHelper;
//     formik: FormikProps<FormValues>;
//     insideModal: boolean;
//   } & T
// ) => React.ReactNode;

// eslint-disable-next-line @typescript-eslint/ban-types
export interface BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector, ChildrenProps = {}> {
  children?(
    props: {
      closeModal(): void;
      fieldsHelper: FieldsHelper;
      formik: FormikProps<FormValues>;
      insideModal: boolean;
      onSuccess?(): void;
    } & ChildrenProps
  ): React.ReactNode;
  className?: string;
  //  disableLabel?: boolean;
  initialValues?: Partial<FormValues>;
  onCancel?(): void;
  onSuccess?(): void;
  singleColumn?: boolean;
  validationSchemaSelector?: ValidationSchemaSelector;
}
