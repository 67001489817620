import React from 'react';

import { createContext } from '../util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface MapContextInterface {
  map: google.maps.Map;
}

export const [MapContextProvider, useMapContext] = createContext<MapContextInterface>('MapContext');

// Proprietà necessarie per creare il contesto
interface UseMapContextInitializerProps {
  map: google.maps.Map;
}

// Funzione per inizializzare il contesto
const useMapContextInitializer = ({ map }: UseMapContextInitializerProps) => {
  // Crea l'oggetto mapContext
  const mapContext: MapContextInterface = React.useMemo(() => ({ map }), [map]);

  return {
    MapContextProvider,
    mapContext,
  };
};

// Interfaccia componente contesto
export interface MapContextProps extends UseMapContextInitializerProps {
  children: React.ReactNode | ((mapContext: MapContextInterface) => React.ReactNode);
}

// Componente Contesto
export const MapContext = ({ children, ...otherProps }: MapContextProps) => {
  const mapContextInitializer = useMapContextInitializer(otherProps);
  return (
    <mapContextInitializer.MapContextProvider value={mapContextInitializer.mapContext}>
      {typeof children === 'function' ? children(mapContextInitializer.mapContext) : children}
    </mapContextInitializer.MapContextProvider>
  );
};
