import classNames from 'classnames';

import { Col } from '../Col';
import { Container } from '../Container';
import { PageWithSubpageContext } from '../PageWithSubpageContext';
import { PageWithSubpageHeader } from '../PageWithSubpageHeader';
import { Route, Routes } from '../router/router';
import { Row } from '../Row';
import { useMenu } from '../useMenu';
import { usePageByCode } from '../usePageByCode';
import { renderUI } from '../util';
import { Box } from './accountSubpages/accountSubpagesA/Box';
import { ServiceContactsSubpage } from './serviceSubpages/ServiceContactsSubpage';
import { ServiceCookieSubpage } from './serviceSubpages/ServiceCookieSubpage';
import { ServiceFaqSubpage } from './serviceSubpages/ServiceFaqSubpage';
import { ServiceGenericSubpage } from './serviceSubpages/ServiceGenericSubpage';
import { ServiceIndexSubpage } from './serviceSubpages/ServiceIndexSubpage';
import { ServiceSearchOrderSubpage } from './serviceSubpages/ServiceSearchOrderSubpage';
import { ListC } from './serviceSubpages/serviceSubpagesA/ListC';

export interface ServicePageAProps {}

export const ServicePageA = (props: ServicePageAProps) => {
  const customerCareMenu = useMenu('MENU_CUSTOMER_CARE', undefined, { suppressErrorModal: true });
  const legalAreaMenu = useMenu('MENU_LEGAL_AREA', undefined, { suppressErrorModal: true });

  const customerCarePage = usePageByCode(
    'SITE_CUSTOMER_CARE',
    { populate: { children: true } },
    { suppressErrorModal: true }
  );
  const legalAreaPage = usePageByCode(
    'SITE_LEGAL_AREA',
    { populate: { children: true } },
    { suppressErrorModal: true }
  );

  return renderUI({
    bs5: (
      <PageWithSubpageContext>
        <PageWithSubpageHeader />
        <Container>
          <Row>
            <Col className="d-none d-lg-block" size={{ lg: 4, xl: 3 }}>
              {customerCareMenu?.children && (
                <div className={classNames('mb-3')}>
                  {customerCareMenu.label && (
                    <ListC
                      items={customerCareMenu.children.map((item) => ({
                        label: item.label,
                        link: item.link,
                      }))}
                      title={customerCareMenu.label}
                    />
                  )}
                </div>
              )}
              {legalAreaMenu?.children && legalAreaMenu.label && (
                <ListC
                  items={legalAreaMenu.children.map((item) => ({
                    label: item.label,
                    link: item.link,
                  }))}
                  title={legalAreaMenu.label}
                />
              )}
            </Col>
            <Col size={{ lg: 8, xl: 9 }}>
              <Box>
                <Routes>
                  {customerCarePage?.children?.map((child) => (
                    <Route
                      element={
                        child.code === 'SITE_CST_INDEX' ? (
                          <ServiceIndexSubpage />
                        ) : child.code === 'SITE_CST_SHIPPING' ? (
                          <ServiceGenericSubpage />
                        ) : child.code === 'SITE_CST_SEARCH_ORDER' ? (
                          <ServiceSearchOrderSubpage />
                        ) : child.code === 'SITE_CST_RETURN' ? (
                          <ServiceGenericSubpage />
                        ) : child.code === 'SITE_CST_PAYMENTS' ? (
                          <ServiceGenericSubpage />
                        ) : child.code === 'SITE_CST_FAQ' ? (
                          <ServiceFaqSubpage />
                        ) : child.code === 'SITE_CST_SIZE_GUIDE' ? (
                          <ServiceFaqSubpage />
                        ) : child.code === 'SITE_CST_CONTACT' ? (
                          <ServiceContactsSubpage />
                        ) : (
                          <ServiceGenericSubpage />
                        )
                      }
                      index={child.code === 'SITE_CST_INDEX'}
                      key={child.code}
                      path={child.path}
                    />
                  ))}
                  {legalAreaPage?.children?.map((child) => (
                    <Route
                      element={
                        child.code === 'SITE_LGL_INDEX' ? (
                          <ServiceIndexSubpage />
                        ) : child.code === 'SITE_LGL_PRIVACY' ? (
                          <ServiceGenericSubpage />
                        ) : child.code === 'SITE_LGL_COOKIE' ? (
                          <ServiceCookieSubpage />
                        ) : child.code === 'SITE_LGL_CONDITIONS' ? (
                          <ServiceGenericSubpage />
                        ) : child.code === 'SITE_LGL_TERMS' ? (
                          <ServiceGenericSubpage />
                        ) : (
                          <ServiceGenericSubpage />
                        )
                      }
                      index={child.code === 'SITE_LGL_INDEX'}
                      key={child.code}
                      path={child.path}
                    />
                  ))}
                </Routes>
              </Box>
            </Col>
          </Row>
        </Container>
      </PageWithSubpageContext>
    ),
  });
};
