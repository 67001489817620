import classnames from 'classnames';

import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { CommonFieldProps, HTMLAutocomplete, useField } from '../Form';
import { useFormGroupContext } from '../FormGroupContext';

export interface TextAreaProps<V = string> extends CommonFieldProps<HTMLTextAreaElement, V> {
  autoComplete?: HTMLAutocomplete;
  placeholder?: string;
  rows: number;
}
export const TextArea = (props: TextAreaProps): React.ReactElement => {
  const { className, innerRef, onFormikChange, placeholder, size, ...otherProps } = props;
  const { b2xHelpers, field } = useField(props, otherProps);
  const { id, required, withLabel } = useFormGroupContext();

  return renderUI({
    bs5: (
      <>
        <textarea
          {...field}
          {...props}
          className={classnames(
            'form-control',
            { 'form-control-sm': size === 'small' },
            { 'form-control-lg': size === 'large' },
            { 'is-valid': b2xHelpers.isValid },
            { 'is-invalid': b2xHelpers.isInvalid },
            className
          )}
          id={id}
          onChange={b2xHelpers.handleChange}
          placeholder={!withLabel && required ? `${placeholder} *` : placeholder}
          ref={innerRef}
        />
        <Feedback name={props.name} />
      </>
    ),
  });
};
