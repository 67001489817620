// import { Locale } from './interfaces';

import { getPrefixedKey } from './cookies';

/**
 * Funzioni interne
 */
const getItem = (key: string, persistent = false): string | undefined => {
  const prefixedKey = getPrefixedKey(key);
  const item = persistent ? window.localStorage.getItem(prefixedKey) : window.sessionStorage.getItem(prefixedKey);
  return item === null ? undefined : item;
};
const getItemNotUndefined = (key: string, persistent = false): string => {
  const item = getItem(key, persistent);
  if (item === undefined) throw new Error(`${key} not found in storage`);
  return item;
};
const setItem = (key: string, value: string, persist = false) => {
  const prefixedKey = getPrefixedKey(key);
  if (persist) {
    window.localStorage.setItem(prefixedKey, value);
  } else {
    window.sessionStorage.setItem(prefixedKey, value);
  }
};
const removeItem = (key: string, persistent = false) => {
  const prefixedKey = getPrefixedKey(key);
  if (persistent) {
    window.localStorage.removeItem(prefixedKey);
  } else {
    window.sessionStorage.removeItem(prefixedKey);
  }
};

/**
 * String
 */

const getString = (key: string, persistent?: boolean): string | undefined => {
  return getItem(key, persistent);
};

const getStringNotUndefined = (key: string, persistent?: boolean): string => {
  return getItemNotUndefined(key, persistent);
};

const setString = (key: string, value: string, persist?: boolean) => {
  return setItem(key, value, persist);
};

/**
 * Number
 */

const getNumber = (key: string, persistent?: boolean): number | undefined => {
  const value = getItem(key, persistent);
  if (value) {
    return parseInt(value);
  } else {
    return undefined;
  }
};

const getNumberNotUndefined = (key: string, persistent?: boolean): number => {
  const value = getItemNotUndefined(key, persistent);
  return parseInt(value);
};

const setNumber = (key: string, value: number, persist?: boolean) => {
  return setItem(key, value.toString(), persist);
};

/**
 * Enum
 */

const getEnum = <T extends string>(key: string, persistent?: boolean): T | undefined => {
  const value = getItem(key, persistent);
  if (value) {
    return value as T;
  } else {
    return undefined;
  }
};

const getEnumNotUndefined = <T extends string>(key: string, persistent?: boolean): T => {
  const value = getItemNotUndefined(key, persistent);
  return value as T;
};

const setEnum = <T extends string>(key: string, value: T, persist?: boolean) => {
  return setItem(key, value, persist);
};

/**
 * Boolean
 */

const getBoolean = (key: string, persistent?: boolean): boolean => {
  const value = getItem(key, persistent);
  if (value && JSON.parse(value)) {
    return true;
  } else {
    return false;
  }
};

const setBoolean = (key: string, value: boolean, persist?: boolean) => {
  return setItem(key, JSON.stringify(value), persist);
};

/**
 * Object
 */

const getObject = <T>(key: string, persistent?: boolean): T | undefined => {
  const value = getItem(key, persistent);
  if (value) {
    return JSON.parse(value);
  } else {
    return undefined;
  }
};

const getObjectNotUndefined = <T>(key: string, persistent?: boolean): T => {
  const value = getItemNotUndefined(key, persistent);
  return JSON.parse(value);
};

const setObject = <T>(key: string, value: T, persist?: boolean) => {
  return setItem(key, JSON.stringify(value), persist);
};

/**
 * Da qui iniziano i metodi dedicati da esportare
 */

export const storage = {
  getBoolean,
  getEnum,
  getEnumNotUndefined,
  getNumber,
  getNumberNotUndefined,
  getObject,
  getObjectNotUndefined,
  getString,
  getStringNotUndefined,
  removeItem,
  setBoolean,
  setEnum,
  setNumber,
  setObject,
  setString,
};
