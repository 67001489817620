import { Accordion, AccordionItem } from '../../../Accordion';
import { FaqSubpageContentType } from '../../../contentTypes';
import { formatHtml, renderUI } from '../../../util';
import { ServiceSubpage } from '../ServiceSubpage';

export interface ServiceFaqSubpageAProps {}

export const ServiceFaqSubpageA = (props: ServiceFaqSubpageAProps) => {
  return renderUI({
    bs5: (
      <ServiceSubpage<FaqSubpageContentType>>
        {(page) => (
          <>
            {page.content?.body.topics &&
              page.content.body.topics.map((topic, firstIndex) => (
                <div key={topic.contentSectionId}>
                  <h6 className="faq-title mb-3">{topic.title}</h6>
                  {topic.questions && (
                    <Accordion
                      accordionColClassName="p-0 px-lg-2"
                      className="mb-5"
                      id="AccordionFaq"
                      itemBodyClassName="pt-0"
                    >
                      {topic.questions.map(
                        (item, secondIndex) =>
                          item.question && (
                            <AccordionItem
                              id={`accordionItem-${firstIndex}-${secondIndex}`}
                              key={item.question}
                              title={`${secondIndex + 1} - ${item.question}`}
                            >
                              <div className="answer">{formatHtml(item.answer)}</div>
                            </AccordionItem>
                          )
                      )}
                    </Accordion>
                  )}
                </div>
              ))}
          </>
        )}
      </ServiceSubpage>
    ),
  });
};
