import './CheckoutStep.scss';

import classnames from 'classnames';
import React from 'react';

import { Button } from '../../../Button';
import { CheckoutStep as CheckoutStepType, useCheckoutContext } from '../../../CheckoutContext';
import { ResourceKeys, t } from '../../../i18n/i18n';
import { renderUI } from '../../../util';

export interface CheckoutStepProps {
  children?: React.ReactNode;
  completed?: boolean;
  enabled?: boolean;
  hideEditButton?: boolean;
  number: number;
  onEdit?: boolean;
  step: CheckoutStepType;
}

export const CheckoutStep = ({
  children,
  completed,
  enabled,
  hideEditButton,
  number,
  onEdit,
  step,
}: CheckoutStepProps) => {
  const { deleteForcedStep, setForcedStep } = useCheckoutContext();

  const handleEditButtonClick = React.useCallback(() => {
    setForcedStep(step);
  }, [setForcedStep, step]);

  return renderUI({
    bs5: (
      <div className="checkout-step">
        <div className={classnames('card bg-transparent', { 'border-bottom border-light': !onEdit && number !== 5 })}>
          <div
            className={classnames('card-header border-bottom-0 bg-transparent py-3', { 'pb-0': !onEdit && enabled })}
          >
            <div className={classnames('hstack gap-3 fw-semi-bold', { 'step-disabled': !enabled })}>
              {number !== 5 && <span className="step-number">{number}</span>}
              {number !== 5 && <span className="step-title">{t(`checkout.step.${step}.title` as ResourceKeys)}</span>}
              {!onEdit && enabled && !hideEditButton && (
                <Button
                  className="ms-auto extra-small text-dark d-none d-lg-inline text-decoration-underline"
                  label={t('checkout.edit')}
                  onClick={handleEditButtonClick}
                  variant="blank"
                />
              )}
              {onEdit && completed && !hideEditButton && (
                <Button
                  className="ms-auto extra-small text-dark d-none d-lg-inline text-decoration-underline"
                  label={t('checkout.cancel')}
                  onClick={deleteForcedStep}
                  variant="blank"
                />
              )}
            </div>
          </div>
          <div
            className={classnames(
              'card-body',
              { show: enabled },
              { 'bg-white p-3': enabled && onEdit && number !== 5 },
              { 'ps-5 pt-0': enabled && !onEdit }
            )}
          >
            {children}
            <div className="d-lg-none mt-2">
              {!onEdit && enabled && !hideEditButton && (
                <Button
                  className="ms-auto extra-small text-dark text-decoration-underline"
                  label={t('checkout.edit')}
                  onClick={handleEditButtonClick}
                  variant="blank"
                />
              )}
              {onEdit && completed && !hideEditButton && (
                <Button
                  className="ms-auto extra-small text-dark text-decoration-underline"
                  label={t('checkout.cancel')}
                  onClick={deleteForcedStep}
                  variant="blank"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    ),
  });
};
