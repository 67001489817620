import { PageApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { useAppContext } from '../../AppContext';
import { Fetching } from '../../Fetching';
import { usePageWithSubpageContext } from '../../PageWithSubpageContext';
import { usePage } from '../../usePage';

export interface ServiceSubpageProps<T> {
  children: ((page: PageApiDto<T>) => React.ReactNode) | React.ReactNode;
}

export const ServiceSubpage = <T,>({ children }: ServiceSubpageProps<T>) => {
  const { setMobileBackLink, setPageTitle, setShowMobileBackLink, setShowSubpageTitle, setSubpageTitle } =
    usePageWithSubpageContext();

  const page = usePage<T>({ populate: { breadcrumb: true } });
  const { session } = useAppContext();

  React.useEffect(() => {
    setShowSubpageTitle(true);
    setSubpageTitle(page?.name);
    page?.breadcrumb && setPageTitle(page.breadcrumb[page.breadcrumb.length - 2].name);
    setShowMobileBackLink(true);
    page?.breadcrumb &&
      setMobileBackLink({
        children: page.breadcrumb[1].name,
        to: page.breadcrumb[1].fullPath,
      });
  }, [
    page?.breadcrumb,
    page?.name,
    setPageTitle,
    setSubpageTitle,
    setShowSubpageTitle,
    setShowMobileBackLink,
    setMobileBackLink,
    session?.customer?.name,
  ]);

  return <Fetching item={page}>{page && typeof children === 'function' ? children(page) : children}</Fetching>;
};
