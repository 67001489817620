import { analytics } from './analytics/analytics';

interface CookieConsentsData {
  consents: {
    marketing: boolean | undefined;
    necessary: boolean | undefined;
    preferences: boolean | undefined;
    statistics: boolean | undefined;
  };
  provider: 'Cookiebot' | 'Civic' | undefined;
  regulations: {
    ccpa: boolean | undefined;
    gdpr: boolean | undefined;
    lgpd: boolean | undefined;
  };
}

declare global {
  interface Window {
    b2xCookieConsents: CookieConsentsData | undefined;
  }
}

let analyticsInitialized = false;

const get = () => window.b2xCookieConsents;

const set = (data: CookieConsentsData) => {
  window.b2xCookieConsents = data;
  if (!analyticsInitialized && data.consents.statistics === true) {
    analytics.init();
    analyticsInitialized = true;
  }
};

export const cookieConsents = {
  get,
  set,
};
