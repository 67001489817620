import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface LoadingOverlayProps {}

const LoadingOverlay = (props: LoadingOverlayProps) => {
  return (
    <div
      className="LoadingOverlay"
      style={{
        backgroundColor: 'black',
        bottom: 0,
        color: 'white',
        left: 0,
        opacity: 0.5,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 1100,
      }}
    >
      {/* loading... */}
    </div>
  );
};

export type LoadingOverlayVariants = '';

const LoadingOverlayController = (props: PropsWithCustomComponent<LoadingOverlayProps>) => (
  <VariantsController<LoadingOverlayProps, LoadingOverlayVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: LoadingOverlay,
      name: 'LoadingOverlay',
    }}
  />
);
export { LoadingOverlayController as LoadingOverlay };
