import React from 'react';
import { SwiperClass } from 'swiper/react';

import { useRerenderer } from './useRerenderer';
import { createContext } from './util';

interface SwiperContextInterface {
  navigationNextElRef: React.RefObject<HTMLButtonElement>;
  navigationPrevElRef: React.RefObject<HTMLButtonElement>;
  paginationElRef: React.RefObject<HTMLDivElement>;
  rerender(): void;
  scrollbarElRef: React.RefObject<HTMLDivElement>;
  setSwiper: React.Dispatch<React.SetStateAction<SwiperClass | undefined>>;
  swiper?: SwiperClass;
}

export const [SwiperContextProvider, useSwiperContext] = createContext<SwiperContextInterface>('SwiperContext');

interface UseSwiperContextInitializerProps {}

const useSwiperContextInitializer = (props: UseSwiperContextInitializerProps) => {
  const [swiper, setSwiper] = React.useState<SwiperClass>();

  const navigationPrevElRef = React.useRef<HTMLButtonElement | null>(null);
  const navigationNextElRef = React.useRef<HTMLButtonElement | null>(null);
  const paginationElRef = React.useRef<HTMLDivElement | null>(null);
  const scrollbarElRef = React.useRef<HTMLDivElement | null>(null);

  const { rerender } = useRerenderer();

  const swiperContext: SwiperContextInterface = React.useMemo(
    () => ({
      navigationNextElRef,
      navigationPrevElRef,
      paginationElRef,
      rerender,
      scrollbarElRef,
      setSwiper,
      swiper,
    }),
    [rerender, swiper]
  );

  return {
    SwiperContextProvider,
    swiperContext,
  };
};

export interface SwiperContextProps extends UseSwiperContextInitializerProps {
  children: React.ReactNode | ((swiperContext: SwiperContextInterface) => React.ReactNode);
}

export const SwiperContext = ({ children, ...otherProps }: SwiperContextProps) => {
  const swiperContextInitializer = useSwiperContextInitializer(otherProps);
  return (
    <swiperContextInitializer.SwiperContextProvider value={swiperContextInitializer.swiperContext}>
      {typeof children === 'function' ? children(swiperContextInitializer.swiperContext) : children}
    </swiperContextInitializer.SwiperContextProvider>
  );
};
