import classnames from 'classnames';
import React from 'react';

import { Breakpoint } from './interfaces';
import { renderUI } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  fluid?: Breakpoint | 'always';
}

const Container = ({ children, className, fluid = 'xs', ...otherProps }: ContainerProps) => {
  return renderUI({
    bs5: (
      <div
        {...otherProps}
        className={classnames(
          className,
          { container: fluid === 'xs' },
          { 'container-sm': fluid === 'sm' },
          { 'container-md': fluid === 'md' },
          { 'container-lg': fluid === 'lg' },
          { 'container-xl': fluid === 'xl' },
          { 'container-xxl': fluid === 'xxl' },
          { 'container-fluid': fluid === 'always' }
        )}
      >
        {children}
      </div>
    ),
  });
};

export type ContainerVariants = '';

const ContainerController = (props: PropsWithCustomComponent<ContainerProps>) => (
  <VariantsController<ContainerProps, ContainerVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: Container,
      name: 'Container',
    }}
  />
);
export { ContainerController as Container };
