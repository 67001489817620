import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountLoyaltySubpageA } from './accountSubpagesA/AccountLoyaltySubpageA';
import { AccountLoyaltySubpageB } from './accountSubpagesB/AccountLoyaltySubpageB';

export interface AccountLoyaltySubpageProps {}

export type AccountLoyaltySubpageVariants = 'A' | 'B';

const AccountLoyaltySubpageController = (props: PropsWithCustomComponent<AccountLoyaltySubpageProps>) => (
  <VariantsController<AccountLoyaltySubpageProps, AccountLoyaltySubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountLoyaltySubpageA, B: AccountLoyaltySubpageB },
      name: 'AccountLoyaltySubpage',
    }}
  />
);
export { AccountLoyaltySubpageController as AccountLoyaltySubpage };
