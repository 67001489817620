import { AddressApiDto, CartApiDto, CountryApiDto, ProvinceApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';
import * as yup from 'yup';

import { useInfoApi } from '../api/useInfoApi';
import { useAppContext } from '../AppContext';
import { useCheckoutContextStrict } from '../CheckoutContext';
import { t } from '../i18n/i18n';
import { DynamicFieldProps } from './fields/DynamicField';
import { TextInputProps } from './fields/Input';
import { CheckboxProps, RadiosProps } from './fields/RadioCheck';
import { SelectProps } from './fields/Select';
import {
  formikBoolean,
  formikEnum,
  FormikProps,
  formikString,
  getInitialBoolean,
  getInitialEnum,
  getInitialString,
} from './Form';
import { FormGroupProps } from './FormGroup';

export const useAddressForm = (
  form:
    | 'CustomerAddressForm'
    | 'CustomerAddressFormDuringCheckout'
    | 'CartAddressesForm'
    | 'CartAddressFromAddressBookForm',
  type: 'shipping' | 'billing' | undefined,
  address?: AddressApiDto
) => {
  // const [countries, setCountries] = React.useState<Array<CountryApiDto>>();
  const [provinces, setProvinces] = React.useState<Array<ProvinceApiDto>>();
  const [zipCodes, setZipCodes] = React.useState<Array<string>>();

  // const [fetchingCountries, setFetchingCountries] = React.useState<boolean>(false);
  const [fetchingProvinces, setFetchingProvinces] = React.useState<boolean>(false);
  const [fetchingZipCodes, setFetchingZipCodes] = React.useState<boolean>(false);

  const { info, session, shippingCountry } = useAppContext();
  const { getCountryProvinces, getProvinceZipCodes } = useInfoApi();

  const checkoutContext = useCheckoutContextStrict();

  const isCountryLocked = React.useMemo<boolean>(
    () => form === 'CartAddressesForm' || form === 'CustomerAddressFormDuringCheckout',
    [form]
  );

  const isItaly = React.useMemo(() => shippingCountry?.code === 'IT', [shippingCountry?.code]);

  // Metodo per prendere le province in base alla country.
  const getProvinces = React.useCallback(
    (country: string) => {
      if (country === '') {
        setProvinces(undefined);
        setZipCodes(undefined);
        return Promise.resolve(undefined);
      } else {
        setFetchingProvinces(true);
        return getCountryProvinces(country)
          .then((response) => {
            setProvinces(response.data);
            setZipCodes(undefined);
            return response.data;
          })
          .catch(() => {
            setProvinces(undefined);
            setZipCodes(undefined);
            return Promise.resolve(undefined);
          })
          .finally(() => {
            setFetchingProvinces(false);
          });
      }
    },
    [getCountryProvinces]
  );

  // Metodo per prendere i CAP in base alla provincia.
  const getZipCodes = React.useCallback(
    (province: string) => {
      if (province === '') {
        setZipCodes(undefined);
        return Promise.resolve(undefined);
      } else {
        setFetchingZipCodes(true);
        return getProvinceZipCodes(province)
          .then((response) => {
            setZipCodes(response.data);
            return response.data;
          })
          .catch(() => {
            setZipCodes(undefined);
            return Promise.resolve(undefined);
          })
          .finally(() => {
            setFetchingZipCodes(false);
          });
      }
    },
    [getProvinceZipCodes]
  );

  const getAddressFieldsHelper = React.useCallback(
    <T extends { address: AddressFormValues }>(formik: FormikProps<T>): AddressFieldsHelper => {
      return {
        addressLine1: {
          formGroup: { label: t('form.addressForm.address.addressLine1.label'), names: ['address.addressLine1'] },
          textInput: { maxLength: 50, name: 'address.addressLine1' },
        },
        addressLine2: {
          formGroup: { label: t('form.addressForm.address.addressLine2.label'), names: ['address.addressLine2'] },
          textInput: { maxLength: 50, name: 'address.addressLine2' },
        },
        addressLine3: {
          formGroup: { label: t('form.addressForm.address.addressLine3.label'), names: ['address.addressLine3'] },
          textInput: { maxLength: 50, name: 'address.addressLine3' },
        },
        alternativePhone: {
          formGroup: {
            label: t('form.addressForm.address.alternativePhone.label'),
            names: ['address.alternativePhone'],
          },
          textInput: { maxLength: 15, name: 'address.alternativePhone' },
        },
        city: {
          formGroup: { label: t('form.addressForm.address.city.label'), names: ['address.city'] },
          textInput: { maxLength: 40, name: 'address.city' },
        },
        civicNumber: {
          formGroup: { label: t('form.addressForm.address.civicNumber.label'), names: ['address.civicNumber'] },
          textInput: { maxLength: 7, name: 'address.civicNumber' },
        },
        country: {
          formGroup: { label: t('form.addressForm.address.country.label'), names: ['address.country'] },
          select: {
            disabled: isCountryLocked,
            name: 'address.country',
            onFormikInitAndChange: (value, _formik, useCase) => {
              if (useCase === 'change') {
                formik.setFieldValue('address.province', '');
                formik.setFieldValue('address.zipCode', '');
              }
              getProvinces(value);
            },
            options: info?.shippingCountries?.map((country) => ({
              label: country.name,
              value: country.code,
            })),
          },
        },
        latitude: {
          formGroup: { label: t('form.addressForm.address.latitude.label'), names: ['address.latitude'] },
          textInput: { name: 'address.latitude' },
        },
        longitude: {
          formGroup: { label: t('form.addressForm.address.longitude.label'), names: ['address.longitude'] },
          textInput: { name: 'address.longitude' },
        },
        middleName: {
          formGroup: { label: t('form.addressForm.address.middleName.label'), names: ['address.middleName'] },
          textInput: { name: 'address.middleName' },
        },
        name: {
          formGroup: { label: t('form.addressForm.address.name.label'), names: ['address.name'] },
          textInput: { name: 'address.name' },
        },
        phone: {
          formGroup: { label: t('form.addressForm.address.phone.label'), names: ['address.phone'] },
          textInput: { maxLength: 15, name: 'address.phone' },
        },
        province:
          formik.values.address.country === 'IT'
            ? {
                formGroup: {
                  label: t('form.addressForm.address.province.label'),
                  names: ['address.province'],
                  required: true,
                },
                select: {
                  disabled: fetchingProvinces,
                  name: 'address.province',
                  onFormikInitAndChange: (value, _formik, useCase) => {
                    if (useCase === 'change') {
                      formik.setFieldValue('address.zipCode', '');
                    }
                    getZipCodes(value);
                  },
                  options: provinces?.map((province) => ({
                    label: province.name,
                    value: province.code,
                  })),
                },
              }
            : undefined,
        region:
          formik.values.address.country !== 'IT'
            ? {
                formGroup: { label: t('form.addressForm.address.region.label'), names: ['address.region'] },
                textInput: { name: 'address.region' },
              }
            : undefined,
        surname: {
          formGroup: { label: t('form.addressForm.address.surname.label'), names: ['address.surname'] },
          textInput: { name: 'address.surname' },
        },
        zipCode: {
          dynamicField: {
            disabled:
              fetchingZipCodes || (formik.values.address.country === 'IT' && formik.values.address.province === ''),
            fieldProps: zipCodes
              ? {
                  name: 'address.zipCode',
                  options: zipCodes.map((zipCode) => ({
                    label: zipCode,
                    value: zipCode,
                  })),
                }
              : { name: 'address.zipCode' },
            type: zipCodes ? 'select' : 'textInput',
          },
          formGroup: {
            label: t('form.addressForm.address.zipCode.label'),
            names: ['address.zipCode'],
          },
        },
      };
    },
    [
      fetchingProvinces,
      fetchingZipCodes,
      getProvinces,
      getZipCodes,
      info?.shippingCountries,
      isCountryLocked,
      provinces,
      zipCodes,
    ]
  );

  const getInvoiceFieldsHelper = React.useCallback(
    <T extends { invoice: InvoiceFormValues }>(formik: FormikProps<T>): InvoiceFieldsHelper => ({
      data: formik.values.invoice.request
        ? {
            company:
              formik.values.invoice.type === 'company' || formik.values.invoice.type === 'freelance'
                ? {
                    formGroup: {
                      label: t('form.addressForm.invoice.data.company.label'),
                      names: ['invoice.data.company'],
                    },
                    textInput: { name: 'invoice.data.company' },
                  }
                : undefined,
            pec:
              isItaly && (formik.values.invoice.type === 'company' || formik.values.invoice.type === 'freelance')
                ? {
                    formGroup: {
                      label: t('form.addressForm.invoice.data.pec.label'),
                      names: ['invoice.data.pec'],
                    },
                    textInput: { name: 'invoice.data.pec' },
                  }
                : undefined,
            taxCode: isItaly
              ? {
                  formGroup: {
                    label: t('form.addressForm.invoice.data.taxCode.label'),
                    names: ['invoice.data.taxCode'],
                  },
                  textInput: {
                    disabled:
                      checkoutContext?.cart?.invoiceRequired &&
                      checkoutContext.cart.invoiceTypes?.length === 1 &&
                      checkoutContext.cart.invoiceTypes.includes('PRIVATE'),
                    name: 'invoice.data.taxCode',
                  },
                }
              : undefined,
            uniqueCode:
              isItaly && (formik.values.invoice.type === 'company' || formik.values.invoice.type === 'freelance')
                ? {
                    formGroup: {
                      label: t('form.addressForm.invoice.data.uniqueCode.label'),
                      names: ['invoice.data.uniqueCode'],
                    },
                    textInput: { name: 'invoice.data.uniqueCode' },
                  }
                : undefined,
            vatNumber:
              formik.values.invoice.type === 'company' || formik.values.invoice.type === 'freelance'
                ? {
                    formGroup: {
                      label: t('form.addressForm.invoice.data.vatNumber.label'),
                      names: ['invoice.data.vatNumber'],
                    },
                    textInput: { name: 'invoice.data.vatNumber' },
                  }
                : undefined,
          }
        : undefined,
      request: {
        checkbox: { id: 'invoiceRequest', label: t('form.addressForm.invoice.request.label'), name: 'invoice.request' },
        formGroup: { names: ['invoice.request'] },
      },
      type:
        formik.values.invoice.request && isItaly
          ? {
              formGroup: { names: ['invoice.type'], omitForAttribute: true },
              radios: {
                radios: [
                  {
                    disabled: !checkoutContext?.cart?.invoiceTypes?.includes('PRIVATE'),
                    id: 'privatePerson',
                    inline: true,
                    label: t('form.addressForm.invoice.type.options.privatePerson'),
                    name: 'invoice.type',
                    // onClick: () => {
                    //   formik.setFieldValue('invoice.data.company', '');
                    //   formik.setFieldValue('invoice.data.vatNumber', '');
                    //   formik.setFieldValue('invoice.data.pec', '');
                    //   formik.setFieldValue('invoice.data.uniqueCode', '');
                    // },
                    value: 'privatePerson',
                  },
                  {
                    disabled: !checkoutContext?.cart?.invoiceTypes?.includes('COMPANY'),
                    id: 'company',
                    inline: true,
                    label: t('form.addressForm.invoice.type.options.company'),
                    name: 'invoice.type',
                    value: 'company',
                  },
                  {
                    disabled: !checkoutContext?.cart?.invoiceTypes?.includes('COMPANY'),
                    id: 'freelance',
                    inline: true,
                    label: t('form.addressForm.invoice.type.options.freelance'),
                    name: 'invoice.type',
                    value: 'freelance',
                  },
                ],
              },
            }
          : undefined,
    }),
    [checkoutContext?.cart?.invoiceRequired, checkoutContext?.cart?.invoiceTypes, isItaly]
  );

  // const [firstRun, setFirstRun] = React.useState<boolean>(true);

  const getAddressInitialValues = React.useCallback(
    (): AddressFormValues => ({
      addressLine1: getInitialString(address?.addressLine1),
      addressLine2: getInitialString(address?.addressLine2),
      addressLine3: getInitialString(address?.addressLine3),
      alternativePhone: getInitialString(address?.alternativePhone),
      city: getInitialString(address?.city),
      civicNumber: getInitialString(address?.civicNumber),
      country: getInitialString(
        // Se sto modificando un indirizzo, imposto la sua country
        address?.country
          ? address.country.code
          : // Altrimenti se ho impostata una shippingCountry, imposto quella
          shippingCountry
          ? shippingCountry.code
          : // Altrimenti se ho una sola country, imposto quella
          info?.shippingCountries?.length === 1
          ? info.shippingCountries.at(0)?.code
          : // Altrimenti non imposto nulla
            ''
      ),
      latitude: getInitialString(address?.latitude?.toString()),
      longitude: getInitialString(address?.longitude?.toString()),
      middleName: getInitialString(address?.middleName),
      name: getInitialString(address?.name),
      phone: getInitialString(address?.phone),
      province: getInitialString(address?.province?.code),
      region: getInitialString(address?.region),
      surname: getInitialString(address?.surname),
      zipCode: getInitialString(address?.zipCode),
    }),
    [
      address?.addressLine1,
      address?.addressLine2,
      address?.addressLine3,
      address?.alternativePhone,
      address?.city,
      address?.civicNumber,
      address?.country,
      address?.latitude,
      address?.longitude,
      address?.middleName,
      address?.name,
      address?.phone,
      address?.province?.code,
      address?.region,
      address?.surname,
      address?.zipCode,
      info?.shippingCountries,
      shippingCountry,
    ]
  );

  const getInvoiceInitialValues = React.useCallback(
    (): InvoiceFormValues => ({
      data: {
        company: getInitialString(
          checkoutContext?.cart?.billingAddress?.company
            ? checkoutContext.cart.billingAddress.company
            : session?.customer?.company
            ? session.customer.company
            : address?.company
        ),
        pec: getInitialString(
          checkoutContext?.cart?.billingAddress?.pec
            ? checkoutContext.cart.billingAddress.pec
            : session?.customer?.pec
            ? session.customer.pec
            : address?.pec
        ),
        taxCode: getInitialString(
          // checkoutContext?.cart?.invoiceRequired ? session?.customer?.taxCode : address?.taxCode
          checkoutContext?.cart?.billingAddress?.taxCode
            ? checkoutContext.cart.billingAddress.taxCode
            : session?.customer?.taxCode
            ? session.customer.taxCode
            : address?.taxCode
        ),
        uniqueCode: getInitialString(
          checkoutContext?.cart?.billingAddress?.uniqueCode
            ? checkoutContext.cart.billingAddress.uniqueCode
            : session?.customer?.uniqueCode
            ? session.customer.uniqueCode
            : address?.uniqueCode
        ),
        vatNumber: getInitialString(
          checkoutContext?.cart?.billingAddress?.vatNumber
            ? checkoutContext.cart.billingAddress.vatNumber
            : session?.customer?.vatNumber
            ? session.customer.vatNumber
            : address?.vatNumber
        ),
      },
      request: getInitialBoolean(
        type === 'billing'
          ? checkoutContext?.cart?.invoice
            ? checkoutContext.cart.invoice
            : checkoutContext?.cart?.invoiceRequired
          : false
      ),
      type: getInitialEnum(
        !isItaly ? 'company' : type === 'billing' ? checkoutContext?.invoiceType ?? 'privatePerson' : undefined
      ),
    }),
    [
      address?.company,
      address?.pec,
      address?.taxCode,
      address?.uniqueCode,
      address?.vatNumber,
      checkoutContext?.cart?.billingAddress?.company,
      checkoutContext?.cart?.billingAddress?.pec,
      checkoutContext?.cart?.billingAddress?.taxCode,
      checkoutContext?.cart?.billingAddress?.uniqueCode,
      checkoutContext?.cart?.billingAddress?.vatNumber,
      checkoutContext?.cart?.invoice,
      checkoutContext?.cart?.invoiceRequired,
      checkoutContext?.invoiceType,
      isItaly,
      session?.customer?.company,
      session?.customer?.pec,
      session?.customer?.taxCode,
      session?.customer?.uniqueCode,
      session?.customer?.vatNumber,
      type,
    ]
  );

  return { getAddressFieldsHelper, getAddressInitialValues, getInvoiceFieldsHelper, getInvoiceInitialValues };
};

export interface AddressFormValues {
  addressLine1: formikString;
  addressLine2: formikString;
  addressLine3: formikString;
  alternativePhone: formikString;
  city: formikString;
  civicNumber: formikString;
  country: formikString;
  latitude: formikString;
  longitude: formikString;
  middleName: formikString;
  name: formikString;
  phone: formikString;
  province: formikString;
  region: formikString;
  surname: formikString;
  zipCode: formikString;
}

export interface InvoiceFormValues {
  data: {
    company: formikString;
    pec: formikString;
    taxCode: formikString;
    uniqueCode: formikString;
    vatNumber: formikString;
  };
  request: formikBoolean;
  type: formikEnum<InvoiceType>;
}

export type InvoiceType = 'company' | 'privatePerson' | 'freelance';

export type AddressValidationSchema = {
  addressLine1: yup.StringSchema;
  addressLine2: yup.StringSchema;
  addressLine3: yup.StringSchema;
  alternativePhone: yup.StringSchema;
  city: yup.StringSchema;
  civicNumber: yup.StringSchema;
  country: yup.StringSchema;
  latitude: yup.StringSchema;
  longitude: yup.StringSchema;
  middleName: yup.StringSchema;
  name: yup.StringSchema;
  phone: yup.StringSchema;
  province: yup.StringSchema;
  region: yup.StringSchema;
  surname: yup.StringSchema;
  zipCode: yup.StringSchema;
};

export interface AddressValidationSchemaSelector {
  // addressLine1: boolean;
  addressLine2: boolean;
  addressLine3: boolean;
  alternativePhone: boolean;
  // city: boolean;
  // civicNumber: boolean;
  // country: boolean;
  latitude: boolean;
  longitude: boolean;
  middleName: boolean;
  // name: boolean;
  phone: boolean;
  // province: boolean;
  region: boolean;
  // surname: boolean;
  // zipCode: boolean;
}

export type InvoiceValidationSchema = yup.ObjectSchema<{
  data: yup.ObjectSchema<{
    company: yup.StringSchema;
    pec: yup.StringSchema;
    taxCode: yup.StringSchema;
    uniqueCode: yup.StringSchema;
    vatNumber: yup.StringSchema;
  }>;
  request: yup.BooleanSchema;
  type: yup.StringSchema;
}>;

export interface AddressFieldsHelper {
  addressLine1: { formGroup: FormGroupProps; textInput: TextInputProps };
  addressLine2: { formGroup: FormGroupProps; textInput: TextInputProps };
  addressLine3: { formGroup: FormGroupProps; textInput: TextInputProps };
  alternativePhone: { formGroup: FormGroupProps; textInput: TextInputProps };
  city: { formGroup: FormGroupProps; textInput: TextInputProps };
  civicNumber: { formGroup: FormGroupProps; textInput: TextInputProps };
  country: { formGroup: FormGroupProps; select: SelectProps };
  latitude: { formGroup: FormGroupProps; textInput: TextInputProps };
  longitude: { formGroup: FormGroupProps; textInput: TextInputProps };
  middleName: { formGroup: FormGroupProps; textInput: TextInputProps };
  name: { formGroup: FormGroupProps; textInput: TextInputProps };
  phone: { formGroup: FormGroupProps; textInput: TextInputProps };
  province?: { formGroup: FormGroupProps; select: SelectProps };
  region?: { formGroup: FormGroupProps; textInput: TextInputProps };
  surname: { formGroup: FormGroupProps; textInput: TextInputProps };
  zipCode: { dynamicField: DynamicFieldProps; formGroup: FormGroupProps };
}

export interface InvoiceFieldsHelper {
  data?: {
    company?: { formGroup: FormGroupProps; textInput: TextInputProps };
    pec?: { formGroup: FormGroupProps; textInput: TextInputProps };
    taxCode?: { formGroup: FormGroupProps; textInput: TextInputProps };
    uniqueCode?: { formGroup: FormGroupProps; textInput: TextInputProps };
    vatNumber?: { formGroup: FormGroupProps; textInput: TextInputProps };
  };
  request: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  type?: { formGroup: FormGroupProps; radios: RadiosProps };
}

export const getAddressValidationSchema = (selector: AddressValidationSchemaSelector): AddressValidationSchema => ({
  addressLine1: yup.string().required(),
  addressLine2: selector.addressLine2 ? yup.string().required() : yup.string(),
  addressLine3: selector.addressLine3 ? yup.string().required() : yup.string(),
  alternativePhone: selector.alternativePhone ? yup.string().required() : yup.string(),
  city: yup.string().required(),
  civicNumber: yup.string().required(),
  country: yup.string().required(),
  latitude: selector.latitude ? yup.string().required() : yup.string(),
  longitude: selector.longitude ? yup.string().required() : yup.string(),
  middleName: selector.middleName ? yup.string().required() : yup.string(),
  name: yup.string().required(),
  phone: selector.phone ? yup.string().required() : yup.string(),
  province: yup.string().when('country', {
    is: 'IT',
    then: (schema) => schema.required(),
  }),
  region: selector.region ? yup.string().required() : yup.string(),
  surname: yup.string().required(),
  zipCode: yup.string().required(),
});

export const getInvoiceValidationSchema = (
  shippingCountry?: CountryApiDto,
  cart?: CartApiDto,
  type?: 'billing' | 'shipping'
) => {
  const isItaly = shippingCountry?.code === 'IT';
  return yup.object({
    data: yup
      .object({
        company: yup.string(),
        pec: yup.string(),
        taxCode: yup.string(),
        uniqueCode: yup.string(),
        vatNumber: yup.string(),
      })
      .when('request', {
        is: true,
        then: (schema) =>
          schema
            .when('type', {
              is: 'privatePerson',
              then: () =>
                schema.shape({
                  taxCode: isItaly ? yup.string().required() : yup.string(),
                }),
            })
            .when('type', {
              is: 'company',
              then: () =>
                schema.shape({
                  company: isItaly ? yup.string().required() : yup.string(),
                  pec: isItaly ? yup.string().required() : yup.string(),
                  taxCode: isItaly ? yup.string().required() : yup.string(),
                  uniqueCode: isItaly ? yup.string().required() : yup.string(),
                  vatNumber: isItaly ? yup.string().required() : yup.string().required(),
                }),
            })
            .when('type', {
              is: 'freelance',
              then: () =>
                schema.shape({
                  pec: isItaly ? yup.string().required() : yup.string(),
                  taxCode: isItaly ? yup.string().required() : yup.string(),
                  uniqueCode: isItaly ? yup.string().required() : yup.string(),
                  vatNumber: isItaly ? yup.string().required() : yup.string(),
                }),
            }),
      }),
    request: cart?.invoiceRequired && type === 'billing' ? yup.boolean().required().oneOf([true]) : yup.boolean(),
    type: yup.string(),
  });
};

export const getAddressFromFormValues = (addressValues: AddressFormValues, address?: AddressApiDto): AddressApiDto => ({
  addressLine1: addressValues.addressLine1,
  addressLine2: addressValues.addressLine2,
  addressLine3: addressValues.addressLine3,
  alternativePhone: addressValues.alternativePhone,
  city: addressValues.city,
  civicNumber: addressValues.civicNumber,
  country: { code: addressValues.country, id: '0', name: '' },
  id: address?.id ?? '0',
  latitude: addressValues.latitude ? parseFloat(addressValues.latitude) : undefined,
  longitude: addressValues.longitude ? parseFloat(addressValues.longitude) : undefined,
  middleName: addressValues.middleName,
  name: addressValues.name,
  phone: addressValues.phone,
  province:
    addressValues.country === 'IT'
      ? { code: addressValues.province, name: '' }
      : { code: '', name: addressValues.province },
  region: addressValues.region,
  surname: addressValues.surname,
  zipCode: addressValues.zipCode,
});
