/* eslint-disable @typescript-eslint/no-namespace */
import React, { DOMAttributes } from 'react';

import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { Remounter } from './Remounter';
import { addScript } from './util';

interface ScalapayWidget {
  amount?: string;
  hide?: boolean;
  locale?: string;
  max?: number;
  min?: number;
  theme?: string;
}

type CustomElement<T> = Partial<T & DOMAttributes<T>>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['scalapay-widget']: CustomElement<ScalapayWidget>;
    }
  }
}

export interface InstallmentPaymentBannerProps {
  amount?: number;
  paypal?: {
    enabled?: boolean;
    flex?: {
      color?: 'blue' | 'black' | 'white' | 'white-no-border';
      ratio?: '20x1' | '8x1' | '1x4' | '1x1';
    };
    layout: 'text' | 'flex';
    text?: {
      color?: 'white' | 'black';
      logo?: 'inline' | 'primary' | 'alternative' | 'none';
    };
  };
  scalapay?: {
    enabled?: boolean;
    frequencyNumber?: '30' | '14';
    hidePrice?: boolean;
    max?: number;
    min?: number;
    numberOfInstallments?: '3' | '4' | '1';
  };
}

export const InstallmentPaymentBanner = (props: InstallmentPaymentBannerProps) => {
  const { locale, shippingCountry } = useAppContext();
  const paypalMerchantClientId = appConfig.paypal?.merchantClientId;

  const { amount, paypal, scalapay } = { ...appConfig.installmentPaymentBanner, ...props };

  React.useEffect(() => {
    paypal?.enabled &&
      addScript({
        dataAttributes: { namespace: 'paypal_sdk' },
        id: 'paypal-sdk',
        src: `https://www.paypal.com/sdk/js?client-id=${paypalMerchantClientId}&currency=${shippingCountry?.currencyCode}&components=messages`,
      });
    if (scalapay?.enabled) {
      addScript({
        id: 'scalapay-nomodule',
        nomodule: true,
        src: 'https://cdn.scalapay.com/widget/v3/js/scalapay-widget.js',
      });
      addScript({
        id: 'scalapay-module',
        src: 'https://cdn.scalapay.com/widget/v3/js/scalapay-widget.esm.js',
        type: 'module',
      });
    }
  }, [shippingCountry?.currencyCode, paypalMerchantClientId, paypal, scalapay?.enabled]);

  return (
    <div className="installment-payment-banner">
      {paypal?.enabled && (
        <div className="paypal">
          <div
            data-pp-amount={amount ? amount : undefined}
            data-pp-message
            data-pp-style-color={paypal.layout === 'flex' ? paypal.flex?.color : undefined}
            data-pp-style-layout={paypal.layout}
            data-pp-style-logo-type={paypal.layout === 'text' ? paypal.text?.logo : undefined}
            data-pp-style-ratio={paypal.layout === 'flex' ? paypal.flex?.ratio : undefined}
            data-pp-style-text-color={paypal.layout === 'text' ? paypal.text?.color : undefined}
          ></div>
        </div>
      )}
      {scalapay?.enabled && (
        <div className="scalapay">
          <Remounter deps={[amount]}>
            <scalapay-widget
              amount={amount?.toString()}
              currency-display="symbol"
              currency-position="after"
              frequency-number={scalapay.frequencyNumber ?? 30}
              hide={false}
              hide-price={scalapay.hidePrice ? 'true' : 'false'}
              locale={locale?.languageCode}
              logo-size="100"
              max={scalapay.max ?? 1500}
              min={scalapay.min ?? 5}
              number-of-installments={scalapay.numberOfInstallments ?? '3'}
              theme="primary"
            />
          </Remounter>
        </div>
      )}
    </div>
  );
};
