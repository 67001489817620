import React from 'react';
import reactSpeechRecognition from 'react-speech-recognition';

import { useAppContext } from './AppContext';
import { iosDetection } from './util';

if ((reactSpeechRecognition as unknown) !== undefined) {
  // Zozzata giusto per importare i typings, trovare un modo migliore.
}

export interface UseSpeechRecognitionOptions {
  continuous?: boolean;
  onEnd?(): void;
  onError?(): void;
  onResult?(result: string | undefined): void;
  onStart?(): void;
}

export interface UseSpeechRecognitionResult {
  listening: boolean;
  result?: string;
  start(): void;
  stop(): void;
  supported: boolean;
  toggle(): void;
}

export const useSpeechRecognition = ({
  continuous = false,
  onEnd,
  onError,
  onResult,
  onStart,
}: UseSpeechRecognitionOptions): UseSpeechRecognitionResult => {
  const speechRecognition = React.useRef<SpeechRecognition>();

  const [listening, setListening] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<string>();

  const supported = React.useMemo(() => {
    const speechRecognitionSupported = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
    return speechRecognitionSupported && !iosDetection();
  }, []);

  const { locale } = useAppContext();

  React.useEffect(() => {
    if (supported) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

      speechRecognition.current = new SpeechRecognition();

      if (locale?.code) {
        speechRecognition.current.lang = locale.code;
      }

      speechRecognition.current.continuous = continuous;

      speechRecognition.current.onstart = (event) => {
        console.log('onstart', event);
        setListening(true);
        onStart && onStart();
      };

      speechRecognition.current.onend = (event) => {
        console.log('onend', event);
        setListening(false);
        onEnd && onEnd();
      };

      speechRecognition.current.onresult = (event) => {
        console.log('onresult', event);
        const _result = event.results[0][0].transcript;
        setResult(_result);
        onResult && onResult(_result);
        // // Fix per i dispositivi Apple (quando il parlato finisce, il microfono rimane acceso).
        // if (navigator.vendor.indexOf('Apple') > -1) {
        //   try {
        //     speechRecognition.current?.start();
        //     speechRecognition.current?.stop();
        //   } catch (err) {}
        // }
      };

      speechRecognition.current.onerror = (event) => {
        console.log('onerror', event);
        onError && onError();
      };
    }
  }, [continuous, locale?.code, onEnd, onError, onResult, onStart, supported]);

  const start = React.useCallback(() => {
    speechRecognition.current?.start();
  }, []);

  const stop = React.useCallback(() => {
    speechRecognition.current?.stop();
  }, []);

  const toggle = React.useCallback(() => {
    listening ? stop() : start();
  }, [listening, start, stop]);

  return { listening, result, start, stop, supported, toggle };
};
