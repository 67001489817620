import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountOrdersSubpageA } from './accountSubpagesA/AccountOrdersSubpageA';

export interface AccountOrdersSubpageProps {
  enableReceiverColumn?: boolean;
}

export type AccountOrdersSubpageVariants = 'A';

const AccountOrdersSubpageController = (props: PropsWithCustomComponent<AccountOrdersSubpageProps>) => (
  <VariantsController<AccountOrdersSubpageProps, AccountOrdersSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountOrdersSubpageA },
      name: 'AccountOrdersSubpage',
    }}
  />
);
export { AccountOrdersSubpageController as AccountOrdersSubpage };
