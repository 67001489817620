import React from 'react';

import { Image as NotResponsiveImageContentSection } from './contentTypes';
import { Image, ImageProps } from './Image';

export interface NotResponsiveImageFromContentProps
  extends NotResponsiveImageContentSection,
    Omit<ImageProps, 'src' | 'exclude2x' | 'height' | 'width'> {}

export const NotResponsiveImageFromContent = ({
  density,
  height,
  url,
  width,
  ...otherProps
}: NotResponsiveImageFromContentProps) => {
  const aspectRatio = React.useMemo<number | undefined>(
    () => (width !== undefined && height !== undefined ? width / height : undefined),
    [height, width]
  );

  const exclude2x = React.useMemo<boolean>(() => {
    if (density !== undefined) {
      return density === 1;
    } else {
      return true;
    }
  }, [density]);

  return <>{url && <Image {...otherProps} aspectRatio={aspectRatio} exclude2x={exclude2x} src={url} />}</>;
};
