import './Drawers.scss';

import classnames from 'classnames';
import React from 'react';

import { b2x } from '.';
import { Button } from './Button';
import { DrawersContext, DrawersContextInterface, useDrawersContext } from './DrawersContext';
import { createUUID } from './util';

export interface DrawersProps {
  children: React.ReactNode | ((context: DrawersContextInterface) => React.ReactNode);
  className?: string;
  drawerClassName?: string;
  itemsClassName?: string;
}

export const Drawers = ({ children, className, drawerClassName, itemsClassName }: DrawersProps) => {
  const entryDrawerId = React.useMemo(() => createUUID(), []);

  return (
    <div className={classnames(className, 'Drawers')}>
      <DrawersContext drawerClassName={drawerClassName} itemsClassName={itemsClassName}>
        {(drawersContext) => (
          <Drawer entryDrawer id={entryDrawerId}>
            {typeof children === 'function' ? children(drawersContext) : children}
          </Drawer>
        )}
      </DrawersContext>
    </div>
  );
};

interface DrawerProps {
  children: React.ReactNode;
  entryDrawer?: boolean;
  id: string;
}

const Drawer = ({ children, entryDrawer, id }: DrawerProps) => {
  const { activeDrawersId, addActiveDrawerId, drawerClassName, removeDrawerId } = useDrawersContext();

  React.useEffect(() => {
    if (entryDrawer) {
      addActiveDrawerId(id);
    }
    return () => {
      removeDrawerId(id);
    };
  }, [addActiveDrawerId, entryDrawer, id, removeDrawerId]);

  return (
    <div
      className={classnames(
        'Drawer',
        drawerClassName,
        { in: activeDrawersId.includes(id) },
        { out: !activeDrawersId.includes(id) },
        { active: activeDrawersId.slice(-1)[0] === id }, // quella attuale
        { 'child-active': activeDrawersId.slice(0, -1).includes(id) }, // quelle aperte ma coperte dall'attuale
        { 'direct-child-active': activeDrawersId.slice(-2, -1)[0] === id } // quella prima dell'attuale
      )}
    >
      <div className="drawer-content">{children}</div>
    </div>
  );
};

export interface DrawerItemProps {
  center?: {
    bottom?: string | React.ReactElement;
    center?: string | React.ReactElement;
    top?: string | React.ReactElement;
  };
  className?: string;
  end?: {
    bottom?: string | React.ReactElement;
    center?: string | React.ReactElement;
    top?: string | React.ReactElement;
  };
  height: number;
  justifyContent: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
  start?: {
    bottom?: string | React.ReactElement;
    center?: string | React.ReactElement;
    top?: string | React.ReactElement;
  };
}

export const DrawerItem = ({ center, className, end, height, justifyContent, start }: DrawerItemProps) => {
  const { itemsClassName } = useDrawersContext();

  return (
    <div
      className={classnames(
        itemsClassName,
        className,
        'd-flex align-items-center',
        `justify-content-${justifyContent}`
      )}
      style={{ minHeight: height }}
    >
      {start && (
        <div className="d-flex flex-column align-items-start text-start">
          {start.top && start.top}
          {start.center && start.center}
          {start.bottom && start.bottom}
        </div>
      )}
      {center && (
        <div className="d-flex flex-column align-items-center text-center">
          {center.top && center.top}
          {center.center && center.center}
          {center.bottom && center.bottom}
        </div>
      )}
      {end && (
        <div className="d-flex flex-column align-items-end text-end">
          {end.top && end.top}
          {end.center && end.center}
          {end.bottom && end.bottom}
        </div>
      )}
    </div>
  );
};

export interface DrawerButtonItemProps {
  children: React.ReactNode;
  className?: string;
  onClick(): void;
}

export const DrawerButtonItem = ({ children, className, onClick }: DrawerButtonItemProps) => {
  return (
    <div className={classnames(className, 'd-grid')}>
      <Button alignWithFlex={false} className="border-bottom" onClick={onClick} variant="blank">
        {children}
      </Button>
    </div>
  );
};

export interface DrawerTriggerItemProps {
  children: React.ReactNode;
  drawerButtonClassName?: string;
  newDrawer: React.ReactElement;
}

export const DrawerTriggerItem = ({ children, drawerButtonClassName, newDrawer }: DrawerTriggerItemProps) => {
  const [id] = React.useState<string>(createUUID());

  const { addActiveDrawerId } = useDrawersContext();

  const handleClick = React.useCallback(() => {
    addActiveDrawerId(id);
  }, [addActiveDrawerId, id]);

  return (
    <>
      <DrawerButtonItem className={drawerButtonClassName} onClick={handleClick}>
        {children}
      </DrawerButtonItem>
      <Drawer id={id}>{newDrawer}</Drawer>
    </>
  );
};

export interface DrawerLinkItemProps extends b2x.router.LinkProps {}

export const DrawerLinkItem = ({ children, className, onClick, ...otherPros }: DrawerLinkItemProps) => {
  const { close } = b2x.useModalContext();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      onClick && onClick(event);
      close();
    },
    [close, onClick]
  );

  return (
    <b2x.router.Link
      {...otherPros}
      className={classnames(className, 'text-reset text-decoration-none d-block border-bottom')}
      onClick={handleClick}
    >
      {children}
    </b2x.router.Link>
  );
};

export interface DrawerCheckboxItemProps extends b2x.CheckboxProps {}

export const DrawerCheckboxItem = ({ children, ...otherProps }: DrawerCheckboxItemProps) => {
  return (
    <div className="d-grid">
      <b2x.Checkbox {...otherProps}>{children}</b2x.Checkbox>
    </div>
  );
};
