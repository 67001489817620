import './ListB.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from './Button';
import { Icon, IconName } from './Icon';

export interface ListBProps {
  items: Array<ListBItem>;
  textColor?: string;
}

export interface ListBItem {
  icon: IconName;
  label: string;
  link?: string;
  onClick?(): void;
  scrollTop?: boolean;
}

export const ListB = ({ items, textColor }: ListBProps) => {
  return (
    <div className="ListB">
      <div className="list-group">
        {items.map(({ icon, label, link, onClick, scrollTop }) =>
          link ? (
            <b2x.router.NavLink
              className={classnames(
                textColor,
                'text-decoration-none border-0 small fw-bold list-group-item list-group-item-action hstack gap-4 gap-lg-5 py-3 px-4 px-lg-5 mb-1'
              )}
              key={label}
              scrollTop={scrollTop}
              to={link}
            >
              <Icon name={icon} size={25} />
              {label}
            </b2x.router.NavLink>
          ) : (
            <Button
              className={classnames(
                textColor,
                'text-decoration-none border-0 small fw-bold list-group-item list-group-item-action hstack gap-4 gap-lg-5 py-3 px-4 px-lg-5 mb-1'
              )}
              iconStart={{ name: icon, size: 25 }}
              justifyContent="start"
              key={label}
              label={label}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                onClick && onClick();
              }}
            />
          )
        )}
      </div>
    </div>
  );
};
