import React from 'react';

import { createContext } from '../util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface SteppedFormContextInterface {
  currentStepIndex: number;
  isFirstStep: boolean;
  isLastStep: boolean;
}

export const [SteppedFormContextProvider, useSteppedFormContext] =
  createContext<SteppedFormContextInterface>('SteppedFormContext');

// Proprietà necessarie per creare il contesto
interface UseSteppedFormContextInitializerProps {
  currentStepIndex: number;
  isFirstStep: boolean;
  isLastStep: boolean;
}

// Funzione per inizializzare il contesto
const useSteppedFormContextInitializer = (props: UseSteppedFormContextInitializerProps) => {
  // Crea l'oggetto steppedFormContext
  const steppedFormContext: SteppedFormContextInterface = { ...props };

  return {
    SteppedFormContextProvider,
    steppedFormContext,
  };
};

// Interfaccia componente contesto
export interface SteppedFormContextProps extends UseSteppedFormContextInitializerProps {
  children: React.ReactNode | ((steppedFormContext: SteppedFormContextInterface) => React.ReactNode);
}

// Componente Contesto
export const SteppedFormContext = ({ children, ...otherProps }: SteppedFormContextProps) => {
  const steppedFormContextInitializer = useSteppedFormContextInitializer(otherProps);
  return (
    <steppedFormContextInitializer.SteppedFormContextProvider value={steppedFormContextInitializer.steppedFormContext}>
      {typeof children === 'function' ? children(steppedFormContextInitializer.steppedFormContext) : children}
    </steppedFormContextInitializer.SteppedFormContextProvider>
  );
};
