import { ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ModalProps } from '../Modal';
import { useStaticModal } from '../useModal';
import { uiClassName } from '../util';
import { useStoreLocatorContext } from './StoreLocatorContext';

interface DesktopPhoneModalProps extends ModalProps {
  shop: ShopApiDto;
}

export const DesktopPhoneModal = ({ shop, ...modalProps }: DesktopPhoneModalProps) => {
  const { getShopName } = useStoreLocatorContext();

  return (
    <Modal className="desktop-phone-modal" {...modalProps}>
      <ModalHeader closable={false} title={t('storeLocator.modal.desktopPhone.title')} />
      <ModalBody>
        <p className={uiClassName({ bs5: 'lh-sm' })}>
          {t('storeLocator.modal.desktopPhone.content', {
            name: getShopName(shop),
            phoneNumber: shop.phoneNumber,
          })}{' '}
        </p>
      </ModalBody>
      <ModalFooter>
        <Div className={uiClassName({ bs5: 'w-100' })} display="grid">
          <ModalButton close label="Chiudi" />
        </Div>
      </ModalFooter>
    </Modal>
  );
};

export const useDesktopPhoneModal = (props: DesktopPhoneModalProps) =>
  useStaticModal(DesktopPhoneModal, 'modal', props);
