import classnames from 'classnames';

import { renderUI } from './util';

export interface DropdownItemProps {
  active?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  onClick?(): void;
}

export const DropdownItem = ({ active, children, disabled, onClick }: DropdownItemProps) => {
  return renderUI({
    bs5: (
      <li>
        <span
          className={classnames('dropdown-item', { active: active }, { disabled: disabled })}
          onClick={onClick}
          onKeyPress={onClick}
          role={onClick ? 'button' : undefined}
        >
          {children}
        </span>
      </li>
    ),
  });
};
