import React from 'react';

import { VideoAsBackgroundContentSectionDeprecated } from './contentTypes';
import { DeprecatedVideoAsBackground, DeprecatedVideoAsBackgroundProps } from './DeprecatedVideoAsBackground';

interface DeprecatedVideoAsBackgroundFromContentProps extends VideoAsBackgroundContentSectionDeprecated {}

export const DeprecatedVideoAsBackgroundFromContent = ({ src }: DeprecatedVideoAsBackgroundFromContentProps) => {
  const filteredSrcs = React.useMemo(() => {
    const _filteredSrcs: DeprecatedVideoAsBackgroundProps['src'] = [];
    src?.forEach((video) => {
      if (video.height !== undefined && video.video !== undefined && video.width !== undefined) {
        _filteredSrcs.push({
          height: video.height,
          preview: video.preview?.url,
          video: video.video,
          width: video.width,
        });
      }
    });
    return _filteredSrcs;
  }, [src]);

  return <>{src && <DeprecatedVideoAsBackground src={filteredSrcs} />}</>;
};
