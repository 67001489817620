import { b2x } from '@b2x/react/src';

import { SocialsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface SocialIconsProps {
  iconTextColor?: string;
}

export const SocialIcons = ({ iconTextColor }: SocialIconsProps) => {
  const socialsContent = b2x.useContent<SocialsContentType>('SOCIALS_CONTENT');
  return (
    <>
      <ul className="list-group list-group-horizontal list-unstyled">
        {socialsContent?.body.iconsList &&
          socialsContent.body.iconsList.map((item) => {
            return (
              item.name &&
              item.url &&
              isIconName(item.name) && (
                <li key={item.iconTitle}>
                  {/* eslint-disable-next-line react/forbid-elements */}
                  <a className="text-reset " href={item.url} rel="noreferrer" target="_blank">
                    <Icon className={iconTextColor} fontSize={48} name={item.name as IconName} />
                  </a>
                </li>
              )
            );
          })}
        <li></li>
      </ul>
    </>
  );
};
