/* eslint-disable react/no-array-index-key */
import 'swiper/scss/navigation';
import 'swiper/scss/thumbs';
import 'swiper/scss/free-mode';
import 'swiper/scss';
import './SliderProductPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import SwiperInterface, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface SliderProductPageProps {
  selectedProductVariant: b2x.ProductApiDto;
}

export const SliderProductPage = ({ selectedProductVariant }: SliderProductPageProps): React.ReactElement => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperInterface>();

  return (
    <div className="SliderProductPage">
      <div className="gallery-slide-container text-center py-lg-5 mb-lg-3">
        <b2x.Lightbox>
          <Swiper
            modules={[FreeMode, Thumbs, Navigation]}
            navigation
            spaceBetween={10}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          >
            {[
              selectedProductVariant.image,
              ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
            ].map(
              (image, index) =>
                image?.src && (
                  <SwiperSlide key={`product-slide-${index}`}>
                    <b2x.LightboxItem bcomImageId={image.id} src={image.src}>
                      <b2x.Image
                        aspectRatio={b2x.appConfig.productImageAspectRatio}
                        fluid
                        format={800}
                        src={image.src}
                      />
                    </b2x.LightboxItem>
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </b2x.Lightbox>
      </div>
      <div className="gallery-thumbnail-container d-none d-lg-flex">
        <Swiper
          className="flex-grow-1 auto-width"
          freeMode
          modules={[Thumbs, Navigation, FreeMode]}
          onSwiper={setThumbsSwiper}
          slidesPerView={4}
          spaceBetween={20}
          watchSlidesProgress
        >
          {[
            selectedProductVariant.image,
            ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
          ].map(
            (image, index) =>
              image && (
                <SwiperSlide className="py-4 px-3" key={`product-thumbnail-${index}`}>
                  <b2x.Image aspectRatio={b2x.appConfig.productImageAspectRatio} fluid src={image.src} />
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
    </div>
  );
};
