// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import {
  BrandApiDto,
  CartApiDto,
  CartSkuApiDto,
  CreateSmartOrderRequestApiDto,
  ErrorResponseApiDto,
  OrderApiDto,
  OrderedSkuApiDto,
  PageApiDto,
  PriceApiDto,
  ProductApiDto,
  SkuApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import { AxiosError } from 'axios';
import _ from 'lodash';

import { appConfig } from '../config';
import { fillProduct, fillProducts, fillSku } from '../filler';
import { baim } from './baim';
import { facebookPixel } from './facebookPixel';
import { googleAds } from './googleAds';
import { googleAnalytics4 } from './googleAnalytics4';
import { googleTagManager } from './googleTagManager';

export interface InitCommonProps {
  debug?: boolean;
}

export const calculateCompleteSkuName = (productName?: string, skuName?: string): string => {
  return `${productName} - ${skuName}`;
};

interface Price {
  crossedOutValue?: number;
  value: number;
}

// FIXME Rendere tutto obbligatorio
interface Product {
  brand: {
    name?: string;
  };
  categories?: Array<{
    name?: string;
  }>;
  listing?: ListingItemOptions;
  name?: string;
}

export interface Sku {
  discount?: number;
  id: string;
  name?: string;
  price: Price;
}

export type ProductSku = Product & {
  sku: Sku;
};

export type ProductSkus = Product & {
  fromPrice: Price;
  skus: Array<Sku>;
};

export type ProductSkuWithQuantity = ProductSku & {
  quantity: number;
};

export interface ListingOptions {
  name: string;
}

export interface ListingItemOptions extends ListingOptions {
  index: number;
}

interface BaseEventProps {
  eventId: string;
}

interface AddPaymentInfoProps extends BaseEventProps {
  coupon?: {
    code: string;
  };
  paymentType: string;
  products: Array<ProductSkuWithQuantity>;
  productsNumber: number;
  totalCost: number;
}

interface AddShippingInfoProps extends BaseEventProps {
  coupon?: {
    code: string;
  };
  products: Array<ProductSkuWithQuantity>;
  productsNumber: number;
  shippingProfile: string;
  totalCost: number;
}

interface AddToCartProps extends BaseEventProps {
  product: ProductSkuWithQuantity;
}

interface AddToWishlistProps extends BaseEventProps {
  product: ProductSku;
}

export interface CompleteRegistrationProps extends BaseEventProps {
  origin: 'sign-up';
}

interface ContactProps extends BaseEventProps {}

interface CustomizeProductProps extends BaseEventProps {}

interface DonateProps extends BaseEventProps {}

interface ExceptionProps extends BaseEventProps {
  description: string;
  fatal?: boolean;
}

interface FindLocationProps extends BaseEventProps {}

interface CheckoutInitProps extends BaseEventProps {
  coupon?: {
    code: string;
  };
  products: Array<ProductSkuWithQuantity>;
  productsNumber: number;
  totalCost: number;
}

export interface LeadProps extends BaseEventProps {
  origin: 'newsletter';
}

export interface LoginProps extends BaseEventProps {
  email: string;
  method?: 'google' | 'facebook';
}

interface PageViewProps extends BaseEventProps {
  location: string;
  path: string;
  title: string;
}

interface PurchaseProps extends BaseEventProps {
  coupon?: {
    code: string;
  };
  orderCode: string;
  products: Array<ProductSkuWithQuantity>;
  productsNumber: number;
  result: string;
  shippingCost: number;
  taxCost?: number;
  totalCost: number;
}
interface RemoveFromCartProps extends BaseEventProps {
  product: ProductSkuWithQuantity;
}

interface ScheduleProps extends BaseEventProps {}

interface SearchProps extends BaseEventProps {
  simpleSearch: string;
}

interface SelectProductProps extends BaseEventProps {
  listing: ListingOptions;
  product: ProductSkus;
}

interface StartPaymentProps extends BaseEventProps {
  coupon?: {
    code: string;
  };
  products: Array<ProductSkuWithQuantity>;
  productsNumber: number;
  totalCost: number;
}

interface SubmitApplicationProps extends BaseEventProps {}

interface ViewcartProps extends BaseEventProps {
  products: Array<ProductSkuWithQuantity>;
  productsNumber: number;
  totalCost: number;
}

interface ViewProductProps extends BaseEventProps {
  product: ProductSkus;
}

interface ViewProductsProps extends BaseEventProps {
  listing: ListingOptions;
  products: Array<ProductSkus>;
}

export interface AnalyticsEventsProps {
  addPaymentInfo(props: AddPaymentInfoProps): void;
  addShippingInfo(props: AddShippingInfoProps): void;
  addToCart(props: AddToCartProps): void;
  addToWishlist(props: AddToWishlistProps): void;
  checkoutInit(props: CheckoutInitProps): void;
  completeRegistration(props: CompleteRegistrationProps): void;
  contact(props: ContactProps): void;
  customizeProduct(props: CustomizeProductProps): void;
  donate(props: DonateProps): void;
  exception(props: ExceptionProps): void;
  findLocation(props: FindLocationProps): void;
  lead(props: LeadProps): void;
  login(props: LoginProps): void;
  pageView(props: PageViewProps): void;
  purchase(props: PurchaseProps): void;
  removeFromCart(props: RemoveFromCartProps): void;
  schedule(props: ScheduleProps): void;
  search(props: SearchProps): void;
  selectProduct(props: SelectProductProps): void;
  startPayment(props: StartPaymentProps): void;
  submitApplication(props: SubmitApplicationProps): void;
  viewCart(props: ViewcartProps): void;
  viewProduct(props: ViewProductProps): void;
  viewProducts(props: ViewProductsProps): void;
}

/*
  Funzione che, a partire da un prodotto e uno sku passati da fuori,
  li controlla e ritorna dei nuovi oggetti completi,
  eventualmente chiedendo alle API informazioni mancanti ma necessarie per i tracciamenti.
*/

// FIXME, rimuovere e passare per filler.ts
// const fillProductAndSku = (partialProduct: ProductApiDto, partialSku: SkuApiDto) =>
//   new Promise<{
//     brand: BrandApiDto;
//     categories?: Array<PageApiDto>;
//     price: PriceApiDto;
//     product: ProductApiDto;
//     sku: SkuApiDto;
//   }>((resolve, reject) => {
//     // Faccio chiamata alle API per prendere le eventuali informazioni mancanti.
//     api.products
//       .getSku(partialSku.id, {
//         populate: {
//           price: !partialSku.price,
//           product:
//             !partialProduct.brand || !partialProduct.breadcrumbs
//               ? {
//                   brand: !partialProduct.brand,
//                   breadcrumbs: !partialProduct.breadcrumbs,
//                 }
//               : undefined,
//         },
//       })
//       .then((response) => {
//         // Unisco i dati di partenza con quelli appena ottenuti.
//         const product: ProductApiDto = _.merge({}, partialProduct, response.data.product);
//         const sku: SkuApiDto = _.merge({}, partialSku, response.data);
//         // Faccio una serie di controlli per accertarmi di avere tutto (quando costruirò le props non dovrò usare dei ?).
//         if (sku.price === undefined) {
//           throw new Error('Analytics - fillProductAndSku - missing sku.price');
//         }
//         if (product.brand === undefined) {
//           throw new Error('Analytics - fillProductAndSku - missing product.brand');
//         }
//         if (product.breadcrumbs === undefined) {
//           throw new Error('Analytics - fillProductAndSku - missing product.breadcrumbs');
//         }
//         const categories = getProductCategories(product);
//         resolve({ brand: product.brand, categories, price: sku.price, product, sku });
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// FIXME, rimuovere e passare per filler.ts
// const fillProduct = (partialProduct: ProductApiDto) =>
//   new Promise<{
//     brand: BrandApiDto;
//     categories?: Array<PageApiDto>;
//     priceRange: PriceRangeApiDto;
//     product: ProductApiDto;
//     skus: Array<SkuApiDto>;
//   }>((resolve, reject) => {
//     // Faccio chiamata alle API per prendere le eventuali informazioni mancanti.

//     // Prendo uno sku di prova per vedere se ho tutto.
//     const partialTestSku = partialProduct.skus?.at(0);

//     api.products
//       .get(partialProduct.id, {
//         populate: {
//           brand: !partialProduct.brand,
//           breadcrumbs: !partialProduct.breadcrumbs,
//           priceRange: !partialProduct.priceRange,
//           skus: !partialTestSku?.price
//             ? {
//                 price: !partialTestSku?.price,
//               }
//             : undefined,
//         },
//       })
//       .then((response) => {
//         // Unisco i dati di partenza con quelli appena ottenuti.
//         const product: ProductApiDto = _.merge({}, partialProduct, response.data);

//         // Faccio una serie di controlli per accertarmi di avere tutto (quando costruirò le props non dovrò usare dei ?).
//         if (product.priceRange === undefined) {
//           throw new Error('Analytics - fillProducts - missing product.priceRange');
//         }
//         if (product.brand === undefined) {
//           throw new Error('Analytics - fillProducts - missing product.brand');
//         }
//         if (product.breadcrumbs === undefined) {
//           throw new Error('Analytics - fillProducts - missing product.breadcrumbs');
//         }
//         const categories = getProductCategories(product);
//         const skus = product.skus;
//         if (skus === undefined || skus.length === 0) {
//           throw new Error('Analytics - fillProducts - missing skus');
//         }

//         resolve({
//           brand: product.brand,
//           categories,
//           priceRange: product.priceRange,
//           product,
//           skus,
//         });
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// const fillProducts = (partialProducts: Array<ProductApiDto>) =>
//   new Promise<
//     Array<{
//       brand: BrandApiDto;
//       // categories: Array<PageApiDto>;
//       priceRange: PriceRangeApiDto;
//       product: ProductApiDto;
//       skus: Array<SkuApiDto>;
//     }>
//   >((resolve, reject) => {
//     // Faccio chiamata alle API per prendere le eventuali informazioni mancanti.

//     // Prendo un prodotto di prova per vedere se ho tutto, ne scelgo uno che abbia sku.
//     const partialTestProduct = partialProducts.find(
//       (partialProduct) => partialProduct.skus !== undefined && partialProduct.skus.length > 0
//     );

//     // Prendo uno sku di prova per vedere se ho tutto.
//     const partialTestSku = partialTestProduct?.skus?.at(0);

//     api.products
//       .search(undefined, {
//         pageSize: partialProducts.length,
//         populate: {
//           items: {
//             brand: !partialTestProduct?.brand,
//             // breadcrumbs: !partialTestProduct?.breadcrumbs, // FIXME, commento in quanto troppo pesante lato API
//             priceRange: !partialTestProduct?.priceRange,
//             skus: !partialTestSku?.price
//               ? {
//                   price: !partialTestSku?.price,
//                 }
//               : undefined,
//           },
//         },
//         products: partialProducts.map((partialProduct) => partialProduct.id),
//       })
//       .then((response) => {
//         // Unisco i dati di partenza con quelli appena ottenuti.
//         const products: Array<ProductApiDto> = _.merge([], partialProducts, response.data.items);

//         resolve(
//           products.map((product) => {
//             // Faccio una serie di controlli per accertarmi di avere tutto (quando costruirò le props non dovrò usare dei ?).
//             if (product.priceRange === undefined) {
//               throw new Error('Analytics - fillProducts - missing product.priceRange');
//             }
//             if (product.brand === undefined) {
//               throw new Error('Analytics - fillProducts - missing product.brand');
//             }
//             // if (product.breadcrumbs === undefined) {
//             //   throw new Error('Analytics - fillProducts - missing product.breadcrumbs');
//             // }
//             // const breadcrumb = product.breadcrumbs.at(0);
//             // if (breadcrumb === undefined) {
//             //   throw new Error('Analytics - fillProducts - missing breadcrumb');
//             // }
//             // const categories = breadcrumb.slice(1, -1); // Tolgo il primo (Home) e l'ultimo (il prodotto stesso).
//             // if (categories.length === 0) {
//             //   throw new Error('Analytics - fillProducts - missing categories');
//             // }
//             const skus = product.skus;
//             if (skus === undefined || skus.length === 0) {
//               throw new Error('Analytics - fillProducts - missing skus');
//             }
//             return {
//               brand: product.brand,
//               // categories,
//               priceRange: product.priceRange,
//               product,
//               skus,
//             };
//           })
//         );
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

// FIXME, rimuovere e passare per filler.ts
const getProductAndSkuFromCartSku = (cartSku: CartSkuApiDto) =>
  new Promise<{
    brand: BrandApiDto;
    categories?: Array<PageApiDto>;
    price: PriceApiDto;
    product: ProductApiDto;
    quantity: number;
    sku: SkuApiDto;
  }>((resolve, reject) => {
    if (cartSku.sku === undefined) {
      throw new Error('Analytics - getProductAndSkuFromCartSku - missing cartSku.sku');
    }
    // Faccio chiamata alle API per prendere le eventuali informazioni mancanti.
    api.products
      .getSku(cartSku.sku.id, {
        populate: {
          price: !cartSku.sku.price,
          product:
            !cartSku.sku.product?.brand || !cartSku.sku.product.breadcrumbs
              ? {
                  brand: !cartSku.sku.product?.brand,
                  breadcrumbs: !cartSku.sku.product?.breadcrumbs,
                }
              : undefined,
        },
      })
      .then((response) => {
        // Unisco i dati di partenza con quelli appena ottenuti.
        const product: ProductApiDto = _.merge({}, cartSku.sku?.product, response.data.product);
        const sku: SkuApiDto = _.merge({}, cartSku.sku, response.data);
        // Faccio una serie di controlli per accertarmi di avere tutto (quando costruirò le props non dovrò usare dei ?).
        if (cartSku.price === undefined) {
          throw new Error('Analytics - getProductAndSkuFromCartSku - missing cartSku.price');
        }
        if (product.brand === undefined) {
          throw new Error('Analytics - getProductAndSkuFromCartSku - missing product.brand');
        }
        if (product.breadcrumbs === undefined) {
          throw new Error('Analytics - getProductAndSkuFromCartSku - missing product.breadcrumbs');
        }
        const categories = getProductCategories(product);
        resolve({ brand: product.brand, categories, price: cartSku.price, product, quantity: cartSku.quantity, sku });
      })
      .catch((error) => {
        reject(error);
      });
  });

// FIXME, rimuovere e passare per filler.ts
const getProductsAndSkusFromCart = (cart: CartApiDto) =>
  new Promise<
    Array<{
      brand: BrandApiDto;
      categories?: Array<PageApiDto>;
      price: PriceApiDto;
      product: ProductApiDto;
      quantity: number;
      sku: SkuApiDto;
    }>
  >((resolve, reject) => {
    const promises: Array<
      Promise<{
        brand: BrandApiDto;
        categories?: Array<PageApiDto>;
        price: PriceApiDto;
        product: ProductApiDto;
        quantity: number;
        sku: SkuApiDto;
      }>
    > = [];
    cart.packs?.forEach((pack) => {
      pack.cartSkus?.forEach((cartSku) => {
        promises.push(getProductAndSkuFromCartSku(cartSku));
      });
    });
    return Promise.all(promises)
      .then((productsAndSkusFromCart) => {
        resolve(productsAndSkusFromCart);
      })
      .catch((error) => {
        reject(error);
      });
  });

// FIXME, rimuovere e passare per filler.ts
const getProductAndSkuFromOrderedSku = (orderedSku: OrderedSkuApiDto) =>
  new Promise<{
    brand: BrandApiDto;
    categories?: Array<PageApiDto>;
    price: number;
    product: ProductApiDto;
    quantity: number;
    sku: SkuApiDto;
  }>((resolve, reject) => {
    if (orderedSku.sku === undefined) {
      throw new Error('Analytics - getProductAndSkuFromOrderedSku - missing cartSku.sku');
    }
    // Faccio chiamata alle API per prendere le eventuali informazioni mancanti.
    api.products
      .getSku(orderedSku.sku.id, {
        populate: {
          price: !orderedSku.sku.price,
          product:
            !orderedSku.sku.product?.brand || !orderedSku.sku.product.breadcrumbs
              ? {
                  brand: !orderedSku.sku.product?.brand,
                  breadcrumbs: !orderedSku.sku.product?.breadcrumbs,
                }
              : undefined,
        },
      })
      .then((response) => {
        // Unisco i dati di partenza con quelli appena ottenuti.
        const product: ProductApiDto = _.merge({}, orderedSku.sku?.product, response.data.product);
        const sku: SkuApiDto = _.merge({}, orderedSku.sku, response.data);
        // Faccio una serie di controlli per accertarmi di avere tutto (quando costruirò le props non dovrò usare dei ?).
        if (product.brand === undefined) {
          throw new Error('Analytics - getProductAndSkuFromOrderedSku - missing product.brand');
        }
        if (product.breadcrumbs === undefined) {
          throw new Error('Analytics - getProductAndSkuFromOrderedSku - missing product.breadcrumbs');
        }
        const categories = getProductCategories(product);
        resolve({
          brand: product.brand,
          categories,
          price: orderedSku.price,
          product,
          quantity: orderedSku.quantity,
          sku,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

// FIXME, rimuovere e passare per filler.ts
const getProductsAndSkusFromOrder = (order: OrderApiDto) =>
  new Promise<
    Array<{
      brand: BrandApiDto;
      categories?: Array<PageApiDto>;
      price: number;
      product: ProductApiDto;
      quantity: number;
      sku: SkuApiDto;
    }>
  >((resolve, reject) => {
    const promises: Array<
      Promise<{
        brand: BrandApiDto;
        categories?: Array<PageApiDto>;
        price: number;
        product: ProductApiDto;
        quantity: number;
        sku: SkuApiDto;
      }>
    > = [];
    order.warehouseOrders?.forEach((warehouseOrder) => {
      warehouseOrder.orderedSkus?.forEach((orderedSku) => {
        promises.push(getProductAndSkuFromOrderedSku(orderedSku));
      });
    });
    return Promise.all(promises)
      .then((productsAndSkusFromOrder) => {
        resolve(productsAndSkusFromOrder);
      })
      .catch((error) => {
        reject(error);
      });
  });

const getProductCategories = (product: ProductApiDto) => {
  /*
    FIXME
    Ho delle categorie valide quando il breadcrumb è di almeno 3 elementi,
    Con il primo (Home) e l'ultimo (il prodotto stesso).
    Se sono 2, sono probabilmente nel caso "prodotto/url-del-prodotto", che non va bene.
  */
  let categories: Array<PageApiDto> | undefined;
  const breadcrumb = product.breadcrumbs?.find((_breadcrumb) => _breadcrumb.length >= 3);
  if (breadcrumb !== undefined) {
    categories = breadcrumb.slice(1, -1); // Tolgo il primo (Home) e l'ultimo (il prodotto stesso).
  }
  return categories;
};

export const analytics = {
  events: {
    addPaymentInfo: (eventId: string, cart: CartApiDto, paymentType: string) => {
      getProductsAndSkusFromCart(cart).then((productsAndSkus) => {
        const props: AddPaymentInfoProps = {
          coupon: cart.coupon ? { code: cart.coupon.code } : undefined,
          eventId,
          paymentType: paymentType,
          products: productsAndSkus.map(({ brand, categories, price, product, quantity, sku }) => ({
            brand: {
              name: brand.name,
            },
            categories: categories?.map((_category) => ({ name: _category.name })),
            name: product.name,
            quantity: quantity,
            sku: {
              discount: price.discountValue,
              id: sku.eventSkuId,
              name: sku.name,
              price: {
                crossedOutValue: price.crossedOutValue,
                value: price.value,
              },
            },
          })),
          productsNumber: cart.itemsNumber,
          totalCost: cart.totalCost,
        };
        facebookPixel.events.addPaymentInfo(props);
        googleAnalytics4.events.addPaymentInfo(props);
        googleTagManager.events.addPaymentInfo(props);
        baim.events.addPaymentInfo(props);
        googleAds.gtagReportConversion('addPaymentInfo', { value: props.totalCost });
        remoteLog('addPaymentInfo');
      });
    },
    addShippingInfo: (eventId: string, cart: CartApiDto, shippingProfile: string) => {
      getProductsAndSkusFromCart(cart).then((productsAndSkus) => {
        const props: AddShippingInfoProps = {
          coupon: cart.coupon ? { code: cart.coupon.code } : undefined,
          eventId,
          products: productsAndSkus.map(({ brand, categories, price, product, quantity, sku }) => ({
            brand: {
              name: brand.name,
            },
            categories: categories?.map((_category) => ({ name: _category.name })),
            name: product.name,
            quantity: quantity,
            sku: {
              discount: price.discountValue,
              id: sku.eventSkuId,
              name: sku.name,
              price: {
                crossedOutValue: price.crossedOutValue,
                value: price.value,
              },
            },
          })),
          productsNumber: cart.itemsNumber,
          shippingProfile: shippingProfile,
          totalCost: cart.totalCost,
        };
        facebookPixel.events.addShippingInfo(props);
        googleAnalytics4.events.addShippingInfo(props);
        googleTagManager.events.addShippingInfo(props);
        baim.events.addShippingInfo(props);
        googleAds.gtagReportConversion('addShippingInfo', { value: props.totalCost });
        remoteLog('addShippingInfo');
      });
    },
    addToCart: (
      eventId: string,
      partialProduct: ProductApiDto,
      quantity: number,
      partialSku: SkuApiDto,
      listing?: ListingItemOptions
    ) => {
      fillSku(partialSku, { price: true, product: { brand: true, breadcrumbs: true } }, { silent: true }).then(
        (filledSku) => {
          if (filledSku.price === undefined) {
            throw new Error('Analytics - addToCart - missing sku.price');
          }

          const categories = filledSku.product && getProductCategories(filledSku.product);

          // Costruisco le props (uniche) da inoltrare ai vari gestori.
          const props: AddToCartProps = {
            eventId,
            product: {
              brand: {
                name: filledSku.product?.brand?.name,
              },
              categories: categories?.map((_category) => ({ name: _category.name })),
              listing: listing,
              name: filledSku.product?.name,
              quantity: quantity,
              sku: {
                discount: filledSku.price.discountValue,
                id: filledSku.eventSkuId,
                name: filledSku.name,
                price: {
                  crossedOutValue: filledSku.price.crossedOutValue,
                  value: filledSku.price.value,
                },
              },
            },
          };
          // Chiamo i vari gestori.
          facebookPixel.events.addToCart(props);
          googleAnalytics4.events.addToCart(props);
          googleTagManager.events.addToCart(props);
          baim.events.addToCart(props);
          googleAds.gtagReportConversion('addToCart', { value: props.product.sku.price.value });
          remoteLog('addToCart');
        }
      );
    },
    addToWishlist: (
      eventId: string,
      partialProduct: ProductApiDto,
      partialSku: SkuApiDto
      // , listing: ListingItemOptions
    ) => {
      fillSku(partialSku, { price: true, product: { brand: true, breadcrumbs: true } }, { silent: true }).then(
        (filledSku) => {
          if (filledSku.price === undefined) {
            throw new Error('Analytics - addToWishlist - missing sku.price');
          }

          const categories = filledSku.product && getProductCategories(filledSku.product);

          const props: AddToWishlistProps = {
            eventId,
            product: {
              brand: {
                name: filledSku.product?.brand?.name,
              },
              categories: categories?.map((_category) => ({ name: _category.name })),
              // listing: listing,
              name: filledSku.product?.name,
              sku: {
                discount: filledSku.price.discountValue,
                id: filledSku.eventSkuId,
                name: filledSku.name,
                price: {
                  crossedOutValue: filledSku.price.crossedOutValue,
                  value: filledSku.price.value,
                },
              },
            },
          };
          facebookPixel.events.addToWishlist(props);
          googleAnalytics4.events.addToWishlist(props);
          googleTagManager.events.addToWishlist(props);
          baim.events.addToWishlist(props);
          googleAds.gtagReportConversion('addToWishlist', { value: props.product.sku.price.value });
          remoteLog('addToWishlist');
        }
      );
    },
    checkoutInit: (eventId: string, cart: CartApiDto) => {
      getProductsAndSkusFromCart(cart).then((productsAndSkus) => {
        const props: CheckoutInitProps = {
          coupon: cart.coupon ? { code: cart.coupon.code } : undefined,
          eventId,
          products: productsAndSkus.map(({ brand, categories, price, product, quantity, sku }) => ({
            brand: {
              name: brand.name,
            },
            categories: categories?.map((_category) => ({ name: _category.name })),
            name: product.name,
            quantity: quantity,
            sku: {
              discount: price.discountValue,
              id: sku.eventSkuId,
              name: sku.name,
              price: {
                crossedOutValue: price.crossedOutValue,
                value: price.value,
              },
            },
          })),
          productsNumber: cart.itemsNumber,
          totalCost: cart.totalCost,
        };
        facebookPixel.events.checkoutInit(props);
        googleAnalytics4.events.checkoutInit(props);
        googleTagManager.events.checkoutInit(props);
        baim.events.checkoutInit(props);
        googleAds.gtagReportConversion('checkoutInit', { value: props.totalCost });
        remoteLog('checkoutInit');
      });
    },
    // checkoutProgress: (eventId: string, cart: CartApiDto, step: CheckoutStep, steps: Array<CheckoutStep>) => {
    //   // FIXME Non viene mai chiamato
    //   if (cart.packs === undefined) {
    //     throw new Error('Analytics - checkoutProgress - missing cart.packs');
    //   }
    //   const props: CheckoutProgressProps = {
    //     coupon: cart.coupon ? { code: cart.coupon.code } : undefined,
    //     paymentMethod: cart.paymentMethod?.type,
    //     products: cart.packs.reduce<CheckoutInitProps['products']>((accumulator, pack) => {
    //       if (pack.cartSkus === undefined) {
    //         throw new Error('Analytics - checkoutProgress - missing pack.cartSkus');
    //       }
    //       return accumulator.concat(
    //         pack.cartSkus.map((cartSku) => {
    //           if (cartSku.sku === undefined) {
    //             throw new Error('Analytics - checkoutProgress - missing cartSku.sku');
    //           }
    //           if (cartSku.sku.product === undefined) {
    //             throw new Error('Analytics - checkoutProgress - missing cartSku.sku.product');
    //           }
    //           if (cartSku.price === undefined) {
    //             throw new Error('Analytics - checkoutProgress - missing cartSku.price');
    //           }
    //           return {
    //             brand: {
    //               // name: cartSku.sku.brandName,
    //             },
    //             categories: [
    //               //
    //             ],
    //             name: cartSku.sku.product.name,
    //             quantity: cartSku.quantity,
    //             sku: {
    //               discount: cartSku.price.discountValue,
    //               id: cartSku.sku.eventSkuId,
    //               name: cartSku.sku.name,
    //               price: {
    //                 crossedOutValue: cartSku.price.crossedOutValue,
    //                 value: cartSku.price.value,
    //               },
    //             },
    //           };
    //         })
    //       );
    //     }, []),
    //     productsNumber: cart.itemsNumber,
    //     step: steps.findIndex((_step) => _step === step) + 1,
    //     totalCost: cart.totalCost,
    //   };
    //   facebookPixel.events.checkoutProgress(props);
    //   googleAnalytics4.events.checkoutProgress(props);
    //   googleTagManager.events.checkoutProgress(props);
    //   baim.events.addPaymentInfo(props);
    //   googleAds.gtagReportConversion('checkoutProgress', { value: props.totalCost });
    //   remoteLog('checkoutProgress');
    // },
    completeRegistration: (eventId: string, origin: CompleteRegistrationProps['origin']) => {
      const props: CompleteRegistrationProps = {
        eventId,
        origin: origin,
      };
      facebookPixel.events.completeRegistration(props);
      googleAnalytics4.events.completeRegistration(props);
      googleTagManager.events.completeRegistration(props);
      baim.events.completeRegistration(props);
      googleAds.gtagReportConversion('completeRegistration', {});
      remoteLog('completeRegistration');
    },
    contact: (eventId: string) => {
      // FIXME Non viene mai chiamato
      const props: ContactProps = { eventId };
      facebookPixel.events.contact(props);
      googleAnalytics4.events.contact(props);
      googleTagManager.events.contact(props);
      baim.events.contact(props);
      googleAds.gtagReportConversion('contact', {});
      remoteLog('contact');
    },
    customizeProduct: (eventId: string) => {
      // FIXME Non viene mai chiamato
      const props: CustomizeProductProps = { eventId };
      facebookPixel.events.customizeProduct(props);
      googleAnalytics4.events.customizeProduct(props);
      googleTagManager.events.customizeProduct(props);
      baim.events.customizeProduct(props);
      googleAds.gtagReportConversion('customizeProduct', {});
      remoteLog('customizeProduct');
    },
    donate: (eventId: string) => {
      // FIXME Non viene mai chiamato
      const props: DonateProps = { eventId };
      facebookPixel.events.donate(props);
      googleAnalytics4.events.donate(props);
      googleTagManager.events.donate(props);
      baim.events.donate(props);
      googleAds.gtagReportConversion('donate', {});
      remoteLog('donate');
    },
    exception: (eventId: string, axiosError: AxiosError<ErrorResponseApiDto>) => {
      // FIXME Da gestire meglio, con errore più parlanti
      const props: ExceptionProps = {
        description: axiosError.response?.data.message || axiosError.response?.data.key || '',
        eventId,
      };
      facebookPixel.events.exception(props);
      googleAnalytics4.events.exception(props);
      googleTagManager.events.exception(props);
      baim.events.exception(props);
      googleAds.gtagReportConversion('exception', {});
      remoteLog('exception');
    },
    findLocation: (eventId: string) => {
      // FIXME Non viene mai chiamato
      const props: FindLocationProps = { eventId };
      facebookPixel.events.findLocation(props);
      googleAnalytics4.events.findLocation(props);
      googleTagManager.events.findLocation(props);
      baim.events.findLocation(props);
      googleAds.gtagReportConversion('findLocation', {});
      remoteLog('findLocation');
    },
    lead: (eventId: string, origin: LeadProps['origin']) => {
      const props: LeadProps = {
        eventId,
        origin: origin,
      };
      facebookPixel.events.lead(props);
      googleAnalytics4.events.lead(props);
      googleTagManager.events.lead(props);
      baim.events.lead(props);
      googleAds.gtagReportConversion('lead', {});
      remoteLog('lead');
    },
    login: (eventId: string, email: LoginProps['email'], method?: LoginProps['method']) => {
      const props: LoginProps = {
        email: email,
        eventId,
        method: method,
      };
      facebookPixel.events.login(props);
      googleAnalytics4.events.login(props);
      googleTagManager.events.login(props);
      baim.events.login(props);
      googleAds.gtagReportConversion('login', {});
      remoteLog('login');
    },
    orderPayment: (eventId: string, order: OrderApiDto) => {
      const props: StartPaymentProps = {
        coupon: order.couponCode ? { code: order.couponCode } : undefined,
        eventId,
        products:
          order.warehouseOrders?.reduce<StartPaymentProps['products']>((accumulator, pack) => {
            if (pack.orderedSkus === undefined) {
              throw new Error('Analytics - startPayment - missing pack.orderedSkus');
            }
            return accumulator.concat(
              pack.orderedSkus.map((orderSku) => {
                if (orderSku.sku === undefined) {
                  throw new Error('Analytics - startPayment - missing orderSku.sku');
                }
                if (orderSku.sku.product === undefined) {
                  throw new Error('Analytics - startPayment - missing orderSku.sku.product');
                }
                return {
                  brand: {},
                  categories: [],
                  name: orderSku.sku.product.name,
                  quantity: orderSku.quantity,
                  sku: {
                    id: orderSku.sku.eventSkuId,
                    name: orderSku.sku.name,
                    price: {
                      value: orderSku.price,
                    },
                  },
                };
              })
            );
          }, []) ?? [],
        productsNumber: order.itemsNumber,
        totalCost: order.totalCost,
      };
      facebookPixel.events.startPayment(props);
      googleAnalytics4.events.startPayment(props);
      googleTagManager.events.startPayment(props);
      baim.events.startPayment(props);
      googleAds.gtagReportConversion('startPayment', { value: props.totalCost });
      remoteLog('startPayment');
    },
    pageView: (eventId: string) => {
      // FIXME Da capire se viene chiamato da TUTTE le pagine (a volte non viene fatto usePage, ha senso stia in usePAge e non in api?)
      const props: PageViewProps = {
        eventId,
        location: window.location.href,
        path: window.location.pathname,
        title: document.title,
      };
      facebookPixel.events.pageView(props);
      googleAnalytics4.events.pageView(props);
      googleTagManager.events.pageView(props);
      baim.events.pageView(props);
      googleAds.gtagReportConversion('pageView', {});
      remoteLog('pageView');
    },
    purchase: (eventId: string, order: OrderApiDto /*resultType: CompleteCheckoutEnum*/) => {
      // spostare la chiamata in b2x-react, ora replicata su ogni store
      getProductsAndSkusFromOrder(order).then((productsAndSkus) => {
        const props: PurchaseProps = {
          coupon: order.couponCode ? { code: order.couponCode } : undefined,
          eventId,
          orderCode: order.code,
          products: productsAndSkus.map(({ brand, categories, price, product, quantity, sku }) => ({
            brand: {
              name: brand.name,
            },
            categories: categories?.map((_category) => ({ name: _category.name })),
            name: product.name,
            quantity: quantity,
            sku: {
              // discount: price.discountValue,
              id: sku.eventSkuId,
              name: sku.name,
              price: {
                // crossedOutValue: price.crossedOutValue,
                value: price,
              },
            },
          })),
          productsNumber: 0,
          //FIXME
          result: '',
          //FIXME
          shippingCost: order.shippingCost,
          // taxCost: 0,
          //FIXME
          totalCost: order.totalCost,
        };
        facebookPixel.events.purchase(props);
        googleAnalytics4.events.purchase(props);
        googleTagManager.events.purchase(props);
        baim.events.purchase(props);
        googleAds.gtagReportConversion('purchase', { transaction_id: props.orderCode, value: props.totalCost });
        remoteLog('purchase');
      });
    },
    removeFromCart: (eventId: string, cartSku: CartSkuApiDto) => {
      getProductAndSkuFromCartSku(cartSku).then(({ brand, categories, price, product, sku }) => {
        const props: RemoveFromCartProps = {
          eventId,
          product: {
            brand: {
              name: brand.name,
            },
            categories: categories?.map((_category) => ({ name: _category.name })),
            name: product.name,
            quantity: cartSku.quantity,
            sku: {
              discount: price.discountValue,
              id: sku.eventSkuId,
              name: sku.name,
              price: {
                crossedOutValue: price.crossedOutValue,
                value: price.value,
              },
            },
          },
        };
        facebookPixel.events.removeFromCart(props);
        googleAnalytics4.events.removeFromCart(props);
        googleTagManager.events.removeFromCart(props);
        baim.events.removeFromCart(props);
        googleAds.gtagReportConversion('removeFromCart', { value: props.product.sku.price.value });
        remoteLog('removeFromCart');
      });
    },
    schedule: (eventId: string) => {
      // FIXME Non viene mai chiamato
      const props: ScheduleProps = { eventId };
      facebookPixel.events.schedule(props);
      googleAnalytics4.events.schedule(props);
      googleTagManager.events.schedule(props);
      baim.events.schedule(props);
      googleAds.gtagReportConversion('schedule', {});
      remoteLog('schedule');
    },
    search: (eventId: string, simpleSearch: string) => {
      const props: SearchProps = {
        eventId,
        simpleSearch: simpleSearch,
      };
      facebookPixel.events.search(props);
      googleAnalytics4.events.search(props);
      googleTagManager.events.search(props);
      baim.events.search(props);
      googleAds.gtagReportConversion('search', {});
      remoteLog('search');
    },
    selectProduct: (eventId: string, partialProduct: ProductApiDto, listing: ListingItemOptions) => {
      fillProduct(
        partialProduct,
        { brand: true, breadcrumbs: true, priceRange: true, skus: { price: true } },
        { silent: true }
      ).then((filledProduct) => {
        if (filledProduct.priceRange === undefined) {
          throw new Error('Analytics - selectProduct - missing product.priceRange');
        }
        if (filledProduct.skus === undefined || filledProduct.skus.length === 0) {
          throw new Error('Analytics - selectProduct - missing product.skus');
        }

        const categories = getProductCategories(filledProduct);

        // Costruisco le props (uniche) da inoltrare ai vari gestori.
        const props: SelectProductProps = {
          eventId,
          listing: listing,
          product: {
            brand: {
              name: filledProduct.brand?.name,
            },
            categories: categories?.map((_category) => ({ name: _category.name })),
            fromPrice: {
              crossedOutValue: filledProduct.priceRange.from.crossedOutValue,
              value: filledProduct.priceRange.from.value,
            },
            listing: listing,
            name: filledProduct.name,
            skus: filledProduct.skus.map((sku) => {
              if (sku.price === undefined) {
                throw new Error('Analytics - selectProduct - missing sku.price');
              }
              return {
                discount: sku.price.discountValue,
                id: sku.eventSkuId,
                name: sku.name,
                price: {
                  crossedOutValue: sku.price.crossedOutValue,
                  value: sku.price.value,
                },
              };
            }),
          },
        };
        // Chiamo i vari gestori.
        facebookPixel.events.selectProduct(props);
        googleAnalytics4.events.selectProduct(props);
        googleTagManager.events.selectProduct(props);
        baim.events.selectProduct(props);
        googleAds.gtagReportConversion('selectProduct', {});
        remoteLog('selectProduct');
      });
    },
    smartOrderPayment: (eventId: string, order: CreateSmartOrderRequestApiDto) => {
      const props: StartPaymentProps = {
        coupon: order.couponCode ? { code: order.couponCode } : undefined,
        eventId,
        products:
          order.skus?.map((sku) => ({
            brand: {},
            quantity: sku.quantity,
            sku: {
              id: sku.id,
              price: {
                value: sku.price,
              },
            },
          })) ?? [],
        productsNumber: order.skus?.reduce((acc, sku) => acc + sku.quantity, 0) || 0,
        totalCost: order.skus?.reduce((acc, sku) => acc + sku.price, 0) || 0,
      };
      facebookPixel.events.startPayment(props);
      googleAnalytics4.events.startPayment(props);
      googleTagManager.events.startPayment(props);
      baim.events.startPayment(props);
      googleAds.gtagReportConversion('startPayment', { value: props.totalCost });
      remoteLog('startPayment');
    },
    startPayment: (eventId: string, cart: CartApiDto) => {
      if (cart.packs === undefined) {
        throw new Error('Analytics - startPayment - missing cart.packs');
      }
      const props: StartPaymentProps = {
        coupon: cart.coupon ? { code: cart.coupon.code } : undefined,
        eventId,
        products: cart.packs.reduce<StartPaymentProps['products']>((accumulator, pack) => {
          if (pack.cartSkus === undefined) {
            throw new Error('Analytics - startPayment - missing pack.cartSkus');
          }
          return accumulator.concat(
            pack.cartSkus.map((cartSku) => {
              if (cartSku.sku === undefined) {
                throw new Error('Analytics - startPayment - missing cartSku.sku');
              }
              if (cartSku.sku.product === undefined) {
                throw new Error('Analytics - startPayment - missing cartSku.sku.product');
              }
              if (cartSku.price === undefined) {
                throw new Error('Analytics - startPayment - missing cartSku.price');
              }
              return {
                brand: {
                  // name: sku.brandName,
                },
                categories: [
                  //
                ],
                name: cartSku.sku.product.name,
                quantity: cartSku.quantity,
                sku: {
                  discount: cartSku.price.discountValue,
                  id: cartSku.sku.eventSkuId,
                  name: cartSku.sku.name,
                  price: {
                    crossedOutValue: cartSku.price.crossedOutValue,
                    value: cartSku.price.value,
                  },
                },
              };
            })
          );
        }, []),
        productsNumber: cart.itemsNumber,
        totalCost: cart.totalCost,
      };
      facebookPixel.events.startPayment(props);
      googleAnalytics4.events.startPayment(props);
      googleTagManager.events.startPayment(props);
      baim.events.startPayment(props);
      googleAds.gtagReportConversion('startPayment', { value: props.totalCost });
      remoteLog('startPayment');
    },
    submitApplication: (eventId: string) => {
      // FIXME Non viene mai chiamato
      const props: SubmitApplicationProps = { eventId };
      facebookPixel.events.submitApplication(props);
      googleAnalytics4.events.submitApplication(props);
      googleTagManager.events.submitApplication(props);
      baim.events.submitApplication(props);
      googleAds.gtagReportConversion('submitApplication', {});
      remoteLog('submitApplication');
    },
    viewCart: (eventId: string, cart: CartApiDto) => {
      getProductsAndSkusFromCart(cart).then((productsAndSkus) => {
        const props: ViewcartProps = {
          eventId,
          products: productsAndSkus.map(({ brand, categories, price, product, quantity, sku }) => ({
            brand: {
              name: brand.name,
            },
            categories: categories?.map((_category) => ({ name: _category.name })),
            name: product.name,
            quantity: quantity,
            sku: {
              discount: price.discountValue,
              id: sku.eventSkuId,
              name: sku.name,
              price: {
                crossedOutValue: price.crossedOutValue,
                value: price.value,
              },
            },
          })),
          productsNumber: cart.itemsNumber,
          totalCost: cart.totalCost,
        };
        facebookPixel.events.viewCart(props);
        googleAnalytics4.events.viewCart(props);
        googleTagManager.events.viewCart(props);
        baim.events.viewCart(props);
        googleAds.gtagReportConversion('viewCart', { value: props.totalCost });
        remoteLog('viewCart');
      });
    },
    viewProduct: (eventId: string, partialProduct: ProductApiDto) => {
      fillProduct(partialProduct, { brand: true, breadcrumbs: true, priceRange: true, skus: { price: true } }).then(
        (filledProduct) => {
          if (filledProduct.priceRange === undefined) {
            throw new Error('Analytics - viewProduct - missing product.priceRange');
          }
          if (filledProduct.skus === undefined || filledProduct.skus.length === 0) {
            throw new Error('Analytics - viewProduct - missing product.skus');
          }

          const categories = getProductCategories(filledProduct);

          const props: ViewProductProps = {
            eventId,
            product: {
              brand: {
                name: filledProduct.brand?.name,
              },
              categories: categories?.map((_category) => ({ name: _category.name })),
              fromPrice: {
                crossedOutValue: filledProduct.priceRange.from.crossedOutValue,
                value: filledProduct.priceRange.from.value,
              },
              name: filledProduct.name,
              skus: filledProduct.skus.map((sku) => {
                if (sku.price === undefined) {
                  throw new Error('Analytics - selectProduct - missing sku.price');
                }
                return {
                  discount: sku.price.discountValue,
                  id: sku.eventSkuId,
                  name: sku.name,
                  price: {
                    crossedOutValue: sku.price.crossedOutValue,
                    value: sku.price.value,
                  },
                };
              }),
            },
          };
          facebookPixel.events.viewProduct(props);
          googleAnalytics4.events.viewProduct(props);
          googleTagManager.events.viewProduct(props);
          baim.events.viewProduct(props);
          googleAds.gtagReportConversion('viewProduct', {});
          remoteLog('viewProduct');
        }
      );
    },
    viewProducts: (eventId: string, partialProducts: Array<ProductApiDto>, listing: ListingOptions) => {
      fillProducts(partialProducts, { brand: true, priceRange: true, skus: { price: true } }).then((filledProducts) => {
        const props: ViewProductsProps = {
          eventId,
          listing: listing,
          products: filledProducts.map((filledProduct, index) => {
            if (filledProduct.priceRange === undefined) {
              throw new Error('Analytics - viewProducts - missing product.priceRange');
            }
            if (filledProduct.skus === undefined || filledProduct.skus.length === 0) {
              throw new Error('Analytics - viewProducts - missing product.skus');
            }
            return {
              brand: {
                name: filledProduct.brand?.name,
              },
              // categories: categories?.map((_category) => ({ name: _category.name })),
              fromPrice: {
                crossedOutValue: filledProduct.priceRange.from.crossedOutValue,
                value: filledProduct.priceRange.from.value,
              },
              listing: { ...listing, index },
              name: filledProduct.name,
              skus: filledProduct.skus.map((sku) => {
                if (sku.price === undefined) {
                  throw new Error('Analytics - viewProducts - missing sku.price');
                }
                return {
                  discount: sku.price.discountValue,
                  id: sku.eventSkuId,
                  name: sku.name,
                  price: {
                    crossedOutValue: sku.price.crossedOutValue,
                    value: sku.price.value,
                  },
                };
              }),
            };
          }),
        };
        facebookPixel.events.viewProducts(props);
        googleAnalytics4.events.viewProducts(props);
        googleTagManager.events.viewProducts(props);
        baim.events.viewProducts(props);
        googleAds.gtagReportConversion('viewProducts', {});
        remoteLog('viewProducts');
      });
    },
  },
  init: () => {
    googleAnalytics4.init(appConfig.analytics?.googleAnalytics4);
    googleTagManager.init(appConfig.analytics?.googleTagManager);
    baim.init(appConfig.analytics?.baim);
    googleAds.init(appConfig.analytics?.googleAds);
    facebookPixel.init(appConfig.analytics?.facebook);
  },
};

const remoteLog = (event: string) => {
  // if (appConfig.api?.apiKey && (environment === 'TEST' || environment === 'PRODUCTION')) {
  //   api.events.log(appConfig.api.apiKey, event);
  // }
};
