import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { ServiceCookieSubpageA } from './serviceSubpagesA/ServiceCookieSubpageA';

export interface ServiceCookieSubpageProps {}

export type ServiceCookieSubpageVariants = 'A';

const ServiceCookieSubpageController = (props: PropsWithCustomComponent<ServiceCookieSubpageProps>) => (
  <VariantsController<ServiceCookieSubpageProps, ServiceCookieSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServiceCookieSubpageA },
      name: 'ServiceCookieSubpage',
    }}
  />
);
export { ServiceCookieSubpageController as ServiceCookieSubpage };
