import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { WebSite as WebSiteSchema } from 'schema-dts';

import { useAppContext } from '../AppContext';
import { getAbsoluteUrl } from '../util';

export type WebSiteProps = Omit<WebSiteSchema, '@context' | '@type' | 'url'>;

export const WebSite = (props: WebSiteProps) => {
  const { structuredDataContent } = useAppContext();

  const website = structuredDataContent?.body.website;

  return (
    <Helmet
      script={[
        helmetJsonLdProp<WebSiteSchema>(
          {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            description: website?.description,
            name: website?.name,
            potentialAction:
              website?.potentialAction?.name && website.potentialAction.target?.url
                ? {
                    '@type': 'Action',
                    name: website.potentialAction.name,
                    target: website.potentialAction.target.url,
                  }
                : undefined,
            url: getAbsoluteUrl('/'),
            ...props,
          },
          {
            space: 1,
          }
        ),
      ]}
    />
  );
};
