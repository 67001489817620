import { SessionApiDto } from '.';
import { CartPopulate } from './cart';
import { CustomerPopulate, WishlistPopulate } from './customer';
import {
  AutoLoginRequestApiDto,
  CustomerApiDto,
  LoginRequestApiDto,
  UpdateCustomerRequiredFieldsRequestApiDto,
} from './dto';
import { apiRequest, BaseOptions, getBaseOptions, Populate, updateHeaders } from './utils/util';

export interface SessionPopulate {
  cart?: Populate<CartPopulate>;
  customer?: Populate<CustomerPopulate>;
  wishlist?: Populate<WishlistPopulate>;
}

const rootPath = '/v2/session';

export interface GetSessionOptions extends BaseOptions {
  // Qui non uso Populate<SessionPopulate> per facilitarmi la vita con la getSession dentro apiConfig
  populate?: SessionPopulate;
}
export const get = async (options?: GetSessionOptions) =>
  apiRequest<SessionApiDto, void, SessionPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}`,
  }).then((response) => {
    updateHeaders({ sessionToken: response.data.sessionToken });
    return response;
  });

export const login = async (data: LoginRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, LoginRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/login`,
  });

export const logout = async (options?: BaseOptions) =>
  apiRequest<void, void, void>({
    ...getBaseOptions(options),
    method: 'POST',
    url: `${rootPath}/logout`,
  });

export const autologin = async (data: AutoLoginRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, AutoLoginRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/autologin`,
  });

export interface GetCustomerByTokenOptions extends BaseOptions {
  populate?: CustomerPopulate;
}
export const getCustomerByToken = async (token: string, options?: GetCustomerByTokenOptions) =>
  apiRequest<CustomerApiDto, void, CustomerPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}/getCustomerByToken/${token}`,
  });

export interface UpdateCustomerRequiredFieldsOptions extends BaseOptions {
  populate: Populate<CustomerPopulate>;
}

export const updateCustomerRequiredFields = async (
  data: UpdateCustomerRequiredFieldsRequestApiDto,
  options?: UpdateCustomerRequiredFieldsOptions
) =>
  apiRequest<void, UpdateCustomerRequiredFieldsRequestApiDto, CustomerPopulate>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    populate: options?.populate,
    url: `${rootPath}`,
  });
