import classnames from 'classnames';

import { TableLayout } from './Table';
import { renderUI } from './util';

export interface TableFootProps extends TableLayout {
  children?: React.ReactNode;
}

export const TableFoot = ({
  border,
  borderless,
  children,
  className,
  variant,
  xAlignment,
  yAlignment,
}: TableFootProps): React.ReactElement => {
  return renderUI({
    bs5: (
      <tfoot
        className={classnames(
          'TableFoot',
          className,
          variant && `table-${variant}`,
          { 'text-start': xAlignment === 'left' },
          { 'text-center': xAlignment === 'center' },
          { 'text-end': xAlignment === 'right' },
          { 'align-top': yAlignment === 'top' },
          { 'align-middle': yAlignment === 'center' },
          { 'align-bottom': yAlignment === 'bottom' },
          borderless && 'table-borderless',
          border && `table-bordered border-${border}`
        )}
      >
        {children}
      </tfoot>
    ),
  });
};
