import { ButtonSize } from '../Button';
import { Dropdown, DropdownAlignment, DropdownAutoclose, DropdownDirection } from '../Dropdown';
import { DropdownDivider } from '../DropdownDivider';
import { DropdownHeader } from '../DropdownHeader';
import { DropdownItem } from '../DropdownItem';
import { renderUI } from '../util';

export interface DropdownsProps {
  hideAlignments?: boolean;
  hideAutoClose?: boolean;
  hideComponent?: boolean;
  hideDarkVariant?: boolean;
  hideDirections?: boolean;
  hideOffsets?: boolean;
  sizes?: Array<ButtonSize>;
  variants?: Array<string>;
}

export const Dropdowns = ({
  hideAlignments,
  hideAutoClose,
  hideComponent,
  hideDarkVariant,
  hideDirections,
  hideOffsets,
  sizes = ['small', undefined, 'large'],
  variants = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'],
}: DropdownsProps) => {
  const DropItems = () => (
    <>
      <DropdownHeader>Header</DropdownHeader>
      <DropdownItem>Item 1</DropdownItem>
      <DropdownItem active>item 2 (active)</DropdownItem>
      <DropdownItem>item 3</DropdownItem>
      <DropdownItem disabled>item 4 (disabled)</DropdownItem>
      <DropdownItem>item 5</DropdownItem>
      <DropdownDivider />
      <DropdownItem>Item 6</DropdownItem>
    </>
  );

  return renderUI({
    bs5: (
      <>
        {(sizes as Array<ButtonSize>).map((size) =>
          ['', 'outline-'].map((prefix) => (
            <div className="d-flex gap-2 mb-3 flex-wrap" key={`${size}-${prefix}`}>
              {variants.map((variant) => (
                <Dropdown
                  key={`${size}-${prefix}-${variant}`}
                  label="Dropdown"
                  size={size}
                  variant={`${prefix}${variant}`}
                >
                  <DropItems />
                </Dropdown>
              ))}
            </div>
          )),
        )}
        {!hideDarkVariant && (
          <div className="d-flex gap-2 mb-3">
            <Dropdown dark label="Dropdown dark">
              <DropItems />
            </Dropdown>
          </div>
        )}
        {!hideDirections && (
          <div className="d-flex gap-2 mb-3">
            {(['up', 'down', 'right', 'left'] as Array<DropdownDirection>).map((direction) => (
              <Dropdown direction={direction} key={direction} label={`Drop${direction}`}>
                <DropItems />
              </Dropdown>
            ))}
          </div>
        )}
        {!hideAlignments && (
          <div className="d-flex gap-2 mb-3">
            {(['left', 'right'] as Array<DropdownAlignment>).map((alignment) => (
              <Dropdown alignment={alignment} key={alignment} label={`Dropdown aligned ${alignment}`}>
                <DropItems />
              </Dropdown>
            ))}
          </div>
        )}
        {!hideComponent && (
          <div className="d-flex gap-2 mb-3">
            <Dropdown label={'Dropdown with component inside'}>
              <div className="p-3 w-100">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                  laborum.
                </p>
              </div>
            </Dropdown>
          </div>
        )}
        {!hideOffsets && (
          <>
            <div className="d-flex gap-2 mb-3">
              {([0, 20, 40] as Array<number>).map((offset) => (
                <Dropdown key={`${offset}-x`} label={`Dropdown with offset x ${offset}`} offset={{ x: offset, y: 0 }}>
                  <DropItems />
                </Dropdown>
              ))}
            </div>
            <div className="d-flex gap-2 mb-3">
              {([0, 20, 40] as Array<number>).map((offset) => (
                <Dropdown key={`${offset}-y`} label={`Dropdown with offset y ${offset}`} offset={{ x: 0, y: offset }}>
                  <DropItems />
                </Dropdown>
              ))}
            </div>
            <div className="d-flex gap-2 mb-3">
              {([0, 20, 40] as Array<number>).map((offset) => (
                <Dropdown
                  key={`${offset}-y`}
                  label={`Dropdown with offset x and y ${offset}`}
                  offset={{ x: offset, y: offset }}
                >
                  <DropItems />
                </Dropdown>
              ))}
            </div>
          </>
        )}
        {!hideAutoClose && (
          <div className="d-flex gap-2 mb-3">
            {([true, false, 'inside', 'outside'] as Array<DropdownAutoclose>).map((autoclose) => (
              <Dropdown
                autoClose={autoclose}
                key={JSON.stringify(autoclose)}
                label={`Dropdown with autoclose ${autoclose}`}
              >
                <DropItems />
              </Dropdown>
            ))}
          </div>
        )}
      </>
    ),
  });
};
