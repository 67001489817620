import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import ReactSelect, { components, SingleValue } from 'react-select';

import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { CommonFieldProps, HTMLAutocomplete, useField } from '../Form';
import { useFormGroupContext } from '../FormGroupContext';
import { OptionProps } from './Option';

export interface DynamicSelectProps<T, V = string> extends CommonFieldProps<HTMLSelectElement, V> {
  autoComplete?: HTMLAutocomplete;
  children?: React.ReactNode;
  includeEmptyOption?: boolean;
  options?: Array<OptionProps<T>>;
  placeholder?: string;
  renderOption?(option: OptionProps<T>): React.ReactElement;
  variants?: {
    bs5?: {
      menu?: {
        borderVariant?: string;
      };
      option?: {
        backgroundFocusedVariant?: string;
        backgroundSelectedVariant?: string;
        backgroundVariant?: string;
        textFocusedVariant?: string;
        textSelectedVariant?: string;
        textVariant?: string;
      };
      select?: {
        backgroundOpenVariant?: string;
        borderFocusOpenVariant?: string;
        borderFocusVariant?: string;
      };
    };
  };
}

export const DynamicSelect = <T,>(props: DynamicSelectProps<T>): React.ReactElement => {
  const {
    children,
    className,
    disabled,
    includeEmptyOption = true,
    innerRef,
    onFormikChange,
    options,
    placeholder,
    renderOption,
    size,
    variants,
    ...otherProps
  } = props;

  const { b2xHelpers, field } = useField(props, otherProps);
  const { setFieldValue } = useFormikContext();
  const { id, required, withLabel } = useFormGroupContext();

  const onChange = useCallback(
    (opt: SingleValue<OptionProps>) => {
      setFieldValue(field.name, opt?.value);
    },
    [field, setFieldValue]
  );

  let optionsToRender = options;
  if (includeEmptyOption && options) {
    optionsToRender = [{ label: !withLabel && required ? `${placeholder} *` : placeholder, value: '' }, ...options];
  }

  const isOptionDisabled = useCallback((option: OptionProps<T>) => option.disabled || false, []);

  return renderUI({
    bs5: (
      <>
        <ReactSelect
          {...field}
          className={classnames({ 'is-valid': b2xHelpers.isValid }, { 'is-invalid': b2xHelpers.isInvalid })}
          classNames={{
            control: (state) => {
              let backgroundClass = '';
              if (state.menuIsOpen) {
                backgroundClass = `bg-${variants?.bs5?.select?.backgroundOpenVariant ?? 'gray-300'}`;
              }

              let borderClass = '';
              if (state.isFocused) {
                if (state.menuIsOpen) {
                  borderClass = `border-${variants?.bs5?.select?.borderFocusOpenVariant ?? 'gray-500'}`;
                } else {
                  borderClass = `border-${variants?.bs5?.select?.borderFocusVariant ?? 'primary'}`;
                }
              }

              return classnames(
                'form-select form-dynamic-select rounded-2 px-1 shadow-none',
                borderClass,
                backgroundClass,
                { 'form-select-sm': size === 'small' },
                { 'form-select-lg': size === 'large' },
                { 'is-valid': b2xHelpers.isValid },
                { 'is-invalid': b2xHelpers.isInvalid },
                className
              );
            },
            menu: () =>
              `my-1 overflow-hidden bg-white border border-${
                variants?.bs5?.menu?.borderVariant ?? 'gray-500'
              } rounded-1`,
            menuList: () => 'py-0',
            option: (state) => {
              let backgroundClass = `bg-${variants?.bs5?.option?.backgroundVariant ?? 'transparent'} text-${
                variants?.bs5?.option?.textVariant ?? 'secondary'
              }`;
              if (state.isSelected) {
                backgroundClass = `bg-${variants?.bs5?.option?.backgroundSelectedVariant ?? 'gray-300'} text-${
                  variants?.bs5?.option?.textSelectedVariant ?? 'secondary'
                }`;
              } else if (state.isFocused) {
                backgroundClass = `bg-${variants?.bs5?.option?.backgroundFocusedVariant ?? 'gray-300'} text-${
                  variants?.bs5?.option?.textFocusedVariant ?? 'secondary'
                }`;
              }

              let disabledClass = '';
              if (state.isDisabled) {
                disabledClass = 'opacity-50 cursor-not-allowed';
              }

              return classnames(backgroundClass, disabledClass);
            },
          }}
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
            Option: (optionProps) => {
              if (!renderOption) {
                return <components.Option {...optionProps} />;
              }

              const { data } = optionProps;
              return <components.Option {...optionProps}>{renderOption(data)}</components.Option>;
            },
            SingleValue: (optionProps) => {
              if (!renderOption) {
                return <components.SingleValue {...optionProps} />;
              }

              const { getValue } = optionProps;
              return <components.SingleValue {...optionProps}>{renderOption(getValue()[0])}</components.SingleValue>;
            },
          }}
          id={id}
          isDisabled={disabled}
          isOptionDisabled={isOptionDisabled}
          isSearchable={false}
          onBlur={field.onBlur}
          onChange={onChange}
          options={optionsToRender}
          placeholder={!withLabel && required ? `${placeholder} *` : placeholder}
          styles={{
            option: (provided, state) => ({
              ...provided,
              cursor: state.isDisabled ? 'not-allowed' : state.isFocused ? 'pointer' : undefined,
            }),
            singleValue: (provided) => ({
              ...provided,
              marginInline: 0,
            }),
          }}
          value={optionsToRender?.find((opt) => opt.value === field.value)}
        />
        <Feedback name={props.name} />
      </>
    ),
  });
};
