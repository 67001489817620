import { CheckoutStepContentSection } from '../../contentTypes';
import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AdditionalCheckoutStepContentProps } from '../CheckoutPage';
import { CheckoutPaymentMethodStepA } from './checkoutStepsA/CheckoutPaymentMethodStepA';

export interface CheckoutPaymentMethodStepProps {
  additionalContent?: AdditionalCheckoutStepContentProps;
  content?: CheckoutStepContentSection;
  onEdit: boolean;
}

export type CheckoutPaymentMethodStepVariants = 'A';

const CheckoutPaymentMethodStepController = (props: PropsWithCustomComponent<CheckoutPaymentMethodStepProps>) => (
  <VariantsController<CheckoutPaymentMethodStepProps, CheckoutPaymentMethodStepVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CheckoutPaymentMethodStepA },
      name: 'CheckoutPaymentMethodStep',
    }}
  />
);
export { CheckoutPaymentMethodStepController as CheckoutPaymentMethodStep };
