import { CartApiDto, CheckoutApiType } from '@b2x/storefront-api-js-client/src/';
import { GetCartOptions } from '@b2x/storefront-api-js-client/src/cart';
import React from 'react';

import { analytics } from './analytics/analytics';
import { ApiRequestConfig } from './api/useApiRequest';
import { useCartApi } from './api/useCartApi';
import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { useStable } from './util';

export interface UseCartOptions extends GetCartOptions {}

export const useCart = (options?: UseCartOptions, config?: ApiRequestConfig) => {
  const [cart, setCart] = React.useState<CartApiDto>();

  const { getCart } = useCartApi();
  const { session } = useAppContext();

  options = useStable(options);
  config = useStable(config);

  /*
  Prendo il carrello al primo caricamento e ogni volta che cambia quello in sessione, per garantirne la coerenza.
  L'iter sarà quindi:
   - sto nella pagina carrello
   - modifico una quantità
   - alla onSuccess verrà aggiornato il carrello in sessione
   - la modifica del carrello in sessione scatenerà questo aggiornamento del carrello in pagina
  Ricapitolado, qualsiasi modifica al carrello deve prima passare per quello in sessione, che a cascata scanerà l'aggiornamento di questo.
  */
  React.useEffect(() => {
    getCart(options, config).then((response) => {
      setCart(response.data);
    });
  }, [config, getCart, options, session?.cart]);

  const blockingAlerts = React.useMemo(
    () => cart?.alerts?.filter((alert) => appConfig.cartBlockingAlerts?.includes(alert.code as CheckoutApiType)),
    [cart?.alerts]
  );

  const canMoveOn = React.useMemo(() => blockingAlerts && blockingAlerts.length === 0, [blockingAlerts]);

  // Mando evento di "viewCart"
  const analyticsViewCartEventSent = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (cart && !analyticsViewCartEventSent.current) {
      /*
        FIXME Da capire come fare. lato back la getCart verrà fatta a ogni modifica del carrello, ma solo la prima dovrebbe generare l'evento?
        In caso mi salvo il primo eventId?
      */
      analytics.events.viewCart('EVENT_ID', cart);
      analyticsViewCartEventSent.current = true;
    }
  }, [cart]);

  return { blockingAlerts, canMoveOn, cart };
};
