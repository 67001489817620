import {
  OrderingApiType,
  OrderStateApiType,
  SearchOrderFieldApiType,
  SearchOrdersApiDto,
} from '@b2x/storefront-api-js-client/src';
import { SearchOrdersOptions } from '@b2x/storefront-api-js-client/src/orders';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useOrdersApi } from './api/useOrdersApi';
import { useSearchParams } from './router/router';
import { useStable } from './util';

export interface UseOrdersSearchOptions extends SearchOrdersOptions {}

export const useOrdersSearch = (options?: UseOrdersSearchOptions, config?: ApiRequestConfig) => {
  const [searchResult, setSearchResult] = React.useState<SearchOrdersApiDto>();
  const [fetching, setFetching] = React.useState<boolean>(false);

  const { searchOrders } = useOrdersApi();

  const [searchParams] = useSearchParams();
  options = useStable(options);
  config = useStable(config);

  // Faccio partire la search ogni volta che cambia quacosa nella path
  React.useEffect(() => {
    const pageSize = searchParams.get('pageSize') ?? undefined;
    const pageNum = searchParams.get('pageNum') ?? undefined;
    const orderBy = (searchParams.get('orderBy') as SearchOrderFieldApiType | undefined) ?? undefined;
    const orderingType = (searchParams.get('orderingType') as OrderingApiType | undefined) ?? undefined;

    const searchOrdersOptions: SearchOrdersOptions = {
      code: searchParams.get('code') ?? undefined,
      email: searchParams.get('code') ?? undefined,
      orderBy: orderBy,
      orderingType: orderingType,
      pageNum: pageNum ? parseInt(pageNum) : undefined,
      pageSize: pageSize ? parseInt(pageSize) : undefined,
      pending: searchParams.get('pending') ? true : undefined,
      populate: options?.populate,
      state: searchParams.getAll('state') as Array<OrderStateApiType> | undefined,
    };

    setFetching(true);
    searchOrders(searchOrdersOptions, config)
      .then((response) => {
        setSearchResult(response.data);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [config, options?.populate, searchOrders, searchParams]);

  return { fetching, searchResult };
};
