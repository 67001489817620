import { ShippingProfileApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useCartApi } from './api/useCartApi';

export const useShippingProfiles = () => {
  const { getShippingProfiles } = useCartApi();

  const [shippingProfiles, setShippingProfiles] = React.useState<Array<ShippingProfileApiDto>>();

  React.useEffect(() => {
    getShippingProfiles().then((response) => setShippingProfiles(response.data));
  }, [getShippingProfiles]);

  return shippingProfiles;
};
