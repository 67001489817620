import React from 'react';

export const useScrollTo = (offset = 0) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const scrollToRef = React.useCallback(() => {
    ref.current && window.scrollBy({ top: ref.current.getBoundingClientRect().y + offset });
  }, [ref, offset]);

  return [ref, scrollToRef] as const;
};
