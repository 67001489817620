import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { AddressFieldsetA } from './AddressFieldsetA';
import { DynamicField } from './fields/DynamicField';
import { TextInput } from './fields/Input';
import { Select } from './fields/Select';
import { FormGroup } from './FormGroup';
import { AddressFieldsHelper, AddressValidationSchemaSelector } from './useAddressForm';

export interface AddressFieldsetProps {
  addressFieldsHelper: AddressFieldsHelper;
  // values: AddressFormValues;
}

const AddressFieldset = ({ addressFieldsHelper }: AddressFieldsetProps) => {
  return (
    <>
      <FormGroup {...addressFieldsHelper.addressLine1.formGroup}>
        <TextInput {...addressFieldsHelper.addressLine1.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.addressLine2.formGroup}>
        <TextInput {...addressFieldsHelper.addressLine2.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.addressLine3.formGroup}>
        <TextInput {...addressFieldsHelper.addressLine3.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.alternativePhone.formGroup}>
        <TextInput {...addressFieldsHelper.alternativePhone.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.city.formGroup}>
        <TextInput {...addressFieldsHelper.city.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.civicNumber.formGroup}>
        <TextInput {...addressFieldsHelper.civicNumber.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.country.formGroup}>
        <Select {...addressFieldsHelper.country.select} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.latitude.formGroup}>
        <TextInput {...addressFieldsHelper.latitude.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.longitude.formGroup}>
        <TextInput {...addressFieldsHelper.longitude.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.middleName.formGroup}>
        <TextInput {...addressFieldsHelper.middleName.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.name.formGroup}>
        <TextInput {...addressFieldsHelper.name.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.phone.formGroup}>
        <TextInput {...addressFieldsHelper.phone.textInput} />
      </FormGroup>
      {addressFieldsHelper.province && (
        <FormGroup {...addressFieldsHelper.province.formGroup}>
          <Select {...addressFieldsHelper.province.select} />
        </FormGroup>
      )}
      {addressFieldsHelper.region && (
        <FormGroup {...addressFieldsHelper.region.formGroup}>
          <TextInput {...addressFieldsHelper.region.textInput} />
        </FormGroup>
      )}
      <FormGroup {...addressFieldsHelper.surname.formGroup}>
        <TextInput {...addressFieldsHelper.surname.textInput} />
      </FormGroup>
      <FormGroup {...addressFieldsHelper.zipCode.formGroup}>
        <DynamicField {...addressFieldsHelper.zipCode.dynamicField} />
      </FormGroup>
    </>
  );
};

export const addressValidationSchemaSelector: AddressValidationSchemaSelector = {
  addressLine2: true,
  addressLine3: true,
  alternativePhone: true,
  latitude: true,
  longitude: true,
  middleName: true,
  phone: true,
  region: true,
};

export type AddressFieldsetVariants = 'A';

const AddressFieldsetController = (props: PropsWithCustomComponent<AddressFieldsetProps>) => (
  <VariantsController<AddressFieldsetProps, AddressFieldsetVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: {
        A: AddressFieldsetA,
      },
      defaultComponent: AddressFieldset,
      name: 'AddressFieldset',
    }}
  />
);
export { AddressFieldsetController as AddressFieldset };
