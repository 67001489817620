import classnames from 'classnames';

import { NavLink } from '../../../router/NavLink';
import { renderUI } from '../../../util';

export interface ListCProps {
  items: Array<{
    label?: string;
    link?: string;
  }>;
  title: string;
}

export const ListC = ({ items, title }: ListCProps) => {
  return renderUI({
    bs5: (
      <div className="ListC">
        <div className="aside-menu-title py-3 px-4 px-lg-5 bg-primary mb-1">{title}</div>
        <div className="aside-menu-item vstack gap-2 bg-white py-3">
          {items.map((item) => (
            <NavLink
              className="text-reset text-decoration-none py-1 px-4 px-lg-5 small"
              key={item.label}
              silent
              to={item.link}
            >
              {({ isActive }) => (
                <span className={classnames(isActive && 'border-bottom border-primary border-3 py-1')}>
                  {item.label}
                </span>
              )}
            </NavLink>
          ))}
        </div>
      </div>
    ),
  });
};
