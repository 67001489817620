import { AddressApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { UseFormModalProps } from '../Modal';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { AddressFieldset, addressValidationSchemaSelector } from './AddressFieldset';
import { CustomerAddressFormA } from './CustomerAddressFormA';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBoolean,
  getFormikBooleanValue,
  getInitialBoolean,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import {
  AddressFieldsHelper,
  AddressFormValues,
  AddressValidationSchema,
  AddressValidationSchemaSelector,
  getAddressFromFormValues,
  getAddressValidationSchema,
  useAddressForm,
} from './useAddressForm';

export interface CustomerAddressFormProps
  extends Omit<BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector>, 'onSuccess'> {
  address?: AddressApiDto;
  duringCheckout?: boolean;
  onSuccess?(createdAddressId?: string): void;
}

interface FormValues {
  address: AddressFormValues;
  defaultBilling: formikBoolean;
  defaultShipping: formikBoolean;
}

type ValidationSchema = {
  address: yup.ObjectSchema<AddressValidationSchema>;
  defaultBilling: yup.BooleanSchema;
  defaultShipping: yup.BooleanSchema;
};

type ValidationSchemaSelector = {
  address: AddressValidationSchemaSelector;
};

interface FieldsHelper {
  address: AddressFieldsHelper;
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  defaultBilling: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  defaultShipping: { checkbox: CheckboxProps; formGroup: FormGroupProps };
}

export const CustomerAddressFormHelper = ({
  address,
  children,
  className,
  duringCheckout,
  initialValues,
  onCancel,
  onSuccess,
  validationSchemaSelector = { address: addressValidationSchemaSelector },
  ...otherProps
}: CustomerAddressFormProps) => {
  const { getAddressFieldsHelper, getAddressInitialValues } = useAddressForm(
    duringCheckout ? 'CustomerAddressFormDuringCheckout' : 'CustomerAddressForm',
    undefined,
    address
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      address: getAddressInitialValues(),
      // preflaggare default se nessuno in rubrica già lo è.
      defaultBilling: getInitialBoolean(false),
      // preflaggare default se nessuno in rubrica già lo è.
      defaultShipping: getInitialBoolean(false),
      ...initialValues,
    }),
    [getAddressInitialValues, initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      address: yup.object(getAddressValidationSchema(validationSchemaSelector.address)),
      defaultBilling: yup.boolean(),
      defaultShipping: yup.boolean(),
    }),
    [validationSchemaSelector.address]
  );

  const { createAddress, updateAddress } = useCustomerApi();

  const handleSuccess = React.useCallback(
    (createdAddressId?: string) => {
      onSuccess && onSuccess(createdAddressId);
    },
    [onSuccess]
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const addressToSend: AddressApiDto = getAddressFromFormValues(values.address, address);

      if (address === undefined) {
        return createAddress({
          address: addressToSend,
          defaultBilling: getFormikBooleanValue(values.defaultBilling),
          defaultShipping: getFormikBooleanValue(values.defaultShipping),
        }).then((response) => {
          handleSuccess(response.data.toString());
        });
      } else {
        return updateAddress({
          address: addressToSend,
          defaultBilling: getFormikBooleanValue(values.defaultBilling),
          defaultShipping: getFormikBooleanValue(values.defaultShipping),
        }).then(() => {
          handleSuccess();
        });
      }
    },
    [address, createAddress, handleSuccess, updateAddress]
  );

  const closeModal = useModalCloser();
  const { insideModal } = useInsideModalDetector();

  return (
    <HelpedForm<FormValues>
      className={classnames('CustomerAddressForm', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          address: getAddressFieldsHelper(formik),
          buttons: {
            // cancel: {
            //   label: t('form.addressForm.buttons.cancel.label'),
            //   onClick: closeModal,
            //   ...cancelButton,
            // },
            cancel: onCancel
              ? {
                  label: t('form.addressForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.addressForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: address ? t('form.addressForm.buttons.edit.label') : t('form.addressForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          defaultBilling: {
            checkbox: {
              id: 'defaultBilling',
              label: t('form.addressForm.account.defaultBilling.label'),
              name: 'defaultBilling',
            },
            formGroup: { names: ['defaultBilling'] },
          },
          defaultShipping: {
            checkbox: {
              id: 'defaultShipping',
              label: t('form.addressForm.account.defaultShipping.label'),
              name: 'defaultShipping',
            },
            formGroup: { names: ['defaultShipping'] },
          },
        };

        return (
          <>
            {children ? (
              children({ closeModal, fieldsHelper, formik, insideModal })
            ) : (
              <>
                <AddressFieldset addressFieldsHelper={fieldsHelper.address} />
                <FormGroup {...fieldsHelper.defaultShipping.formGroup}>
                  <Checkbox {...fieldsHelper.defaultShipping.checkbox} />
                </FormGroup>
                <FormGroup {...fieldsHelper.defaultBilling.formGroup}>
                  <Checkbox {...fieldsHelper.defaultBilling.checkbox} />
                </FormGroup>

                {fieldsHelper.buttons.cancel && <Button {...fieldsHelper.buttons.cancel} variant="outline-primary" />}
                <Button {...fieldsHelper.buttons.submit} />
              </>
            )}
          </>
        );
      }}
    </HelpedForm>
  );
};

export type CustomerAddressFormVariants = 'A';

const CustomerAddressFormController = (props: PropsWithCustomComponentWithoutChildren<CustomerAddressFormProps>) => (
  <VariantsController<CustomerAddressFormProps, CustomerAddressFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: {
        A: CustomerAddressFormA,
      },
      defaultComponent: CustomerAddressFormHelper,
      name: 'CustomerAddressForm',
    }}
  />
);
export { CustomerAddressFormController as CustomerAddressForm };

export const useCustomerAddressFormModal = (modalProps?: UseFormModalProps) => {
  const { showModal } = useModals();
  return React.useCallback(
    (formProps: CustomerAddressFormProps) => {
      showModal({
        children: <CustomerAddressFormController {...formProps} />,
        fullscreen: 'sm',
        size: 'extra-large',
        title: t(formProps.address ? 'form.addressForm.editAddressTitle' : 'form.addressForm.newAddressTitle'),
        ...modalProps,
      });
    },
    [modalProps, showModal]
  );
};
