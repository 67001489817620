import React from 'react';

import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { addScript } from './util';

export interface CookiebotDeclarationProps {}

export const CookiebotDeclaration = (props: CookiebotDeclarationProps) => {
  const cookiebotRef = React.useRef<HTMLDivElement>(null);
  const { info, locale } = useAppContext();

  React.useEffect(() => {
    if (appConfig.cookiebot && info?.environment !== 'LOCAL') {
      addScript(
        {
          async: true,
          dataAttributes: {
            culture: locale?.languageCode,
          },
          id: 'cookie-declaration',
          src: `https://consent.cookiebot.com/${appConfig.cookiebot.id}/cd.js`,
        },
        { appendTo: cookiebotRef }
      );
    }
  }, [info, locale]);

  return (
    <>
      {info && info.environment !== 'LOCAL' ? (
        <div ref={cookiebotRef}></div>
      ) : (
        <>Cookiebot disabilitato in ambiente di sviluppo</>
      )}
    </>
  );
};
