import { buildButtonChildren, buildButtonClassName, ButtonProps } from './Button';
import { Link, LinkProps } from './router/Link';

export interface LinkAsButtonProps<ButtonVariant, IconName, IconSize> {
  button?: ButtonProps<ButtonVariant, IconName, IconSize>;
  link?: LinkProps;
}

export const LinkAsButton = <ButtonVariant extends string, IconName extends string, IconSize extends number>({
  button,
  link,
}: LinkAsButtonProps<ButtonVariant, IconName, IconSize>) => {
  return (
    <Link {...link} className={buildButtonClassName({ ...button })}>
      {buildButtonChildren({ ...button })}
    </Link>
  );
};
