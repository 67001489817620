import { CheckoutStepContentSection } from '../../contentTypes';
import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AdditionalCheckoutStepContentProps } from '../CheckoutPage';
import { CheckoutSummaryStepA } from './checkoutStepsA/CheckoutSummaryStepA';

export interface CheckoutSummaryStepProps {
  additionalContent?: Omit<AdditionalCheckoutStepContentProps, 'onClosed'>;
  content?: CheckoutStepContentSection;
  onEdit: boolean;
}

export type CheckoutSummaryStepVariants = 'A';

const CheckoutSummaryStepController = (props: PropsWithCustomComponent<CheckoutSummaryStepProps>) => (
  <VariantsController<CheckoutSummaryStepProps, CheckoutSummaryStepVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CheckoutSummaryStepA },
      name: 'CheckoutSummaryStep',
    }}
  />
);
export { CheckoutSummaryStepController as CheckoutSummaryStep };
