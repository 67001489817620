import { ServiceSubpageContentType } from '../../../contentTypes';
import { CookiebotDeclaration } from '../../../CookiebotDeclaration';
import { formatHtml } from '../../../util';
import { ServiceSubpage } from '../ServiceSubpage';

export interface ServiceCookieSubpageAProps {}

export const ServiceCookieSubpageA = (props: ServiceCookieSubpageAProps) => {
  return (
    <ServiceSubpage<ServiceSubpageContentType>>
      {(page) => (
        <>
          <div>{formatHtml(page.content?.body.content)}</div>
          <CookiebotDeclaration />
        </>
      )}
    </ServiceSubpage>
  );
};
