import React from 'react';

import { Button } from '../Button';
import { Col } from '../Col';
import { useExampleOffcanvas } from '../ExampleOffcanvas';
import { ExampleForm } from '../form/ExampleForm';
import { I18nExample } from '../i18n/I18nExample';
import { OffcanvasPlacement } from '../Offcanvas';
import { Row } from '../Row';
import { useModals } from '../useModals';
import { uiClassName } from '../util';
import { RecursiveOffcanvas } from './RecursiveOffcanvas';

export interface OffcanvassesProps {}

export const Offcanvasses = () => {
  const { showOffcanvas } = useModals();
  const [ExampleOffcanvas, showExampleOffcanvas] = useExampleOffcanvas({ stringToAlert: 'Hello World!' });

  const handleDynamicOffcanvasWithTextButtonClick = React.useCallback(() => {
    showOffcanvas({
      children: 'Lorem Ipsum',
      title: 'Dynamic offcanvas',
    });
  }, [showOffcanvas]);

  const handleDynamicOffcanvasWithComponentButtonClick = React.useCallback(() => {
    showOffcanvas({
      children: <I18nExample />,
      title: 'i18n example',
    });
  }, [showOffcanvas]);

  const handleDynamicOffcanvasWithFormComponentButtonClick = React.useCallback(() => {
    showOffcanvas({
      children: <ExampleForm />,
      title: 'Example form',
    });
  }, [showOffcanvas]);

  return (
    <>
      <h5>Different positions</h5>
      <Row>
        {(['left', 'right', 'top', 'bottom'] as Array<OffcanvasPlacement>).map((placement) => (
          <Col display="grid" key={placement}>
            <Button
              className={uiClassName({ bs5: 'mb-3' })}
              label={`Show ${placement} offcanvas`}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                showOffcanvas({
                  children:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                  placement: placement,
                  title: 'Offcanvas title',
                });
              }}
            />
          </Col>
        ))}
      </Row>
      <h5>Other options</h5>
      <Row>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label={'Show offcanvas with backdrop'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              showOffcanvas({
                backdrop: true,
                children:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                title: 'Offcanvas title',
              });
            }}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label={'Show offcanvas without backdrop'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              showOffcanvas({
                backdrop: false,
                children:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                title: 'Offcanvas title',
              });
            }}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label={'Show offcanvas enabling body scrolling'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              showOffcanvas({
                children:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                scroll: true,
                title: 'Offcanvas title',
              });
            }}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label={'Show offcanvas disabling body scrolling'}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              showOffcanvas({
                children:
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                scroll: false,
                title: 'Offcanvas title',
              });
            }}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Show simple text in Offcanvas"
            onClick={handleDynamicOffcanvasWithTextButtonClick}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Show a Component in Offcanvas"
            onClick={handleDynamicOffcanvasWithComponentButtonClick}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Show a Form in Offcanvas"
            onClick={handleDynamicOffcanvasWithFormComponentButtonClick}
          />
        </Col>
        <Col display="grid">
          <RecursiveOffcanvas className={uiClassName({ bs5: 'mb-3' })} />
        </Col>
        {ExampleOffcanvas}
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Show Offcanvas by useOffcanvas"
            onClick={showExampleOffcanvas}
          />
        </Col>
      </Row>
    </>
  );
};
