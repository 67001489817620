import { TextInput } from './fields/Input';
import { Checkbox, Radios } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';
import { InvoiceFieldsetProps } from './InvoiceFieldset';

export const InvoiceFieldsetA = ({ invoiceFieldsHelper }: InvoiceFieldsetProps) => (
  <>
    <FormGroup {...invoiceFieldsHelper.request.formGroup}>
      <Checkbox {...invoiceFieldsHelper.request.checkbox} />
    </FormGroup>

    {invoiceFieldsHelper.type && (
      <>
        <FormGroup {...invoiceFieldsHelper.type.formGroup}>
          <Radios {...invoiceFieldsHelper.type.radios} />
        </FormGroup>
      </>
    )}

    {invoiceFieldsHelper.data && (
      <div className="row">
        <div className="col-12 col-sm-6">
          {invoiceFieldsHelper.data.taxCode && (
            <FormGroup {...invoiceFieldsHelper.data.taxCode.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.taxCode.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.vatNumber && (
            <FormGroup {...invoiceFieldsHelper.data.vatNumber.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.vatNumber.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.company && (
            <FormGroup {...invoiceFieldsHelper.data.company.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.company.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.pec && (
            <FormGroup {...invoiceFieldsHelper.data.pec.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.pec.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.uniqueCode && (
            <FormGroup {...invoiceFieldsHelper.data.uniqueCode.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.uniqueCode.textInput} />
            </FormGroup>
          )}
        </div>
      </div>
    )}
  </>
);
