import _ from 'lodash';
import React from 'react';

export interface UseWindowScrollPositionResult {
  percentage: number;
  position: number;
}

export const useWindowScrollPosition = (throttle = 100): UseWindowScrollPositionResult => {
  const getWindowScrollPosition = (): number => window.scrollY;
  const getWindowScrollPercentage = (): number => (window.scrollY * 100) / window.outerHeight;

  const [position, setPosition] = React.useState<number>(getWindowScrollPosition());
  const [percentage, setPercentage] = React.useState<number>(getWindowScrollPercentage());

  const scrollListener = React.useCallback(() => {
    setPosition(getWindowScrollPosition());
    setPercentage(getWindowScrollPercentage());
  }, []);

  React.useEffect(() => {
    const throttledScrollListener = _.throttle(scrollListener, throttle);

    window.addEventListener('scroll', throttledScrollListener);

    return () => {
      window.removeEventListener('scroll', throttledScrollListener);
    };
  }, [scrollListener, throttle]);

  return { percentage, position };
};
