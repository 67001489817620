import { ProductApiDto } from '@b2x/storefront-api-js-client/src';
import { ProductPopulate } from '@b2x/storefront-api-js-client/src/products';
import React from 'react';

import { useProductsApi } from './api/useProductsApi';
import { appConfig } from './config';

export interface RelatedProductsProps {
  children(props: { fetching: boolean; relatedProducts?: Array<ProductApiDto> }): React.ReactNode;
  options?: { populate?: ProductPopulate };
  productId: string;
}

export const RelatedProducts = React.memo(
  ({ children, options, productId }: RelatedProductsProps) => {
    const [relatedProducts, setRelatedProducts] = React.useState<Array<ProductApiDto>>();
    const [fetching, setFetching] = React.useState<boolean>(false);

    const { getProduct } = useProductsApi();

    // const stableOptions = useStable(options);

    // const queryKey = React.useRef<Array<unknown>>();

    React.useEffect(() => {
      // const newQueryKey = ['useRelatedProducts', id];
      // if (queryKey.current && !_.isEqual(queryKey.current, newQueryKey)) {
      //   queryClient.cancelQueries({ queryKey: queryKey.current });
      // }
      // queryKey.current = newQueryKey;

      setFetching(true);
      getProduct(
        productId,
        // queryKey.current,
        {
          populate: {
            associatedProducts: options?.populate ?? appConfig.api?.productTilePopulate,
          },
        }
      )
        .then((response) => {
          setRelatedProducts(response.data.associatedProducts);
        })
        .finally(() => {
          setFetching(false);
        });
    }, [getProduct, productId, options?.populate]);

    return <>{children({ fetching, relatedProducts })}</>;
  },
  (prevProps, nextProps) => prevProps.productId === nextProps.productId
);
RelatedProducts.displayName = 'RelatedProducts';
