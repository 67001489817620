export interface OptionProps<T = unknown> {
  className?: string;
  data?: T extends undefined ? never : T;
  disabled?: boolean;
  label?: string;
  value: string;
}

export const Option = ({ className, disabled, label, value }: OptionProps): React.ReactElement => {
  return (
    <option className={className} disabled={disabled} value={value}>
      {label ?? value}
    </option>
  );
};
