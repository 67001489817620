import { EntertainmentBusiness } from './EntertainmentBusiness';
import { Organization } from './Organization';
import { Product } from './Product';
import { WebSite } from './WebSite';

export const structuredData = {
  EntertainmentBusiness,
  Organization,
  Product,
  WebSite,
};
