import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountTaxBenefitsSubpageA } from './accountSubpagesA/AccountTaxBenefitsSubpageA';

export interface AccountTaxBenefitsSubpageProps {}

export type AccountTaxBenefitsSubpageVariants = 'A';

const AccountTaxBenefitsSubpageController = (props: PropsWithCustomComponent<AccountTaxBenefitsSubpageProps>) => (
  <VariantsController<AccountTaxBenefitsSubpageProps, AccountTaxBenefitsSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountTaxBenefitsSubpageA },
      name: 'AccountTaxBenefitsSubpage',
    }}
  />
);
export { AccountTaxBenefitsSubpageController as AccountTaxBenefitsSubpage };
