import { CheckoutStepContentSection } from '../../contentTypes';
import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AdditionalCheckoutStepContentProps } from '../CheckoutPage';
import { CheckoutAddressStepA } from './checkoutStepsA/CheckoutAddressStepA';

export interface CheckoutAddressStepProps {
  additionalContent?: AdditionalCheckoutStepContentProps;
  content?: CheckoutStepContentSection;
  onEdit: boolean;
  type: 'shipping' | 'billing';
}

export type CheckoutAddressStepVariants = 'A';

const CheckoutAddressStepController = (props: PropsWithCustomComponent<CheckoutAddressStepProps>) => (
  <VariantsController<CheckoutAddressStepProps, CheckoutAddressStepVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CheckoutAddressStepA },
      name: 'CheckoutAddressStep',
    }}
  />
);
export { CheckoutAddressStepController as CheckoutAddressStep };
