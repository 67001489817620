import classnames from 'classnames';
import React from 'react';

import { appConfig } from './config';
import { Breakpoint } from './interfaces';
import { calculatePaddingTopForAspectRatioHack, renderUI, uiClassName } from './util';

export type AspectRatio = Partial<Record<Breakpoint, number>>;

export interface ImagePlaceholderProps {
  aspectRatio?: number | AspectRatio;
  backgroundColor?: string;
  backgroundVariant?: string;
  className?: string;
  label?: string;
  labelColor?: string;
  labelVariant?: string;
}

export const ImagePlaceholder = ({
  aspectRatio = 16 / 9,
  backgroundColor = appConfig.imagePlaceholder?.backgroundColor ?? '#ccc',
  backgroundVariant,
  className,
  label = 'Image Placeholder',
  labelColor = '#fff',
  labelVariant,
}: ImagePlaceholderProps): React.ReactElement => {
  const [aspectRatioFullObject, setAspectRatioFullObject] = React.useState<AspectRatio>();

  React.useEffect(() => {
    if (typeof aspectRatio === 'object') {
      let lastAspectRatio: number | undefined = undefined;
      const aspectRatioFullObjectTemp: AspectRatio = {};
      (['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as Array<Breakpoint>).forEach((breakpoint) => {
        if (aspectRatio[breakpoint] !== undefined) {
          aspectRatioFullObjectTemp[breakpoint] = aspectRatio[breakpoint];
          lastAspectRatio = aspectRatio[breakpoint];
        } else {
          aspectRatioFullObjectTemp[breakpoint] = lastAspectRatio;
        }
      });
      setAspectRatioFullObject(aspectRatioFullObjectTemp);
    }
  }, [aspectRatio]);

  return (
    <>
      {typeof aspectRatio === 'number' ? (
        <InternalImagePlaceholder
          aspectRatio={aspectRatio}
          backgroundColor={backgroundColor}
          backgroundVariant={backgroundVariant}
          className={className}
          label={label}
          labelColor={labelColor}
          labelVariant={labelVariant}
        />
      ) : (
        aspectRatioFullObject && (
          <>
            <InternalImagePlaceholder
              aspectRatio={aspectRatioFullObject.xs}
              backgroundColor={backgroundColor}
              backgroundVariant={backgroundVariant}
              className={uiClassName({ bs5: 'd-block d-sm-none' })}
              label={label}
              labelColor={labelColor}
              labelVariant={labelVariant}
            />
            <InternalImagePlaceholder
              aspectRatio={aspectRatioFullObject.sm}
              backgroundColor={backgroundColor}
              backgroundVariant={backgroundVariant}
              className={uiClassName({ bs5: 'd-none d-sm-block d-md-none' })}
              label={label}
              labelColor={labelColor}
              labelVariant={labelVariant}
            />
            <InternalImagePlaceholder
              aspectRatio={aspectRatioFullObject.md}
              backgroundColor={backgroundColor}
              backgroundVariant={backgroundVariant}
              className={uiClassName({ bs5: 'd-none d-md-block d-lg-none' })}
              label={label}
              labelColor={labelColor}
              labelVariant={labelVariant}
            />
            <InternalImagePlaceholder
              aspectRatio={aspectRatioFullObject.lg}
              backgroundColor={backgroundColor}
              backgroundVariant={backgroundVariant}
              className={uiClassName({ bs5: 'd-none d-lg-block d-xl-none' })}
              label={label}
              labelColor={labelColor}
              labelVariant={labelVariant}
            />
            <InternalImagePlaceholder
              aspectRatio={aspectRatioFullObject.xl}
              backgroundColor={backgroundColor}
              backgroundVariant={backgroundVariant}
              className={uiClassName({ bs5: 'd-none d-xl-block' })}
              label={label}
              labelColor={labelColor}
              labelVariant={labelVariant}
            />
          </>
        )
      )}
    </>
  );
};

interface InternalImagePlaceholderProps {
  aspectRatio?: number;
  backgroundColor?: string;
  backgroundVariant?: string;
  className?: string;
  label?: string;
  labelColor?: string;
  labelVariant?: string;
}

const InternalImagePlaceholder = ({
  aspectRatio,
  backgroundColor,
  backgroundVariant,
  className,
  label,
  labelColor,
  labelVariant,
}: InternalImagePlaceholderProps) =>
  renderUI({
    bs5: (
      <>
        {aspectRatio && (
          <div
            className={classnames(
              className,
              { [`bg-${backgroundVariant}`]: backgroundVariant },
              { [`text-${labelVariant}`]: labelVariant }
            )}
            style={{
              alignItems: 'center',
              backgroundColor: backgroundColor,
              color: labelColor,
              display: 'flex',
              justifyContent: 'center',
              paddingTop: calculatePaddingTopForAspectRatioHack(aspectRatio),
              position: 'relative',
            }}
          >
            {label && (
              <span
                className="d-flex justify-content-center align-items-center"
                style={{ bottom: 0, display: 'flex', left: 0, position: 'absolute', right: 0, top: 0 }}
              >
                {label}
              </span>
            )}
          </div>
        )}
      </>
    ),
  });
