import { b2x } from '@b2x/react/src';

import { ContactsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface ContactsBoxProps {
  showIcon: boolean;
}

export const ContactsBox = ({ showIcon }: ContactsBoxProps) => {
  const contactsContent = b2x.useContent<ContactsContentType>('CONTACTS_CONTENT');

  return (
    <>
      <div>
        {contactsContent?.body.contactsList &&
          contactsContent.body.contactsList.map((item) => {
            return (
              item.content && (
                <div key={item.contentSectionId}>
                  {showIcon && isIconName(item.icon) && (
                    <Icon className="pe-1" fontSize={20} name={item.icon as IconName} />
                  )}
                  <span className="small">{b2x.formatHtml(item.content)}</span>
                </div>
              )
            );
          })}
      </div>
    </>
  );
};
