import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { ButtonVariant } from '../Button';
import { Container } from '../Container';
import { ProductContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { IconName, IconSize } from '../Icon';
import { ImageContent } from '../ImageContent';
import { SliderProductPage } from '../slider/SliderProductPage';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct<ProductContentType>({ populate: b2x.appConfig.api?.productPopulate });

  const { isFooterCopyrightVisible, isTopBarRowRefVisible } = useAppContext();
  const [skusDivContainer, scrollToSkusDivContainer] = b2x.useScrollTo(-180);

  return (
    <Page className="ProductPage" noPaddingBottom noPaddingTop>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <>
              <Container>
                <b2x.AddToCartFormHelper product={product} scope="product">
                  {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                    <>
                      <div className="my-3">
                        <Breadcrumb />
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-6 col-xxl-5 mb-5 position-relative">
                          <SliderProductPage selectedProductVariant={selectedProductVariant} />
                        </div>
                        <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1">
                          <hgroup className="mb-3">
                            <h3 className="h2 text-uppercase fw-medium">
                              {b2x.formatHtml(selectedProductVariant.name)}
                            </h3>
                            <h6 className="small text-gray-300">
                              {t('product.code')}: {selectedProductVariant.code}
                            </h6>
                          </hgroup>
                          {selectedProductVariant.descriptionShort && (
                            <div className="mb-5">{b2x.formatHtml(selectedProductVariant.descriptionShort)}</div>
                          )}
                          <div className="product-price-container mb-4">
                            <div className="d-flex flex-wrap mb-2">
                              <b2x.PriceBlock
                                classNames={{
                                  crossedOutPrice: 'fs-3 lh-1',
                                  price: 'fs-3 lh-1',
                                  specialPrice: 'fs-3 lh-1',
                                }}
                                gap={3}
                                priceHelper={priceHelper}
                              />
                              {priceHelper.perUnit?.price && (
                                <div className="ms-lg-3 mt-2 mt-lg-0 col-12 col-md-auto text-gray-300 d-flex align-items-end">
                                  <small>
                                    {t('misc.pricePerUnit', {
                                      measurementUnit: priceHelper.perUnit.measurement,
                                      price: b2x.formatCurrency(priceHelper.perUnit.price),
                                    })}
                                  </small>
                                </div>
                              )}
                            </div>

                            {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
                              <b2x.BestPrice priceHelper={priceHelper} />
                            )}
                          </div>
                          {fieldsHelper.productVariants.formFields.length > 1 && (
                            <b2x.FormGroup
                              {...fieldsHelper.productVariants.formGroup}
                              className="productVariant bg-gray-100 pt-2 pt-sm-3 px-2 px-sm-3 d-inline-block"
                              label={undefined}
                            >
                              <div className="d-flex flex-wrap align-items-center lh-1" ref={skusDivContainer}>
                                <div className="col-12 col-sm-auto me-sm-3 pb-1 pb-sm-3">
                                  {t('form.productForm.size')}
                                </div>
                                <div className="d-flex flex-wrap lh-1">
                                  {fieldsHelper.productVariants.formFields.map((formField, index) => (
                                    <b2x.Radio
                                      key={formField.productVariant.id}
                                      {...formField.radio}
                                      className="m-0"
                                      inline
                                      labelClassName={classnames(
                                        {
                                          'me-2 me-sm-3': fieldsHelper.productVariants.formFields.length !== index + 1,
                                        },
                                        'mb-2 mb-sm-3 fw-bold shadow-sm px-2 py-1'
                                      )}
                                    >
                                      {formField.productVariant.skus?.map(
                                        (sku, indexSku) => indexSku === 0 && sku.name
                                      )}
                                    </b2x.Radio>
                                  ))}
                                </div>
                              </div>
                            </b2x.FormGroup>
                          )}
                          {/* {fieldsHelper.skuAttributes.map(({ attribute, formGroup, radios }, index) => (
                      <div key={attribute.title}>
                        <SkuAttributeFormGroup
                          attribute={attribute}
                          formGroup={formGroup}
                          index={index}
                          radios={radios}
                        />
                      </div>
                    ))} */}
                          <b2x.Row className="mb-3">
                            <b2x.Col className="col-4 col-md-3">
                              <b2x.FormGroup {...fieldsHelper.quantity.formGroup} label={undefined}>
                                <div>{fieldsHelper.quantity.formGroup.label}</div>
                                <b2x.Select {...fieldsHelper.quantity.select} />
                              </b2x.FormGroup>
                            </b2x.Col>
                            <b2x.Col className="col-12 col-md-9">
                              <div className="FormGroup mb-3">
                                <div className="d-none d-md-block">&nbsp;</div>
                                <div className="d-grid">
                                  {/* <b2x.Button {...fieldsHelper.buttons.submit} className="text-uppercase" /> */}
                                  <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                    fieldsHelper={fieldsHelper}
                                    selectedSku={selectedSku}
                                  />
                                </div>
                              </div>
                            </b2x.Col>
                          </b2x.Row>
                          <Accordion attributeGroups={product.attributeGroups} attributes={product.attributes} />
                        </div>
                      </div>
                      <b2x.Portal>
                        <b2x.ProductStickyFooter
                          fieldsHelper={fieldsHelper}
                          formik={formik}
                          isVisible={!isTopBarRowRefVisible && !isFooterCopyrightVisible}
                          priceHelper={priceHelper}
                          product={product}
                          scrollToElement={scrollToSkusDivContainer}
                          selectedProductImage={selectedProductVariant.image}
                          selectedSku={selectedSku}
                        />
                      </b2x.Portal>
                    </>
                  )}
                </b2x.AddToCartFormHelper>
              </Container>
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts &&
                    relatedProducts.length > 0 && (
                      <div className="bg-secondary py-5">
                        <b2x.Listing name="Product page - Potrebbe interessarti" products={relatedProducts}>
                          <SliderProducts products={relatedProducts} title={t('misc.relatedProducts')} />
                        </b2x.Listing>
                      </div>
                    )
                  )
                }
              </b2x.RelatedProducts>
              {product.content?.body.imageContentBoxSection && (
                <div className="pb-5">
                  <ImageContent {...product.content.body.imageContentBoxSection} />
                </div>
              )}
            </>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface AccordionProps {
  attributeGroups?: Array<b2x.GroupAttributeApiDto>;
  attributes: Array<b2x.AttributeApiDto> | undefined;
}

const Accordion = ({ attributeGroups, attributes }: AccordionProps) => {
  return (
    <>
      {(attributes || attributeGroups) && (
        <b2x.Accordion
          className="border-bottom"
          id="product-details"
          itemBodyClassName="px-0 pb-4 pt-2"
          itemButtonClassName="ps-0 fw-bold"
          itemClassName="border-start-0 border-end-0 border-bottom-0 border-top"
        >
          {attributes?.map(
            (attribute, index) =>
              attribute.typeCode &&
              attribute.value !== '' && (
                <b2x.AccordionItem id={`accordion-item-${index}`} key={attribute.typeCode} title={`${attribute.title}`}>
                  {b2x.formatHtml(attribute.value)}
                </b2x.AccordionItem>
              )
          )}
          {attributeGroups?.map(
            (attributeGroup) =>
              attributeGroup.code === 'SchedaNutrizionale' && (
                <b2x.AccordionItem
                  id={`accordion-item-${attributeGroup.code}`}
                  key={attributeGroup.code}
                  title={`${attributeGroup.name}`}
                >
                  <div className="table-responsive">
                    <b2x.Table className="table-hover m-0">
                      <b2x.TableBody>
                        {attributeGroup.attributes?.map((attribute) => (
                          <tr key={attribute.typeCode}>
                            <th>{attribute.title}</th>
                            <td>{attribute.value}</td>
                          </tr>
                        ))}
                      </b2x.TableBody>
                    </b2x.Table>
                  </div>
                </b2x.AccordionItem>
              )
          )}
        </b2x.Accordion>
      )}
    </>
  );
};

// interface SkuAttributeFormGroupProps {
//   attribute: {
//     title: string;
//     value?: string;
//   };
//   formGroup: b2x.FormGroupProps;
//   index: number;
//   radios: Array<{ radio: b2x.RadioProps; skus: Array<b2x.SkuApiDto> }>;
// }

// const SkuAttributeFormGroup = ({ attribute, formGroup, index, radios }: SkuAttributeFormGroupProps) => {
//   return (
//     <b2x.FormGroup
//       {...formGroup}
//       className="border-top pt-2 mb-2 pt-md-3 mb-md-3 position-relative size-select-container"
//       label={undefined}
//       noMarginBottom
//     >
//       <div className="form-label small mb-3">
//         <span className="fw-normal">{attribute.title}:</span>&nbsp;
//         {attribute.value ?? t('product.selectSize')}
//       </div>
//       <div className="d-flex">
//         {radios.map(({ radio }) => (
//           <b2x.Radio key={radio.id} {...radio} inline />
//         ))}
//       </div>
//     </b2x.FormGroup>
//   );
// };
