import React from 'react';

interface FormGroupContextInterface {
  id?: string;
  required: boolean;
  withLabel: boolean;
}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [FormGroupContextProvider, useFormGroupContext] =
  createContext<FormGroupContextInterface>('FormGroupContext');

interface UseFormGroupContextInitializerProps {
  id?: string;
  required: boolean;
  withLabel: boolean;
}

const useFormGroupContextInitializer = ({ id, required, withLabel }: UseFormGroupContextInitializerProps) => {
  const formGroupContext: FormGroupContextInterface = {
    id,
    required,
    withLabel,
  };

  return {
    FormGroupContextProvider,
    formGroupContext,
  };
};

export interface FormGroupContextProps extends UseFormGroupContextInitializerProps {
  children: React.ReactNode;
}

export const FormGroupContext = ({ children, ...otherProps }: FormGroupContextProps) => {
  const formGroupContextInitializer = useFormGroupContextInitializer(otherProps);
  return (
    <formGroupContextInitializer.FormGroupContextProvider value={formGroupContextInitializer.formGroupContext}>
      {children}
    </formGroupContextInitializer.FormGroupContextProvider>
  );
};
