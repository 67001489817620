import React from 'react';

import { appConfig } from './config';

export interface UseGooglePlacesAutocompleteOptions extends google.maps.places.AutocompleteOptions {
  onPlaceChanged?(placeResult: google.maps.places.PlaceResult): void;
}

export type UseGooglePlacesAutocompleteResult = readonly [
  React.RefObject<HTMLInputElement>,
  google.maps.places.Autocomplete | undefined
];

export interface GooglePlacesAutocompleteInstance {}

export const useGooglePlacesAutocomplete = ({
  onPlaceChanged,
  ...autocompleteOptions
}: UseGooglePlacesAutocompleteOptions): UseGooglePlacesAutocompleteResult => {
  const ref = React.useRef<HTMLInputElement>(null);

  const initialized = React.useRef<boolean>(false);

  const libraries = React.useRef<{
    places: google.maps.PlacesLibrary;
  }>();

  const autocomplete = React.useRef<google.maps.places.Autocomplete>();

  const [ready, setReady] = React.useState<boolean>(false);

  const loadLibraries = React.useCallback(async () => {
    log('loadLibraries');
    if (appConfig.googleMaps?.apiKey) {
      const [places] = await Promise.all([google.maps.importLibrary('places')]);
      libraries.current = {
        places: places as google.maps.PlacesLibrary,
      };
    }
  }, []);

  const init = React.useCallback(() => {
    log('init');

    if (ref.current) {
      if (appConfig.googleMaps?.apiKey) {
        if (libraries.current === undefined) {
          throw new Error('libraries not loaded yet');
        }

        autocomplete.current = new libraries.current.places.Autocomplete(ref.current, autocompleteOptions);

        if (onPlaceChanged) {
          autocomplete.current.addListener('place_changed', () => {
            if (autocomplete.current) {
              onPlaceChanged(autocomplete.current.getPlace());
            }
          });
        }
      } else {
        ref.current.placeholder = 'Missing GoogleMaps ApiKey';
        ref.current.disabled = true;
      }
    }

    // if (appConfig.googleMaps?.apiKey) {
    //   if (libraries.current === undefined) {
    //     throw new Error('libraries not loaded yet');
    //   }

    //   if (ref.current) {
    //     autocomplete.current = new libraries.current.places.Autocomplete(ref.current, autocompleteOptions);

    //     if (onPlaceChanged) {
    //       autocomplete.current.addListener('place_changed', () => {
    //         if (autocomplete.current) {
    //           onPlaceChanged(autocomplete.current.getPlace());
    //         }
    //       });
    //     }
    //   }
    // } else {
    //   if (ref.current) {
    //     ref.current.placeholder = 'Missing GoogleMaps ApiKey';
    //     ref.current.disabled = true;
    //   }
    // }
  }, [autocompleteOptions, onPlaceChanged]);

  React.useEffect(() => {
    if (!initialized.current) {
      loadLibraries().then(() => {
        init();
        setReady(true);
      });
      initialized.current = true;
    }
  }, [init, loadLibraries]);

  return [ref, ready ? autocomplete.current : undefined] as const;
};

const log = (...message: Array<unknown>) => {
  // console.log('Google Places Autocomplete', message);
};
