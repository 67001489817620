import 'photoswipe/style.css';

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import React from 'react';

import { Div } from './HTMLElement';
import { Icon, IconProps } from './Icon';
import { LightboxContext, LightboxItemsDimensions } from './LightboxContext';
import { createUUID } from './util';

export interface LightboxProps<IconName> {
  arrowKeys?: boolean;
  arrowNextIcon?: IconProps<IconName, number>;
  arrowPrevIcon?: IconProps<IconName, number>;
  backgroundClickAction?: ClickAction;
  backgroundOpacity?: number;
  children?: React.ReactNode;
  closeIcon?: IconProps<IconName, number>;
  closeOnVerticalDrag?: boolean;
  cursor?: React.CSSProperties['cursor'];
  doubleTapAction?: ClickAction;
  escKey?: boolean;
  imageClickAction?: ClickAction;
  initialZoomLevel?: 1 | 'fill' | 'fit';
  loop?: boolean;
  padding?: number;
  pinchToClose?: boolean;
  showHideAnimationType?: 'zoom' | 'fade' | 'none';
  tapAction?: ClickAction;
  wheelToZoom?: boolean;
  zoomIcon?: IconProps<IconName, number>;
}

type ClickAction = 'zoom' | 'zoom-or-close' | 'toggle-controls' | 'next' | 'close';

export const Lightbox = <IconName extends string>({
  arrowKeys = true,
  arrowNextIcon,
  arrowPrevIcon,
  backgroundClickAction = 'close',
  backgroundOpacity = 0.8,
  children,
  closeIcon,
  closeOnVerticalDrag = true,
  cursor = 'zoom-in',
  doubleTapAction = 'zoom',
  escKey = true,
  imageClickAction = 'zoom-or-close',
  initialZoomLevel = 'fit',
  loop = true,
  padding = 0,
  pinchToClose = true,
  showHideAnimationType = 'zoom',
  tapAction = 'toggle-controls',
  wheelToZoom,
  zoomIcon,
}: LightboxProps<IconName>) => {
  const id = React.useMemo(() => `lightbox-${createUUID()}`, []);

  const arrowNextIconRef = React.useRef<HTMLDivElement>(null);
  const arrowPrevIconRef = React.useRef<HTMLDivElement>(null);
  const zoomIconRef = React.useRef<HTMLDivElement>(null);
  const closeIconRef = React.useRef<HTMLDivElement>(null);

  const [allDimensions, setAllDimensions] = React.useState<LightboxItemsDimensions>({});

  React.useEffect(() => {
    let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
      arrowKeys: arrowKeys,
      arrowNextSVG: arrowNextIconRef.current?.innerHTML,
      arrowPrevSVG: arrowPrevIconRef.current?.innerHTML,
      bgClickAction: backgroundClickAction,
      bgOpacity: backgroundOpacity,
      children: 'a',
      closeOnVerticalDrag: closeOnVerticalDrag,
      closeSVG: closeIconRef.current?.innerHTML,
      doubleTapAction: doubleTapAction,
      escKey: escKey,
      gallery: `#${id}`,
      imageClickAction: imageClickAction,
      initialZoomLevel: initialZoomLevel,
      loop: loop,
      padding: padding ? { bottom: padding, left: padding, right: padding, top: padding } : undefined,
      pinchToClose: pinchToClose,
      pswpModule: () => import('photoswipe'),
      showHideAnimationType: showHideAnimationType,
      tapAction: tapAction,
      wheelToZoom: wheelToZoom,
      zoomSVG: zoomIconRef.current?.innerHTML,
    });

    lightbox.on('loadComplete', (event) => {
      const src = event.content.data.src;
      const element = event.content.element as HTMLImageElement;
      if (src !== undefined) {
        setAllDimensions((prevState) => ({
          ...prevState,
          [src]: { height: element.naturalHeight, width: element.naturalWidth },
        }));
      }
    });

    lightbox.init();

    return () => {
      lightbox !== null && lightbox.destroy();
      lightbox = null;
    };
  }, [
    arrowKeys,
    backgroundClickAction,
    backgroundOpacity,
    closeOnVerticalDrag,
    doubleTapAction,
    escKey,
    id,
    imageClickAction,
    initialZoomLevel,
    loop,
    padding,
    pinchToClose,
    showHideAnimationType,
    tapAction,
    wheelToZoom,
  ]);

  return (
    <>
      <div className="pswp-gallery" id={id}>
        <LightboxContext allDimensions={allDimensions} cursor={cursor} id={id} setAllDimensions={setAllDimensions}>
          {children}
        </LightboxContext>
      </div>
      {arrowNextIcon && (
        <Div display="none">
          <div ref={arrowNextIconRef}>
            <Icon {...arrowNextIcon} />
          </div>
        </Div>
      )}
      {arrowPrevIcon && (
        <Div display="none">
          <div ref={arrowPrevIconRef}>
            <Icon {...arrowPrevIcon} />
          </div>
        </Div>
      )}
      {closeIcon && (
        <Div display="none">
          <div ref={closeIconRef}>
            <Icon {...closeIcon} />
          </div>
        </Div>
      )}
      {zoomIcon && (
        <Div display="none">
          <div ref={zoomIconRef}>
            <Icon {...zoomIcon} />
          </div>
        </Div>
      )}
    </>
  );
};
