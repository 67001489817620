import classnames from 'classnames';
import React from 'react';

import { b2x } from '.';
import { useSearchParams } from './router/router';
import { storage } from './storage';
import { renderUI } from './util';

export interface PaginationProps {
  alignment?: 'left' | 'center' | 'right';
  currentPage: number;
  fullStepNavigation?: boolean;
  pageOffset?: 1 | 2 | 3 | 4 | 5;
  showDisabledArrows?: boolean;
  showDots?: boolean;
  showSummary?: boolean;
  singleStepNavigation?: boolean;
  totalPages: number;
}

export const Pagination = ({
  alignment = 'center',
  currentPage,
  fullStepNavigation,
  pageOffset = 2,
  showDisabledArrows = false,
  showDots,
  showSummary,
  singleStepNavigation,
  totalPages,
}: PaginationProps) => {
  const handleClickPagination = React.useCallback(() => {
    alert('navigation click');
  }, []);

  return renderUI({
    bs5: (
      <nav aria-label="Page navigation" className="Pagination">
        <ul
          className={classnames(
            'pagination mb-0',
            { 'justify-content-start': alignment === 'left' },
            { 'justify-content-center': alignment === 'center' },
            { 'justify-content-end': alignment === 'right' }
          )}
        >
          {totalPages > pageOffset + 1 && (
            <>
              {fullStepNavigation && (showDisabledArrows || currentPage > 1) && (
                <PaginationItem
                  className="arrow-to-first"
                  disabled={currentPage === 1}
                  onClick={handleClickPagination}
                  page={1}
                  to="#"
                >
                  &lt;&lt;
                </PaginationItem>
              )}
              {singleStepNavigation && (showDisabledArrows || currentPage > 1) && (
                <PaginationItem
                  className="arrow-prev"
                  disabled={currentPage === 1}
                  onClick={handleClickPagination}
                  page={currentPage - 1}
                  to="#"
                >
                  &lt;
                </PaginationItem>
              )}
              {!showSummary && currentPage - pageOffset > 1 && (
                <PaginationItem onClick={handleClickPagination} page={1} to="#">
                  1
                </PaginationItem>
              )}
              {showDots && currentPage - pageOffset > 1 + (showSummary ? 0 : 1) && (
                <PaginationItem disabled to="#">
                  ...
                </PaginationItem>
              )}
            </>
          )}

          {[...Array(totalPages)].map((page, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {/* Condizione in cui l'elemento iterato è inferiore alla pagina corrente */}
              {index + 1 < currentPage && (
                <>
                  {index + 1 >= currentPage - pageOffset && (
                    <PaginationItem onClick={handleClickPagination} page={index + 1} to="#">
                      {index + 1}
                    </PaginationItem>
                  )}
                </>
              )}

              {/* Condizione in cui l'elemento iterato è uguale alla pagina corrente */}
              {index + 1 === currentPage && (
                <PaginationItem active to="#">
                  {index + 1}
                </PaginationItem>
              )}

              {/* Condizione in cui l'elemento iterato è maggiore della pagina corrente */}
              {index + 1 > currentPage && (
                <>
                  {index + 1 <= currentPage + pageOffset && (
                    <PaginationItem onClick={handleClickPagination} page={index + 1} to="#">
                      {index + 1}
                    </PaginationItem>
                  )}
                </>
              )}
            </React.Fragment>
          ))}

          {totalPages > pageOffset + 1 && (
            <>
              {showDots && currentPage + pageOffset < totalPages - (showSummary ? 0 : 1) && (
                <PaginationItem disabled to="#">
                  ...
                </PaginationItem>
              )}
              {!showSummary && currentPage + pageOffset < totalPages && (
                <PaginationItem onClick={handleClickPagination} page={totalPages} to="#">
                  {totalPages}
                </PaginationItem>
              )}
              {singleStepNavigation && (showDisabledArrows || currentPage < totalPages) && (
                <PaginationItem
                  className="arrow-next"
                  disabled={currentPage === totalPages}
                  onClick={handleClickPagination}
                  page={currentPage + 1}
                  to="#"
                >
                  &gt;
                </PaginationItem>
              )}
              {fullStepNavigation && (showDisabledArrows || currentPage < totalPages) && (
                <PaginationItem
                  className="arrow-to-last"
                  disabled={currentPage === totalPages}
                  onClick={handleClickPagination}
                  page={totalPages}
                  to="#"
                >
                  &gt;&gt;
                </PaginationItem>
              )}
            </>
          )}
        </ul>
        {showSummary && (
          <div
            className={classnames(
              'mt-3',
              { 'text-start': alignment === 'left' },
              { 'text-center': alignment === 'center' },
              { 'text-end': alignment === 'right' }
            )}
          >
            {currentPage} of {totalPages}
          </div>
        )}
      </nav>
    ),
  });
};

interface PaginationItemProps {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?(): void;
  page?: number;
  to?: string;
}

const PaginationItem = ({ active, children, className, disabled, page, to }: PaginationItemProps) => {
  const [searchParams] = useSearchParams();
  if (page) {
    searchParams.set('pageNum', page.toString());
  }
  const storedPageSize = storage.getNumber('searchPageSize');
  if (storedPageSize) {
    searchParams.set('pageSize', storedPageSize.toString());
  }
  // searchParams.sort();
  return (
    <li className={classnames('page-item', { disabled: disabled }, { active: active }, className)}>
      {page ? (
        <b2x.router.Link className={classnames('page-link')} to={{ search: searchParams.toString() }}>
          {children}
        </b2x.router.Link>
      ) : (
        <span className="page-link">{children}</span>
      )}
    </li>
  );
};
