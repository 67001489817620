import { CartApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classNames from 'classnames';
import React from 'react';

import { appConfig } from './config';
import { FreeShippingThresholdContentType } from './contentTypes';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { ResponsivePaddingSize } from './interfaces';
import { ProgressBar } from './ProgressBar';
import { Link } from './router/Link';
import { formatCurrency, formatHtml, formatWeight } from './util';

export interface FreeShippingThresholdProps extends FreeShippingThresholdContentType {
  cart: CartApiDto;
  padding?: ResponsivePaddingSize;
}

export const FreeShippingThreshold = ({
  cart,
  enableContinueShopping = false,
  freeShippingThresholdCriterion = 'currency',
  padding,
}: FreeShippingThresholdProps) => {
  const containerPadding = padding ?? 3;

  const remainingAmountToFreeShipping = React.useMemo(() => {
    switch (freeShippingThresholdCriterion) {
      case 'currency':
        return cart.remainingAmountToFreeShipping;
      case 'weight':
        return cart.remainingAmountToFreeShippingWeight;
      default:
        return undefined;
    }
  }, [cart.remainingAmountToFreeShipping, cart.remainingAmountToFreeShippingWeight, freeShippingThresholdCriterion]);

  const toFreeShippingAmount = React.useMemo(() => {
    if (remainingAmountToFreeShipping) {
      switch (freeShippingThresholdCriterion) {
        case 'currency':
          return formatCurrency(remainingAmountToFreeShipping);
        case 'weight':
          return formatWeight(remainingAmountToFreeShipping);
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }, [freeShippingThresholdCriterion, remainingAmountToFreeShipping]);

  // const freeThreshold = React.useMemo(() => {
  //   switch (freeShippingThresholdCriterion) {
  //     case 'currency':
  //       return cart.shippingProfile?.freeThreshold;
  //     case 'weight':
  //       return cart.shippingProfile?.freeThresholdWeight;
  //     default:
  //       return undefined;
  //   }
  // }, [cart.shippingProfile?.freeThreshold, cart.shippingProfile?.freeThresholdWeight, freeShippingThresholdCriterion]);

  const current = React.useMemo(() => {
    switch (freeShippingThresholdCriterion) {
      case 'currency':
        return cart.currentPercentToFreeShipping;
      case 'weight':
        return cart.currentPercentToFreeShippingWeight;
      default:
        return undefined;
    }
  }, [cart.currentPercentToFreeShipping, cart.currentPercentToFreeShippingWeight, freeShippingThresholdCriterion]);

  /**
   * Considero raggiunto l'obiettivo sia se il remainingAmountToFreeShipping è 0 sia se in generale ho shippingCost a 0,
   * magari per altri motivi, come uno sconto totale sulla spedizione.
   */
  const complete = React.useMemo<boolean>(() => {
    return remainingAmountToFreeShipping === 0 || cart.shippingCost === 0;
  }, [cart.shippingCost, remainingAmountToFreeShipping]);

  return (
    <>
      {remainingAmountToFreeShipping !== undefined && (
        <Div className="cart-to-free-shipping" marginBottom={3} padding={containerPadding} textAlign="center">
          <Div className="copy-container" dynamicMargin={{ bottom: 2 }}>
            <>
              <Div>
                {appConfig.icons?.shipping && <Icon name={appConfig.icons.shipping} />}
                {complete
                  ? formatHtml(t('cart.summary.toFreeShippingComplete'))
                  : formatHtml(
                      t('cart.summary.toFreeShipping', {
                        toFreeShippingAmount: toFreeShippingAmount,
                      })
                    )}
              </Div>
              {enableContinueShopping && (
                <Div margin={2} textAlign="center">
                  <Link className={classNames('my-2 text-decoration-underline text-reset')} to="/search">
                    {t('cart.summary.continueShopping')}
                  </Link>
                </Div>
              )}
            </>
          </Div>
          {current && <ProgressBar current={complete ? 100 : current} hiddenCount max={100} min={0} />}
        </Div>
      )}
    </>
  );
};
