import { Button } from '../Button';
import {
  CartAddressFromAddressBookFormHelper,
  CartAddressFromAddressBookFormProps,
} from './CartAddressFromAddressBookForm';
import { Radio } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';
import { InvoiceFieldset } from './InvoiceFieldset';

export const CartAddressFromAddressBookFormA = (props: CartAddressFromAddressBookFormProps) => (
  <CartAddressFromAddressBookFormHelper {...props}>
    {({ fieldsHelper }) => (
      <>
        <FormGroup {...fieldsHelper.addresses.formGroup} label={undefined}>
          {fieldsHelper.addresses.formFields.map(({ address, editAddressButton, radio }) => (
            <div className="mb-2" key={address.id}>
              <Radio {...radio} />
              <div className="ps-4 mt-n2">
                {
                  <Button
                    {...editAddressButton}
                    className="btn-sm fw-light text-decoration-underline text-dark"
                    variant="blank"
                  />
                }
              </div>
            </div>
          ))}
        </FormGroup>
        <div className="mb-3">
          <Button
            {...fieldsHelper.buttons.newAddress}
            alignWithFlex
            className="text-decoration-none px-0"
            iconStart={{ name: 'plus', size: 16 }}
            variant="link"
          />
        </div>

        {fieldsHelper.invoice && <InvoiceFieldset invoiceFieldsHelper={fieldsHelper.invoice} />}

        {fieldsHelper.buttons.submit && (
          <Button {...fieldsHelper.buttons.submit} iconEnd={undefined} iconStart={undefined} variant={'primary'} />
        )}
      </>
    )}
  </CartAddressFromAddressBookFormHelper>
);
