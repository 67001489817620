import { Button } from '../Button';
import { AddressFieldset } from './AddressFieldset';
import { addressValidationSchemaSelector } from './AddressFieldsetA'; //FIXME
import { CartAddressesFormHelper, CartAddressesFormProps } from './CartAddressesForm';
import { Checkbox } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';
import { InvoiceFieldset } from './InvoiceFieldset';

export const CartAddressesFormA = (props: CartAddressesFormProps) => (
  <CartAddressesFormHelper validationSchemaSelector={{ address: addressValidationSchemaSelector }} {...props}>
    {({ fieldsHelper }) => (
      <>
        {fieldsHelper.copyFrom && (
          <FormGroup {...fieldsHelper.copyFrom.formGroup}>
            <Checkbox {...fieldsHelper.copyFrom.checkbox} />
          </FormGroup>
        )}
        {fieldsHelper.address && (
          <>
            <AddressFieldset addressFieldsHelper={fieldsHelper.address} />
            {fieldsHelper.copyTo && (
              <FormGroup {...fieldsHelper.copyTo.formGroup}>
                <Checkbox {...fieldsHelper.copyTo.checkbox} />
              </FormGroup>
            )}
          </>
        )}
        {fieldsHelper.invoice && <InvoiceFieldset invoiceFieldsHelper={fieldsHelper.invoice} />}
        <Button {...fieldsHelper.buttons.submit} />
      </>
    )}
  </CartAddressesFormHelper>
);
