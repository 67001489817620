import { useAppContext } from './AppContext';

export const ModalsAndOffcanvasses = () => {
  const { AccountOffcanvas, CartOffcanvas } = useAppContext();

  return (
    <>
      {AccountOffcanvas}
      {CartOffcanvas}
    </>
  );
};
