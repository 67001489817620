import { FormikValues } from 'formik';
import React from 'react';
import * as yup from 'yup';

export interface FormContextInterface {
  id: string;
  showValidFeedback?: boolean;
  validationSchema?: yup.AnyObjectSchema;
  values: FormikValues;
}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [FormContextProvider, useFormContext, useFormContextStrict] =
  createContext<FormContextInterface>('FormContext');

interface UseFormContextInitializerProps {
  id: string;
  showValidFeedback?: boolean;
  validationSchema?: yup.AnyObjectSchema;
  values: FormikValues;
}

const useFormContextInitializer = ({
  id,
  showValidFeedback,
  validationSchema,
  values,
}: UseFormContextInitializerProps) => {
  const formContext: FormContextInterface = React.useMemo(
    () => ({
      id,
      showValidFeedback,
      validationSchema,
      values,
    }),
    [id, showValidFeedback, validationSchema, values]
  );

  return {
    FormContextProvider,
    formContext,
  };
};

export interface FormContextProps extends UseFormContextInitializerProps {
  children: React.ReactNode | ((accordionContext: FormContextInterface) => React.ReactNode);
}

export const FormContext = ({ children, ...otherProps }: FormContextProps) => {
  const formContextInitializer = useFormContextInitializer(otherProps);
  return (
    <formContextInitializer.FormContextProvider value={formContextInitializer.formContext}>
      {typeof children === 'function' ? children(formContextInitializer.formContext) : children}
    </formContextInitializer.FormContextProvider>
  );
};
