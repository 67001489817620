// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { GetGiftCardOptions } from '@b2x/storefront-api-js-client/src/giftCards';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useGiftCardsApi = () => {
  const { apiRequest } = useApiRequest();

  const getGiftCardByCode = React.useCallback(
    (code: string, options?: GetGiftCardOptions, config?: ApiRequestConfig) =>
      apiRequest(api.giftCards.getByCode(code, options), { ...config }),
    [apiRequest]
  );

  return { getGiftCardByCode };
};
