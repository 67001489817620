import {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
} from 'yup/lib/locale';

const mixed: MixedLocale = {
  default: (params) => ({ key: 'mixed.default', params }),
  defined: (params) => ({ key: 'mixed.defined', params }),
  notOneOf: (params) => ({ key: 'mixed.notOneOf', params }),
  notType: (params) => ({ key: 'mixed.notType', params }),
  oneOf: (params) => ({ key: 'mixed.oneOf', params }),
  required: (params) => ({ key: 'mixed.required', params }),
};

const string: StringLocale = {
  email: (params) => ({ key: 'string.email', params }),
  length: (params) => ({ key: 'string.length', params }),
  lowercase: (params) => ({ key: 'string.lowercase', params }),
  matches: (params) => ({ key: 'string.matches', params }),
  max: (params) => ({ key: 'string.max', params }),
  min: (params) => ({ key: 'string.min', params }),
  trim: (params) => ({ key: 'string.trim', params }),
  uppercase: (params) => ({ key: 'string.uppercase', params }),
  url: (params) => ({ key: 'string.url', params }),
  uuid: (params) => ({ key: 'string.uuid', params }),
};

const number: NumberLocale = {
  integer: (params) => ({ key: 'number.integer', params }),
  lessThan: (params) => ({ key: 'number.lessThan', params }),
  max: (params) => ({ key: 'number.max', params }),
  min: (params) => ({ key: 'number.min', params }),
  moreThan: (params) => ({ key: 'number.moreThan', params }),
  negative: (params) => ({ key: 'number.negative', params }),
  positive: (params) => ({ key: 'number.positive', params }),
};

const date: DateLocale = {
  max: (params) => ({ key: 'date.max', params }),
  min: (params) => ({ key: 'date.min', params }),
};

const boolean: BooleanLocale = {
  isValue: (params) => ({ key: 'boolean.isValue', params }),
};

const object: ObjectLocale = {
  noUnknown: (params) => ({ key: 'object.noUnknown', params }),
};

const array: ArrayLocale = {
  length: (params) => ({ key: 'array.length', params }),
  max: (params) => ({ key: 'array.max', params }),
  min: (params) => ({ key: 'array.min', params }),
};

// eslint-disable-next-line no-restricted-syntax
export default Object.assign(Object.create(null), {
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  string,
});
