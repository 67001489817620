import classnames from 'classnames';

import { Div } from './HTMLElement';
import { ResponsiveAlignContent, ResponsiveAlignItems, ResponsiveJustifyContent } from './interfaces';
import { buildAlignContentClassNames, buildAlignItemsClassNames, buildJustifyContentClassNames } from './util';

export interface FixedAspectRatioBoxProps {
  alignContent?: ResponsiveAlignContent;
  alignItems?: ResponsiveAlignItems;
  aspectRatio?: number;
  backgroundColor?: string;
  children?: React.ReactNode;
  className?: string;
  justifyContent?: ResponsiveJustifyContent;
}

export const FixedAspectRatioBox = ({
  alignContent,
  alignItems,
  aspectRatio = 1,
  backgroundColor,
  children,
  className,
  justifyContent,
}: FixedAspectRatioBoxProps) => {
  return (
    <Div
      className="fixed-aspect-ratio-box"
      style={{
        backgroundColor: backgroundColor,
        height: 0,
        paddingBottom: `${100 / aspectRatio}%`,
        position: 'relative',
        width: '100%',
      }}
    >
      <Div
        className={classnames(
          className,
          alignItems && buildAlignItemsClassNames(alignItems),
          alignContent && buildAlignContentClassNames(alignContent),
          justifyContent && buildJustifyContentClassNames(justifyContent)
        )}
        display="flex"
        style={{ bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 }}
      >
        {children}
      </Div>
    </Div>
  );
};
