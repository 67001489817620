import React from 'react';

import { Alert } from '../../../Alert';
import { useCartApi } from '../../../api/useCartApi';
import { Button } from '../../../Button';
import { useCheckoutContext } from '../../../CheckoutContext';
import { DevAlert } from '../../../DevAlert';
import { CartEmailFormHelper } from '../../../form/CartEmailForm';
import { TextInput } from '../../../form/fields/Input';
import { Checkbox } from '../../../form/fields/RadioCheck';
import { FormGroup } from '../../../form/FormGroup';
import { LoginForm } from '../../../form/LoginForm';
import { t } from '../../../i18n/i18n';
import { formatHtml, renderUI } from '../../../util';
import { CheckoutEmailStepProps } from '../CheckoutEmailStep';

export const CheckoutEmailStepA = ({ additionalContent, content, onEdit }: CheckoutEmailStepProps) => {
  const { setEmail } = useCartApi();

  const { cart } = useCheckoutContext();

  const { updateSessionCart } = useCartApi();

  const handleMockEmailButtonClick = React.useCallback(() => {
    setEmail({ email: 'fixme@it', newsletterConsent: false }).then(() => {
      updateSessionCart({ silent: false });
    });
  }, [setEmail, updateSessionCart]);

  const [loginFormActive, setLoginFormActive] = React.useState<boolean>(false);

  const handleSwitchFormButtonClick = React.useCallback(() => {
    setLoginFormActive((prevState) => !prevState);
  }, []);

  return renderUI({
    bs5: (
      <>
        {cart && (
          <>
            {onEdit ? (
              <>
                {content?.onEdit?.alerts?.map((alert) => (
                  <Alert
                    className="extra-small py-2"
                    key={alert.contentSectionId}
                    {...alert}
                    variant={
                      alert.type === 'info'
                        ? 'info'
                        : alert.type === 'success'
                        ? 'success'
                        : alert.type === 'warning'
                        ? 'warning'
                        : alert.type === 'error'
                        ? 'error'
                        : undefined
                    }
                  >
                    {formatHtml(alert.message)}
                  </Alert>
                ))}
                {additionalContent?.onEdit?.alerts?.map((alert, index) => (
                  <Alert
                    className="extra-small py-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`checkout-email-alert-on-edit-${index}`}
                    {...alert}
                  />
                ))}
                {cart.guest ? (
                  <>
                    {!loginFormActive ? (
                      <CartEmailFormHelper variant="checkout">
                        {({ fieldsHelper }) => (
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <h5>{t('checkout.edit')}</h5>
                              <FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
                                <TextInput {...fieldsHelper.email.textInput} />
                              </FormGroup>
                              <FormGroup {...fieldsHelper.privacy.formGroup}>
                                <Checkbox {...fieldsHelper.privacy.checkbox} />
                              </FormGroup>
                              <div className="d-grid mb-3 border-bottom pb-3">
                                <Button {...fieldsHelper.buttons.submit} />
                              </div>
                              <h5>{t('account.userRegistered')}</h5>
                              <p className="mb-3">
                                {t('checkout.step.email.subTitle1')}
                                <br />
                                {t('checkout.step.email.subTitle2')}
                              </p>
                              <div className="d-grid">
                                <Button
                                  label={t('checkout.step.email.login')}
                                  onClick={handleSwitchFormButtonClick}
                                  variant="outline-primary"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </CartEmailFormHelper>
                    ) : (
                      <div className="row">
                        <div className="col-12 col-lg-6">
                          <h5>{t('checkout.step.email.login')}</h5>
                          <p>{t('checkout.step.email.insertCredentials')}</p>
                          <LoginForm />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {cart.email === undefined && (
                      <DevAlert className="mb-0" heading="API">
                        <p>{t('checkout.step.email.alert.title')}</p>
                        <Button
                          label={t('checkout.step.email.alert.buttons.label')}
                          onClick={handleMockEmailButtonClick}
                          variant="primary"
                        />
                      </DevAlert>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {content?.onClosed?.alerts?.map((alert) => (
                  <Alert
                    className="extra-small py-2"
                    key={alert.contentSectionId}
                    {...alert}
                    variant={
                      alert.type === 'info'
                        ? 'info'
                        : alert.type === 'success'
                        ? 'success'
                        : alert.type === 'warning'
                        ? 'warning'
                        : alert.type === 'error'
                        ? 'error'
                        : undefined
                    }
                  >
                    {formatHtml(alert.message)}
                  </Alert>
                ))}
                {additionalContent?.onClosed?.alerts?.map((alert, index) => (
                  <Alert
                    className="extra-small py-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`checkout-email-alert-on-closed-${index}`}
                    {...alert}
                  />
                ))}
                {t('checkout.step.email.orderAs')} {''} <b>{cart.email}</b>
              </>
            )}
          </>
        )}
      </>
    ),
  });
};
