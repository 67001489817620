import { PageWithSubpageHeaderA } from './PageWithSubpageHeaderA';
import { LinkProps } from './router/Link';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface PageWithSubpageHeaderProps {
  mobileBackLink?: LinkProps;
  pageSubtitle?: string;
  pageTitle?: string;
  showMobileBackLink?: boolean;
  showPageSubtitle?: boolean;
  showPageTitle?: boolean;
  showSubpageTitle?: boolean;
  subpageTitle?: string;
}

export type PageWithSubpageHeaderVariants = 'A';

const PageWithSubpageHeaderController = (props: PropsWithCustomComponent<PageWithSubpageHeaderProps>) => (
  <VariantsController<PageWithSubpageHeaderProps, PageWithSubpageHeaderVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: PageWithSubpageHeaderA },
      name: 'PageWithSubpageHeader',
    }}
  />
);
export { PageWithSubpageHeaderController as PageWithSubpageHeader };
