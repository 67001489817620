import { ContactUsProps } from '../../ContactUs';
import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountHelpSubpageA } from './accountSubpagesA/AccountHelpSubpageA';

export interface AccountHelpSubpageProps {
  contactUs?: ContactUsProps;
}

export type AccountHelpSubpageVariants = 'A';

const AccountHelpSubpageController = (props: PropsWithCustomComponent<AccountHelpSubpageProps>) => (
  <VariantsController<AccountHelpSubpageProps, AccountHelpSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountHelpSubpageA },
      name: 'AccountHelpSubpage',
    }}
  />
);
export { AccountHelpSubpageController as AccountHelpSubpage };
