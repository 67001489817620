import { SendEventRequestApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions } from './utils/util';

const rootPath = '/v2/events';

// export const log = async (apiKey: string, event: string, options?: BaseOptions) =>
//   apiRequest<void, void, void>({
//     ...getBaseOptions(options),
//     url: `${rootPath}/log/${apiKey}/${event}`,
//   });

export const send = async (data: SendEventRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SendEventRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/send`,
  });
