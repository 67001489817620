import { Button } from '../Button';
import { Div } from '../HTMLElement';
import { ChangePasswordFormHelper, ChangePasswordFormProps } from './ChangePasswordForm';
import { PasswordInput } from './fields/Input';
import { FormGroup } from './FormGroup';

export const ChangePasswordFormA = (props: ChangePasswordFormProps) => (
  <ChangePasswordFormHelper {...props}>
    {({ fieldsHelper }) => (
      <>
        <FormGroup {...fieldsHelper.currentPassword.formGroup}>
          <PasswordInput {...fieldsHelper.currentPassword.passwordInput} />
        </FormGroup>
        <FormGroup {...fieldsHelper.newPassword.formGroup}>
          <PasswordInput {...fieldsHelper.newPassword.passwordInput} />
        </FormGroup>
        <FormGroup {...fieldsHelper.confirmNewPassword.formGroup}>
          <PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
        </FormGroup>
        <Div display={'flex'} gap={3} justifyContent={'center'}>
          <Button {...fieldsHelper.buttons.submit} />
        </Div>
      </>
    )}
  </ChangePasswordFormHelper>
);
