import { PaymentResponseApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { Modal, ModalBody, ModalProps } from './Modal';
import { PayPalCheckout } from './PayPalCheckout';
import { useDynamicModal } from './useModal';
import { useModals } from './useModals';

export interface PayPalCheckoutModalProps extends ModalProps {
  currency: string;
  mode: 'start' | 'restart';
  payment: PaymentResponseApiDto;
}

export const PayPalCheckoutModal = ({ currency, innerRef, mode, payment, ...modalProps }: PayPalCheckoutModalProps) => {
  if (!innerRef) {
    throw new Error('PayPalCheckoutModal without innerRef');
  }

  const { closeByRef } = useModals();

  const closeModal = React.useCallback(() => {
    return closeByRef(innerRef);
  }, [closeByRef, innerRef]);

  return (
    <Modal {...modalProps} closable={false} innerRef={innerRef}>
      <ModalBody>
        <PayPalCheckout
          currency={currency}
          mode={mode}
          onCancel={closeModal}
          onError={closeModal}
          onSuccess={closeModal}
          payment={payment}
        />
      </ModalBody>
    </Modal>
  );
};

export const usePayPalCheckoutModal = () => useDynamicModal(PayPalCheckoutModal, 'modal', true);
