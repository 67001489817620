import { AddressApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCartApi } from '../api/useCartApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { CheckoutStep, useCheckoutContext } from '../CheckoutContext';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { formatAddress } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { AddressFieldset, addressValidationSchemaSelector } from './AddressFieldset';
import { CartAddressesFormA } from './CartAddressesFormA';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBoolean,
  getInitialBoolean,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { InvoiceFieldset } from './InvoiceFieldset';
import {
  AddressFieldsHelper,
  AddressFormValues,
  AddressValidationSchema,
  AddressValidationSchemaSelector,
  getAddressFromFormValues,
  getAddressValidationSchema,
  getInvoiceValidationSchema,
  InvoiceFieldsHelper,
  InvoiceFormValues,
  InvoiceValidationSchema,
  useAddressForm,
} from './useAddressForm';

export interface CartAddressesFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  billingAddress?: AddressApiDto;
  copyType?: 'to' | 'from';
  shippingAddress?: AddressApiDto;
  type: 'shipping' | 'billing';
}

interface FormValues {
  address: AddressFormValues;
  copyFrom: formikBoolean;
  copyTo: formikBoolean;
  invoice: InvoiceFormValues;
}

type ValidationSchema = {
  address: yup.ObjectSchema<AddressValidationSchema>;
  copyFrom: yup.BooleanSchema;
  copyTo: yup.BooleanSchema;
  invoice: InvoiceValidationSchema;
};

type ValidationSchemaSelector = {
  address: AddressValidationSchemaSelector;
};

interface FieldsHelper {
  address?: AddressFieldsHelper;
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  copyFrom?: { addressToCopyFrom: AddressApiDto; checkbox: CheckboxProps; formGroup: FormGroupProps };
  copyTo?: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  invoice?: InvoiceFieldsHelper;
}

export const CartAddressesFormHelper = ({
  billingAddress,
  children,
  className,
  copyType,
  initialValues,
  onCancel,
  onSuccess,
  shippingAddress,
  type,
  validationSchemaSelector = { address: addressValidationSchemaSelector },
  ...otherProps
}: CartAddressesFormProps) => {
  const address = React.useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    () => (type === 'shipping' ? shippingAddress : type === 'billing' ? billingAddress : undefined),
    [billingAddress, shippingAddress, type]
  );

  const { shippingCountry } = useAppContext();

  const { cart, deleteForcedStep, sameAddresses, setStepAlreadyDone } = useCheckoutContext();

  const { getAddressFieldsHelper, getAddressInitialValues, getInvoiceFieldsHelper, getInvoiceInitialValues } =
    useAddressForm('CartAddressesForm', type, address);

  const ref = React.useRef<HTMLFormElement>(null);

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      account: {
        defaultBilling: getInitialBoolean(false),
        defaultShipping: getInitialBoolean(false),
      },
      address: getAddressInitialValues(),
      copyFrom: getInitialBoolean(copyType === 'from' && sameAddresses ? true : false),
      copyTo: getInitialBoolean(
        // preflaggato solo se l'altro non c'è.
        copyType === 'to' && type === 'shipping'
          ? // ? billingAddress === undefined
            false // cablo false, vari clienti ci hanno segnalato che altrimenti si perde l'inserimento della fattura.
          : copyType === 'to' && type === 'billing'
          ? shippingAddress === undefined
          : false
      ),
      invoice: getInvoiceInitialValues(),
      ...initialValues,
    }),
    [copyType, getAddressInitialValues, getInvoiceInitialValues, initialValues, sameAddresses, shippingAddress, type]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      address: yup
        .object({
          addressLine1: yup.string(),
          addressLine2: yup.string(),
          addressLine3: yup.string(),
          alternativePhone: yup.string(),
          city: yup.string(),
          civicNumber: yup.string(),
          country: yup.string(),
          latitude: yup.string(),
          longitude: yup.string(),
          middleName: yup.string(),
          name: yup.string(),
          phone: yup.string(),
          province: yup.string(),
          region: yup.string(),
          surname: yup.string(),
          zipCode: yup.string(),
        })
        .when('copyFrom', {
          is: false,
          then: (schema) => schema.shape(getAddressValidationSchema(validationSchemaSelector.address)),
        }),
      copyFrom: yup.boolean(),
      copyTo: yup.boolean(),
      invoice: getInvoiceValidationSchema(shippingCountry, cart, type === 'billing' ? 'billing' : 'shipping'),
    }),
    [shippingCountry, cart, type, validationSchemaSelector.address]
  );

  const { setAddresses } = useCartApi();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const submittedAddressWithoutInvoiceFields = getAddressFromFormValues(values.address, address);

      let shippingAddressToSend: AddressApiDto | undefined;
      let billingAddressToSend: AddressApiDto | undefined;
      let step: CheckoutStep | undefined;

      switch (type) {
        case 'shipping':
          step = 'shippingAddress';
          shippingAddressToSend =
            copyType === 'from' && values.copyFrom && billingAddress
              ? billingAddress
              : submittedAddressWithoutInvoiceFields;

          billingAddressToSend =
            copyType === 'to' && values.copyTo
              ? {
                  ...submittedAddressWithoutInvoiceFields,
                  // Mantengo eventuali info relative alla fattura già presenti a carrello nel billing address.
                  company: billingAddress?.company,
                  pec: billingAddress?.pec,
                  taxCode: billingAddress?.taxCode,
                  uniqueCode: billingAddress?.uniqueCode,
                  vatNumber: billingAddress?.vatNumber,
                }
              : undefined;
          break;
        case 'billing':
          step = 'billingAddress';
          billingAddressToSend =
            copyType === 'from' && values.copyFrom && shippingAddress
              ? shippingAddress
              : submittedAddressWithoutInvoiceFields;
          // Gestisco i dati aggiutivi per la fattura (invoice)
          if (values.invoice.request) {
            billingAddressToSend = {
              ...billingAddressToSend,
              company: values.invoice.data.company,
              pec: values.invoice.data.pec,
              taxCode: values.invoice.data.taxCode,
              uniqueCode: values.invoice.data.uniqueCode,
              vatNumber: values.invoice.data.vatNumber,
            };
          }
          shippingAddressToSend =
            // Se lo voglio copiare lo shipping a partire dal billing, controllo che il billing a sua volta non sia copiato dallo shipping.
            copyType === 'to' && values.copyTo ? submittedAddressWithoutInvoiceFields : undefined;
          break;
      }

      // Mi accerto di non salvare informazioni relative alla fattura nello shipping address
      delete shippingAddressToSend?.company;
      delete shippingAddressToSend?.taxCode;
      delete shippingAddressToSend?.vatNumber;
      delete shippingAddressToSend?.pec;
      delete shippingAddressToSend?.uniqueCode;

      if (values.invoice.type === 'privatePerson') {
        delete billingAddressToSend?.company;
        delete billingAddressToSend?.vatNumber;
        delete billingAddressToSend?.pec;
        delete billingAddressToSend?.uniqueCode;
      } else if (values.invoice.type === 'company') {
      } else if (values.invoice.type === 'freelance') {
      }

      return setAddresses({
        billingAddress: billingAddressToSend,
        shippingAddress: shippingAddressToSend,
      }).then(() => {
        deleteForcedStep();
        if (step) {
          setStepAlreadyDone(step);
        }
        onSuccess && onSuccess();
      });
    },
    [
      address,
      billingAddress,
      copyType,
      deleteForcedStep,
      onSuccess,
      setAddresses,
      setStepAlreadyDone,
      shippingAddress,
      type,
    ]
  );

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('CartAddressesForm', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          address: copyType === 'from' && formik.values.copyFrom ? undefined : getAddressFieldsHelper(formik),
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.addressForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.addressForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          copyFrom:
            copyType === 'from' && type === 'shipping' && billingAddress !== undefined
              ? {
                  addressToCopyFrom: billingAddress,
                  checkbox: {
                    id: 'copyFrom',
                    label: t('form.addressForm.copyFromBilling.label'),
                    name: 'copyFrom',
                  },
                  formGroup: { names: ['copyFrom'] },
                }
              : copyType === 'from' && type === 'billing' && shippingAddress !== undefined
              ? {
                  addressToCopyFrom: shippingAddress,
                  checkbox: {
                    id: 'copyFrom',
                    label: t('form.addressForm.copyFromShipping.label'),
                    name: 'copyFrom',
                  },
                  formGroup: { names: ['copyFrom'] },
                }
              : undefined,
          copyTo:
            copyType === 'to'
              ? {
                  checkbox: {
                    id: 'copyTo',
                    label:
                      type === 'shipping'
                        ? t('form.addressForm.copyToBilling.label')
                        : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        type === 'billing'
                        ? t('form.addressForm.copyToShipping.label')
                        : undefined,
                    name: 'copyTo',
                  },
                  formGroup: { names: ['copyTo'] },
                }
              : undefined,
          invoice: type === 'billing' ? getInvoiceFieldsHelper(formik) : undefined,
        };

        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            {fieldsHelper.copyFrom && (
              <FormGroup {...fieldsHelper.copyFrom.formGroup}>
                <Checkbox {...fieldsHelper.copyFrom.checkbox} />
              </FormGroup>
            )}
            {fieldsHelper.address ? (
              <>
                <AddressFieldset addressFieldsHelper={fieldsHelper.address} />
                {fieldsHelper.copyTo && (
                  <FormGroup {...fieldsHelper.copyTo.formGroup}>
                    <Checkbox {...fieldsHelper.copyTo.checkbox} />
                  </FormGroup>
                )}
              </>
            ) : (
              fieldsHelper.copyFrom?.addressToCopyFrom && (
                <p>{formatAddress(fieldsHelper.copyFrom.addressToCopyFrom, { withName: true })}</p>
              )
            )}

            {fieldsHelper.invoice && <InvoiceFieldset invoiceFieldsHelper={fieldsHelper.invoice} />}

            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type CartAddressesFormVariants = 'A';

const CartAddressesFormController = (props: PropsWithCustomComponentWithoutChildren<CartAddressesFormProps>) => (
  <VariantsController<CartAddressesFormProps, CartAddressesFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: {
        A: CartAddressesFormA,
      },
      defaultComponent: CartAddressesFormHelper,
      name: 'CartAddressesForm',
    }}
  />
);
export { CartAddressesFormController as CartAddressesForm };
