// eslint-disable-next-line no-restricted-imports
import { api as _api, api, queryClient } from '@b2x/storefront-api-js-client/src';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import moment from 'moment';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { useContentsApi } from './api/useContentsApi';
import { useInfoApi } from './api/useInfoApi';
import { useSessionApi } from './api/useSessionApi';
import { AppContext, useAppContext, useAppStaticContext } from './AppContext';
import { appConfig, updateConfig } from './config';
import { Configurator } from './Configurator';
import { StickersProductContentType, StructuredDataContentType } from './contentTypes';
import { env } from './env';
import { Fade } from './Fade';
import { FloatingActionButtonContext } from './FloatingActionButtonContext';
import { Div } from './HTMLElement';
import { i18nInit } from './i18n/i18n';
import { LoadingOverlay } from './LoadingOverlay';
import { MainHelmet } from './MainHelmet';
import { Modals } from './ModalsContext';
import { useBrowserRouterContext } from './router/BrowserRouterContext';
import { useSearchParams } from './router/useSearchParams';
import { updateStaticVariables } from './staticVariables';
import { storage } from './storage';
import { useCookiebotInitializer } from './useCookiebotInitializer';
import {
  addScript,
  getDefaultLocale,
  getRouterBasename,
  getSupportedLocale,
  getSupportedShippingCountryFromPathOrUserLocales,
  isWithContextPath,
} from './util';
import { VariantsContext } from './VariantsContext';

export interface AppProps {
  children?: React.ReactNode;
  comingSoonPage?: JSX.Element;
  enableConfigurator?: boolean;
  enableReactQueryDevtools?: boolean;
  faviconIco?: string;
  faviconPng?: string;
  onAddToCartSuccess?(): void;
  onAddToWishlistAsGuestButtonClick?(): void;
  onLoginActionCallback?(): void;
}

export const App = ({ children, ...otherProps }: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <VariantsContext>
        <FloatingActionButtonContext>
          <AppContext
            onAddToCartSuccess={otherProps.onAddToCartSuccess}
            onAddToWishlistAsGuestButtonClick={otherProps.onAddToWishlistAsGuestButtonClick}
            onLoginActionCallback={otherProps.onLoginActionCallback}
          >
            <HelmetProvider>
              <AppInsideContexts {...otherProps}>{children}</AppInsideContexts>
            </HelmetProvider>
          </AppContext>
        </FloatingActionButtonContext>
      </VariantsContext>
    </QueryClientProvider>
  );
};

const AppInsideContexts = ({
  children,
  comingSoonPage,
  enableConfigurator = true,
  enableReactQueryDevtools = true,
  faviconIco,
  faviconPng,
}: AppProps) => {
  const { appReady, fetching, info, pageReady, session, thingsToLoadBeforeAppReady } = useAppContext();

  const {
    setAppReady,
    setInfo,
    setLocale,
    setMainCategory,
    setSession,
    setShippingCountry,
    setStickersProductContent,
    setStructuredDataContent,
    setSupportedLocales,
    setSupportedShippingCountries,
  } = useAppStaticContext();

  const { getInfo } = useInfoApi();
  const { getContentByCode } = useContentsApi();
  const { getSession } = useSessionApi();

  const { setBasename } = useBrowserRouterContext();

  useCookiebotInitializer();

  const [searchParams, setSearchParams] = useSearchParams();

  const init = React.useCallback(() => {
    /*
      Questa prima chiamata a info è temporanea.
      Non ho ancora un locale settato, quindi mi risponderà nella lingua di default dello store.
      Le info ottenute qui non possono quindi essere usate per mostrarle al cliente, ma solo per fare delle indagini iniziali.
      In particolare serve sapere le lingue e i paesi supportati, in modo da scegliere quelle più giuste per il cliente.
      Una volta ottenuti questi dati, vieni rifatta la chiamata a info, ma stavolta quella vera, nella lingua desiderata.
    */
    getInfo({
      populate: {
        defaultLocale: true,
        defaultShippingCountry: true,
        shippingCountries: true,
        shippingCountriesLocales: true,
        supportedLocales: true,
      },
    }).then((responseWithDefaultLocale) => {
      updateConfig({
        cookiePrefix: responseWithDefaultLocale.data.cookiePrefix,
        storeId: responseWithDefaultLocale.data.storeId,
      });
      let supportedLocales = responseWithDefaultLocale.data.supportedLocales;
      let supportedShippingCountries = responseWithDefaultLocale.data.shippingCountries;
      let defaultLocale = getDefaultLocale(responseWithDefaultLocale.data);
      let defaultShippingCountry = responseWithDefaultLocale.data.defaultShippingCountry;

      if (!supportedLocales) {
        throw new Error('No locales set');
      }
      if (!supportedShippingCountries) {
        throw new Error('No shipping countries set');
      }
      if (!defaultShippingCountry) {
        throw new Error('No default shipping country set');
      }

      let locale = getSupportedLocale(supportedLocales, defaultLocale);
      let shippingCountry = getSupportedShippingCountryFromPathOrUserLocales(
        supportedShippingCountries,
        defaultShippingCountry,
        locale
      );

      _api.updateHeaders({
        locale: locale.code,
        shippingCountry: shippingCountry.code,
      });

      getInfo({
        populate: {
          bankAccountDetails: true,
          defaultLocale: true,
          defaultShippingCountry: true,
          shippingCountries: true,
          shippingCountriesLocales: true,
          supportedLocales: true,
        },
      }).then((response) => {
        supportedLocales = response.data.supportedLocales;
        supportedShippingCountries = response.data.shippingCountries;
        defaultLocale = getDefaultLocale(response.data);
        defaultShippingCountry = response.data.defaultShippingCountry;

        if (!supportedLocales) {
          throw new Error('No locales set');
        }
        if (!supportedShippingCountries) {
          throw new Error('No shipping countries set');
        }
        if (!defaultShippingCountry) {
          throw new Error('No default shipping country set');
        }

        locale = getSupportedLocale(supportedLocales, defaultLocale);
        shippingCountry = getSupportedShippingCountryFromPathOrUserLocales(
          supportedShippingCountries,
          defaultShippingCountry,
          locale
        );

        const checkComingSoon = () =>
          new Promise<boolean>((resolve) => {
            if (appConfig.enableCientSideComingSoonManagement && response.data.comingSoon) {
              if (window.location.hostname === 'localhost') {
                // salto la coming soon in sviluppo
                resolve(false);
              } else {
                const previewStorage = storage.getBoolean('preview', true);
                if (previewStorage) {
                  // salto la coming soon nello storage ho il flag che me la fa saltare
                  resolve(false);
                } else {
                  const previewCode = searchParams.get('preview') ?? undefined;
                  if (previewCode) {
                    api.info.checkComingSoon(previewCode).then((checkComingSoonResponse) => {
                      if (!checkComingSoonResponse.data) {
                        // salto la coming soon se ho un queryParam "preview" valido
                        storage.setBoolean('preview', true, true);
                        searchParams.delete('preview');
                        setSearchParams(searchParams);
                        resolve(false);
                      }
                    });
                  } else {
                    resolve(true);
                  }
                }
              }
            } else {
              resolve(false);
            }
          });

        checkComingSoon().then((comingSoon) => {
          setInfo({ ...response.data, comingSoon });
          updateConfig({
            cookiePrefix: response.data.cookiePrefix,
            googleMaps: response.data.googleMapsApiKey ? { apiKey: response.data.googleMapsApiKey } : undefined,
            storeId: response.data.storeId,
          });

          const sessionToken = storage.getString('sessionToken', appConfig.persistentSession);
          sessionToken && _api.updateHeaders({ sessionToken: sessionToken });
          setMainCategory(storage.getObject('mainCategory'));

          if (!supportedLocales) {
            throw new Error('No locales set');
          }
          if (!supportedShippingCountries) {
            throw new Error('No shipping countries set');
          }
          if (!defaultShippingCountry) {
            throw new Error('No default shipping country set');
          }

          // Controllo che la coppia locale/shippingCountry combaci con quella nel path, in caso contrario faccio redirect (non se ho una sola lingua/country)
          const token = window.location.pathname.split('/')[isWithContextPath() ? 2 : 1];
          if (
            !appConfig.widgetMode &&
            (appConfig.keepUselessLocaleTokenInPath ||
              supportedLocales.length > 1 ||
              supportedShippingCountries.length > 1) &&
            token !== `${locale.code}-${shippingCountry.code.toLowerCase()}`
          ) {
            // qualcosa non torna, faccio redirect aggiungendo la coppia al punto giusto
            const localeAndShippingCountryToken = `${locale.code}-${shippingCountry.code.toLowerCase()}`;
            const stringBuilder: Array<string> = [];
            stringBuilder.push(window.location.origin);
            if (isWithContextPath()) {
              if (window.location.pathname === `${env.PUBLIC_URL}`) {
                stringBuilder.push(`${window.location.pathname}/${localeAndShippingCountryToken}/`);
              } else {
                if (token === locale.code) {
                  stringBuilder.push(
                    window.location.pathname.replace(
                      `${env.PUBLIC_URL}/${token}`,
                      `${env.PUBLIC_URL}/${localeAndShippingCountryToken}`
                    )
                  );
                } else {
                  stringBuilder.push(
                    window.location.pathname.replace(
                      `${env.PUBLIC_URL}/`,
                      `${env.PUBLIC_URL}/${localeAndShippingCountryToken}/`
                    )
                  );
                }
              }
            } else {
              stringBuilder.push(`/${localeAndShippingCountryToken}`);
              if (token === locale.code) {
                // se il token era di 2 lettere ed esprimeva il locale, lo escludo dal pathname da appendere
                stringBuilder.push(window.location.pathname.substring(3));
              } else {
                stringBuilder.push(window.location.pathname);
              }
            }
            stringBuilder.push(window.location.search);
            let url = stringBuilder.join('');
            if (url.endsWith('/')) {
              url = url.substring(0, url.length - 1);
            }
            window.location.replace(url);
          } else {
            if (
              appConfig.keepUselessLocaleTokenInPath ||
              supportedLocales.length > 1 ||
              supportedShippingCountries.length > 1
            ) {
              setBasename(getRouterBasename(locale, shippingCountry));
            } else {
              setBasename(getRouterBasename());
            }

            i18nInit(
              appConfig.i18n,
              locale.code,
              supportedLocales.map((supportedLocale) => supportedLocale.code)
            );
            moment.locale(locale.code);

            storage.setString('shippingCountry', shippingCountry.code, true);

            setLocale(locale);
            setShippingCountry(shippingCountry);
            setSupportedLocales(supportedLocales);
            setSupportedShippingCountries(supportedShippingCountries);

            // metto locale e shippingCountry nelle variabili statiche
            updateStaticVariables({ locale, shippingCountry });

            if (appConfig.cookiebot && response.data.environment !== 'LOCAL') {
              addScript({
                dataAttributes: {
                  // commento blockingmode finchè non capisco come risolvere il problema delle immagini che non chiamano la onLoad (rimanendo i placeholder)
                  // blockingmode: 'auto',
                  cbid: appConfig.cookiebot.id,
                  culture: locale.languageCode,
                },
                id: 'cookiebot',
                src: 'https://consent.cookiebot.com/uc.js',
                type: 'text/javascript',
              });
            }

            if (
              appConfig.oneTrust
              // && response.data.environment !== 'LOCAL'
            ) {
              addScript({
                charset: 'UTF-8',
                dataAttributes: {
                  'domain-script': appConfig.oneTrust.domainScript,
                },
                id: 'onetrust',
                src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
                type: 'text/javascript',
              });
              addScript({
                id: 'onetrust-invoke',
                text: 'function OptanonWrapper() { }',
                type: 'text/javascript',
              });
            }

            // getContentByCode<LayoutsContentType>('LAYOUTS_CONTENT', { forceDefaultLanguage: true }).then(
            //   ({ data: layoutsContent }) => {
            //     setLayouts((prevState) => ({ ...prevState, ...layoutsContent.body }));
            //   }
            // );

            if (appConfig.googleMaps) {
              addScript({
                id: 'google-maps',
                text: `
                (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
                  key: "${appConfig.googleMaps.apiKey}",
                  v: "weekly",
                  language: "${locale.languageCode}"
                });
                `,
              });
            }

            // if (appConfig.googleMaps) {
            //   addScript({
            //     async: true,
            //     id: 'google-maps',
            //     src: `https://maps.googleapis.com/maps/api/js?key=${appConfig.googleMaps.apiKey}&libraries=marker`,
            //   });
            // }

            // Prendo prima una sessione leggera (senza alcuna populate), giusto per avere il sessionToken e poter inizializzare l'app.
            // Subito dopo prendo silenziosamente la vera sessione con le varie populate, più lenta.
            getSession({}).then((partialSessionResponse) => {
              setSession(partialSessionResponse.data);
              getSession(appConfig.api?.sessionOptions, { silent: true }).then((fullSessionResponse) => {
                setSession(fullSessionResponse.data);
              });
            });
            document.body.classList.add(`b2x-env-${env.REACT_APP_B2X_ENV.toLowerCase()}`);

            getContentByCode<StickersProductContentType>('STICKERS_PRODUCT_CONTENT', undefined, {
              suppressErrorLog: true,
              suppressErrorModal: true,
            })
              .then((stickersResponse) => {
                setStickersProductContent(stickersResponse.data);
              })
              .catch(() => {
                // pazienza
              });

            getContentByCode<StructuredDataContentType>('STRUCTURED_DATA_CONTENT', undefined, {
              suppressErrorLog: true,
              suppressErrorModal: true,
            })
              .then((structuredDataResponse) => {
                setStructuredDataContent(structuredDataResponse.data);
              })
              .catch(() => {
                // pazienza
              });
          }
        });
      });
    });

    ///
  }, [
    getContentByCode,
    getInfo,
    getSession,
    searchParams,
    setBasename,
    setInfo,
    setLocale,
    setMainCategory,
    setSearchParams,
    setSession,
    setShippingCountry,
    setStickersProductContent,
    setStructuredDataContent,
    setSupportedLocales,
    setSupportedShippingCountries,
  ]);

  const initialized = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (!initialized.current) {
      init();
      initialized.current = true;
    }
  }, [init]);

  React.useEffect(() => {
    if (pageReady) {
      const everythingIsReady = Object.values(thingsToLoadBeforeAppReady).every((thing) => thing !== undefined);
      if (everythingIsReady) {
        setAppReady(true);
      }
    }
  }, [pageReady, setAppReady, thingsToLoadBeforeAppReady]);

  // React.useEffect(() => {
  //   if (env.REACT_APP_API_PROXY === 'PRODUCTION' && appConfig.preRelease === false) {
  //     document.body.style.border = 'solid 5px red';
  //   }
  // }, []);

  return (
    <>
      {info?.comingSoon === true ? (
        comingSoonPage ?? (
          <Div margin="auto" textAlign="center">
            coming soon...
          </Div>
        )
      ) : (
        <>
          {/* <StaticContextChecker /> */}
          {enableConfigurator && <Configurator />}
          {enableReactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
          <Modals />
          <MainHelmet faviconIco={faviconIco} faviconPng={faviconPng} />
          <Fade show={fetching > 0 || session === undefined || !appReady || !pageReady}>
            <LoadingOverlay />
          </Fade>
          {/* <ConditionalWrapper
        condition={env.REACT_APP_API_PROXY === 'PRODUCTION' && appConfig.preRelease === false}
        wrapper={<Div border={{ color: 'red', style: 'solid', width: 5 }} />}
      > */}
          {session && children}
          {/* </ConditionalWrapper> */}
        </>
      )}
    </>
  );
};
