import {
  // eslint-disable-next-line no-restricted-imports
  api,
} from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const usePayPalApi = () => {
  const { apiRequest } = useApiRequest();

  const createOrder = React.useCallback(
    (paymentTransactionId: string, config?: ApiRequestConfig) =>
      apiRequest(api.payPal.createOrder(paymentTransactionId), {
        silent: false,
        suppressErrorModal: true,
        ...config,
      }),
    [apiRequest]
  );

  const capturePayment = React.useCallback(
    (orderId: string, paymentTransactionId: string, config?: ApiRequestConfig) =>
      apiRequest(api.payPal.capturePayment(orderId, paymentTransactionId), {
        silent: false,
        suppressErrorModal: true,
        ...config,
      }),
    [apiRequest]
  );

  return {
    capturePayment,
    createOrder,
  };
};
