import { ProductApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { appConfig } from '../../../config';
import { t } from '../../../i18n/i18n';
import { Listing } from '../../../Listing';
import { ProductsTiles } from '../../../ProductsTiles';
import { useWishlist } from '../../../useWishlist';
import { AccountSubpage } from '../AccountSubpage';
import { AccountWishlistSubpageProps } from '../AccountWishlistSubpage';
import { Box } from './Box';

export const AccountWishlistSubpageA = (props: AccountWishlistSubpageProps) => {
  const wishlist = useWishlist({
    populate: {
      products: appConfig.api?.productTilePopulate,
      skus: { product: appConfig.api?.productTilePopulate },
    },
  });

  const wishlistProducts = React.useMemo<Array<{ preselectedSkuId?: string; product: ProductApiDto }>>(
    () => [
      ...(wishlist?.products ? wishlist.products.map((product) => ({ product: product })) : []),
      ...(wishlist?.skus
        ? wishlist.skus
            .filter((sku) => sku.product !== undefined)
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((sku) => ({ preselectedSkuId: sku.id, product: sku.product! }))
        : []),
    ],
    [wishlist?.products, wishlist?.skus]
  );

  return (
    <AccountSubpage>
      <Box>
        {appConfig.enableWishlist ? (
          wishlistProducts.length > 0 ? (
            <Listing name="Account page - Wishlist subpage" products={wishlistProducts.map(({ product }) => product)}>
              <ProductsTiles
                enableAddToCart
                enableExtraInfo
                products={wishlistProducts}
                productsPerRow={{ lg: 3, md: 2, sm: 2, xl: 3, xs: 2, xxl: 3 }}
              />
            </Listing>
          ) : (
            <p className="m-0">{t('account.emptyWishlist')}</p>
          )
        ) : (
          <p className="m-0">{t('account.sectionDisabled')}</p>
        )}
      </Box>
    </AccountSubpage>
  );
};
