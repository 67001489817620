import { Button } from './Button';
import { Div, Span } from './HTMLElement';
import { Link } from './router/Link';
import { elementsJoinerReducer } from './util';

export interface SeparatedListProps {
  items: Array<{
    cta: string | (() => void);
    disabled?: boolean;
    label: string;
  }>;
  separator?: React.ReactElement | string;
  textUnderlined?: boolean;
  textUppercase?: boolean;
}

export const SeparatedList = ({ items, separator = '|', textUnderlined, textUppercase }: SeparatedListProps) => {
  return (
    <Div
      alignItems="center"
      alignSelf="stretch"
      className="SeparatedList"
      display="flex"
      dynamicMargin={{ bottom: 0 }}
      flexDirection="row"
      gap={2}
    >
      {items.length > 0 &&
        items
          .map((item) => (
            <small key={item.label}>
              {typeof item.cta === 'string' ? (
                <Link to={item.cta}>
                  <Span
                    className="extra-small"
                    style={{
                      textDecoration: textUnderlined ? 'underline' : 'none',
                      textTransform: textUppercase ? 'uppercase' : undefined,
                    }}
                  >
                    {item.label}
                  </Span>
                </Link>
              ) : (
                <Button disabled={item.disabled} onClick={item.cta} variant="blank">
                  <Span
                    className="extra-small"
                    style={{
                      textDecoration: textUnderlined ? 'underline' : 'none',
                      textTransform: textUppercase ? 'uppercase' : undefined,
                    }}
                  >
                    {item.label}
                  </Span>
                </Button>
              )}
            </small>
          ))
          .reduce(elementsJoinerReducer(separator))}
    </Div>
  );
};
