import './Footer.scss';

import { b2x } from '@b2x/react/src';

import { Accordion } from './Accordion';
import { useAppStaticContext } from './AppContext';
import { ContactsBox } from './ContactsBox';
import { FooterContentType } from './contentTypes';
import { FooterIcons } from './FooterIcons';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import logoFooter from './images/logo-footer.svg';
import { ListAFromMenu } from './ListAFromMenu';
import { PaymentIcons } from './PaymentIcons';
import { SocialIcons } from './SocialIcons';
export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerBottomMenu = b2x.useMenu('MENU_BOTTOM_FOOTER');
  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');

  const { setFooterCopyrightRef } = useAppStaticContext();

  return (
    <footer className="Footer mt-auto">
      <div className="bg-primary text-white">
        <div className="container-xxl">
          <div className="py-lg-5">
            <div className="d-none d-lg-block">
              <FooterIcons />
              <hr className="mb-5 d-none d-lg-block"></hr>
            </div>
            <div className="row">
              <div className="col-lg-4 p-0 px-lg-2 order-lg-2">
                <div className="ps-lg-4 text-center text-lg-start">
                  <div className="d-none d-lg-block">
                    <h6 className="fw-bold">{t('misc.newsletterTitle')}</h6>
                    <div className="small mb-2">{b2x.formatHtml(footerContent?.body.newsletterSubtitle)}</div>
                    <NewsletterForm className="mb-4" emailFieldStyle="inputGroup" source="website-footer" />
                  </div>
                  <div className="footerSocials d-flex justify-content-start align-items-center py-3 py-lg-0">
                    <h6 className="fw-normal mb-0 me-3 mb-lg-2">{t('misc.followUs')}</h6>
                    <SocialIcons iconTextColor="text-secondary" />
                  </div>
                </div>
              </div>

              <div className="col-lg-8 ">
                <div className="row">
                  <div className="col-12 col-lg-3 d-none d-lg-block">
                    <b2x.Image className="mt-n4" fluid src={logoFooter} />
                    <div className="mt-3">
                      <ContactsBox showIcon={false} />
                    </div>
                  </div>
                  <div className="col-12 col-lg-9 ">
                    <Accordion
                      accordionColClassName="p-0 px-lg-2"
                      columnLayoutFrom="lg"
                      id="accordionExample"
                      itemButtonClassName="bg-transparent text-white"
                      itemClassName="bg-transparent"
                    >
                      {footerMenu?.children.map(
                        (column) =>
                          column.label && (
                            <b2x.AccordionItem id={`accordionItem-${column.id}`} key={column.id} title={column.label}>
                              {column.children.map(
                                (li) =>
                                  li.link && (
                                    <div key={li.id}>
                                      <b2x.router.Link className="text-white text-decoration-none small" to={li.link}>
                                        {li.label}
                                      </b2x.router.Link>
                                    </div>
                                  )
                              )}
                            </b2x.AccordionItem>
                          )
                      )}
                    </Accordion>
                    <div className="p-0 px-lg-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary text-white" ref={setFooterCopyrightRef}>
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-lg-5 p-0 px-lg-2 py-lg-2 order-lg-3">
              <div className="footerPayments d-flex justify-content-center d-flex align-items-center">
                <span className="d-none d-lg-block extra-small pe-3 "> {t('footer.paymentsMethod')}</span>
                <PaymentIcons gap={3} justifyContent="between" />
              </div>
            </div>
            <div className="col-lg-7 p-0 px-lg-2">
              <div className="footerCopyright py-lg-2">
                <div className="d-none d-lg-block mt-1 mb-1 lh-1">
                  {footerBottomMenu && <ListAFromMenu menu={footerBottomMenu} textUppercase />}
                </div>
                {footerContent?.body.copyright && (
                  <div className="extra-small">{b2x.formatHtml(footerContent.body.copyright)}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
