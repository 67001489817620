import { useField } from 'formik';

import { ResourceKeys, t } from '../i18n/i18n';
import { renderUI } from '../util';
import { useInputGroupContextStrict } from './InputGroupContext';

export interface FeedbackProps {
  fromInputGroup?: boolean;
  name: string;
}

export const Feedback = ({ fromInputGroup, name }: FeedbackProps) => {
  const useInputGroupContext = useInputGroupContextStrict();

  const isInsideInputGroupContext = useInputGroupContext !== undefined;

  const [, meta] = useField(name);
  const error =
    typeof meta.error === 'object' ? (meta.error as { key: string; params: Record<string, unknown> }) : undefined;

  const message = error !== undefined ? t(`yup.${error.key}` as ResourceKeys, error.params) : meta.error;
  return renderUI({
    bs5: (
      <>
        {(!isInsideInputGroupContext || fromInputGroup) && message && <div className="invalid-feedback">{message}</div>}
      </>
    ),
  });
};
