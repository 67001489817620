import { ProductApiDto } from '@b2x/storefront-api-js-client/src';
import { ProductPopulate } from '@b2x/storefront-api-js-client/src/products';
import React from 'react';

import { Col } from './Col';
import { EqualHeight } from './EqualHeight';
import { EqualHeightContextInterface } from './EqualHeightContext';
import { Breakpoint } from './interfaces';
import { ProductTile } from './ProductTile';
import { Row, RowProps } from './Row';
import { useBreakpoint } from './useBreakpoint';
import { useWindowSize } from './useWindowSize';
import { useStable } from './util';

type ProductsPerRow = 1 | 2 | 3 | 4;

export interface ProductsTilesProps extends RowProps {
  enableAddToCart?: boolean;
  enableExtraInfo?: boolean;
  enableHover?: boolean;
  populate?: ProductPopulate;
  products: Array<{
    preselectedSkuId?: string;
    product: ProductApiDto;
  }>;
  productsPerRow: Partial<Record<Breakpoint, ProductsPerRow>>;
}

interface ProductTilesWrapperProps extends ProductsTilesProps {
  equalHeightContext: EqualHeightContextInterface;
  tempProductPerRow: number;
}

const ProductTilesWrapper = ({
  enableAddToCart,

  enableExtraInfo,
  enableHover,
  equalHeightContext,
  populate,
  products,
  productsPerRow,
  tempProductPerRow,
  ...otherProps
}: ProductTilesWrapperProps) => {
  const windowSize = useWindowSize();
  const windowHeight = windowSize.height;
  const windowWidth = windowSize.width;

  const equalHeightStable = useStable(equalHeightContext);

  React.useEffect(() => {
    equalHeightStable.updateTrigger();
  }, [tempProductPerRow, equalHeightStable, windowWidth, windowHeight]);

  return (
    <Row cols={productsPerRow} {...otherProps}>
      {products.map(({ preselectedSkuId, product }, index) => (
        <Col key={product.id}>
          <ProductTile
            enableAddToCart={enableAddToCart}
            enableExtraInfo={!product.giftCard && enableExtraInfo}
            enableHover={!product.giftCard && enableHover}
            populate={populate}
            preselectedSkuId={preselectedSkuId}
            product={product}
            productsPerRow={Math.floor((index + 0) / tempProductPerRow)}
          />
        </Col>
      ))}
    </Row>
  );
};

export const ProductsTiles = ({ productsPerRow, ...otherProps }: ProductsTilesProps) => {
  const breakpoint = useBreakpoint();
  let tempProductPerRow = 3;

  if (breakpoint === 'xs') {
    tempProductPerRow = productsPerRow.xs ? productsPerRow.xs : tempProductPerRow;
  }
  if (breakpoint === 'sm') {
    tempProductPerRow = productsPerRow.sm ? productsPerRow.sm : tempProductPerRow;
  }
  if (breakpoint === 'md') {
    tempProductPerRow = productsPerRow.md ? productsPerRow.md : tempProductPerRow;
  }
  if (breakpoint === 'lg') {
    tempProductPerRow = productsPerRow.lg ? productsPerRow.lg : tempProductPerRow;
  }
  if (breakpoint === 'xl') {
    tempProductPerRow = productsPerRow.xl ? productsPerRow.xl : tempProductPerRow;
  }
  if (breakpoint === 'xxl') {
    tempProductPerRow = productsPerRow.xxl ? productsPerRow.xxl : tempProductPerRow;
  }

  return (
    <EqualHeight timeout={50}>
      {(equalHeightContext) => (
        <ProductTilesWrapper
          equalHeightContext={equalHeightContext}
          productsPerRow={productsPerRow}
          tempProductPerRow={tempProductPerRow}
          {...otherProps}
        />
      )}
    </EqualHeight>
  );
};
