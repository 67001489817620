import classnames from 'classnames';

import { appConfig } from './config';
import { Icon } from './Icon';
import { usePageWithSubpageContextStrict } from './PageWithSubpageContext';
import { PageWithSubpageHeaderProps } from './PageWithSubpageHeader';
import { Link } from './router/Link';
import { renderUI } from './util';

export const PageWithSubpageHeaderA = (props: PageWithSubpageHeaderProps) => {
  const { mobileBackLink, pageSubtitle, pageTitle, showMobileBackLink, showPageTitle, showSubpageTitle, subpageTitle } =
    { ...usePageWithSubpageContextStrict(), ...props };

  return renderUI({
    bs5: (
      <div className="container-xxl">
        <div className="row justify-content-center">
          <div className="col-12 px-0 px-lg-3">
            <div className="PageWithSubpageHeader text-center">
              {/* MOBILE - PageTitle & PageSubtitle */}
              <div className="d-lg-none">
                {showMobileBackLink ? (
                  <>
                    <Link
                      {...mobileBackLink}
                      className="text-reset text-decoration-none d-flex align-items-center p-3"
                      to={mobileBackLink?.to ?? '/'}
                    >
                      {appConfig.icons?.arrow?.left && <Icon name={appConfig.icons.arrow.left} size={14} />}
                      <p className="mb-0 ps-3">{mobileBackLink?.children ?? <>&nbsp;</>}</p>
                    </Link>
                  </>
                ) : showPageTitle ? (
                  <div className="p-3">
                    <h1 className={classnames('h3 m-0 title')}>{pageTitle ?? <>&nbsp;</>}</h1>
                    {pageSubtitle && <p className="mt-1 mb-0 subtitle">{pageSubtitle}</p>}
                  </div>
                ) : (
                  <div className="mb-3"></div>
                )}
              </div>
              {/* DESKTOP - PageTitle & PageSubtitle */}
              <div className="d-none d-lg-block">
                <div className={classnames('p-3 py-lg-5')}>
                  <h2 className={classnames('h3 mb-2 title')}>{pageTitle ?? <>&nbsp;</>}</h2>
                  {pageSubtitle && <p className="mb-0 subtitle">{pageSubtitle}</p>}
                </div>
              </div>
              {/* SubpageTitle */}
              {showSubpageTitle && (
                <div className="subpage-title-container border-top">
                  <h1 className="fw-bold py-3 py-lg-4 mb-0 subpage-title h6">{subpageTitle ?? <>&nbsp;</>}</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ),
  });
};
