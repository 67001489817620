/**
 * Funzioni interne
 */

// ANTONIO
declare global {
  interface Window {
    REACT_APP_API_BASE_URL: string;
    REACT_APP_API_FROM_BE: string;
    REACT_APP_API_KEY: string;
  }
}

const getItem = (key: string): string | undefined => {
  // eslint-disable-next-line node/no-process-env
  const item = process.env[key];
  return item;
};

const getItemNotNull = (key: string): string => {
  // eslint-disable-next-line node/no-process-env
  const item = getItem(key);
  if (item === undefined) throw new Error(`Missing env variable: ${key}`);
  return item;
};

const getItems = (key: string): Array<string> | undefined => {
  const item = getItem(key);
  return item ? item.split(' ') : undefined;
};

const getItemsNotNull = (key: string): Array<string> => {
  return getItemNotNull(key).split(' ');
};

/**
 * String
 */

export const getString = (key: string) => {
  return getItem(key);
};
export const getStringNotNull = (key: string) => {
  return getItemNotNull(key);
};

export const getStrings = (key: string) => {
  return getItems(key);
};
export const getStringsNotNull = (key: string) => {
  return getItemsNotNull(key);
};

/**
 * Number
 */

export const getNumber = (key: string) => {
  const value = getItem(key);
  return value ? parseInt(value) : undefined;
};
export const getNumberNotNull = (key: string) => {
  const value = getItemNotNull(key);
  return parseInt(value);
};

export const getNumbers = (key: string) => {
  const values = getItems(key);
  return values ? values.map((value) => parseInt(value)) : undefined;
};
export const getNumbersNotNull = (key: string) => {
  const values = getItemsNotNull(key);
  return values.map((value) => parseInt(value));
};

/**
 * Enum
 */

export const getEnum = <T extends string>(key: string) => {
  const value = getItem(key);
  return value ? (value as T) : undefined;
};
export const getEnumNotNull = <T extends string>(key: string) => {
  const value = getItemNotNull(key);
  return value as T;
};
export const getEnumWithFallback = <T extends string>(key: string, fallback: T) => {
  const value = getItem(key);
  return value ? (value as T) : fallback;
};

export const getEnums = <T extends string>(key: string) => {
  const values = getItems(key);
  return values ? values.map((value) => value as T) : undefined;
};
export const getEnumsNotNull = <T extends string>(key: string) => {
  const values = getItemsNotNull(key);
  return values.map((value) => value as T);
};

/**
 * Boolean
 */

export const getBoolean = (key: string) => {
  const value = getItem(key);
  return value ? JSON.parse(value) : undefined;
};
export const getBooleanNotNull = (key: string) => {
  const value = getItemNotNull(key);
  return JSON.parse(value);
};

export const getBooleans = (key: string) => {
  const values = getItems(key);
  return values ? values.map((value) => JSON.parse(value)) : undefined;
};
export const getBooleansNotNull = (key: string) => {
  const values = getItemsNotNull(key);
  return values.map((value) => JSON.parse(value));
};

/**
 * Da qui iniziano i metodi dedicati da esportare
 */

let apiUrl = getStringNotNull('REACT_APP_API_BASE_URL');
if (window.REACT_APP_API_FROM_BE === 'true') {
  apiUrl = window.REACT_APP_API_BASE_URL;
}

export const env = {
  NODE_ENV: getEnumNotNull<'development' | 'test' | 'production'>('NODE_ENV'),
  PUBLIC_URL: getStringNotNull('PUBLIC_URL'),
  REACT_APP_API_BASE_URL: apiUrl,
  REACT_APP_API_PROXY: getEnum<'LOCAL' | 'DEVELOPMENT' | 'TEST' | 'PRODUCTION'>('REACT_APP_API_PROXY'),
  REACT_APP_B2X_ENV: getEnumWithFallback<'LOCAL' | 'DEVELOPMENT' | 'TEST' | 'PRODUCTION'>('REACT_APP_B2X_ENV', 'LOCAL'),
  REACT_APP_BCOM_BASE_URL: getStringNotNull('REACT_APP_BCOM_BASE_URL'),
  REACT_APP_FACEBOOK_PIXEL_ID: getString('REACT_APP_FACEBOOK_PIXEL_ID'),
  REACT_APP_GA4_ID: getString('REACT_APP_GA4_ID'),
  REACT_APP_GA4_QUERY_PARAMS: getString('REACT_APP_GA4_QUERY_PARAMS'),
  REACT_APP_GOOGLE_ADS_ID: getString('REACT_APP_GOOGLE_ADS_ID'),
  REACT_APP_GOOGLE_ADS_QUERY_PARAMS: getString('REACT_APP_GOOGLE_ADS_QUERY_PARAMS'),
  REACT_APP_GTM_ID: getString('REACT_APP_GTM_ID'),
  REACT_APP_GTM_QUERY_PARAMS: getString('REACT_APP_GTM_QUERY_PARAMS'),
  REACT_APP_SIMULATE_CART_CONSTRAINTS_VIOLATION: getBoolean('REACT_APP_SIMULATE_CART_CONSTRAINTS_VIOLATION'),
  getMgnl: () => ({
    REACT_APP_MGNL_API_PAGES: getStringNotNull('REACT_APP_MGNL_API_PAGES'),
    REACT_APP_MGNL_API_TEMPLATES: getStringNotNull('REACT_APP_MGNL_API_TEMPLATES'),
    REACT_APP_MGNL_APP_BASE: getStringNotNull('REACT_APP_MGNL_APP_BASE'),
    REACT_APP_MGNL_BASE_AUTHOR: getStringNotNull('REACT_APP_MGNL_BASE_AUTHOR'),
    REACT_APP_MGNL_BASE_DEV: getStringNotNull('REACT_APP_MGNL_BASE_DEV'),
    REACT_APP_MGNL_BASE_PUBLIC: getStringNotNull('REACT_APP_MGNL_BASE_PUBLIC'),
    REACT_APP_MGNL_HOST: getStringNotNull('REACT_APP_MGNL_HOST'),
    // REACT_APP_MGNL_LANGUAGES: getStringsNotNull('REACT_APP_MGNL_LANGUAGES'),
  }),
};
