import React from 'react';

export interface ConditionalWrapperProps {
  children?: React.ReactNode;
  condition: boolean;
  wrapper: React.ReactElement;
}

export const ConditionalWrapper = ({ children, condition, wrapper }: ConditionalWrapperProps) => {
  return (
    <>{condition ? React.cloneElement<{ children?: React.ReactNode }>(wrapper, { children: children }) : children}</>
  );
};
