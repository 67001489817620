import React from 'react';

import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ModalProps } from './Modal';
import { useStaticModal } from './useModal';

interface ExampleModalProps extends ModalProps {
  stringToAlert: string;
}

const ExampleModal = ({ stringToAlert, ...modalProps }: ExampleModalProps) => {
  const handleButtonClick = React.useCallback(() => {
    alert(stringToAlert);
  }, [stringToAlert]);

  return (
    <Modal {...modalProps}>
      <ModalHeader title="Title" />
      <ModalBody>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </p>
        <p>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </ModalBody>
      <ModalFooter>
        <ModalButton close label="Close" />
        <ModalButton label="Alert" onClick={handleButtonClick} />
      </ModalFooter>
    </Modal>
  );
};

export const useExampleModal = (props: ExampleModalProps) => useStaticModal(ExampleModal, 'modal', props);
