import { ContactUs } from '../../../ContactUs';
import { ServiceSubpageContentType } from '../../../contentTypes';
import { Div } from '../../../HTMLElement';
import { formatHtml } from '../../../util';
import { ServiceContactsSubpageProps } from '../ServiceContactsSubpage';
import { ServiceSubpage } from '../ServiceSubpage';

export const ServiceContactsSubpageA = ({ contactUs }: ServiceContactsSubpageProps) => {
  return (
    <ServiceSubpage<ServiceSubpageContentType>>
      {(page) => (
        <>
          <Div marginBottom={4}>{formatHtml(page.content?.body.content)}</Div>
          <ContactUs {...contactUs} />
        </>
      )}
    </ServiceSubpage>
  );
};
