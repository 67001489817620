import { FilterApiDto, SearchProductsApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers, FormikProps } from 'formik';
import qs from 'qs';
import React from 'react';
import * as yup from 'yup';

import { useAppContext, useAppStaticContext } from '../AppContext';
import { Button } from '../Button';
import { appConfig } from '../config';
import { CustomRadioCheck } from '../CustomRadioCheck';
import { t } from '../i18n/i18n';
import { useNavigate, useSearchParams } from '../router/router';
import { storage } from '../storage';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { arrayIncludesItems, isValidHexColor } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface SearchFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  basePath?: string;
  scrollTopOnFiltersChange?: boolean;
  searchResult?: SearchProductsApiDto;
  submitOnChange?: boolean;
}

interface FormValues {
  brands: Array<formikString>;
  categories: Array<formikString>;
  facets: Array<formikString>;
  internals: Array<formikString>;
  simpleSearch: formikString;
  warehouses: Array<formikString>;
}

export type SearchFormValues = FormValues;

type ValidationSchema = {
  brands: yup.ArraySchema<yup.StringSchema>;
  categories: yup.ArraySchema<yup.StringSchema>;
  facets: yup.ArraySchema<yup.StringSchema>;
  simpleSearch: yup.StringSchema;
  warehouses: yup.ArraySchema<yup.StringSchema>;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  activeFilters: Array<SearchActiveFilter>;
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  filters: Array<SearchFilter>;
  simpleSearch: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export type SearchFieldsHelper = FieldsHelper;

export interface SearchFilter {
  checkbox: CheckboxProps;
  children: Array<SearchFilter>;
  filter: Omit<FilterApiDto, 'children'>;
}

export interface SearchActiveFilter {
  filter: Omit<FilterApiDto, 'children'>;
  fromSimpleSearch: boolean;
  handleClick(): void;
}

const Filters = ({ filters }: { filters: Array<SearchFilter> }) => {
  return (
    <>
      {filters.length > 0 && (
        <ul>
          {filters.map((filter) => (
            <li key={filter.filter.id}>
              <Checkbox
                {...filter.checkbox}
                label={`${filter.checkbox.label} (${filter.filter.hits}/${filter.filter.total} )`}
              />
              {<Filters filters={filter.children} />}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export const SearchFormHelper = ({
  basePath = '/search',
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  scrollTopOnFiltersChange = true,
  searchResult,
  submitOnChange = false,
  ...otherProps
}: SearchFormProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mainCategory } = useAppContext();
  const { setMainCategory } = useAppStaticContext();

  // Al cambiare della mainCategory, aggiorno lo storage.
  React.useEffect(() => {
    if (appConfig.hasMainCategories) {
      if (mainCategory !== undefined) {
        storage.setObject('mainCategory', mainCategory);
      } else {
        storage.removeItem('mainCategory');
      }
    }
  }, [mainCategory]);

  // Provo a dedurre la mainCategory.
  React.useEffect(() => {
    if (appConfig.hasMainCategories) {
      // if (b2x.isFriendlySearch(location)) {
      const categoriesFilter = searchResult?.filters?.find((filter) => filter.code === 'CATEGORIE');
      const checkedOrchildCheckedMainCategories = categoriesFilter?.children?.filter(
        (item) => item.checkType === 'CHILD_CHECKED' || item.checkType === 'CHECKED'
      );
      if (checkedOrchildCheckedMainCategories && checkedOrchildCheckedMainCategories.length === 1) {
        setMainCategory(checkedOrchildCheckedMainCategories[0]);
      }
      // }
    }
  }, [searchResult?.filters, setMainCategory]);

  const activeFilters = React.useMemo<Array<FilterApiDto>>(() => {
    const f = (filters?: Array<FilterApiDto>, depth = 0) => {
      if (filters) {
        return filters.reduce<Array<FilterApiDto>>((previousValue, currentValue) => {
          if ((depth > 0 || currentValue.code !== 'FC_SEARCH') && currentValue.checkType === 'CHECKED') {
            previousValue.push(currentValue);
          }
          const x: Array<FilterApiDto> = f(currentValue.children, depth + 1);
          if (x.length > 0) {
            previousValue.push(...x);
          }
          return previousValue;
        }, []);
      } else {
        return [];
      }
    };
    return f(searchResult?.filters);
  }, [searchResult?.filters]);

  const _initialValues = React.useMemo<FormValues>(() => {
    return {
      brands: activeFilters
        .filter((activeFilter) => activeFilter.type === 'BRAND')
        .map((activeFilter) => activeFilter.id),
      categories: activeFilters
        .filter((activeFilter) => activeFilter.type === 'CATEGORY')
        .map((activeFilter) => activeFilter.id),
      facets: activeFilters
        .filter((activeFilter) => activeFilter.type === 'FACET')
        .map((activeFilter) => activeFilter.id),
      internals: searchResult?.internalFilters ?? [],
      simpleSearch: getInitialString(searchParams.get('simpleSearch') ?? undefined),
      warehouses: activeFilters
        .filter((activeFilter) => activeFilter.type === 'WAREHOUSE')
        .map((activeFilter) => activeFilter.id),
      ...initialValues,
    };
  }, [activeFilters, initialValues, searchParams, searchResult?.internalFilters]);

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      brands: yup.array(),
      categories: yup.array(),
      facets: yup.array(),
      simpleSearch: yup.string(),
      warehouses: yup.array(),
    }),
    []
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const fixedValues = {
        ...values,
        brands: values.brands,
        categories: values.categories,
        facets: values.facets,
        internals: values.internals,
        pageSize: storage.getNumber('searchPageSize'),
        warehouses: values.warehouses,
      };
      navigate(
        // Per ora commento questa riga. Quando atterro in una directory di tipo LISTING, se cambio filtri esco dal listing e vado in ricerca standard.
        // `${routeInfo?.type !== 'catalog' ? routeInfo?.pathname : basePath}?${qs.stringify(fixedValues, {
        `${basePath}?${qs.stringify(fixedValues, {
          indices: false,
          skipNulls: true,
        })}`,
        {
          scrollTop: scrollTopOnFiltersChange,
        }
      );
      onSuccess && onSuccess();
      return Promise.resolve();
    },
    [navigate, onSuccess, basePath, scrollTopOnFiltersChange]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('SearchForm', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      submitOnChange={false}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          activeFilters: buildActiveFilters(activeFilters, searchParams, formik, setMainCategory, mainCategory),
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: 'Reset',
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: 'Submit',
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          filters: buildFilters(formik, submitOnChange, setMainCategory, mainCategory, searchResult?.filters),
          simpleSearch: {
            formGroup: { label: 'Simple search', names: ['simpleSearch'] },
            textInput: { name: 'simpleSearch', placeholder: 'Simple search' },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.simpleSearch.formGroup}>
              <TextInput {...fieldsHelper.simpleSearch.textInput} />
            </FormGroup>
            <Filters filters={fieldsHelper.filters} />
            <div className="mb-3">
              {fieldsHelper.activeFilters.map((activeFilter) => (
                <Button
                  className="me-2"
                  key={activeFilter.filter.id}
                  label={activeFilter.filter.name}
                  onClick={activeFilter.handleClick}
                />
              ))}
            </div>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

const buildFilters = (
  formik: FormikProps<FormValues>,
  submitOnChange: boolean,
  setMainCategory: React.Dispatch<React.SetStateAction<FilterApiDto | undefined>>,
  mainCategory?: FilterApiDto,
  filters?: Array<FilterApiDto>
): Array<SearchFilter> =>
  filters
    ? filters
        .filter((filter) => filter.depth > 0 || filter.code !== 'FC_SEARCH')
        .map((filter) => {
          const { children, ...otherFilterProps } = filter;
          const colorFromTag = filter.tags?.find((tag) => tag.includes('COLOR_'))?.substring(6, 13);

          return {
            checkbox: {
              // FIX ME: Da migliorare rimuovere il controllo sulla categoria ed effettuare un controllo sui tag del filtro (qualora fossero gratuiti nella chiamata)
              children:
                (filter.image || (colorFromTag && isValidHexColor(colorFromTag))) && filter.type !== 'CATEGORY' ? (
                  <CustomRadioCheck color={colorFromTag} image={filter.image} label={filter.name} />
                ) : undefined,
              id: filter.code,
              inhibitFormikOnChange: true,
              label: filter.name,
              name:
                filter.type === 'CATEGORY'
                  ? 'categories'
                  : filter.type === 'BRAND'
                  ? 'brands'
                  : filter.type === 'WAREHOUSE'
                  ? 'warehouses'
                  : filter.type === 'FACET'
                  ? 'facets'
                  : '',
              onChange: () => {
                // se è di tipo CATEGORY e non è un fratello, resetto tutto
                if (
                  filter.type === 'CATEGORY' &&
                  !arrayIncludesItems<string>(
                    filters.map((sibling) => sibling.id),
                    formik.values.categories
                  )
                ) {
                  formik.setFieldValue('categories', [filter.id]);
                } else {
                  if (filter.type === 'CATEGORY') {
                    // Se non c'era lo aggiungo
                    if (!formik.values.categories.includes(filter.id)) {
                      formik.setFieldValue('categories', formik.values.categories.concat(filter.id));
                    } else {
                      // Se no lo levo
                      formik.setFieldValue(
                        'categories',
                        formik.values.categories.filter((id) => id !== filter.id)
                      );
                    }
                  } else if (filter.type === 'BRAND') {
                    // Se non c'era lo aggiungo
                    if (!formik.values.brands.includes(filter.id)) {
                      formik.setFieldValue('brands', formik.values.brands.concat(filter.id));
                    } else {
                      // Se no lo levo
                      formik.setFieldValue(
                        'brands',
                        formik.values.brands.filter((id) => id !== filter.id)
                      );
                    }
                  } else if (filter.type === 'WAREHOUSE') {
                    // Se non c'era lo aggiungo
                    if (!formik.values.warehouses.includes(filter.id)) {
                      formik.setFieldValue('warehouses', formik.values.warehouses.concat(filter.id));
                    } else {
                      // Se no lo levo
                      formik.setFieldValue(
                        'warehouses',
                        formik.values.warehouses.filter((id) => id !== filter.id)
                      );
                    }
                  } else if (filter.type === 'FACET') {
                    // Se non c'era lo aggiungo
                    if (!formik.values.facets.includes(filter.id)) {
                      formik.setFieldValue('facets', formik.values.facets.concat(filter.id));
                    } else {
                      // Se no lo levo
                      formik.setFieldValue(
                        'facets',
                        formik.values.facets.filter((id) => id !== filter.id)
                      );
                    }
                  }
                }
                // Se devo gestire le mainCategories e ne clicco una, la salvo nel contesto
                if (appConfig.hasMainCategories && filter.type === 'CATEGORY' && filter.depth === 1) {
                  setMainCategory(filter);
                }
                if (submitOnChange) {
                  formik.submitForm();
                }
              },
              value: filter.id,
            },
            children:
              // Se devo gestire le mainCategories e ne ho già impostata una, faccio vedere il suo sottoalbero.
              appConfig.hasMainCategories &&
              mainCategory !== undefined &&
              filter.type === 'CATEGORY' &&
              filter.depth === 0
                ? buildFilters(
                    formik,
                    submitOnChange,
                    setMainCategory,
                    mainCategory,
                    children?.find((mainCategoryFilter) => mainCategoryFilter.id === mainCategory.id)?.children
                  )
                : // Se devo gestire le mainCategories, nei filtri per categoria mi fermo al primo livello, per far scegliere al cliente la mainCategory.
                appConfig.hasMainCategories &&
                  mainCategory === undefined &&
                  filter.type === 'CATEGORY' &&
                  filter.depth === 1
                ? []
                : buildFilters(formik, submitOnChange, setMainCategory, mainCategory, children),
            filter: { ...otherFilterProps },
          };
        })
    : [];

const buildActiveFilters = (
  activeFilters: Array<FilterApiDto>,
  searchParams: URLSearchParams,
  formik: FormikProps<FormValues>,
  setMainCategory: React.Dispatch<React.SetStateAction<FilterApiDto | undefined>>,
  mainCategory?: FilterApiDto
): Array<SearchActiveFilter> => {
  const result: Array<SearchActiveFilter> = [];
  const simpleSearch: string | undefined = searchParams.get('simpleSearch') ?? undefined;

  if (simpleSearch) {
    result.push({
      filter: {
        checkType: 'CHECKED',
        code: '',
        depth: 0,
        id: '0',
        name: simpleSearch,
      },
      fromSimpleSearch: true,
      handleClick: () => {
        formik.setFieldValue('simpleSearch', '');
        formik.submitForm();
      },
    });
  }

  if (appConfig.hasMainCategories && mainCategory !== undefined) {
    // Metto il tasto "Maincategory" che se cliccato leva tutto
    result.push({
      filter: {
        checkType: 'CHECKED',
        code: '',
        depth: 0,
        id: '0',
        name: mainCategory.name,
      },
      fromSimpleSearch: false,
      handleClick: () => {
        setMainCategory(undefined);
        formik.setFieldValue('categories', []);
        formik.setFieldValue('brands', []);
        formik.setFieldValue('warehouses', []);
        formik.setFieldValue('facets', []);
        formik.submitForm();
      },
    });
  }

  result.push(
    ...activeFilters
      .filter(
        (activeFilter) =>
          appConfig.hasMainCategories === false || (appConfig.hasMainCategories && activeFilter.id !== mainCategory?.id)
      )
      .map((activeFilter) => ({
        filter: { ...activeFilter, children: undefined },
        fromSimpleSearch: false,
        handleClick: () => {
          if (activeFilter.type === 'CATEGORY') {
            formik.setFieldValue(
              'categories',
              formik.values.categories.filter((filter) => filter !== activeFilter.id)
            );
          } else if (activeFilter.type === 'BRAND') {
            formik.setFieldValue(
              'brands',
              formik.values.brands.filter((filter) => filter !== activeFilter.id)
            );
          } else if (activeFilter.type === 'WAREHOUSE') {
            formik.setFieldValue(
              'warehouses',
              formik.values.warehouses.filter((filter) => filter !== activeFilter.id)
            );
          } else if (activeFilter.type === 'FACET') {
            formik.setFieldValue(
              'facets',
              formik.values.facets.filter((filter) => filter !== activeFilter.id)
            );
          }
          formik.submitForm();
        },
      }))
  );

  if (result.length >= 2) {
    result.push({
      filter: {
        checkType: 'CHECKED',
        code: '',
        depth: 0,
        id: '0',
        name: t('misc.removeFilters'),
      },
      fromSimpleSearch: false,
      handleClick: () => {
        setMainCategory(undefined);
        formik.setFieldValue('categories', []);
        formik.setFieldValue('brands', []);
        formik.setFieldValue('warehouses', []);
        formik.setFieldValue('facets', []);
        formik.setFieldValue('simpleSearch', '');
        formik.submitForm();
      },
    });
  }
  return result;
};

export type SearchFormVariants = '';

const SearchFormController = (props: PropsWithCustomComponentWithoutChildren<SearchFormProps>) => (
  <VariantsController<SearchFormProps, SearchFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: SearchFormHelper,
      name: 'SearchForm',
    }}
  />
);
export { SearchFormController as SearchForm };
