import { MarkerClusterer, MarkerClustererOptions, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import React from 'react';
import { flushSync } from 'react-dom';
import ReactDOM from 'react-dom/client';

import { createUUID } from './util';

export interface GoogleMapProps extends Omit<google.maps.MapOptions, 'mapId'> {
  className?: string;
  markerClusterer?:
    | boolean
    | (Omit<MarkerClustererOptions, 'markers' | 'map' | 'algorithm' | 'algorithmOptions'> & {
        algorithmOptions?: {
          extent?: number;
          generateId?: boolean;
          log?: boolean;
          maxZoom?: number;
          minPoints?: number;
          minZoom?: number;
          nodeSize?: number;
          radius?: number;
        };
      });
  markers?: Array<
    Omit<google.maps.marker.AdvancedMarkerElementOptions, 'map' | 'content'> & {
      content?: {
        element?: React.ReactElement;
        image?: {
          src: string;
          width?: number;
        };
        pin?: Omit<google.maps.marker.PinElementOptions, 'glyph'> & {
          glyph?: {
            element?: React.ReactElement;
            image?: string;
          };
        };
      };
    }
  >;
  style?: React.CSSProperties;
}

export const GoogleMap = ({ className, markerClusterer, markers, style, ...otherProps }: GoogleMapProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const id = React.useMemo(() => createUUID(), []);
  const initialized = React.useRef<boolean>(false);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [clusterer, setClusterer] = React.useState<MarkerClusterer>();

  const init = React.useCallback(async () => {
    console.log('Map: init');
    if (ref.current) {
      console.log('Map: init inside if');
      const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
      const _map = new Map(ref.current, { ...otherProps, mapId: id });
      setMap(_map);
      setClusterer(
        new MarkerClusterer({
          ...(typeof markerClusterer === 'object' ? markerClusterer : {}),
          algorithm: new SuperClusterAlgorithm(
            typeof markerClusterer === 'object' ? markerClusterer.algorithmOptions : undefined
          ),
          map: _map,
        })
      );
    }
  }, [id, markerClusterer, otherProps]);

  const update = React.useCallback(async () => {
    console.log('Map: update');
    if (map) {
      console.log('Map: update inside if');
      const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;

      const _markers = markers?.map((marker) => {
        let content: google.maps.marker.AdvancedMarkerElementOptions['content'];
        if (marker.content !== undefined) {
          if (marker.content.image) {
            const img = document.createElement('img');
            img.src = marker.content.image.src;
            if (marker.content.image.width) {
              img.width = marker.content.image.width;
            }
            content = img;
          } else if (marker.content.pin) {
            let glyph: google.maps.marker.PinElementOptions['glyph'];
            if (marker.content.pin.glyph !== undefined) {
              if (marker.content.pin.glyph.image) {
                const glyphImg = document.createElement('img');
                glyphImg.src = marker.content.pin.glyph.image;
                glyph = glyphImg;
              } else if (marker.content.pin.glyph.element) {
                const wrapper = document.createElement('div');
                const root = ReactDOM.createRoot(wrapper);
                flushSync(() => {
                  root.render(marker.content?.pin?.glyph?.element);
                });
                glyph = wrapper.firstElementChild;
              }
            }
            const glyphSvgPinElement = new PinElement({
              ...marker.content.pin,
              glyph: glyph,
            });
            content = glyphSvgPinElement.element;
          } else if (marker.content.element) {
            const wrapper = document.createElement('div');
            const root = ReactDOM.createRoot(wrapper);
            flushSync(() => {
              root.render(marker.content?.element);
            });
            content = wrapper.firstElementChild;
          }
        }

        const _marker = new AdvancedMarkerElement({
          ...marker,
          content: content,
          map: markerClusterer === undefined || markerClusterer === false ? map : undefined,
        });
        return _marker;
      });

      if (markerClusterer && _markers) {
        clusterer?.clearMarkers();
        clusterer?.addMarkers(_markers);
      }
    }
  }, [clusterer, map, markerClusterer, markers]);

  React.useEffect(() => {
    if (!initialized.current) {
      init();
      initialized.current = true;
    }
  }, [init]);

  React.useEffect(() => {
    update();
  }, [update]);

  return <div className={className} ref={ref} style={style} />;
};
