import { WishlistApiDto } from '@b2x/storefront-api-js-client/src';
import { GetWishlistOptions } from '@b2x/storefront-api-js-client/src/customer';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useCustomerApi } from './api/useCustomerApi';
import { useAppContext } from './AppContext';
import { useStable } from './util';

export interface UseWishlistOptions extends GetWishlistOptions {}

export const useWishlist = (options?: UseWishlistOptions, config?: ApiRequestConfig) => {
  const [wishlist, setWishlist] = React.useState<WishlistApiDto>();

  const { session } = useAppContext();
  const { getWishlist } = useCustomerApi();

  options = useStable(options);
  config = useStable(config);

  // Prendo la wishlist al primo caricamento e ogni volta che cambia quella in sessione, per garantirne la coerenza.
  React.useEffect(() => {
    getWishlist(options, config).then((response) => {
      setWishlist(response.data);
    });
  }, [config, getWishlist, options, session?.wishlist]);

  return wishlist;
};
