import React from 'react';

import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { cookieConsents } from './cookieConsents';
import { addScript } from './util';

// export interface UseCookiebotInitializerProps {}

export const useCookiebotInitializer = () => {
  const { locale } = useAppContext();

  const handleCookiebotConsentReady = React.useCallback(() => {
    console.log('CookiebotOnConsentReady', Cookiebot);
    cookieConsents.set({
      consents: {
        marketing: Cookiebot.consent.marketing,
        necessary: Cookiebot.consent.necessary,
        preferences: Cookiebot.consent.preferences,
        statistics: Cookiebot.consent.statistics,
      },
      provider: 'Cookiebot',
      regulations: {
        ccpa: Cookiebot.regulations.ccpaApplies,
        gdpr: Cookiebot.regulations.gdprApplies,
        lgpd: Cookiebot.regulations.lgpdApplies,
      },
    });
  }, []);
  const handleCookiebotLoad = React.useCallback(() => {
    console.log('CookiebotOnLoad', Cookiebot);
  }, []);
  const handleCookiebotAccept = React.useCallback(() => {
    console.log('CookiebotOnAccept', Cookiebot);
  }, []);
  const handleCookiebotDecline = React.useCallback(() => {
    console.log('CookiebotOnDecline', Cookiebot);
  }, []);
  const handleCookiebotDialogInit = React.useCallback(() => {
    console.log('CookiebotOnDialogInit', Cookiebot);
  }, []);
  const handleCookiebotDialogDisplay = React.useCallback(() => {
    console.log('CookiebotOnDialogDisplay', Cookiebot);
  }, []);
  const handleCookiebotTagsExecuted = React.useCallback(() => {
    console.log('CookiebotOnTagsExecuted', Cookiebot);
  }, []);

  React.useEffect(() => {
    if (appConfig.cookiebot && locale?.languageCode) {
      addScript({
        dataAttributes: {
          // commento blockingmode finchè non capisco come risolvere il problema delle immagini che non chiamano la onLoad (rimanendo i placeholder)
          // blockingmode: 'auto',
          cbid: appConfig.cookiebot.id,
          consentmode: appConfig.consentMode ? undefined : 'disabled',
          culture: locale.languageCode,
        },
        id: 'cookiebot',
        src: 'https://consent.cookiebot.com/uc.js',
        type: 'text/javascript',
      });

      window.addEventListener('CookiebotOnConsentReady', handleCookiebotConsentReady);
      window.addEventListener('CookiebotOnLoad', handleCookiebotLoad);
      window.addEventListener('CookiebotOnAccept', handleCookiebotAccept);
      window.addEventListener('CookiebotOnDecline', handleCookiebotDecline);
      window.addEventListener('CookiebotOnDialogInit', handleCookiebotDialogInit);
      window.addEventListener('CookiebotOnDialogDisplay', handleCookiebotDialogDisplay);
      window.addEventListener('CookiebotOnTagsExecuted', handleCookiebotTagsExecuted);
      return () => {
        window.removeEventListener('CookiebotOnConsentReady', handleCookiebotConsentReady);
        window.removeEventListener('CookiebotOnLoad', handleCookiebotLoad);
        window.removeEventListener('CookiebotOnAccept', handleCookiebotAccept);
        window.removeEventListener('CookiebotOnDecline', handleCookiebotDecline);
        window.removeEventListener('CookiebotOnDialogInit', handleCookiebotDialogInit);
        window.removeEventListener('CookiebotOnDialogDisplay', handleCookiebotDialogDisplay);
        window.removeEventListener('CookiebotOnTagsExecuted', handleCookiebotTagsExecuted);
      };
    }
  }, [
    handleCookiebotAccept,
    handleCookiebotConsentReady,
    handleCookiebotDecline,
    handleCookiebotDialogDisplay,
    handleCookiebotDialogInit,
    handleCookiebotLoad,
    handleCookiebotTagsExecuted,
    locale?.languageCode,
  ]);
};
