import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountCouponsSubpageA } from './accountSubpagesA/AccountCouponsSubpageA';

export interface AccountCouponsSubpageProps {}

export type AccountCouponsSubpageVariants = 'A';

const AccountCouponsSubpageController = (props: PropsWithCustomComponent<AccountCouponsSubpageProps>) => (
  <VariantsController<AccountCouponsSubpageProps, AccountCouponsSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountCouponsSubpageA },
      name: 'AccountCouponsSubpage',
    }}
  />
);
export { AccountCouponsSubpageController as AccountCouponsSubpage };
