import { FilterApiDto, PageApiDto, queryClient, SearchProductsApiDto } from '@b2x/storefront-api-js-client/src';
import { GetPageOptions } from '@b2x/storefront-api-js-client/src/pages';
import { SearchProductsOptions } from '@b2x/storefront-api-js-client/src/products';
import _ from 'lodash';
import React from 'react';

import { b2x } from '.';
import { ApiRequestConfig } from './api/useApiRequest';
import { usePagesApi } from './api/usePagesApi';
import { useProductsApi } from './api/useProductsApi';
import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { useLocation, useSearchParams } from './router/router';
import { storage } from './storage';
import { useStable } from './util';

export interface UseSearchOptions extends Omit<SearchProductsOptions, 'fixed'> {
  basePath?: string;
  defaultPageSize: number;
  // fixedFilters?: Array<number>;
  pageOptions?: GetPageOptions;
  secondStep?: SearchProductsOptions;
  thingsToLoadBeforeSearch?: Array<unknown>;
  useBestSharedAncestorAlgoritmForPage?: boolean;
}

export const useSearch = <PageContentType,>(options?: UseSearchOptions, config?: ApiRequestConfig) => {
  const [searchResult, setSearchResult] = React.useState<SearchProductsApiDto>();
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [secondStepFetching, setSecondStepFetching] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<PageApiDto<PageContentType>>();

  const { searchProducts } = useProductsApi();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { mainCategory } = useAppContext();
  const { getPage, getPageByPath } = usePagesApi();

  config = useStable(config);

  const {
    basePath = '/search',
    defaultPageSize = 20,
    // fixedFilters,
    populate = { filters: { selector: true }, items: appConfig.api?.productTilePopulate },
    products,
    secondStep,
    thingsToLoadBeforeSearch = [],
    useBestSharedAncestorAlgoritmForPage = true,
  } = options ?? {};

  const queryKey = React.useRef<Array<unknown>>();

  // Faccio partire la search ogni volta che cambia quacosa nella path
  React.useEffect(() => {
    const everythingIsReady = thingsToLoadBeforeSearch.every((thing) => thing !== undefined);
    if (everythingIsReady) {
      const storedPageSize = storage.getNumber('searchPageSize');
      const pageSize = searchParams.get('pageSize') ?? undefined;
      const pageNum = searchParams.get('pageNum') ?? undefined;
      const orderBy = (searchParams.get('orderBy') as b2x.SearchProductOrderApiType | undefined) ?? undefined;
      const orderingType = (searchParams.get('orderingType') as b2x.OrderingApiType | undefined) ?? undefined;

      const searchProductsOptions: SearchProductsOptions = {
        // brands: searchParams.getAll('brands'),
        // categories: [
        //   ...searchParams.getAll('categories'),
        //   // aggiungo la maincategory se è stata scelta e non ho altri filtri impostati
        //   ...(appConfig.hasMainCategories &&
        //   mainCategory &&
        //   searchParams.getAll('categories').length === 0 &&
        //   location.pathname === basePath
        //     ? [mainCategory.id]
        //     : []),
        // ],
        // facets: searchParams.getAll('facets'),
        filters: [
          ...[
            ...searchParams.getAll('categories'),
            // aggiungo la maincategory se è stata scelta e non ho altri filtri impostati
            ...(appConfig.hasMainCategories &&
            mainCategory &&
            searchParams.getAll('categories').length === 0 &&
            location.pathname === basePath
              ? [mainCategory.id]
              : []),
          ],
          ...searchParams.getAll('brands'),
          ...searchParams.getAll('warehouses'),
          ...searchParams.getAll('facets'),
          ...searchParams.getAll('internals'),
          // ...(fixedFilters ? fixedFilters.map((fixedFilter) => fixedFilter.toString()) : []),
        ],
        // fixed: fixedFilters ? fixedFilters.map((fixedFilter) => fixedFilter.toString()) : undefined,
        friendlyUrl: location.pathname !== basePath ? location.pathname : undefined,
        // internals: searchParams.getAll('internals'),
        orderBy: orderBy,
        orderingType: orderingType,
        pageNum: pageNum ? parseInt(pageNum) : undefined,
        pageSize: pageSize ? parseInt(pageSize) : storedPageSize ? storedPageSize : defaultPageSize,
        populate: populate,
        // warehouses: searchParams.getAll('warehouses'),
        products: products ?? searchParams.getAll('products'),
        simpleSearch: searchParams.get('simpleSearch') ?? undefined,
      };

      const newQueryKey = ['searchProducts', searchProductsOptions];
      queryKey.current &&
        !_.isEqual(queryKey.current, newQueryKey) &&
        queryClient.cancelQueries({ queryKey: queryKey.current });
      queryKey.current = newQueryKey;

      setFetching(true);
      searchProducts(
        queryKey.current,
        {
          ...searchProductsOptions,
        },
        config
      )
        .then((response) => {
          setSearchResult(response.data);
          if (secondStep && response.data.items && response.data.items.length > 0) {
            // TODO da rivedere l'abort con il 2° step
            // queryKey.current && queryClient.cancelQueries({ queryKey: queryKey.current });
            queryKey.current = ['searchProducts', { ...searchProductsOptions, ...secondStep }];

            setSecondStepFetching(true);
            searchProducts(queryKey.current, { ...searchProductsOptions, ...secondStep }, { ...config, silent: true })
              .then((secondStepResponse) => {
                setSearchResult((prevState) =>
                  prevState ? { ...prevState, items: secondStepResponse.data.items } : undefined
                );
              })
              .finally(() => {
                setSecondStepFetching(false);
              });
          }
        })
        .finally(() => {
          setFetching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, ...thingsToLoadBeforeSearch]);

  let { pageOptions } = options ?? {};

  pageOptions = useStable(pageOptions);

  // Prendo la pagina di appartenenza per mostrare un'eventuale breadcrumb
  React.useEffect(() => {
    if (searchResult) {
      if (location.pathname !== basePath) {
        getPageByPath<PageContentType>(location.pathname, pageOptions, {
          suppressErrorLog: true,
          suppressErrorModal: true,
        })
          .then((response) => {
            setPage(response.data);
          })
          .catch(() => {
            setPage(undefined);
          });
      } else if (searchParams.getAll('categories').length === 1) {
        const singleCategoryId = searchParams.get('categories');
        singleCategoryId &&
          getPage<PageContentType>(singleCategoryId, pageOptions).then((response) => {
            setPage(response.data);
          });
      } else if (searchParams.getAll('categories').length > 1 && useBestSharedAncestorAlgoritmForPage) {
        const cateroryRootFilter = searchResult.filters?.find((child) => child.type === 'CATEGORY');
        if (cateroryRootFilter) {
          const bestSharedAncestor = getBestSharedAncestor(cateroryRootFilter);
          if (bestSharedAncestor) {
            getPage<PageContentType>(bestSharedAncestor.id, pageOptions).then((response) => {
              setPage(response.data);
            });
          }
        }
      } else {
        setPage(undefined);
      }
    }
  }, [
    basePath,
    getPage,
    getPageByPath,
    location.pathname,
    pageOptions,
    searchParams,
    searchResult,
    useBestSharedAncestorAlgoritmForPage,
  ]);

  const [categoriesListing, setCategoriesListing] = React.useState<Array<b2x.PageApiDto>>();

  React.useEffect(() => {
    if (page?.breadcrumb && page.breadcrumb.length > 1) {
      if (page.children && page.children.length > 0) {
        setCategoriesListing([page, ...page.children]);
      } else {
        const parent = page.breadcrumb[page.breadcrumb.length - 2];

        getPage(parent.id, { populate: { children: true } }).then((response) => {
          const parentChildren = response.data.children;

          if (parentChildren) {
            const indexOfElementToMove = parentChildren.findIndex((el) => el.code === page.code);
            const elementToMove = parentChildren[indexOfElementToMove];

            parentChildren.splice(indexOfElementToMove, 1);
            parentChildren.unshift(elementToMove);

            setCategoriesListing([parent, ...parentChildren]);
          }
        });
      }
    } else {
      setCategoriesListing(undefined);
    }
  }, [getPage, page]);

  return { categoriesListing, fetching, page, searchResult, secondStepFetching };
};

const getBestSharedAncestor = (filter: FilterApiDto): FilterApiDto | undefined => {
  const checkedOrChildCheckedChildren = filter.children?.filter(
    (child) => child.checkType === 'CHECKED' || child.checkType === 'CHILD_CHECKED'
  );
  if (checkedOrChildCheckedChildren !== undefined && checkedOrChildCheckedChildren.length > 1) {
    return filter;
  } else {
    return filter.children?.reduce<FilterApiDto | undefined>((prev, curr) => {
      if (prev === undefined) {
        const bestSharedAncestor = getBestSharedAncestor(curr);
        if (bestSharedAncestor) {
          prev = bestSharedAncestor;
        }
      }
      return prev;
    }, undefined);
  }
};
