import React from 'react';

import { Button } from '../Button';
import { addResource, changeLanguage, language, t } from './i18n';

const id = 'i18nTest';

export const alertTranslation = () => alert(t(id));

export const I18nExample = () => {
  const toggleLanguage = React.useCallback(() => {
    const newLanguage = language === 'en' ? 'it' : 'en';
    changeLanguage(newLanguage);
  }, []);

  const changeTranslation = React.useCallback(() => {
    addResource('it', id, 'Nuovo valore common');
    changeLanguage(language);
  }, []);

  return (
    <div>
      <p>
        {t(id)} (from i18n) ({language})
      </p>
      <p>
        <Button label="Toggle language" onClick={toggleLanguage} />{' '}
        <Button label="Change IT translation" onClick={changeTranslation} />{' '}
        <Button label="Alert translation" onClick={alertTranslation} />
      </p>
    </div>
  );
};
