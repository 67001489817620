import axios, { AxiosRequestHeaders, AxiosResponse, Method, ResponseType } from 'axios';
import qs from 'qs';

import { ApiResponse, queryClient } from '../';
import { ProductPopulate } from '../products';
import { GetSessionOptions } from '../session';

let initialized = false;
let _debug: boolean;

export interface Config {
  apiKey: string;
  baseUrl: string;
  debug?: boolean;
  productPopulate?: ProductPopulate;
  productTilePopulate?: ProductPopulate;
  sessionOptions?: GetSessionOptions;
}

export const init = ({ apiKey, baseUrl, debug = false }: Config): Promise<void> => {
  return new Promise<void>((resolve) => {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.headers.common['API-Key'] = apiKey;
    axios.defaults.headers.common['directoriesAsTheyAre'] = true;
    axios.defaults.headers.common['smartFilters'] = true;
    _debug = debug;
    initialized = true;
    resolve();
  });
};

export const updateHeaders = (headers: { locale?: string; sessionToken?: string; shippingCountry?: string }): void => {
  axios.defaults.headers.common = { ...axios.defaults.headers.common, ...headers };
};

export type Populate<P> = boolean | '*' | P;

// eslint-disable-next-line @typescript-eslint/ban-types
export const buildPopulateQuery = <P extends object | void>(obj?: Populate<P>) => {
  if (obj === undefined) {
    return '';
  }
  return _buildPopulateQuery(obj)
    .map((value) => `populate=${value}`)
    .join('&');
};

// eslint-disable-next-line @typescript-eslint/ban-types
const _buildPopulateQuery = <P extends object | void>(obj: Populate<P>, prefix?: string) => {
  const values: Array<string> = [];

  if (typeof obj === 'string') {
    values.push(obj);
  } else if (typeof obj === 'object') {
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === 'boolean' && value === true) {
        values.push(prefix ? `${prefix}.${key}` : key);
      } else if (typeof value === 'string' && (value === '*' || value === '**')) {
        values.push(prefix ? `${prefix}.${key}.${value}` : `${key}.${value}`);
      } else if (typeof value === 'object') {
        values.push(prefix ? `${prefix}.${key}` : key);
        values.push(..._buildPopulateQuery(value, prefix ? `${prefix}.${key}` : key));
      }
    });
  }
  return values;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export interface ApiRequestConfig<D, P> {
  data?: D;
  headers?: AxiosRequestHeaders;
  method?: Method;
  params?: Record<string, unknown>;
  populate?: Populate<P>;
  queryKey?: Array<unknown>;
  responseType?: ResponseType;
  staleTime?: number;
  url: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const apiRequest = async <T, D, P extends object | void>({
  data,
  headers,
  method = 'GET',
  params,
  populate,
  queryKey,
  responseType,
  staleTime,
  url,
}: ApiRequestConfig<D, P>) => {
  if (!initialized) {
    throw new Error('B2X StoreFront API Client not initialized.');
  }
  const populateQuery = buildPopulateQuery(populate);
  let query = `${url}?${populateQuery}`;
  if (query.endsWith('?')) {
    query = query.substring(0, query.length - 1);
  }

  _debug && console.log(`API: ${method} ${url}`, { data, headers, params, populate, query });

  // const userLogged = window.sessionStorage.getItem('userLogged');

  const result = await queryClient.fetchQuery({
    queryFn: ({ signal }) =>
      axios.request<ApiResponse<T>, AxiosResponse<ApiResponse<T>, D>, D>({
        data,
        headers: headers,
        method,
        params: params,
        paramsSerializer: (_params) => qs.stringify(_params, { indices: false, skipNulls: true }),
        responseType: responseType,
        signal: signal,
        url: query,
      }),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKey ?? [data, headers, method, params, query],
    staleTime: staleTime,
  });
  return { ...result, data: result.data.data, eventId: result.data.eventId };
  // return result;
};

export interface BaseOptions {
  locale?: string;
}

export const getBaseOptions = <D, P extends object | void>(
  baseOptions?: BaseOptions
): Partial<ApiRequestConfig<D, P>> => {
  const apiRequestConfig: Partial<ApiRequestConfig<D, P>> = { headers: {} };
  if (baseOptions?.locale && apiRequestConfig.headers) {
    apiRequestConfig.headers.locale = baseOptions.locale;
  }
  return apiRequestConfig;
};
