import { CartApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useOrdersApi } from '../api/useOrdersApi';
import { Button } from '../Button';
import { useCheckoutContext } from '../CheckoutContext';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { usePaymentHandler } from '../usePaymentHandler';
import { formatHtml, getAbsoluteUrl } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBoolean,
  getInitialBoolean,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface StartPaymentFormProps
  extends Omit<BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector>, 'onSuccess'> {
  cart: CartApiDto;
}

interface FormValues {
  checkbox: formikBoolean;
}

type ValidationSchema = {
  checkbox: yup.BooleanSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  checkbox: { checkbox: CheckboxProps; formGroup: FormGroupProps };
}

export const StartPaymentFormHelper = ({
  cart,
  children,
  className,
  initialValues,
  onCancel,
  ...otherProps
}: StartPaymentFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      checkbox: getInitialBoolean(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      checkbox: yup.boolean().required().oneOf([true]),
    }),
    []
  );

  const { initPayment } = useOrdersApi();

  const { PaymentModal, handleCartPaymentCallback } = usePaymentHandler();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      initPayment({
        paymentKoCallbackUrl: getAbsoluteUrl('/order-ko'),
        paymentOkCallbackUrl: getAbsoluteUrl('/order-ok'),
      }).then((response) => handleCartPaymentCallback(response, cart)),
    [cart, initPayment, handleCartPaymentCallback]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { canStartPayment } = useCheckoutContext();
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <>
      <HelpedForm<FormValues>
        className={classnames('StartPaymentForm', className)}
        initialValues={_initialValues}
        innerRef={ref}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        {...otherProps}
      >
        {({ formik }) => {
          const fieldsHelper: FieldsHelper = {
            buttons: {
              cancel: onCancel
                ? {
                    label: t('form.loginForm.buttons.cancel.label'),
                    onClick: onCancel,
                    type: 'button',
                    variant: appConfig.form?.buttons.cancel?.defaultVariant,
                  }
                : undefined,
              reset: {
                disabled: isResetButtonDisabled(formik),
                label: t('form.startPaymentForm.buttons.reset.label'),
                type: 'reset',
                variant: appConfig.form?.buttons.cancel?.defaultVariant,
              },
              submit: {
                disabled: !canStartPayment || isSubmitButtonDisabled(formik),
                label: t('form.startPaymentForm.buttons.submit.label'),
                type: 'submit',
                variant: appConfig.form?.buttons.submit?.defaultVariant,
              },
            },
            checkbox: {
              checkbox: { id: 'checkbox', label: formatHtml(consentsContent?.body.purchase), name: 'checkbox' },
              formGroup: { names: ['checkbox'], omitForAttribute: true },
            },
          };
          return children ? (
            children({ closeModal, fieldsHelper, formik, insideModal })
          ) : (
            <>
              <FormGroup {...fieldsHelper.checkbox.formGroup}>
                <Checkbox {...fieldsHelper.checkbox.checkbox} />
              </FormGroup>
              <Button {...fieldsHelper.buttons.submit} />
            </>
          );
        }}
      </HelpedForm>
      {PaymentModal}
    </>
  );
};

export type StartPaymentFormVariants = '';

const StartPaymentFormController = (props: PropsWithCustomComponentWithoutChildren<StartPaymentFormProps>) => (
  <VariantsController<StartPaymentFormProps, StartPaymentFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: StartPaymentFormHelper,
      name: 'StartPaymentForm',
    }}
  />
);
export { StartPaymentFormController as StartPaymentForm };
