import { SendCustomerCareMessagesRequestApiDto } from '.';
import { apiRequest, BaseOptions, getBaseOptions } from './utils/util';

const rootPath = '/v2/messages';

export const sendCustomerCareMessage = async (data: SendCustomerCareMessagesRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SendCustomerCareMessagesRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/customer-care`,
  });
