import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { AccountPageA } from './AccountPageA';

export interface AccountPageProps {
  enableReceiverColumn?: boolean;
}

export type AccountPageVariants = 'A';

const AccountPageController = (props: PropsWithCustomComponent<AccountPageProps>) => (
  <VariantsController<AccountPageProps, AccountPageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountPageA },
      name: 'AccountPage',
    }}
  />
);
export { AccountPageController as AccountPage };
