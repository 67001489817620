import { Col } from '../../../Col';
import { AccountLoyaltySubpageContentType } from '../../../contentTypes';
import { Div } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { Icon } from '../../../Icon';
import { ImageFromContentV1 } from '../../../ImageFromContentV1';
import { Link } from '../../../router/Link';
import { Row } from '../../../Row';
import { useCustomer } from '../../../useCustomer';
import { formatHtml, uiClassName } from '../../../util';
import { AccountLoyaltySubpageProps } from '../AccountLoyaltySubpage';
import { AccountSubpage } from '../AccountSubpage';

export const AccountLoyaltySubpageA = (props: AccountLoyaltySubpageProps) => {
  const customer = useCustomer({
    populate: {
      fidelityCards: true,
    },
  });
  return (
    <AccountSubpage<AccountLoyaltySubpageContentType>>
      {(page) => (
        <div className="account-loyalty-page">
          {page.content && customer && (
            <>
              <Row>
                <Col className={uiClassName({ bs5: 'mb-3' })} size={{ md: 8, xs: 12 }}>
                  <Div className="border-sm" padding={{ md: 4, xs: 3 }}>
                    <Div display={'flex'} justifyContent={'spaceBetween'}>
                      <Div>
                        <h5>{customer.name}</h5>
                        <div>{formatHtml(page.content.body.welcomeText)}</div>
                      </Div>

                      <Div className={uiClassName({ bs5: 'd-md-none ms-4' })}>
                        <Div
                          alignItems={'center'}
                          className={uiClassName({ bs5: 'box-points m-auto lh-1' })}
                          display={'flex'}
                          justifyContent={'center'}
                        >
                          <Div paddingX={3} textAlign={'center'}>
                            <Div className="points" fontWeight={700}>
                              {customer.fidelityCards && customer.fidelityCards.length >= 1
                                ? customer.fidelityCards[0].creditAmount
                                : 0}
                            </Div>
                            <small>{t('account.loyalty.pointsBalance')}</small>
                          </Div>
                        </Div>
                      </Div>
                    </Div>

                    <Link
                      className={uiClassName({ bs5: 'btn btn-primary mt-5 mb-3 mb-md-5 d-block d-md-inline-block' })}
                      to="/search"
                    >
                      {t('account.loyalty.shopping')}
                    </Link>
                    {page.content.body.welcomeText && (
                      <Div className={uiClassName({ bs5: 'extra-small' })}>
                        {formatHtml(page.content.body.ruleText)}
                      </Div>
                    )}
                  </Div>
                </Col>
                <Col className={uiClassName({ bs5: 'mb-3 p-3 p-md-4 d-none d-md-block' })} size={{ md: 4, xs: 12 }}>
                  <Div
                    alignItems={'center'}
                    className={uiClassName({ bs5: 'box-points m-auto' })}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Div textAlign={'center'}>
                      <Div className="points" fontWeight={700}>
                        {customer.fidelityCards && customer.fidelityCards.length >= 1
                          ? customer.fidelityCards[0].creditAmount
                          : 0}
                      </Div>
                      <small className="label-points">{t('account.loyalty.pointsBalance')}</small>
                    </Div>
                  </Div>
                </Col>
              </Row>
              <Row>
                <Col className={uiClassName({ bs5: 'mb-3' })} size={{ xs: 12 }}>
                  <Div className="border-sm" padding={{ md: 4, xs: 3 }}>
                    <Row>
                      <Col size={{ md: 8, xl: 7, xs: 12 }}>
                        {page.content.body.howItWorksSection?.title && (
                          <h6
                            className={uiClassName({
                              bs5: 'text-uppercase text-center text-md-start fw-bold small mt-2 mt-md-0 mb-4 mb-md-5',
                            })}
                          >
                            {formatHtml(page.content.body.howItWorksSection.title)}
                          </h6>
                        )}
                        {page.content.body.howItWorksSection?.rulesList && (
                          <Div
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={{ md: 'spaceBetween', xs: 'spaceAround' }}
                          >
                            {page.content.body.howItWorksSection.rulesList.map((item) => (
                              <Div
                                alignItems={'center'}
                                className="rule-item"
                                display={'flex'}
                                flexDirection={{ md: 'row', xs: 'column' }}
                                key={item.contentSectionId}
                                paddingBottom={{ md: 0, xs: 4 }}
                              >
                                {item.iconName && (
                                  <Div
                                    className={uiClassName({
                                      bs5: `mb-3 mb-md-0 rule-item-icon rounded-circle bg-${item.iconBgColor} text-${item.iconColor}`,
                                    })}
                                    marginEnd={3}
                                    padding={3}
                                  >
                                    <Icon name={item.iconName} size={28} />
                                  </Div>
                                )}
                                <div className="rule-item-text">
                                  <div>{formatHtml(item.action)}</div>
                                  <Div className={uiClassName({ bs5: 'text-uppercase text-red' })} fontWeight={700}>
                                    {formatHtml(item.points)}
                                  </Div>
                                </div>
                              </Div>
                            ))}
                          </Div>
                        )}
                      </Col>
                      <Col className={uiClassName({ bs5: 'text-center offset-xl-1' })} size={{ md: 4, xl: 4, xs: 12 }}>
                        {page.content.body.howItWorksSection?.image && (
                          <ImageFromContentV1 fluid {...page.content.body.howItWorksSection.image} />
                        )}
                      </Col>
                    </Row>
                  </Div>
                </Col>
              </Row>
              <Row>
                <Col className={uiClassName({ bs5: 'mb-3' })} size={{ xs: 12 }}>
                  <Div className="inner-box" padding={{ md: 4, xs: 3 }}>
                    {page.content.body.pointsTableSection?.title && (
                      <h6
                        className={uiClassName({
                          bs5: 'text-uppercase text-center text-md-start fw-bold small mt-2 mb-4',
                        })}
                      >
                        {formatHtml(page.content.body.pointsTableSection.title)}
                      </h6>
                    )}
                    {page.content.body.pointsTableSection?.couponsList && (
                      <>
                        <table className={uiClassName({ bs5: 'table table-bordered align-middle' })}>
                          <thead>
                            <tr className={uiClassName({ bs5: 'text-uppercase small align-middle' })}>
                              <th
                                className={uiClassName({ bs5: 'col-4 ps-0 py-md-4 px-md-3 px-2 py-3 border-start-0' })}
                              >
                                {t('account.loyalty.pointsTable.pointsLabel')}
                              </th>
                              <th className={uiClassName({ bs5: 'col-4 py-md-4 px-md-3 px-2 py-3' })}>
                                {t('account.loyalty.pointsTable.awardsLabel')}
                              </th>
                              <th className={uiClassName({ bs5: 'col-4 py-md-4 px-md-3 px-2 py-3 border-end-0' })}>
                                {t('account.loyalty.pointsTable.couponLabel')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {page.content.body.pointsTableSection.couponsList.map((coupon) => (
                              <tr key={coupon.contentSectionId}>
                                <td className={uiClassName({ bs5: 'ps-0 py-md-4 px-md-3 px-2 py-3 border-start-0' })}>
                                  {coupon.points} {t('account.loyalty.pointsTable.ptLabel')}
                                </td>
                                <td className={uiClassName({ bs5: 'py-md-4 px-md-3 px-2 py-3' })}>{coupon.award}</td>
                                <td
                                  className={uiClassName({
                                    bs5: 'fw-bold text-red py-md-4 px-md-3 px-2 py-3 border-end-0',
                                  })}
                                >
                                  {coupon.couponCode}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className={uiClassName({ bs5: 'extra-small mb-3 d-none d-md-block' })}>
                          {t('account.loyalty.pointsTable.footerText')}
                        </div>
                      </>
                    )}

                    {page.content.body.pointsTableSection?.documentation?.label &&
                      page.content.body.pointsTableSection.documentation.file && (
                        <Div paddingY={4} textAlign={{ md: 'start', xs: 'center' }}>
                          <Link
                            className={uiClassName({ bs5: 'btn btn-outline-primary' })}
                            target="_blank"
                            to={page.content.body.pointsTableSection.documentation.file}
                          >
                            {page.content.body.pointsTableSection.documentation.label}
                          </Link>
                        </Div>
                      )}
                  </Div>

                  <div className={uiClassName({ bs5: 'extra-small mt-3 mb-4 d-md-none text-center' })}>
                    {t('account.loyalty.pointsTable.footerText')}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </AccountSubpage>
  );
};
