import { CheckoutStepContentSection } from '../../contentTypes';
import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AdditionalCheckoutStepContentProps } from '../CheckoutPage';
import { CheckoutEmailStepA } from './checkoutStepsA/CheckoutEmailStepA';

export interface CheckoutEmailStepProps {
  additionalContent?: AdditionalCheckoutStepContentProps;
  content?: CheckoutStepContentSection;
  onEdit: boolean;
}

export type CheckoutEmailStepVariants = 'A';

const CheckoutEmailStepController = (props: PropsWithCustomComponent<CheckoutEmailStepProps>) => (
  <VariantsController<CheckoutEmailStepProps, CheckoutEmailStepVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CheckoutEmailStepA },
      name: 'CheckoutEmailStep',
    }}
  />
);
export { CheckoutEmailStepController as CheckoutEmailStep };
