import { PaymentResponseApiDto } from '@b2x/storefront-api-js-client/src/dto';
import { CreateOrderActions, CreateOrderData, OnApproveActions, OnApproveData } from '@paypal/paypal-js';
import {
  PayPalButtonsComponentProps,
  PayPalCardFieldsComponentOptions,
  PayPalCardFieldsIndividualFieldOptions,
  PayPalCardFieldsStateObject,
  ReactPayPalScriptOptions,
} from '@paypal/react-paypal-js';
import React from 'react';

import { usePayPalApi } from './api/usePayPalApi';
import { useAppContext, useAppStaticContext } from './AppContext';
import { appConfig } from './config';
import { t } from './i18n/i18n';
import { useNavigate } from './router/useNavigate';
import { useModals } from './useModals';

export {
  PayPalButtons,
  PayPalCardFieldsProvider,
  PayPalCVVField,
  PayPalExpiryField,
  PayPalNameField,
  PayPalNumberField,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js';

export interface UsePayPalCheckoutProps {
  currency: string;
  mode: 'start' | 'restart';
  onCancel(): Promise<void>;
  onError(): Promise<void>;
  onSuccess(): Promise<void>;
  payment: PaymentResponseApiDto;
}

export type IntegrationType = 'standard' | 'advanced';

export interface PayPalCheckoutConfig {
  integrationType: IntegrationType;
}

export interface UsePayPalCheckoutResult {
  buttonsComponentProps: PayPalButtonsComponentProps;
  cardFieldsProviderProps: PayPalCardFieldsComponentOptions;
  cardFieldsState: PayPalCardFieldsStateObject | undefined;
  config: PayPalCheckoutConfig;
  // createOrder(data?: CreateOrderData, actions?: CreateOrderActions): Promise<string>;
  cvvFieldProps: PayPalCardFieldsIndividualFieldOptions;
  expiryFieldProps: PayPalCardFieldsIndividualFieldOptions;
  // goToOrderKoPage(): void;
  // goToOrderOkPage(): void;
  handleCancel(): void;
  nameFieldProps: PayPalCardFieldsIndividualFieldOptions;
  numberFieldProps: PayPalCardFieldsIndividualFieldOptions;
  // onApprove(data: OnApproveData, actions?: OnApproveActions): Promise<void>;
  // onError(error: Record<string, unknown>): void;
  scriptProviderOptions: ReactPayPalScriptOptions;
  setCardFieldsState: React.Dispatch<React.SetStateAction<PayPalCardFieldsStateObject | undefined>>;
}

const PayPalCheckoutConfigDefaults: PayPalCheckoutConfig = {
  integrationType: 'standard',
};

export const usePayPalCheckout = ({
  currency,
  mode,
  onCancel,
  onError: _onError,
  onSuccess,
  payment,
}: UsePayPalCheckoutProps): UsePayPalCheckoutResult => {
  const clientId = payment.properties['clientId'] as string | undefined;

  const [cardFieldsState, setCardFieldsState] = React.useState<PayPalCardFieldsStateObject>();

  if (clientId === undefined) {
    throw new Error('usePayPalCheckout, missing clientId');
  }

  const { getFixedPagePath } = useAppStaticContext();
  const navigate = useNavigate();

  const config = appConfig.payPalCheckout || PayPalCheckoutConfigDefaults;

  const { capturePayment, createOrder: _createOrder } = usePayPalApi();

  const { showModal } = useModals();

  const goToOrderKoPage = React.useCallback(() => {
    navigate(getFixedPagePath('order-ko'));
  }, [getFixedPagePath, navigate]);

  const goToOrderOkPage = React.useCallback(() => {
    navigate(`${getFixedPagePath('order-ok')}?token=${payment.token}`);
  }, [getFixedPagePath, navigate, payment.token]);

  const createOrder = React.useCallback(
    (data?: CreateOrderData, actions?: CreateOrderActions): Promise<string> => {
      console.log('createOrder', data, actions, payment.properties);
      const paymentTransactionId = payment.properties['paymentTransactionId'] as string | undefined;
      if (paymentTransactionId === undefined) {
        throw new Error('Could not initiate PayPal Checkout, missing paymentTransactionId');
      }
      return _createOrder(paymentTransactionId)
        .then((response) => {
          console.log('createOrder', 'then', response);
          if (response.data.response) {
            console.log('createOrder', 'then', 'response');
            return response.data.response.id;
          } else {
            console.log('createOrder', 'then', 'error');
            const error = response.data.error;
            const errorDetail = error?.details?.[0];
            const errorMessage = errorDetail
              ? `${errorDetail.issue} ${errorDetail.description} (${error.debug_id})`
              : JSON.stringify(error);
            console.log('createOrder', 'then', 'error', errorMessage, error);
            throw new Error(errorMessage); // Da testare
          }
        })
        .catch((error) => {
          console.log('createOrder', 'catch', error);
          throw new Error(`Could not initiate PayPal Checkout, ${error}`);
        });
    },
    [_createOrder, payment.properties]
  );

  const onApprove = React.useCallback(
    (data: OnApproveData, actions?: OnApproveActions): Promise<void> => {
      console.log('onApprove', data, actions);
      const { liabilityShift } = data as unknown as { liabilityShift: 'POSSIBLE' | 'YES' | 'NO' | 'UNKNOWN' }; // bug nei tipi di PayPal, hanno dimenticato di mettere liabilityShift.

      switch (liabilityShift) {
        case 'NO':
          // Do not continue with authorization.
          throw new Error(
            `Sorry, your transaction could not be processed, an issue with the 3D Secure occurred. LiabilityShift: ${liabilityShift}`
          );
        case 'POSSIBLE':
          // Continue with authorization.
          break;
        case 'UNKNOWN':
          // Do not continue with authorization. Request the cardholder to retry.
          throw new Error(
            `Sorry, your transaction could not be processed, an issue with the 3D Secure occurred. LiabilityShift: ${liabilityShift}`
          );
        case 'YES':
          // Continue with authorization.
          break;
      }

      const paymentTransactionId = payment.properties['paymentTransactionId'] as string | undefined;
      if (paymentTransactionId === undefined) {
        throw new Error('Sorry, your transaction could not be processed, missing paymentTransactionId');
      }
      return capturePayment(data.orderID, paymentTransactionId)
        .then((response) => {
          console.log('onApprove', 'then', response);
          if (response.data.response) {
            console.log('onApprove', 'then', 'response');
            const orderData = response.data.response;

            const transaction = orderData.purchase_units?.[0]?.payments?.captures?.[0];

            if (response.data.success) {
              return onSuccess().then(() => {
                console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                goToOrderOkPage();
              });
            } else {
              let errorMessage;
              if (transaction) {
                errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
              } else {
                errorMessage = JSON.stringify(orderData);
              }
              throw new Error(errorMessage);
            }
          } else {
            console.log('onApprove', 'then', 'error');
            const error = response.data.error;
            const errorDetail = error?.details?.[0];
            const errorMessage = `${errorDetail?.description} (${error?.debug_id})`;
            throw new Error(errorMessage);
          }
        })
        .catch((error) => {
          console.log('onApprove catch', error);
          throw new Error(`Sorry, your transaction could not be processed, ${error}`);
        });
    },
    [capturePayment, goToOrderOkPage, onSuccess, payment.properties]
  );

  const onError = React.useCallback(
    (error: Record<string, unknown>): void => {
      console.log('onError', error);
      _onError().then(() => {
        showModal({
          children: t('misc.payPalCheckout.capture.errorModal.body'),
          onClose: () => {
            if (mode === 'start') {
              goToOrderKoPage();
            }
          },
          title: t('misc.payPalCheckout.capture.errorModal.title'),
        });
      });
    },
    [_onError, goToOrderKoPage, mode, showModal]
  );

  const handleCancel = React.useCallback(() => {
    onCancel().then(() => {
      if (mode === 'start') {
        goToOrderKoPage();
      }
    });
  }, [goToOrderKoPage, mode, onCancel]);

  const { locale, shippingCountry } = useAppContext();

  const scriptProviderOptions = React.useMemo<ReactPayPalScriptOptions>(
    () => ({
      clientId: payment.properties['clientId'] as string,
      components: ['card-fields', 'buttons'],
      currency: currency,
      debug: false,
      disableFunding: [
        // 'card',
        'credit',
        'paylater',
        'bancontact',
        'blik',
        'eps',
        'giropay',
        'ideal',
        'mercadopago',
        'mybank',
        'p24',
        'sepa',
        'sofort',
        'venmo',
      ],
      locale: `${locale?.languageCode}_${shippingCountry?.code}`,
    }),
    [currency, locale?.languageCode, payment.properties, shippingCountry?.code]
  );

  const nameFieldProps = React.useMemo<PayPalCardFieldsIndividualFieldOptions>(
    () => ({
      inputEvents: {
        onBlur: setCardFieldsState,
        onChange: setCardFieldsState,
        onFocus: setCardFieldsState,
        onInputSubmitRequest: setCardFieldsState,
      },
      placeholder: t('misc.payPalCheckout.form.fields.name.placeholder'),
    }),
    []
  );

  const numberFieldProps = React.useMemo<PayPalCardFieldsIndividualFieldOptions>(
    () => ({
      inputEvents: {
        onBlur: setCardFieldsState,
        onChange: setCardFieldsState,
        onFocus: setCardFieldsState,
        onInputSubmitRequest: setCardFieldsState,
      },
      placeholder: t('misc.payPalCheckout.form.fields.number.placeholder'),
    }),
    []
  );

  const expiryFieldProps = React.useMemo<PayPalCardFieldsIndividualFieldOptions>(
    () => ({
      inputEvents: {
        onBlur: setCardFieldsState,
        onChange: setCardFieldsState,
        onFocus: setCardFieldsState,
        onInputSubmitRequest: setCardFieldsState,
      },
      placeholder: t('misc.payPalCheckout.form.fields.expiry.placeholder'),
    }),
    []
  );

  const cvvFieldProps = React.useMemo<PayPalCardFieldsIndividualFieldOptions>(
    () => ({
      inputEvents: {
        onBlur: setCardFieldsState,
        onChange: setCardFieldsState,
        onFocus: setCardFieldsState,
        onInputSubmitRequest: setCardFieldsState,
      },
      placeholder: t('misc.payPalCheckout.form.fields.cvv.placeholder'),
    }),
    []
  );

  const cardFieldsProviderProps = React.useMemo<PayPalCardFieldsComponentOptions>(
    () => ({
      createOrder,
      onApprove,
      onError,
    }),
    [createOrder, onApprove, onError]
  );

  const buttonsComponentProps = React.useMemo<PayPalButtonsComponentProps>(
    () => ({
      createOrder,
      onApprove,
      onError,
      style: { label: 'pay' },
    }),
    [createOrder, onApprove, onError]
  );

  return {
    buttonsComponentProps,
    cardFieldsProviderProps,
    cardFieldsState,
    config,
    // createOrder,
    cvvFieldProps,
    expiryFieldProps,
    handleCancel,
    nameFieldProps,
    numberFieldProps,
    // onApprove,
    // onError,
    scriptProviderOptions,
    setCardFieldsState,
  };
};
