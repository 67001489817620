import { ContactUsProps } from '../../ContactUs';
import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { ServiceContactsSubpageA } from './serviceSubpagesA/ServiceContactsSubpageA';

export interface ServiceContactsSubpageProps {
  contactUs?: ContactUsProps;
}

export type ServiceContactsSubpageVariants = 'A';

const ServiceContactsSubpageController = (props: PropsWithCustomComponent<ServiceContactsSubpageProps>) => (
  <VariantsController<ServiceContactsSubpageProps, ServiceContactsSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServiceContactsSubpageA },
      name: 'ServiceContactsSubpage',
    }}
  />
);
export { ServiceContactsSubpageController as ServiceContactsSubpage };
