import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountWishlistSubpageA } from './accountSubpagesA/AccountWishlistSubpageA';

export interface AccountWishlistSubpageProps {}

export type AccountWishlistSubpageVariants = 'A';

const AccountWishlistSubpageController = (props: PropsWithCustomComponent<AccountWishlistSubpageProps>) => (
  <VariantsController<AccountWishlistSubpageProps, AccountWishlistSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountWishlistSubpageA },
      name: 'AccountWishlistSubpage',
    }}
  />
);
export { AccountWishlistSubpageController as AccountWishlistSubpage };
