import './Table.scss';

import classnames from 'classnames';

import { ConditionalWrapper } from './ConditionalWrapper';
import { Breakpoint } from './interfaces';
import { renderUI } from './util';

export interface TableLayout {
  border?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  bordered?: boolean;
  borderless?: boolean;
  className?: string;
  responsive?: boolean | Breakpoint;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  xAlignment?: 'left' | 'center' | 'right';
  yAlignment?: 'top' | 'center' | 'bottom';
}

export interface TableProps extends TableLayout {
  children?: React.ReactNode;
  colNumber?: number;
  hover?: boolean;
  small?: boolean;
  striped?: boolean;
}

export const Table = ({
  border,
  bordered,
  borderless,
  children,
  className,
  colNumber,
  hover,
  responsive,
  small,
  striped,
  variant,
  xAlignment,
  yAlignment,
}: TableProps): React.ReactElement => {
  return renderUI({
    bs5: (
      <ConditionalWrapper
        condition={responsive !== undefined}
        wrapper={
          <div
            className={classnames(
              { 'table-responsive': responsive === true || responsive === 'xs' },
              { [`table-responsive-${responsive}`]: typeof responsive === 'string' && responsive !== 'xs' }
            )}
          />
        }
      >
        <table
          className={classnames(
            'Table table',
            className,
            variant && `table-${variant}`,
            hover && 'table-hover',
            small && 'table-sm',
            striped && 'table-striped',
            { 'text-start': xAlignment === 'left' },
            { 'text-center': xAlignment === 'center' },
            { 'text-end': xAlignment === 'right' },
            { 'align-top': yAlignment === 'top' },
            { 'align-middle': yAlignment === 'center' },
            { 'align-bottom': yAlignment === 'bottom' },
            borderless && 'table-borderless',
            bordered && 'table-bordered',
            border && `border-${border}`,
            colNumber && 'table-layout-fixed'
          )}
        >
          {colNumber && (
            <colgroup>
              {[...Array(colNumber)].map((col, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <col key={index} />
              ))}
            </colgroup>
          )}
          {children}
        </table>
      </ConditionalWrapper>
    ),
  });
};
