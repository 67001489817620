import { AdditionalPropertyStateApiType, ContentApiDto, CustomerApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import {
  AccountTaxBenefitsContentType,
  AccountTaxBenefitsElemContentSection,
  ConsentsContentType,
} from '../contentTypes';
import { t } from '../i18n/i18n';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { formatHtml, fromCustomerApiDtoToCustomerRequestApiDto } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { FileUploaderInput, FileUploaderInputProps, TextInput, TextInputProps } from './fields/Input';
import { CheckboxProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBooleanAsString,
  formikString,
  getInitialBooleanAsString,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { TaxBenefitsFormA } from './TaxBenefitsFormA';

export interface TaxBenefitsFormProps<CustomPropertiesFormValues, CustomPropertiesValidationSchema>
  extends BaseHelpedFormProps<FormValues<CustomPropertiesFormValues>, FieldsHelper, ValidationSchemaSelector> {
  content: ContentApiDto<AccountTaxBenefitsContentType>;
  customProperties?: {
    initialValues: CustomPropertiesFormValues;
    validationSchema: CustomPropertiesValidationSchema;
  };
  customer: CustomerApiDto;
}

type AdditionalPropertiesFormValues = Record<string, formikString>;

type AdditionalPropertiesValidationSchema = yup.ObjectSchema<Record<string, yup.StringSchema>>;

interface FormValues<CustomPropertiesFormValues> {
  additionalProperties?: AdditionalPropertiesFormValues;
  customProperties?: CustomPropertiesFormValues;
  privacy: formikBooleanAsString;
  taxCode?: formikString;
}

type ValidationSchema<CustomPropertiesValidationSchema> = {
  additionalProperties?: AdditionalPropertiesValidationSchema;
  customProperties?: CustomPropertiesValidationSchema;
  privacy: yup.BooleanSchema;
  taxCode: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  filesToAttach: Array<{
    fileUploader: {
      contentSection: AccountTaxBenefitsElemContentSection;
      fileUploaderInput: FileUploaderInputProps;
      formGroup: FormGroupProps;
    };
    status?: AdditionalPropertyStateApiType;
  }>;
  privacy: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  taxCode: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const TaxBenefitsFormHelper = <
  CustomPropertiesFormValues extends Record<string, unknown>,
  CustomPropertiesValidationSchema extends yup.AnyObjectSchema
>({
  children,
  className,
  content,
  customProperties,
  customer,
  initialValues,
  onCancel,
  onSuccess,
  validationSchemaSelector,
  ...otherProps
}: TaxBenefitsFormProps<CustomPropertiesFormValues, CustomPropertiesValidationSchema>) => {
  const _initialValues = React.useMemo<FormValues<CustomPropertiesFormValues>>(
    () => ({
      additionalProperties: content.body.filesToAttach?.reduce<AdditionalPropertiesFormValues>((acc, curr) => {
        if (curr.additionalProperty?.code) {
          if (
            customer.additionalPropertiesExt === undefined ||
            (customer.additionalPropertiesExt[curr.additionalProperty.code]?.state !== 'APPROVED' &&
              customer.additionalPropertiesExt[curr.additionalProperty.code]?.state !== 'AWAITING_APPROVAL')
          ) {
            acc[curr.additionalProperty.code] = getInitialString();
          }
        }
        return acc;
      }, {}),
      customProperties: customProperties?.initialValues,
      privacy: getInitialBooleanAsString(),
      taxCode: getInitialString(customer.taxCode),
      ...initialValues,
    }),
    [
      content.body.filesToAttach,
      customProperties?.initialValues,
      customer.additionalPropertiesExt,
      customer.taxCode,
      initialValues,
    ]
  );

  const validationSchema = React.useMemo<ValidationSchema<CustomPropertiesValidationSchema>>(
    () => ({
      additionalProperties: yup.object(
        content.body.filesToAttach?.reduce<Record<string, yup.StringSchema>>((acc, curr) => {
          if (curr.additionalProperty?.code) {
            if (
              customer.additionalPropertiesExt === undefined ||
              (customer.additionalPropertiesExt[curr.additionalProperty.code]?.state !== 'APPROVED' &&
                customer.additionalPropertiesExt[curr.additionalProperty.code]?.state !== 'AWAITING_APPROVAL')
            ) {
              acc[curr.additionalProperty.code] = curr.additionalProperty.required
                ? yup.string().required()
                : yup.string();
            }
          }
          return acc;
        }, {})
      ),
      customProperties: customProperties?.validationSchema,
      privacy: yup.boolean().required().oneOf([true]),
      taxCode: yup.string().required(),
    }),
    [content.body.filesToAttach, customProperties?.validationSchema, customer.additionalPropertiesExt]
  );

  const { updateCustomer } = useCustomerApi();

  const handleSubmit = React.useCallback(
    (values: FormValues<CustomPropertiesFormValues>) =>
      updateCustomer({
        ...fromCustomerApiDtoToCustomerRequestApiDto(customer),
        additionalProperties: values.additionalProperties,
        taxCode: values.taxCode,
      }).then(() => {
        onSuccess && onSuccess();
      }),
    [customer, updateCustomer, onSuccess]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  return (
    <HelpedForm<FormValues<CustomPropertiesFormValues>>
      className={classnames('tax-benefits-form', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.taxBenefitsForm.buttons.cancel'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.taxBenefitsForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.taxBenefitsForm.buttons.submit'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          filesToAttach: content.body.filesToAttach
            ? content.body.filesToAttach.flatMap((contentSection) => {
                const _status =
                  customer.additionalPropertiesExt && contentSection.additionalProperty?.code
                    ? customer.additionalPropertiesExt[contentSection.additionalProperty.code]?.state === 'APPROVED'
                      ? 'APPROVED'
                      : customer.additionalPropertiesExt[contentSection.additionalProperty.code]?.state ===
                        'AWAITING_APPROVAL'
                      ? 'AWAITING_APPROVAL'
                      : customer.additionalPropertiesExt[contentSection.additionalProperty.code]?.state === 'REJECTED'
                      ? 'REJECTED'
                      : undefined
                    : undefined;

                return contentSection.additionalProperty?.code
                  ? [
                      {
                        fileUploader: {
                          contentSection: contentSection,
                          fileUploaderInput: {
                            disabled: _status === 'AWAITING_APPROVAL' || _status === 'APPROVED',
                            name: `additionalProperties.${contentSection.additionalProperty.code}`,
                          },
                          formGroup: {
                            label: contentSection.title,
                            names: [`additionalProperties.${contentSection.additionalProperty.code}`],
                          },
                        },
                        status: _status,
                      },
                    ]
                  : [];
              })
            : [],
          privacy: {
            checkbox: {
              id: 'privacy',
              label: formatHtml(consentsContent?.body.privacy),
              name: 'privacy',
            },
            formGroup: { names: ['privacy'] },
          },
          taxCode: {
            formGroup: { label: t('form.taxBenefitsForm.taxCode.label'), names: ['taxCode'] },
            textInput: {
              disabled: customer.taxCode ? true : false,
              name: 'taxCode',
              placeholder: t('form.taxBenefitsForm.taxCode.placeholder'),
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.taxCode.formGroup}>
              <TextInput {...fieldsHelper.taxCode.textInput} />
            </FormGroup>
            {fieldsHelper.filesToAttach.map(({ fileUploader: { contentSection, fileUploaderInput, formGroup } }) => (
              <FormGroup key={fileUploaderInput.name} {...formGroup}>
                <FileUploaderInput {...fileUploaderInput} />
              </FormGroup>
            ))}
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type TaxBenefitsFormVariants = 'A';

const TaxBenefitsFormController = <
  CustomPropertiesFormValues extends Record<string, unknown>,
  CustomPropertiesValidationSchema extends yup.AnyObjectSchema
>(
  props: PropsWithCustomComponentWithoutChildren<
    TaxBenefitsFormProps<CustomPropertiesFormValues, CustomPropertiesValidationSchema>
  >
) => (
  <VariantsController<
    TaxBenefitsFormProps<CustomPropertiesFormValues, CustomPropertiesValidationSchema>,
    TaxBenefitsFormVariants
  >
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: TaxBenefitsFormA },
      defaultComponent: TaxBenefitsFormHelper,
      name: 'TaxBenefitsForm',
    }}
  />
);
export { TaxBenefitsFormController as TaxBenefitsForm };
