import classnames from 'classnames';

import { renderUI } from './util';

export interface AlertProps {
  children?: React.ReactNode;
  className?: string;
  dismissable?: boolean;
  heading?:
    | string
    | {
        className?: string;
        size?: 1 | 2 | 3 | 4 | 5 | 6;
        text?: string;
      };
  variant?: 'success' | 'error' | 'warning' | 'info';
}

export const Alert = ({ children, className, dismissable, heading, variant }: AlertProps) => {
  const headingText: string | undefined = typeof heading === 'string' ? heading : heading?.text;
  const headingSize: number = typeof heading === 'string' ? 5 : heading?.size ?? 5;
  const headingClassName: string | undefined = typeof heading === 'string' ? undefined : heading?.className;

  return renderUI({
    bs5: (
      <div
        className={classnames(
          className,
          'Alert alert',
          { 'alert-primary': variant === 'info' },
          { 'alert-success': variant === 'success' },
          { 'alert-danger': variant === 'error' },
          { 'alert-warning': variant === 'warning' },
          { 'alert-dismissible': dismissable }
        )}
        role="alert"
      >
        {heading && (
          <div className={classnames(headingClassName, 'alert-heading', `h${headingSize}`)}>{headingText}</div>
        )}
        {children}
        {/* eslint-disable-next-line react/forbid-elements */}
        {dismissable && <button aria-label="Close" className="btn-close" data-bs-dismiss="alert" type="button" />}
      </div>
    ),
  });
};
