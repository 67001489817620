import { Alert } from '../../../Alert';
import { useCheckoutContext } from '../../../CheckoutContext';
import { appConfig } from '../../../config';
import { CartPaymentMethodsForm } from '../../../form/CartPaymentMethodsForm';
import { Div } from '../../../HTMLElement';
import { InstallmentPaymentBanner } from '../../../InstallmentPaymentBanner';
import { formatCurrency, formatHtml, renderUI } from '../../../util';
import { CheckoutPaymentMethodStepProps } from '../CheckoutPaymentMethodStep';

export const CheckoutPaymentMethodStepA = ({ additionalContent, content, onEdit }: CheckoutPaymentMethodStepProps) => {
  const { cart } = useCheckoutContext();

  return renderUI({
    bs5: (
      <>
        {cart ? (
          <>
            {onEdit ? (
              <>
                {content?.onEdit?.alerts?.map((alert) => (
                  <Alert
                    className="extra-small py-2"
                    key={alert.contentSectionId}
                    {...alert}
                    variant={
                      alert.type === 'info'
                        ? 'info'
                        : alert.type === 'success'
                        ? 'success'
                        : alert.type === 'warning'
                        ? 'warning'
                        : alert.type === 'error'
                        ? 'error'
                        : undefined
                    }
                  >
                    {formatHtml(alert.message)}
                  </Alert>
                ))}
                {additionalContent?.onEdit?.alerts?.map((alert, index) => (
                  <Alert
                    className="extra-small py-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`checkout-payment-alert-on-edit-${index}`}
                    {...alert}
                  />
                ))}
                <CartPaymentMethodsForm appliedPaymentMethod={cart.paymentMethod} />
                {appConfig.installmentPaymentBanner && (
                  <Div marginTop={3}>
                    <InstallmentPaymentBanner amount={cart.totalCost} />
                  </Div>
                )}
              </>
            ) : (
              <>
                {content?.onClosed?.alerts?.map((alert) => (
                  <Alert
                    className="extra-small py-2"
                    key={alert.contentSectionId}
                    {...alert}
                    variant={
                      alert.type === 'info'
                        ? 'info'
                        : alert.type === 'success'
                        ? 'success'
                        : alert.type === 'warning'
                        ? 'warning'
                        : alert.type === 'error'
                        ? 'error'
                        : undefined
                    }
                  >
                    {formatHtml(alert.message)}
                  </Alert>
                ))}
                {additionalContent?.onClosed?.alerts?.map((alert, index) => (
                  <Alert
                    className="extra-small py-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`checkout-payment-alert-on-closed-${index}`}
                    {...alert}
                  />
                ))}
                <div className="small">
                  {cart.paymentMethod?.name}
                  {cart.paymentMethod?.extraCost !== undefined && cart.paymentMethod.extraCost > 0 && (
                    <>({formatCurrency(cart.paymentMethod.extraCost)})</>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    ),
  });
};
