// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { GetMenuOptions } from '@b2x/storefront-api-js-client/src/menus';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useMenusApi = () => {
  const { apiRequest } = useApiRequest();

  const getRouter = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.menus.getRouter(), { ...config }),
    [apiRequest]
  );

  const getMenuByCode = React.useCallback(
    (code: string, options?: GetMenuOptions, config?: ApiRequestConfig) =>
      apiRequest(api.menus.getByCode(code, options), { suppressErrorModal: true, ...config }),
    [apiRequest]
  );

  return { getMenuByCode, getRouter };
};
