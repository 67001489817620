import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Button } from './Button';

export interface ListAProps {
  contentAlignment?: string;
  items: Array<{
    cta: string | (() => void);
    disabled?: boolean;
    label: string;
  }>;
  textInitial?: boolean;
  textUnderlined?: boolean;
  textUppercase?: boolean;
}

export const ListA = ({ contentAlignment, items, textInitial, textUnderlined, textUppercase }: ListAProps) => {
  return (
    <div className={classNames('ListA hstack gap-2 mb-0', contentAlignment)}>
      {items.length > 0 &&
        items
          .map((item) => (
            <div key={item.label}>
              {typeof item.cta === 'string' ? (
                <b2x.router.Link
                  className={classNames(
                    textUnderlined && 'text-decoration-underline',
                    textUppercase && 'text-uppercase',
                    textInitial && 'text-initial',
                    !textUnderlined && 'text-decoration-none',
                    'text-reset extra-small'
                  )}
                  to={item.cta}
                >
                  {item.label}
                </b2x.router.Link>
              ) : (
                <Button
                  className={classNames(
                    textUnderlined && 'text-decoration-underline',
                    textUppercase && 'text-uppercase',
                    !textUnderlined && 'text-decoration-none',
                    'text-reset extra-small'
                  )}
                  disabled={item.disabled}
                  label={item.label}
                  onClick={item.cta}
                  variant="blank"
                />
              )}
            </div>
          ))
          .reduce(b2x.elementsJoinerReducer(<div className="vr align-self-center"></div>))}
    </div>
  );
};
