import React, { useState } from 'react';

import { ModalBody, ModalButton, ModalFooter, ModalHeader, ModalWithId, PackedModalProps } from './Modal';
import { OffcanvasBody, OffcanvasHeader, OffcanvasWithId, PackedOffcanvasProps } from './Offcanvas';
import { getElementById, getInstanceById } from './useModals';

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(
  displayName: string
): readonly [React.Provider<T | undefined>, () => T] => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  return [Context.Provider, useContext] as const;
};
interface ModalsContextInterface {
  ids: Array<string>;
  modals: Array<PackedModalProps>;
  offcanvasses: Array<PackedOffcanvasProps>;
  onlyOneAtATime: boolean;
}

export const [ModalsContextProvider, useModalsContext] = createContext<ModalsContextInterface>('ModalsContext');

interface ModalsStaticContextInterface {
  setIds: React.Dispatch<React.SetStateAction<Array<string>>>;
  setModals: React.Dispatch<React.SetStateAction<Array<PackedModalProps>>>;
  setOffcanvasses: React.Dispatch<React.SetStateAction<Array<PackedOffcanvasProps>>>;
}

export const [ModalsStaticContextProvider, useModalsStaticContext] =
  createContext<ModalsStaticContextInterface>('ModalsStaticContext');

interface UseModalsContextInitializerProps {
  onlyOneAtATime?: boolean;
}

export const useModalsContextInitializer = ({ onlyOneAtATime = true }: UseModalsContextInitializerProps) => {
  const [ids, setIds] = useState<Array<string>>([]);
  const [modals, setModals] = useState<Array<PackedModalProps>>([]);
  const [offcanvasses, setOffcanvasses] = useState<Array<PackedOffcanvasProps>>([]);

  const modalsContext: ModalsContextInterface = React.useMemo(
    () => ({
      ids,
      modals,
      offcanvasses,
      onlyOneAtATime,
    }),
    [ids, modals, offcanvasses, onlyOneAtATime]
  );

  const modalsStaticContext: ModalsStaticContextInterface = React.useMemo(
    () => ({
      setIds,
      setModals,
      setOffcanvasses,
    }),
    []
  );

  return {
    ModalsContextProvider,
    ModalsStaticContextProvider,
    modalsContext,
    modalsStaticContext,
  };
};

export interface ModalsContextProps extends UseModalsContextInitializerProps {
  children:
    | React.ReactNode
    | ((modalsContext: ModalsContextInterface, modalsStaticContext: ModalsStaticContextInterface) => React.ReactNode);
}

export const ModalsContext = ({ children, ...otherProps }: ModalsContextProps) => {
  const modalsContextInitializer = useModalsContextInitializer(otherProps);
  return (
    <modalsContextInitializer.ModalsContextProvider value={modalsContextInitializer.modalsContext}>
      <modalsContextInitializer.ModalsStaticContextProvider value={modalsContextInitializer.modalsStaticContext}>
        {typeof children === 'function'
          ? children(modalsContextInitializer.modalsContext, modalsContextInitializer.modalsStaticContext)
          : children}
      </modalsContextInitializer.ModalsStaticContextProvider>
    </modalsContextInitializer.ModalsContextProvider>
  );
};

export const Modals = () => {
  const { ids, modals, offcanvasses, onlyOneAtATime } = useModalsContext();

  React.useEffect(() => {
    let modalOrOffcanvasToShow;
    const nonClosingModalsOrOffcanvasses = ids.filter((id) => {
      const element = getElementById(id);
      return element.dataset.closing !== 'true';
    });
    if (nonClosingModalsOrOffcanvasses.length === 1) {
      modalOrOffcanvasToShow = nonClosingModalsOrOffcanvasses[0];
    } else if (nonClosingModalsOrOffcanvasses.length >= 1) {
      modalOrOffcanvasToShow = nonClosingModalsOrOffcanvasses.slice(-1)[0];
      const modalsOrOffcanvassesToClose = nonClosingModalsOrOffcanvasses.slice(0, -1);
      // Solo un modale alla volta può essere visibile.
      // Prima di mostrarne uno nuovo, controllo se ce ne è uno vecchio attualmente visibile.
      // In caso positivo nascondo momentaneamente il vecchio (senza chiuderlo) per poi riaprirlo alla chiusura del nuovo.
      if (onlyOneAtATime) {
        modalsOrOffcanvassesToClose.forEach((modalOrOffcanvasToClose) => {
          const elementToClose = getElementById(modalOrOffcanvasToClose);
          const keepForLater = elementToClose.dataset.keepForLater;
          if (keepForLater !== 'false') {
            elementToClose.dataset.keepForLater = 'true';
          }
          const instance = getInstanceById(modalOrOffcanvasToClose);
          instance && instance.hide();
        });
      }
    }
    if (modalOrOffcanvasToShow) {
      const elementToShow = getElementById(modalOrOffcanvasToShow);
      delete elementToShow.dataset.keepForLater;
      const instance = getInstanceById(modalOrOffcanvasToShow);
      instance && instance.show();
    }
  }, [ids, onlyOneAtATime]);

  return (
    <>
      {modals.map((modal) => (
        <ModalWithId {...modal} key={modal.id}>
          <ModalHeader className={modal.headerClassName} closable={modal.closable} title={modal.title} />
          <ModalBody className={modal.bodyClassName}>
            {typeof modal.children !== 'function' && modal.children}
          </ModalBody>
          {modal.buttons && (
            <ModalFooter className={modal.footerClassName}>
              {modal.buttons.map((button, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ModalButton key={index} {...button} />
              ))}
            </ModalFooter>
          )}
        </ModalWithId>
      ))}
      {offcanvasses.map((offcanvas) => (
        <OffcanvasWithId {...offcanvas} key={offcanvas.id}>
          <OffcanvasHeader title={offcanvas.title} />
          <OffcanvasBody>{typeof offcanvas.children !== 'function' && offcanvas.children}</OffcanvasBody>
        </OffcanvasWithId>
      ))}
    </>
  );
};
