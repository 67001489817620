import React from 'react';

import { getDistanceFromTwoPositions } from './util';

export const usePositionsDistanceCalculator = (
  latitude1: number | undefined,
  longitute1: number | undefined,
  latitude2: number | undefined,
  longitude2: number | undefined
) =>
  React.useMemo(
    () =>
      latitude1 !== undefined && longitute1 !== undefined && latitude2 !== undefined && longitude2 !== undefined
        ? getDistanceFromTwoPositions(latitude1, longitute1, latitude2, longitude2)
        : undefined,
    [latitude1, latitude2, longitude2, longitute1]
  );
