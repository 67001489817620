import { b2x } from '@b2x/react/src';

import { Page } from './Page';

export const AccountHomePage = (props: b2x.AccountHomePageProps) => {
  return (
    <Page className="account-home-page" greyBackground noPaddingTop>
      <b2x.AccountHomePage {...props} />
    </Page>
  );
};
