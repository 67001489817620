import classnames from 'classnames';
import React from 'react';
import * as yup from 'yup';

import { usePagesApi } from '../api/usePagesApi';
import { useProductsApi } from '../api/useProductsApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { appConfig } from '../config';
import { SupportedLanguage, t } from '../i18n/i18n';
import { storage } from '../storage';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Select, SelectProps } from './fields/Select';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { LocaleFormA } from './LocaleFormA';
import { LocaleFormB } from './LocaleFormB';

export interface LocaleFormProps
  extends Omit<BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector>, 'onSuccess'> {}

interface FormValues {
  locale: formikString;
  shippingCountry: formikString;
}

type ValidationSchema = {
  locale: yup.StringSchema;
  shippingCountry: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  locale: { formGroup: FormGroupProps; select: SelectProps };
  shippingCountry: { formGroup: FormGroupProps; select: SelectProps };
}

export const LocaleFormHelper = ({ children, className, initialValues, onCancel, ...otherProps }: LocaleFormProps) => {
  const appContext = useAppContext();

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      locale: getInitialString(appContext.locale?.code),
      shippingCountry: getInitialString(appContext.shippingCountry?.code),
      ...initialValues,
    }),
    [appContext.locale?.code, appContext.shippingCountry?.code, initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      locale: yup.string().required(),
      shippingCountry: yup.string().required(),
    }),
    []
  );

  const { getPathInAnotherLocaleByPath } = usePagesApi();
  const { getUrlInAnotherLocaleByPath } = useProductsApi();
  const { routeInfo } = useAppContext();

  const handleSubmit = React.useCallback(
    (values: FormValues) => {
      const locale = values.locale as SupportedLanguage;
      const shippingCountry = values.shippingCountry;
      storage.setString('locale', values.locale, true);
      storage.setString('shippingCountry', values.shippingCountry, true);
      // changeLanguage(locale);

      const newLocation = `/${locale}-${shippingCountry.toLowerCase()}`;

      if (routeInfo?.type === 'product') {
        getUrlInAnotherLocaleByPath(routeInfo.location.pathname, locale, { suppressErrorModal: true })
          .then((response) => {
            window.location.replace(newLocation + response.data);
          })
          .catch(() => {
            window.location.replace(newLocation);
          });
      } else if (
        routeInfo?.type === 'catalog' ||
        routeInfo?.type === 'code' ||
        routeInfo?.type === 'template' ||
        routeInfo?.type === 'listing'
      ) {
        getPathInAnotherLocaleByPath(routeInfo.location.pathname, locale, { suppressErrorModal: true })
          .then((response) => {
            window.location.replace(newLocation + response.data);
          })
          .catch(() => {
            window.location.replace(newLocation);
          });
      } else if (routeInfo?.type === 'fixed') {
        window.location.replace(newLocation + routeInfo.location.pathname);
      } else {
        window.location.replace(newLocation);
      }

      return Promise.resolve();
    },
    [getPathInAnotherLocaleByPath, getUrlInAnotherLocaleByPath, routeInfo?.location.pathname, routeInfo?.type]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('LocaleForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onFieldsChange={{
        shippingCountry: (values, formikHelpers) => {
          const locales =
            appContext.info?.shippingCountriesLocales !== undefined
              ? appContext.info.shippingCountriesLocales[values.shippingCountry]
              : [];
          if (locales !== undefined && locales.length > 0) {
            formikHelpers.setFieldValue('locale', locales[0].code);
          }
        },
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.localeForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.localeForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          locale: {
            formGroup: { label: t('form.localeForm.fields.locale.label'), names: ['locale'] },
            select: {
              includeEmptyOption: false,
              name: 'locale',
              options: appContext.info?.shippingCountriesLocales
                ? appContext.info.shippingCountriesLocales[formik.values.shippingCountry]?.map((locale) => ({
                    label: locale.languageDescriptionLocal,
                    value: locale.code,
                  }))
                : [],
              // options: appContext.info?.supportedLocales?.map((locale) => ({
              //   label: locale.languageDescriptionLocal,
              //   value: locale.code,
              // })),
            },
          },
          shippingCountry: {
            formGroup: { label: t('form.localeForm.fields.shippingCountry.label'), names: ['shippingCountry'] },
            select: {
              includeEmptyOption: false,
              name: 'shippingCountry',
              options: appContext.info?.shippingCountries?.map((country) => ({
                label: country.name,
                value: country.code,
              })),
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.shippingCountry.formGroup}>
              <Select {...fieldsHelper.shippingCountry.select} />
            </FormGroup>
            <FormGroup {...fieldsHelper.locale.formGroup}>
              <Select {...fieldsHelper.locale.select} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type LocaleFormVariants = 'A' | 'B';

const LocaleFormController = (props: PropsWithCustomComponentWithoutChildren<LocaleFormProps>) => (
  <VariantsController<LocaleFormProps, LocaleFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: LocaleFormA, B: LocaleFormB },
      defaultComponent: LocaleFormHelper,
      name: 'LocaleForm',
    }}
  />
);
export { LocaleFormController as LocaleForm };
