import { ContentApiDto, OrderingApiType, SearchContentOrderApiType, SearchContentsApiDto } from '.';
import { PagePopulate } from './pages';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface ContentPopulate {
  pages?: Populate<PagePopulate>;
}

export interface SearchContentsPopulate {
  items?: Populate<ContentPopulate>;
}

const rootPath = '/v2/contents';

export interface SearchContentsOptions extends BaseOptions {
  category?: string;
  friendlyUrl?: string;
  orderBy?: SearchContentOrderApiType;
  orderingType?: OrderingApiType;
  pageNum?: number;
  pageSize?: number;
  populate?: SearchContentsPopulate;
  simpleSearch?: string;
  type?: string;
}

export const search = async <T>(queryKey?: Array<unknown>, options?: SearchContentsOptions) =>
  apiRequest<SearchContentsApiDto<T>, void, SearchContentsPopulate>({
    ...getBaseOptions(options),
    params: {
      category: options?.category,
      friendlyUrl: options?.friendlyUrl,
      orderBy: options?.orderBy,
      orderingType: options?.orderingType,
      pageNum: options?.pageNum,
      pageSize: options?.pageSize,
      simpleSearch: options?.simpleSearch,
      type: options?.type,
    },
    populate: options?.populate,
    queryKey: queryKey,
    staleTime: Infinity,
    url: `${rootPath}`,
  });

export interface GetContentOptions extends BaseOptions {
  forceDefaultLanguage?: boolean;
  populate?: Populate<ContentPopulate>;
}

export const get = async <T>(id: string, options?: GetContentOptions) =>
  apiRequest<ContentApiDto<T>, void, ContentPopulate>({
    ...getBaseOptions(options),
    params: {
      forceDefaultLanguage: options?.forceDefaultLanguage,
    },
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/${id}`,
  });

export const getByCode = async <T>(code: string, options?: GetContentOptions) =>
  apiRequest<ContentApiDto<T>, void, ContentPopulate>({
    ...getBaseOptions(options),
    params: {
      forceDefaultLanguage: options?.forceDefaultLanguage,
    },
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/code/${code}`,
  });

export const getByPath = async <T>(path: string, options?: GetContentOptions) =>
  apiRequest<ContentApiDto<T>, void, ContentPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/path/${path}`,
  });
