export const en = {
  form: {
    newsletterForm: {
      buttons: { submit: { label: 'Subscribe' } },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telephone' },
    },
    productForm: {
      size: 'Size',
    },
  },
  misc: {
    storeName: 'Fiordelisi',
  },
  recipe: {
    cooking: 'Cooking',
    difficulty: 'Difficulty',
    ingredients: 'Ingredients',
    procedure: 'Procedure',
    timing: 'Prep time',
  },
  welcome: 'Welcome to Fiordelisi',
};
