import React from 'react';

import { createContext } from '../util';

interface BrowserRouterContextInterface {
  setBasename: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const [BrowserRouterContextProvider, useBrowserRouterContext] =
  createContext<BrowserRouterContextInterface>('BrowserRouterContext');

interface UseBrowserRouterContextInitializerProps {
  setBasename: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const useBrowserRouterContextInitializer = ({ setBasename }: UseBrowserRouterContextInitializerProps) => {
  const browserRouterContext: BrowserRouterContextInterface = React.useMemo(
    () => ({
      setBasename,
    }),
    [setBasename]
  );

  return {
    BrowserRouterContextProvider,
    browserRouterContext,
  };
};

export interface BrowserRouterContextProps extends UseBrowserRouterContextInitializerProps {
  children: React.ReactNode | ((browserRouterContext: BrowserRouterContextInterface) => React.ReactNode);
}

export const BrowserRouterContext = ({ children, ...otherProps }: BrowserRouterContextProps) => {
  const browserRouterContextInitializer = useBrowserRouterContextInitializer(otherProps);
  return (
    <browserRouterContextInitializer.BrowserRouterContextProvider
      value={browserRouterContextInitializer.browserRouterContext}
    >
      {typeof children === 'function' ? children(browserRouterContextInitializer.browserRouterContext) : children}
    </browserRouterContextInitializer.BrowserRouterContextProvider>
  );
};
