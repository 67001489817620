import React from 'react';

import { createContext } from './util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface AccordionContextInterface {
  accordionColClassName?: string;
  alwaysOpened: boolean;
  columnLayoutFrom?: 'md' | 'lg' | 'xl' | 'xxl';
  id: string;
  itemBodyClassName?: string;
  itemButtonClassName?: string;
  itemClassName?: string;
  itemHeaderClassName?: string;
  withoutXPadding?: boolean;
}

export const [AccordionContextProvider, useAccordionContext] =
  createContext<AccordionContextInterface>('AccordionContext');

// Proprietà necessarie per creare il contesto
interface UseAccordionContextInitializerProps {
  accordionColClassName?: string;
  alwaysOpened: boolean;
  columnLayoutFrom?: 'md' | 'lg' | 'xl' | 'xxl';
  id: string;
  itemBodyClassName?: string;
  itemButtonClassName?: string;
  itemClassName?: string;
  itemHeaderClassName?: string;
  withoutXPadding?: boolean;
}

// Funzione per inizializzare il contesto
const useAccordionContextInitializer = (props: UseAccordionContextInitializerProps) => {
  // Crea l'oggetto accordionContext
  const accordionContext: AccordionContextInterface = { ...props };

  return {
    AccordionContextProvider,
    accordionContext,
  };
};

// Interfaccia componente contesto
export interface AccordionContextProps extends UseAccordionContextInitializerProps {
  children: React.ReactNode | ((accordionContext: AccordionContextInterface) => React.ReactNode);
}

// Componente Contesto
export const AccordionContext = ({ children, ...otherProps }: AccordionContextProps) => {
  const accordionContextInitializer = useAccordionContextInitializer(otherProps);
  return (
    <accordionContextInitializer.AccordionContextProvider value={accordionContextInitializer.accordionContext}>
      {typeof children === 'function' ? children(accordionContextInitializer.accordionContext) : children}
    </accordionContextInitializer.AccordionContextProvider>
  );
};
