import ReactDOM from 'react-dom';

export interface PortalProps {
  children?: React.ReactNode;
  container?: Element;
  enabled?: boolean;
}

export const Portal = ({ children, container, enabled = true }: PortalProps) => {
  return <>{enabled ? ReactDOM.createPortal(children, container ?? document.body) : children}</>;
};
