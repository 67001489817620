import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { CtaContentSection } from './contentTypes';
import { Link, LinkProps } from './router/Link';

export interface DeprecatedCtaProps extends Omit<LinkProps, 'to'> {
  cta: CtaContentSection;
}

const DeprecatedActionButton = ({ cta, ...otherProps }: DeprecatedCtaProps) => {
  const { session } = useAppContext();
  const { getFixedPagePath, getPagePath, onLoginActionCallback } = useAppStaticContext();

  const action = cta.to?.action;
  const isLoggedIn = !!session?.customer;

  let onClick;
  let to;
  switch (action) {
    case 'login':
      if (isLoggedIn) {
        to = getPagePath('SITE_ACCOUNT');
      } else {
        onClick = onLoginActionCallback;
      }
      break;
    case 'register':
      to = isLoggedIn ? getPagePath('SITE_ACCOUNT') : getFixedPagePath('register');
      break;
    case 'cart':
      to = getFixedPagePath('cart');
      break;
    case 'checkout':
      to = getFixedPagePath('checkout');
      break;
    case 'newsletter':
      to = getFixedPagePath('newsletter');
      break;
  }

  return (
    <Button onClick={onClick} style={{ all: 'unset' }}>
      <Link {...otherProps} to={to} />
    </Button>
  );
};

export const DeprecatedCta = ({ cta, ...otherProps }: DeprecatedCtaProps) => {
  const { getPagePath } = useAppStaticContext();
  if (!!cta.to?.action?.trim()) {
    return <DeprecatedActionButton cta={cta} {...otherProps} />;
  }

  const externalLink = cta.to?.href?.toLowerCase().startsWith('http');

  return externalLink ? (
    // eslint-disable-next-line react/forbid-elements
    <a {...otherProps} href={cta.to?.href} rel="noreferrer" target={'_blank'}>
      {otherProps.children}
    </a>
  ) : (
    <Link {...otherProps} to={cta.to?.href ? cta.to.href : cta.to?.code && getPagePath(cta.to.code)}>
      {otherProps.children}
    </Link>
  );
};
