import React from 'react';

import { createContext } from './util';

interface FloatingActionButtonContextInterface {
  addOffset(id: string, value: number): void;
  offset: number;
  removeOffset(id: string): void;
}

export const [FloatingActionButtonContextProvider, useFloatingActionButtonContext] =
  createContext<FloatingActionButtonContextInterface>('FloatingActionButtonContext');

interface UseFloatingActionButtonContextInitializerProps {}

// Funzione per inizializzare il contesto
const useFloatingActionButtonContextInitializer = (props: UseFloatingActionButtonContextInitializerProps) => {
  const [offsets, setOffsets] = React.useState<Record<string, number>>({});

  const addOffset = React.useCallback((id: string, value: number) => {
    setOffsets((prevState) => ({ ...prevState, [id]: value }));
  }, []);

  const removeOffset = React.useCallback((id: string) => {
    setOffsets((prevState) => ({ ...prevState, [id]: 0 }));
  }, []);

  const offset = React.useMemo(
    () => Object.entries(offsets).reduce<number>((accumulator, [, currentOffset]) => accumulator + currentOffset, 0),
    [offsets]
  );

  // Crea l'oggetto floatingActionButtonContext
  const floatingActionButtonContext: FloatingActionButtonContextInterface = React.useMemo(
    () => ({
      addOffset,
      offset,
      removeOffset,
    }),
    [addOffset, offset, removeOffset]
  );

  return {
    FloatingActionButtonContextProvider,
    floatingActionButtonContext,
  };
};

// Interfaccia componente contesto
export interface FloatingActionButtonContextProps extends UseFloatingActionButtonContextInitializerProps {
  children: React.ReactNode | ((floatingActionButtonContext: FloatingActionButtonContextInterface) => React.ReactNode);
}

// Componente Contesto
export const FloatingActionButtonContext = ({ children, ...otherProps }: FloatingActionButtonContextProps) => {
  const floatingActionButtonContextInitializer = useFloatingActionButtonContextInitializer(otherProps);
  return (
    <floatingActionButtonContextInitializer.FloatingActionButtonContextProvider
      value={floatingActionButtonContextInitializer.floatingActionButtonContext}
    >
      {typeof children === 'function'
        ? children(floatingActionButtonContextInitializer.floatingActionButtonContext)
        : children}
    </floatingActionButtonContextInitializer.FloatingActionButtonContextProvider>
  );
};
