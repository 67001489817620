import { b2x } from '@b2x/react/src';

import { HomePageContentType } from '../contentTypes';
import { ImageContent } from '../ImageContent';
import { Newsletter } from '../Newsletter';
import { OurManufacturing } from '../OurManufacturing';
import { OurValues } from '../OurValues';
import { SliderA } from '../slider/SliderA';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  return (
    <Page className="bg-secondary" noPaddingBottom noPaddingTop>
      <>
        <SliderA {...page?.content?.body.mainSection} />
        <div className="pb-5">
          <OurManufacturing {...page?.content?.body.ourManufacturingSection} />
          {page?.products && page.products.length > 0 && (
            <b2x.Listing name={`Home page - ${page.content?.body.ourProductsSection?.title}`} products={page.products}>
              <SliderProducts products={page.products} title={page.content?.body.ourProductsSection?.title} />
            </b2x.Listing>
          )}
        </div>
        <ImageContent {...page?.content?.body.imageContentBoxSection} />
        <OurValues {...page?.content?.body.ourValuesSection} />
        <Newsletter
          subTitle={page?.content?.body.newsletterSection?.subTitle}
          title={page?.content?.body.newsletterSection?.title}
        />
      </>
    </Page>
  );
};
