import { Col } from '../Col';
import { NumberInput, TextInput } from '../form/fields/Input';
import { Checkbox } from '../form/fields/RadioCheck';
import { Select } from '../form/fields/Select';
import {
  Form,
  formikBoolean,
  formikEnum,
  formikNumber,
  formikString,
  getFormikBooleanValue,
  getFormikEnumValue,
  getInitialBoolean,
  getInitialEnum,
  getInitialNumber,
  getInitialString,
} from '../form/Form';
import { FormGroup } from '../form/FormGroup';
import { LoremIpsum } from '../LoremIpsum';
import { Row } from '../Row';
import { Tabs as TabsComponent, TabsProps as TabsComponentProps } from '../Tabs';

export interface TabsProps {}

export const Tabs = (props: TabsProps) => {
  return (
    <Form<{
      contentClassName: formikString;
      direction: formikEnum<TabsComponentProps['direction']>;
      fill: formikBoolean;
      tabStyle: formikEnum<TabsComponentProps['tabStyle']>;
      tabsNumber: formikNumber;
    }>
      initialValues={{
        contentClassName: getInitialString('p-3 border-bottom border-start border-end'),
        direction: getInitialEnum<TabsComponentProps['direction']>('horizontal'),
        fill: getInitialBoolean(false),
        tabStyle: getInitialEnum<TabsComponentProps['tabStyle']>('tabs'),
        tabsNumber: getInitialNumber(3),
      }}
    >
      {({ formik }) => {
        return (
          <Row>
            <Col className="mb-4" size={{ lg: 8, md: 7, sm: 6, xs: 12 }}>
              <TabsComponent
                contentClassName={formik.values.contentClassName}
                direction={getFormikEnumValue<TabsComponentProps['direction']>(formik.values.direction)}
                fill={getFormikBooleanValue(formik.values.fill)}
                tabStyle={getFormikEnumValue<TabsComponentProps['tabStyle']>(formik.values.tabStyle)}
                tabs={[...Array(formik.values.tabsNumber)].map((tab, index) => ({
                  children: <LoremIpsum />,
                  title: `Tab ${index + 1}`,
                }))}
              />
            </Col>
            <Col size={{ lg: 4, md: 5, sm: 6, xs: 12 }}>
              <FormGroup label="Tabs number" names={['tabsNumber']}>
                <NumberInput min={1} name="tabsNumber" />
              </FormGroup>
              <FormGroup label="Tab style" names={['tabStyle']}>
                <Select
                  includeEmptyOption={false}
                  name="tabStyle"
                  options={[{ value: '' }, { value: 'tabs' }, { value: 'pills' }]}
                />
              </FormGroup>
              <FormGroup label="Direction" names={['direction']}>
                <Select
                  includeEmptyOption={false}
                  name="direction"
                  options={[{ value: 'horizontal' }, { value: 'vertical' }]}
                />
              </FormGroup>
              <FormGroup label="Content className" names={['contentClassName']}>
                <TextInput name="contentClassName" />
              </FormGroup>
              <FormGroup label="Fill" names={['fill']}>
                <Checkbox id="fill" name="fill" />
              </FormGroup>
            </Col>
          </Row>
        );
      }}
    </Form>
  );
};
