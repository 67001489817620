import React from 'react';

import { renderUI } from '../util';
import { Feedback } from './Feedback';
import { InputGroupContext } from './InputGroupContext';

export interface InputGroupProps {
  children?: React.ReactNode;
  names: Array<string>;
}

export const InputGroup = ({ children, names }: InputGroupProps) => {
  return renderUI({
    bs5: (
      <div className="InputGroup input-group">
        <InputGroupContext>
          {children}
          {names.map((name) => (
            <Feedback fromInputGroup key={name} name={name} />
          ))}
        </InputGroupContext>
      </div>
    ),
  });
};
