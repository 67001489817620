import React from 'react';

export const useKeyPress = (code: string, callback: () => void) => {
  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if (code === event.code) {
        callback();
      }
    },
    [callback, code]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};
