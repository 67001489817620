import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { RecipePageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { PageHeader } from '../PageHeader';
import { RecipeProduct } from '../RecipeProduct';
import { Page } from './Page';

export interface RecipePageProps {}

export const RecipePage = (props: RecipePageProps) => {
  const page = b2x.usePage<RecipePageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  return (
    <Page noPaddingTop>
      <PageHeader {...page?.content?.body.header} className="mb-3 mb-xl-5" />

      <Container>
        <div className="row justify-content-lg-center">
          <div className="col-lg-10">
            {page?.content?.body.title && (
              <h1 className="display-1 mt-4 mb-3 m-xl-0">{b2x.formatHtml(page.content.body.title)}</h1>
            )}

            <section className="my-4 my-lg-5">
              <div className="row">
                {page?.content?.body.difficulty && (
                  <div className="col-md-4">
                    <div className="icon-box-recipe py-4 bg-gray-100 d-flex flex-row justify-content-center mb-3 mb-md-0">
                      <Icon className="me-3" name="chef" size={48} />
                      <div>
                        <h3 className="text-primary mb-0">{t('recipe.difficulty')}</h3>
                        <h6 className="mb-0 text-gray-300">{b2x.formatHtml(page.content.body.difficulty)}</h6>
                      </div>
                    </div>
                  </div>
                )}
                {page?.content?.body.prepTime && (
                  <div className="col-md-4">
                    <div className="icon-box-recipe py-4 bg-gray-100 d-flex flex-row justify-content-center mb-3 mb-md-0">
                      <Icon className="me-3" name="timing" size={48} />
                      <div>
                        <h3 className="text-primary mb-0">{t('recipe.timing')}</h3>
                        <h6 className="mb-0 text-gray-300">{b2x.formatHtml(page.content.body.prepTime)}</h6>
                      </div>
                    </div>
                  </div>
                )}
                {page?.content?.body.cookingType && (
                  <div className="col-md-4">
                    <div className="icon-box-recipe py-4 bg-gray-100 d-flex flex-row justify-content-center">
                      <Icon className="me-3" name="cooking" size={48} />
                      <div>
                        <h3 className="text-primary mb-0">{t('recipe.cooking')}</h3>
                        <h6 className="mb-0 text-gray-300">{b2x.formatHtml(page.content.body.cookingType)}</h6>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>

            <section>
              <div className="row">
                <div className="col-md-4">
                  {page?.content?.body.recipeImage && (
                    <b2x.ImageFromContentV1 {...page.content.body.recipeImage} className="mt-0 mb-4 mb-md-0" fluid />
                  )}
                </div>
                <div className="col-md-8">
                  {page?.content?.body.ingredients && (
                    <div className="mb-5 ms-lg-4">
                      <h3 className="h2 text-primary">{t('recipe.ingredients')}</h3>
                      {b2x.formatHtml(page.content.body.ingredients)}
                    </div>
                  )}
                  {page?.content?.body.procedure && (
                    <div className="mb-5 ms-lg-4">
                      <h3 className="h2 text-primary">{t('recipe.procedure')}</h3>
                      {b2x.formatHtml(page.content.body.procedure)}
                    </div>
                  )}

                  {page?.products && page.products.length > 0 && <RecipeProduct product={page.products[0]} />}
                </div>
              </div>
            </section>
          </div>
        </div>
      </Container>
    </Page>
  );
};
