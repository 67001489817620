import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { Icon, IconName, isIconName } from '../Icon';
import { Slider } from './Slider';

export interface SliderVerticalProps {
  items?: Array<{
    href?: string;
    icon?: string;
    label: string;
  }>;
}
export const SliderVertical = ({ items }: SliderVerticalProps) => {
  return (
    <Slider
      autoHeight
      className="SliderVertical"
      //      height={auto}
      sliderAutoPlay={items && items.length > 1 ? 3000 : undefined}
      slidesPerView={1}
      vertical
    >
      {items &&
        items.map((item, index) => (
          <SwiperSlide
            className="d-flex justify-content-center align-items-center extra-small text-uppercase"
            key={item.label}
          >
            <b2x.router.Link className="text-white text-decoration-none" to={item.href}>
              {isIconName(item.icon) && <Icon className="me-1" name={item.icon as IconName} size={25} />}
              {item.label}
            </b2x.router.Link>
          </SwiperSlide>
        ))}
    </Slider>
  );
};
