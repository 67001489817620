import { CartSkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useAppContext } from './AppContext';
import { BadgeProps } from './Badge';
import { appConfig } from './config';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { formatCurrency, getProductShippingCost } from './util';

export interface UseCartSkuBadgesProps {
  cartSku: CartSkuApiDto;
}

export interface UseCartSkuBadgesResult<ThemeColor> {
  cartSkuBadges: Array<BadgeProps<ThemeColor>>;
}

export const useCartSkuBadges = <ThemeColor extends string>({
  cartSku,
}: UseCartSkuBadgesProps): UseCartSkuBadgesResult<ThemeColor> => {
  const freeShippingTag = cartSku.sku?.tags?.includes('CART_FREE_SHIPPING');
  const discountValueTag = cartSku.sku?.tags?.includes('CART_DISCOUNT_VALUE');

  const { shippingCountry } = useAppContext();

  const totalSavings = React.useMemo<number | undefined>(() => {
    if (discountValueTag) {
      if (cartSku.price?.discountValue !== undefined) {
        if (appConfig.includeFreeShippingInSavingAmount && cartSku.sku?.product && shippingCountry) {
          const shippingCost = getProductShippingCost(cartSku.sku.product, shippingCountry.code);
          return cartSku.price.discountValue * cartSku.quantity + (shippingCost ?? 0);
        } else {
          return cartSku.price.discountValue * cartSku.quantity;
        }
      }
    }
    return undefined;
  }, [cartSku.price?.discountValue, cartSku.quantity, cartSku.sku?.product, discountValueTag, shippingCountry]);

  const cartSkuBadges = React.useMemo<Array<BadgeProps<ThemeColor>>>(() => {
    const _cartSkuBadges: Array<BadgeProps<ThemeColor>> = [];
    if (freeShippingTag) {
      _cartSkuBadges.push({
        children: (
          <>
            {appConfig.icons?.shipping && <Icon name={appConfig.icons.shipping} />}
            {` ${t('cart.badges.freeShipping')}`}
          </>
        ),
        className: 'free-shipping',
      });
    }
    if (discountValueTag && totalSavings) {
      _cartSkuBadges.push({
        children: (
          <>
            {appConfig.icons?.discount && <Icon name={appConfig.icons.discount} />}
            {` ${t('cart.badges.discountValue', {
              value: formatCurrency(totalSavings),
            })}`}
          </>
        ),
        className: 'discount',
      });
    }
    return _cartSkuBadges;
  }, [discountValueTag, freeShippingTag, totalSavings]);

  return { cartSkuBadges };
};
