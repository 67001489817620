import { AccountReferralSubpageContentType } from '../../../contentTypes';
import { ReferralBox } from '../../../ReferralBox';
import { AccountSubpage } from '../AccountSubpage';

export const AccountReferralSubpageA = () => {
  return (
    <AccountSubpage<AccountReferralSubpageContentType>>
      {(page) => {
        console.log(page);
        return <ReferralBox {...page.content?.body.referralBox} />;
      }}
    </AccountSubpage>
  );
};
