import { AccountCreditCardsSubpageProps } from '.././AccountCreditCardsSubpage';
import { AccountSubpage } from '../AccountSubpage';
import { Box } from './Box';

export const AccountCreditCardsSubpageA = (props: AccountCreditCardsSubpageProps) => {
  return (
    <AccountSubpage>
      <Box>Credit Cart Subpage</Box>
    </AccountSubpage>
  );
};
