import { baim } from '@b2x/baim/src';
import {
  ContentApiDto,
  CountryApiDto,
  FilterApiDto,
  InfoApiDto,
  LocaleApiDto,
  RouteApiDto,
  SessionApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { StickersProductContentType, StructuredDataContentType } from './contentTypes';
import { FixedPath, RouteInfo, TaggedPageTag } from './router/MainRouter';

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

interface AppContextInterface {
  appReady: boolean;
  dynamicSegment404: boolean;
  fetching: number;
  hasMultipleLocalesOrShippingCountries?: boolean;
  info?: InfoApiDto;
  locale?: LocaleApiDto;
  mainCategory?: FilterApiDto;
  pageReady: boolean;
  routeInfo?: RouteInfo;
  routesMapByCode?: Record<string, RouteApiDto>;
  session?: SessionApiDto;
  shippingCountry?: CountryApiDto;
  staticContextChecker: number;
  stickersProductContent?: ContentApiDto<StickersProductContentType>;
  structuredDataContent?: ContentApiDto<StructuredDataContentType>;
  supportedLocales?: Array<LocaleApiDto>;
  supportedShippingCountries?: Array<CountryApiDto>;
  thingsToLoadBeforeAppReady: Record<string, unknown>;
}

export const [AppContextProvider, useAppContext] = createContext<AppContextInterface>('AppContext');

interface AppStaticContextInterface {
  getFixedPagePath(fixedPath: FixedPath): string;
  getPagePath(code: string): string | undefined;
  getTaggedPagePath(tag: TaggedPageTag): string | undefined;
  onAddToCartSuccess?(): void;
  onAddToWishlistAsGuestButtonClick?(): void;
  onLoginActionCallback?(): void;
  setAppReady: React.Dispatch<React.SetStateAction<boolean>>;
  setDynamicSegment404: React.Dispatch<React.SetStateAction<boolean>>;
  setFetching: React.Dispatch<React.SetStateAction<number>>;
  setInfo: React.Dispatch<React.SetStateAction<InfoApiDto | undefined>>;
  setLocale: React.Dispatch<React.SetStateAction<LocaleApiDto | undefined>>;
  setMainCategory: React.Dispatch<React.SetStateAction<FilterApiDto | undefined>>;
  setPageReady: React.Dispatch<React.SetStateAction<boolean>>;
  setRouteInfo: React.Dispatch<React.SetStateAction<RouteInfo | undefined>>;
  setRoutesMapByCode: React.Dispatch<React.SetStateAction<Record<string, RouteApiDto> | undefined>>;
  setRoutesMapByTaggedPageTag: React.Dispatch<
    React.SetStateAction<Partial<Record<TaggedPageTag, RouteApiDto>> | undefined>
  >;
  setSession: React.Dispatch<React.SetStateAction<SessionApiDto | undefined>>;
  setShippingCountry: React.Dispatch<React.SetStateAction<CountryApiDto | undefined>>;
  setStaticContextChecker: React.Dispatch<React.SetStateAction<number>>;
  setStickersProductContent: React.Dispatch<
    React.SetStateAction<ContentApiDto<StickersProductContentType> | undefined>
  >;
  setStructuredDataContent: React.Dispatch<React.SetStateAction<ContentApiDto<StructuredDataContentType> | undefined>>;
  setSupportedLocales: React.Dispatch<React.SetStateAction<Array<LocaleApiDto> | undefined>>;
  setSupportedShippingCountries: React.Dispatch<React.SetStateAction<Array<CountryApiDto> | undefined>>;
  setThingsToLoadBeforeAppReady: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
}

export const [AppStaticContextProvider, useAppStaticContext] =
  createContext<AppStaticContextInterface>('AppStaticContext');

interface UseAppContextInitializerProps {
  onAddToCartSuccess?(): void;
  onAddToWishlistAsGuestButtonClick?(): void;
  onLoginActionCallback?(): void;
}

const useAppContextInitializer = ({
  onAddToCartSuccess,
  onAddToWishlistAsGuestButtonClick,
  onLoginActionCallback,
}: UseAppContextInitializerProps) => {
  const [session, setSession] = React.useState<SessionApiDto>();
  const [info, setInfo] = React.useState<InfoApiDto>();
  const [fetching, setFetching] = React.useState<number>(0);
  const [thingsToLoadBeforeAppReady, setThingsToLoadBeforeAppReady] = React.useState<Record<string, unknown>>({});
  const [appReady, setAppReady] = React.useState<boolean>(false);
  const [pageReady, setPageReady] = React.useState<boolean>(false);
  const [routeInfo, setRouteInfo] = React.useState<RouteInfo>();
  const [locale, setLocale] = React.useState<LocaleApiDto>();
  const [supportedLocales, setSupportedLocales] = React.useState<Array<LocaleApiDto>>();
  const [shippingCountry, setShippingCountry] = React.useState<CountryApiDto>();
  const [supportedShippingCountries, setSupportedShippingCountries] = React.useState<Array<CountryApiDto>>();
  const [routesMapByCode, setRoutesMapByCode] = React.useState<Record<string, RouteApiDto>>();
  const [routesMapByTaggedPageTag, setRoutesMapByTaggedPageTag] =
    React.useState<Partial<Record<TaggedPageTag, RouteApiDto>>>();
  const [stickersProductContent, setStickersProductContent] =
    React.useState<ContentApiDto<StickersProductContentType>>();
  const [structuredDataContent, setStructuredDataContent] = React.useState<ContentApiDto<StructuredDataContentType>>();
  const [mainCategory, setMainCategory] = React.useState<FilterApiDto | undefined>();
  const [dynamicSegment404, setDynamicSegment404] = React.useState<boolean>(false);
  const [staticContextChecker, setStaticContextChecker] = React.useState<number>(0);

  const getPagePath = React.useCallback(
    (code: string) => {
      const route = routesMapByCode && routesMapByCode[code];
      return route?.fullPath;
    },
    [routesMapByCode]
  );
  const getTaggedPagePath = React.useCallback(
    (tag: TaggedPageTag) => {
      const route = routesMapByTaggedPageTag && routesMapByTaggedPageTag[tag];
      return route && route.fullPath;
    },
    [routesMapByTaggedPageTag]
  );
  const getFixedPagePath = React.useCallback((fixedPath: FixedPath) => `/${fixedPath}`, []);

  const hasMultipleLocalesOrShippingCountries = React.useMemo(
    () =>
      supportedLocales !== undefined && supportedShippingCountries !== undefined
        ? supportedLocales.length > 1 || supportedShippingCountries.length > 1
        : undefined,
    [supportedLocales, supportedShippingCountries]
  );

  const _setSession = React.useCallback((_session: React.SetStateAction<SessionApiDto | undefined>) => {
    if (typeof _session === 'object') {
      baim.updateUser(_session.customer?.id);
      setSession(_session);
    } else if (typeof _session === 'function') {
      setSession((prevState) => {
        const newState = _session(prevState);
        baim.updateUser(newState?.customer?.id);
        return newState;
      });
    } else {
      setSession(_session);
    }
  }, []);

  const appContext: AppContextInterface = React.useMemo(
    () => ({
      appReady,
      dynamicSegment404,
      fetching,
      hasMultipleLocalesOrShippingCountries,
      info,
      locale,
      mainCategory,
      pageReady,
      routeInfo,
      routesMapByCode,
      session,
      shippingCountry,
      staticContextChecker,
      stickersProductContent,
      structuredDataContent,
      supportedLocales,
      supportedShippingCountries,
      thingsToLoadBeforeAppReady,
    }),
    [
      appReady,
      dynamicSegment404,
      fetching,
      hasMultipleLocalesOrShippingCountries,
      info,
      locale,
      mainCategory,
      pageReady,
      routeInfo,
      session,
      shippingCountry,
      routesMapByCode,
      stickersProductContent,
      structuredDataContent,
      staticContextChecker,
      supportedLocales,
      supportedShippingCountries,
      thingsToLoadBeforeAppReady,
    ]
  );

  const appStaticContext: AppStaticContextInterface = React.useMemo(
    () => ({
      getFixedPagePath,
      getPagePath,
      getTaggedPagePath,
      onAddToCartSuccess,
      onAddToWishlistAsGuestButtonClick,
      onLoginActionCallback,
      setAppReady,
      setDynamicSegment404,
      setFetching,
      setInfo,
      setLocale,
      setMainCategory,
      setPageReady,
      setRouteInfo,
      setRoutesMapByCode,
      setRoutesMapByTaggedPageTag,
      setSession: _setSession,
      setShippingCountry,
      setStaticContextChecker,
      setStickersProductContent,
      setStructuredDataContent,
      setSupportedLocales,
      setSupportedShippingCountries,
      setThingsToLoadBeforeAppReady,
    }),
    [
      _setSession,
      getFixedPagePath,
      getPagePath,
      getTaggedPagePath,
      onAddToCartSuccess,
      onAddToWishlistAsGuestButtonClick,
      onLoginActionCallback,
    ]
  );

  return {
    AppContextProvider,
    AppStaticContextProvider,
    appContext,
    appStaticContext,
  };
};

export interface AppContextProps extends UseAppContextInitializerProps {
  children:
    | React.ReactNode
    | ((appContext: AppContextInterface, appStaticContext: AppStaticContextInterface) => React.ReactNode);
}

export const AppContext = ({ children, ...otherProps }: AppContextProps) => {
  const appContextInitializer = useAppContextInitializer(otherProps);

  return (
    <appContextInitializer.AppContextProvider value={appContextInitializer.appContext}>
      <appContextInitializer.AppStaticContextProvider value={appContextInitializer.appStaticContext}>
        {typeof children === 'function'
          ? children(appContextInitializer.appContext, appContextInitializer.appStaticContext)
          : children}
      </appContextInitializer.AppStaticContextProvider>
    </appContextInitializer.AppContextProvider>
  );
};
