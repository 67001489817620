import { CartSkuApiDto } from '@b2x/storefront-api-js-client/src';

import { CartSkuA } from './CartSkuA';
import { CartSkuB } from './CartSkuB';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface CartSkuProps {
  cartSku: CartSkuApiDto;
  compactLayout?: boolean;
  hideProductName?: boolean;
  hideSkuName?: boolean;
  hideVariantAttributes?: boolean;
  index: number;
  readOnly?: boolean;
}

export type CartSkuVariants = 'A' | 'B';

const CartSkuController = (props: PropsWithCustomComponent<CartSkuProps>) => (
  <VariantsController<CartSkuProps, CartSkuVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CartSkuA, B: CartSkuB },
      defaultComponent: CartSkuA,
      name: 'CartSku',
    }}
  />
);
export { CartSkuController as CartSku };
