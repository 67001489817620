import classnames from 'classnames';
import React from 'react';

import { Button, ButtonProps } from './Button';
import { appConfig } from './config';
import { Breakpoint } from './interfaces';
import { ModalContext, ModalContextInterface, useModalContext } from './ModalContext';
import { useModals } from './useModals';
import { createUUID, renderUI, wait } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export type ModalSize = 'small' | 'default' | 'large' | 'extra-large';
export type ModalFullScreen = Exclude<Breakpoint, 'xs'> | 'always';

export interface ModalProps {
  autoClose?: number; // in seconds
  backdrop?: boolean | 'static';
  centered?: boolean;
  children?: React.ReactNode | ((modalContext: ModalContextInterface) => React.ReactNode);
  className?: string;
  closable?: boolean;
  fade?: boolean;
  fullscreen?: ModalFullScreen;
  innerRef?: React.RefObject<HTMLDivElement>;
  onClose?(): void;
  scrollable?: boolean;
  size?: ModalSize;
}

export const Modal = (props: ModalProps) => {
  const id = React.useMemo(() => createUUID(), []);
  return <ModalWithId {...props} id={id} />;
};

export interface ModalWithIdProps extends ModalProps {
  id: string;
}

export const ModalWithId = ({
  autoClose,
  backdrop,
  centered,
  children,
  className,
  closable = true,
  fade = true,
  fullscreen,
  id,
  innerRef,
  onClose,
  scrollable,
  size,
}: ModalWithIdProps) => {
  const { addEventListener, close } = useModals();

  React.useEffect(() => {
    if (onClose) {
      return addEventListener(id, 'hidden', 'modal', onClose);
    }
  }, [addEventListener, id, onClose]);

  React.useEffect(() => {
    autoClose &&
      addEventListener(id, 'shown', 'modal', () => {
        wait(autoClose * 1000).then(() => {
          close(id);
        });
      });
  }, [addEventListener, autoClose, close, id, onClose]);

  return (
    <ModalContext id={id}>
      {(modalContext) =>
        renderUI({
          bs5: (
            <div
              aria-hidden="true"
              aria-labelledby={`${id}-label`}
              className={classnames('modal', { fade: fade }, className)}
              data-bs-backdrop={!closable ? 'static' : backdrop}
              data-bs-keyboard={!closable ? false : true}
              id={id}
              ref={innerRef}
              tabIndex={-1}
            >
              <div
                className={classnames(
                  'modal-dialog',
                  { 'modal-dialog-scrollable': scrollable },
                  { 'modal-dialog-centered': centered },
                  { 'modal-sm': size === 'small' },
                  { 'modal-lg': size === 'large' },
                  { 'modal-xl': size === 'extra-large' },
                  { 'modal-fullscreen-sm-down': fullscreen === 'sm' },
                  { 'modal-fullscreen-md-down': fullscreen === 'md' },
                  { 'modal-fullscreen-lg-down': fullscreen === 'lg' },
                  { 'modal-fullscreen-xl-down': fullscreen === 'xl' },
                  { 'modal-fullscreen-xxl-down': fullscreen === 'xxl' },
                  { 'modal-fullscreen': fullscreen === 'always' }
                )}
              >
                <div className="modal-content">
                  {typeof children === 'function' ? children(modalContext) : children}
                </div>
              </div>
            </div>
          ),
        })
      }
    </ModalContext>
  );
};

export interface PackedModalProps extends ModalWithIdProps {
  bodyClassName?: string;
  buttons?: Array<ModalButtonProps>;
  footerClassName?: string;
  headerClassName?: string;
  title: string;
}

export interface ModalHeaderProps {
  className?: string;
  closable?: boolean;
  title: string;
}

const ModalHeader = ({ className, closable = true, title }: ModalHeaderProps) => {
  const { id } = useModalContext();
  return renderUI({
    bs5: (
      <div className={classnames(className, 'modal-header')}>
        <h5 className="modal-title" id={`${id}-label`}>
          {title}
        </h5>
        {closable && (
          <Button
            aria-label="Close"
            data-bs-dismiss="modal"
            iconEnd={appConfig.icons ? { name: appConfig.icons.close } : undefined}
            type="button"
            variant="blank"
          />
        )}
      </div>
    ),
  });
};

export interface ModalBodyProps {
  children?: React.ReactNode;
  className?: string;
}

export const ModalBody = ({ children, className }: ModalBodyProps) => {
  return renderUI({
    bs5: <div className={classnames(className, 'modal-body')}>{children}</div>,
  });
};

export interface ModalFooterProps {
  children?: React.ReactNode;
  className?: string;
}

const ModalFooter = ({ children, className }: ModalFooterProps) => {
  return renderUI({
    bs5: <div className={classnames(className, 'modal-footer')}>{children}</div>,
  });
};

export interface ModalButtonProps extends ButtonProps<string, string, number> {
  close?: boolean;
}

export const ModalButton = ({ close, onClick, ...otherProps }: ModalButtonProps) => {
  const { close: closeModal } = useModalContext();

  const handleButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick && onClick(event);
      close && closeModal();
    },
    [close, closeModal, onClick]
  );

  return <Button {...otherProps} onClick={handleButtonClick} />;
};

export type ModalHeaderVariants = '';

const ModalHeaderController = (props: PropsWithCustomComponent<ModalHeaderProps>) => (
  <VariantsController<ModalHeaderProps, ModalHeaderVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ModalHeader,
      name: 'ModalHeader',
    }}
  />
);
export { ModalHeaderController as ModalHeader };

export type ModalFooterVariants = '';

const ModalFooterController = (props: PropsWithCustomComponent<ModalFooterProps>) => (
  <VariantsController<ModalFooterProps, ModalFooterVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ModalFooter,
      name: 'ModalFooter',
    }}
  />
);

export { ModalFooterController as ModalFooter };

export type UseFormModalProps = Partial<Omit<PackedModalProps, 'children' | 'id'>>;

// export type UseFormModal<FormProps> = (modalProps?: UseFormModalProps) => (formProps?: FormProps) => void;
