import { useAppContext } from '../AppContext';
import { CartPacks } from '../CartPacks';
import { CheckoutContext, CheckoutStep as CheckoutStepType } from '../CheckoutContext';
import { Col } from '../Col';
import { Container } from '../Container';
import { CheckoutStepsContentType } from '../contentTypes';
import { Div, H6, Span } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { OffersList } from '../OffersList';
import { Link } from '../router/Link';
import { Row } from '../Row';
import { useContent } from '../useContent';
import { formatCurrency, renderUI } from '../util';
import { CheckoutPageProps } from './CheckoutPage';
import { CheckoutAddressStep } from './checkoutSteps/CheckoutAddressStep';
import { CheckoutEmailStep } from './checkoutSteps/CheckoutEmailStep';
import { CheckoutPaymentMethodStep } from './checkoutSteps/CheckoutPaymentMethodStep';
import { CheckoutStep } from './checkoutSteps/checkoutStepsA/CheckoutStep';
import { CheckoutSummaryStep } from './checkoutSteps/CheckoutSummaryStep';

export const CheckoutPageA = ({ additionaContent }: CheckoutPageProps) => {
  const steps: Array<CheckoutStepType> = ['email', 'shippingAddress', 'billingAddress', 'paymentMethod', 'summary'];
  const checkoutStepsContent = useContent<CheckoutStepsContentType>('CHECKOUT_STEPS_CONTENT');

  const { info, shippingCountry } = useAppContext();

  return renderUI({
    bs5: (
      <CheckoutContext steps={steps}>
        {({ cart, stepsInfo }) => (
          <Container>
            <Row>
              <Col size={{ lg: 8, xs: 12 }}>
                {cart &&
                  stepsInfo &&
                  stepsInfo
                    .filter((stepInfo, index) => index + 1 !== stepsInfo.length)
                    .map(({ completed, current, editDisabled, step }, index) => (
                      <CheckoutStep
                        completed={completed}
                        enabled={completed || current}
                        hideEditButton={editDisabled}
                        key={step}
                        number={index + 1}
                        onEdit={current}
                        step={step}
                      >
                        {step === 'email' ? (
                          <CheckoutEmailStep
                            additionalContent={additionaContent?.email}
                            content={checkoutStepsContent?.body.email}
                            onEdit={current}
                          />
                        ) : step === 'shippingAddress' ? (
                          <CheckoutAddressStep
                            additionalContent={additionaContent?.shippingAddress}
                            content={checkoutStepsContent?.body.shippingAddress}
                            onEdit={current}
                            type="shipping"
                          />
                        ) : step === 'billingAddress' ? (
                          <CheckoutAddressStep
                            additionalContent={additionaContent?.billingAddress}
                            content={checkoutStepsContent?.body.billingAddress}
                            onEdit={current}
                            type="billing"
                          />
                        ) : step === 'paymentMethod' ? (
                          <CheckoutPaymentMethodStep
                            additionalContent={additionaContent?.paymentMethod}
                            content={checkoutStepsContent?.body.paymentMethod}
                            onEdit={current}
                          />
                        ) : (
                          <></>
                        )}
                      </CheckoutStep>
                    ))}
                <div className="d-none d-lg-block my-4">
                  {stepsInfo && (
                    <CheckoutSummaryStep
                      additionalContent={additionaContent?.summary}
                      content={checkoutStepsContent?.body.summary}
                      onEdit={stepsInfo[stepsInfo.length - 1].current}
                    />
                  )}
                </div>
              </Col>
              <Col size={{ lg: 4, xs: 12 }}>
                {cart && (
                  <Div className="checkout-summary">
                    <div className="bg-white p-3">
                      <Div alignItems="start" display="flex" justifyContent="spaceBetween">
                        <h5>{t('checkout.summary.title')}</h5>
                        <Link className="text-decoration-underline extra-small text-dark btn-sm" to="/cart">
                          {t('checkout.summary.cartItem.edit')}
                        </Link>
                      </Div>
                      <Div className="checkout-subtotal" marginBottom={3} uiClassName={{ bs5: 'border-bottom' }}>
                        {!!cart.offers?.length && (
                          <Div marginTop={2} paddingBottom={3}>
                            <OffersList offers={cart.offers.filter((offer) => !offer.showOnCartSku)} />
                          </Div>
                        )}
                      </Div>
                      <Div className="checkout-coupon" marginBottom={3} uiClassName={{ bs5: 'border-bottom' }}>
                        <Div display={'flex'} justifyContent={'spaceBetween'} marginBottom={3}>
                          <div>
                            <strong>{t('checkout.summary.coupon.title')}</strong>
                          </div>
                          <Link className="text-decoration-underline extra-small text-dark btn-sm" to="/cart">
                            {cart.coupon ? t('checkout.summary.coupon.edit') : t('checkout.summary.coupon.add')}
                          </Link>
                        </Div>
                        {cart.coupon && <Div marginBottom={3}>{cart.coupon.code}</Div>}
                      </Div>
                      {info?.paymentMethods?.includes('GIFT_CARD') && (
                        <Div className="checkout-gift-card" marginBottom={3} uiClassName={{ bs5: 'border-bottom' }}>
                          <Div display={'flex'} justifyContent={'spaceBetween'} marginBottom={3}>
                            <div>
                              <strong>{t('checkout.summary.giftCard.title')}</strong>
                            </div>
                            <Link className="text-decoration-underline extra-small text-dark btn-sm" to="/cart">
                              {cart.depositPayments && cart.depositPayments.length > 0
                                ? t('checkout.summary.giftCard.edit')
                                : t('checkout.summary.giftCard.add')}
                            </Link>
                          </Div>
                          {cart.depositPayments && cart.depositPayments.length > 0 && (
                            <Div marginBottom={3}>
                              {cart.depositPayments.map((depositPayment) => (
                                <Div display="flex" justifyContent="spaceBetween" key={depositPayment.code}>
                                  <div>
                                    <span className="gift-card-label">
                                      {depositPayment.type === 'GIFT' && `${t('misc.giftCard.label')}: `}
                                    </span>
                                    <span className="gift-card-code">{depositPayment.code}</span>
                                  </div>
                                  <Div display="flex">{formatCurrency(depositPayment.value)}</Div>
                                </Div>
                              ))}
                            </Div>
                          )}
                        </Div>
                      )}
                      {/* <Div
                        className="checkout-shipping"
                        marginBottom={3}
                        paddingBottom={3}
                        uiClassName={{ bs5: 'border-bottom' }}
                      >
                        {cart.totalDiscount > 0 && (
                          <>
                            <Div
                              display="flex"
                              justifyContent={'spaceBetween'}
                              marginBottom={1}
                              uiClassName={{ bs5: 'text-offer' }}
                            >
                              <Div>{t('cart.summary.totalProductsCost')}</Div>
                              <Div>{formatCurrency(cart.productsCost + cart.totalDiscount)}</Div>
                            </Div>
                            <Div
                              display={'flex'}
                              justifyContent={'spaceBetween'}
                              marginBottom={1}
                              uiClassName={{ bs5: 'text-offer' }}
                            >
                              <Div>{t('cart.summary.totalDiscount')}</Div>
                              <Div>- {formatCurrency(cart.totalDiscount)}</Div>
                            </Div>
                          </>
                        )}
                        <Div display={'flex'} justifyContent={'spaceBetween'} marginBottom={1}>
                          <div>{t('checkout.summary.subtotal')}</div>
                          <div>{cart.productsCost && formatCurrency(cart.productsCost)}</div>
                        </Div>
                        <Div display={'flex'} justifyContent={'spaceBetween'}>
                          <div>
                            {t('checkout.summary.shipping.title')}
                            {cart.shippingProfile?.description && ` (${cart.shippingProfile.description})`}
                          </div>
                          <div>{formatCurrency(cart.shippingCost, { showLabelIfZero: true })}</div>
                        </Div>
                        {info?.shippingCountries && info.shippingCountries.length > 1 && (
                          <Div>{`${t('checkout.summary.shippingCountry.title')}: ${shippingCountry?.name}`}</Div>
                        )}
                      </Div> */}
                      <Div className="cart-total" marginBottom={3} paddingY={3} uiClassName={{ bs5: 'border-bottom' }}>
                        <Div display={'flex'} flexDirection={'column'} gap={0}>
                          <Div display="flex" justifyContent={'spaceBetween'}>
                            <Div>{t('cart.summary.totalProductsCost')}</Div>
                            <Div>{formatCurrency(cart.productsCrossedOutPrice)}</Div>
                          </Div>
                          {cart.totalDiscount > 0 && (
                            <Div display="flex" justifyContent={'spaceBetween'}>
                              <Div>{t('cart.summary.totalDiscount')}</Div>
                              <Div>-{formatCurrency(cart.totalDiscount)}</Div>
                            </Div>
                          )}
                          <Div display="flex" justifyContent={'spaceBetween'} marginBottom={3}>
                            <Div>{t('cart.summary.subtotal')}</Div>
                            <Div>{formatCurrency(cart.productsCost)}</Div>
                          </Div>
                          <Div display="flex" justifyContent={'spaceBetween'}>
                            <Div>
                              {t('cart.summary.shippingProfiles.title')}{' '}
                              {cart.shippingProfile?.description && ` (${cart.shippingProfile.description})`}
                            </Div>
                            <Div>
                              {cart.shippingCost > 0
                                ? formatCurrency(cart.shippingCost)
                                : t('cart.summary.freeShipping')}
                            </Div>
                          </Div>
                          {info?.shippingCountries && info.shippingCountries.length > 0 && (
                            <Div uiClassName={{ bs5: 'extra-small' }}>{`${t(
                              'checkout.summary.shippingCountry.title'
                            )}: ${shippingCountry?.name}`}</Div>
                          )}
                          {cart.totalDepositPayments > 0 && (
                            <Div display="flex" justifyContent={'spaceBetween'}>
                              <Div>{t('cart.summary.giftCard')}</Div>
                              <Div>-{formatCurrency(cart.totalDepositPayments)}</Div>
                            </Div>
                          )}
                        </Div>
                      </Div>
                      {cart.codShippingCost > 0 && (
                        <Div
                          className="cart-additional-cost"
                          dynamicPadding={{ bottom: 3 }}
                          marginBottom={3}
                          uiClassName={{ bs5: 'border-bottom' }}
                        >
                          <H6 dynamicMargin={{ bottom: 2 }}>
                            <strong>{t('cart.summary.additional.title')}</strong>
                          </H6>
                          <Div display="flex" justifyContent="spaceBetween">
                            <Span>{t('cart.summary.additional.cashOnDelivery')}</Span>
                            <Span>{formatCurrency(cart.codShippingCost)}</Span>
                          </Div>
                        </Div>
                      )}
                      <Div
                        className="checkout-total"
                        marginBottom={3}
                        paddingBottom={3}
                        uiClassName={{ bs5: 'border-bottom' }}
                      >
                        <Div display={'flex'} justifyContent={'spaceBetween'}>
                          <strong>{t('checkout.summary.totalCost')}</strong>
                          <strong>{formatCurrency(cart.totalCost)}</strong>
                        </Div>
                      </Div>
                      <Div className="checkout-products">
                        <Div display={'flex'} justifyContent="spaceBetween">
                          <div>
                            <strong>{t('checkout.summary.cartItem.title')}</strong>
                          </div>
                        </Div>
                        {cart.packs && <CartPacks compactLayout packs={cart.packs} readOnly />}
                      </Div>
                    </div>
                    <div className="checkout-summary-step-mobile d-lg-none my-4">
                      {stepsInfo && (
                        <CheckoutSummaryStep
                          additionalContent={additionaContent?.summary}
                          content={checkoutStepsContent?.body.summary}
                          onEdit={stepsInfo[stepsInfo.length - 1].current}
                        />
                      )}
                    </div>
                  </Div>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </CheckoutContext>
    ),
  });
};
