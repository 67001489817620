import { PayPalCapturePaymentResponseApiDto, PayPalCreateOrderResponseApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions } from './utils/util';

const rootPath = '/v2/paypal';

export const createOrder = async (paymentTransactionId: string, options?: BaseOptions) =>
  apiRequest<PayPalCreateOrderResponseApiDto, void, void>({
    ...getBaseOptions(options),
    method: 'POST',
    params: {
      paymentTransactionId: paymentTransactionId,
    },
    url: `${rootPath}/orders`,
  });

export const capturePayment = async (orderId: string, paymentTransactionId: string, options?: BaseOptions) =>
  apiRequest<PayPalCapturePaymentResponseApiDto, void, void>({
    ...getBaseOptions(options),
    method: 'POST',
    params: {
      paymentTransactionId: paymentTransactionId,
    },
    url: `${rootPath}/orders/${orderId}/capture`,
  });
