import { CouponApiDto } from '@b2x/storefront-api-js-client/src';
import classNames from 'classnames';
import React from 'react';

import { useCustomerApi } from '../../../api/useCustomerApi';
import { Div, P } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { TableBody } from '../../../TableBody';
import { TableHead } from '../../../TableHead';
import { formatDate, renderUI } from '../../../util';
import { AccountCouponsSubpageProps } from '.././AccountCouponsSubpage';
import { AccountSubpage } from '../AccountSubpage';
import { AccountTable } from './AccountTable';
import { Box } from './Box';

export const AccountCouponsSubpageA = (props: AccountCouponsSubpageProps) => {
  const [coupons, setCoupons] = React.useState<Array<CouponApiDto>>();

  const { getCoupons } = useCustomerApi();

  React.useEffect(() => {
    getCoupons().then((response) => {
      setCoupons(response.data);
    });
  }, [getCoupons]);

  const couponsCount = coupons?.length ?? 0;

  return (
    <AccountSubpage>
      <Box>
        {renderUI({
          bs5: (
            <div className="account-coupon-table">
              <Div
                display={{
                  md: 'block',
                  xs: 'none',
                }}
              >
                <AccountTable className="border">
                  <TableHead variant="light">
                    <tr>
                      <th className="small fw-bold text-uppercase">{t('account.coupons.code')}</th>
                      <th className="small fw-bold text-uppercase">{t('account.coupons.description')}</th>
                      <th className="small fw-bold text-uppercase">{t('account.coupons.expiryDate')}</th>
                      <th className="small fw-bold text-uppercase">{t('account.coupons.status')}</th>
                      <th className="small fw-bold text-uppercase">{t('account.coupons.numberOfUses')}</th>
                    </tr>
                  </TableHead>
                  <TableBody className="border-light">
                    {coupons && coupons.length > 0 ? (
                      coupons.map((coupon, index) => (
                        <tr key={coupon.id}>
                          <td className="mobile-data-title" data-title={t('account.coupons.code')}>
                            {coupon.code}
                          </td>
                          <td className="mobile-data-title" data-title={t('account.coupons.description')}>
                            {coupon.description}
                          </td>
                          <td className="mobile-data-title" data-title={t('account.coupons.expiryDate')}>
                            {coupon.expiryDate && formatDate(coupon.expiryDate, 'DD/MM/YYYY')}
                          </td>
                          <td className="mobile-data-title" data-title={t('account.coupons.status')}>
                            {coupon.expired ? t('account.coupons.expired') : t('account.coupons.active')}
                          </td>
                          <td className="mobile-data-title" data-title={t('account.coupons.numberOfUses')}>
                            {coupon.usageAmount} / {coupon.initialAvailability}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center py-5" colSpan={5}>
                          <div className="pb-4">{t('account.coupons.empty')}</div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </AccountTable>
              </Div>
              <Div
                display={{
                  md: 'none',
                }}
              >
                <AccountTable colNumber={12}>
                  <TableBody className="border-light">
                    {couponsCount > 0 ? (
                      coupons?.map((coupon, index) => {
                        return (
                          <>
                            <tr key={coupon.id}>
                              <td className="border-0 pt-3 pb-0 px-0" colSpan={5}>
                                <P className="small" fontWeight={700} margin={0}>
                                  {t('account.coupons.code')}
                                </P>
                              </td>
                              <td className="border-0 pt-3 pb-0 px-0" colSpan={7}>
                                <P className="small" margin={0}>
                                  {coupon.code}
                                </P>
                              </td>
                            </tr>
                            <tr key={coupon.id}>
                              <td className="border-0 p-0" colSpan={5}>
                                <P className="small" fontWeight={700} margin={0}>
                                  {t('account.coupons.description')}
                                </P>
                              </td>
                              <td className="border-0 p-0" colSpan={7}>
                                <P className="small" margin={0}>
                                  {coupon.description}
                                </P>
                              </td>
                            </tr>
                            <tr key={coupon.id}>
                              <td className="border-0 p-0" colSpan={5}>
                                <P className="small" fontWeight={700} margin={0}>
                                  {t('account.coupons.expiryDate')}
                                </P>
                              </td>
                              <td className="border-0 p-0" colSpan={7}>
                                <P className="small" margin={0}>
                                  {coupon.expiryDate && formatDate(coupon.expiryDate, 'DD/MM/YYYY')}
                                </P>
                              </td>
                            </tr>
                            <tr key={coupon.id}>
                              <td className="border-0 p-0" colSpan={5}>
                                <P className="small" fontWeight={700} margin={0}>
                                  {t('account.coupons.status')}
                                </P>
                              </td>
                              <td className="border-0 p-0" colSpan={7}>
                                <P className="small" margin={0}>
                                  {coupon.expired ? t('account.coupons.expired') : t('account.coupons.active')}
                                </P>
                              </td>
                            </tr>
                            <tr key={coupon.id}>
                              <td className="border-0 p-0" colSpan={5}>
                                <P className="small" fontWeight={700} margin={0}>
                                  {t('account.coupons.numberOfUses')}
                                </P>
                              </td>
                              <td className="border-0 p-0" colSpan={7}>
                                <P className="small" margin={0}>
                                  {coupon.usageAmount} / {coupon.initialAvailability}
                                </P>
                              </td>
                            </tr>
                            <tr className={classNames('d-md-none', { 'd-none': couponsCount - 1 === index })}>
                              <td className="border-0 p-0" colSpan={12}>
                                <Div marginX={-3} paddingTop={3}>
                                  <Div className="border-bottom border-5" />
                                </Div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center py-5" colSpan={5}>
                          <div className="pb-4">{t('account.coupons.empty')}</div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </AccountTable>
              </Div>
            </div>
          ),
        })}
      </Box>
    </AccountSubpage>
  );
};
