import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { PaymentsContentType } from './contentTypes';

interface PaymentIconsProps {
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  className?: string;
  gap?: 0 | 1 | 2 | 3 | 4 | 5;
  justifyContent?: 'between' | 'start' | 'end' | 'center' | 'around' | 'evenly';
  width?: number;
}

export const PaymentIcons = ({
  alignItems = 'center',
  className,
  gap = 3,
  justifyContent = 'start',
  width,
}: PaymentIconsProps) => {
  const paymentIcons = b2x.useContent<PaymentsContentType>('PAYMENTS_CONTENT');

  return (
    <div
      className={classnames(
        'd-flex',
        { [`justify-content-${justifyContent}`]: justifyContent },
        { [`align-items-${alignItems}`]: alignItems },
        { [`gap-${gap}`]: gap },
        { className }
      )}
    >
      {paymentIcons?.body.iconsList?.map(({ contentSectionId, image, link }) => (
        <div key={contentSectionId}>
          <b2x.NotResponsiveImageFromContent alt="logo" fluid {...image} width={50} />
        </div>
      ))}
    </div>
  );
};
