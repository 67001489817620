import { OrderingApiType, SearchProductOrderApiType, SearchShopsApiDto, ShopApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface ShopPopulate {
  additionalProperties?: boolean;
  address?: boolean;
  alternativeImages?: boolean;
  currentTimeRange?: boolean;
  distance?: boolean;
  image?: boolean;
  nextTimeRange?: boolean;
  timeRangeByDates?: boolean;
  timeRangeByDays?: boolean;
}

const rootPath = '/v2/shops';

export interface SearchShopsPopulate {
  items?: Populate<ShopPopulate>;
}

export interface SearchShopsOptions extends BaseOptions {
  filters?: Array<string>;
  orderBy?: SearchProductOrderApiType;
  orderingType?: OrderingApiType;
  pageNum?: number;
  pageSize?: number;
  populate?: SearchShopsPopulate;
  products?: Array<string>;
  simpleSearch?: string;
}

export const search = async (queryKey?: Array<unknown>, options?: SearchShopsOptions) =>
  apiRequest<SearchShopsApiDto, void, SearchShopsPopulate>({
    ...getBaseOptions(options),
    params: {
      filters: options?.filters,
      orderBy: options?.orderBy,
      orderingType: options?.orderingType,
      pageNum: options?.pageNum,
      pageSize: options?.pageSize,
      products: options?.products,
      simpleSearch: options?.simpleSearch,
    },
    populate: options?.populate,
    queryKey: queryKey,
    staleTime: Infinity,
    url: `${rootPath}`,
  });

export interface GetShopOptions extends BaseOptions {
  populate?: Populate<ShopPopulate>;
}

export const get = async (id: string, options?: GetShopOptions) =>
  apiRequest<ShopApiDto, void, ShopPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/${id}`,
  });
