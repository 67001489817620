import { FileApiDto, ImageApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions } from './utils/util';

export interface FilePopulate {}

const rootPath = '/v2/file';

export interface FileUploadOptions extends BaseOptions {
  desc?: string;
  folder?: string;
}

export const upload = async (file: File, options?: FileUploadOptions) => {
  const data = new FormData();
  // data.append('maxSize', maxSize.toString());
  data.append('name', file.name);
  data.append('file', file);
  options?.folder && data.append('folder', options.folder);
  options?.desc && data.append('desc', options.desc);

  return apiRequest<FileApiDto, FormData, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}`,
  });
};

const imagePath = `${rootPath}/image`;

export interface ImageUploadOptions extends BaseOptions {
  album?: string;
  desc?: string;
}

export const imageUpload = async (image: File, options?: ImageUploadOptions) => {
  const data = new FormData();
  // data.append('maxSize', maxSize.toString());
  data.append('name', image.name);
  data.append('image', image);
  options?.album && data.append('album', options.album);
  options?.desc && data.append('desc', options.desc);

  return apiRequest<FileApiDto, FormData, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${imagePath}`,
  });
};

export interface GetImageOptions extends BaseOptions {}

export const getImage = async (id: string, options?: GetImageOptions) =>
  apiRequest<ImageApiDto, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/imageid/${id}`, // REFACTORING API, TOGLIERE imageid E USARE imagePath
  });
