import { OrderingApiType, SearchProductOrderApiType } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { ResourceKeys, t } from './i18n/i18n';
import { useSearchParams } from './router/router';

interface SortingOption {
  orderBy: SearchProductOrderApiType;
  orderingType: OrderingApiType;
}

interface SortingOptionItem {
  active: boolean;
  label: string;
  onClick(): void;
}

export const useSortingOptions = (options: Array<SortingOption>) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = React.useCallback(
    (option?: SortingOption) => {
      if (option === undefined) {
        searchParams.delete('orderBy');
        searchParams.delete('orderingType');
      } else {
        searchParams.set('orderBy', option.orderBy);
        searchParams.set('orderingType', option.orderingType);
      }
      searchParams.delete('pageNum');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const sortingOptions = React.useMemo<Array<SortingOptionItem>>(
    () =>
      [
        {
          active: !searchParams.has('orderBy') && !searchParams.has('orderingType'),
          label: t('sortingOption.default'),
          onClick: () => {
            handleClick(undefined);
          },
        },
      ].concat(
        options.map((option) => ({
          active:
            searchParams.get('orderBy')?.toLowerCase() === option.orderBy.toLowerCase() &&
            searchParams.get('orderingType')?.toLowerCase() === option.orderingType.toLowerCase(),
          label: t(
            `sortingOption.${option.orderBy.toLowerCase()}.${option.orderingType.toLowerCase()}` as ResourceKeys
          ),
          onClick: () => {
            handleClick(option);
          },
        }))
      ),
    [handleClick, options, searchParams]
  );

  return { sortingOptions };
};

// const merge = (option: SortingOption): string => `${option.orderBy.toLowerCase()}.${option.orderingType.toLowerCase()}`;

// const split = (merged: string): SortingOption => {
//   const splitArray = merged.split('-');
//   return {
//     orderBy: splitArray[0] as SearchProductOrderApiType,
//     orderingType: splitArray[1] as OrderingApiType,
//   };
// };
