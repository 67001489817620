import { AttributeApiDto, ImageApiDto, ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { AddToCartFieldsHelper, AddToCartFormValues } from './form/AddToCartForm';
import { FormikProps } from './form/Form';
import { ProductStickyFooterA } from './ProductStickyFooterA';
import { ProductStickyFooterB } from './ProductStickyFooterB';
import { ProductStickyFooterC } from './ProductStickyFooterC';
import { UsePriceResult } from './usePrice';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface ProductStickyFooterProps {
  className?: string;
  fieldsHelper: AddToCartFieldsHelper;
  formik: FormikProps<AddToCartFormValues>;
  isVisible?: boolean;
  offsetBottom?: number;
  offsetTop?: number;
  priceHelper: UsePriceResult;
  product: ProductApiDto;
  scrollToElement?(): void;
  selectedProductAttributes?: Array<AttributeApiDto>;
  selectedProductImage?: ImageApiDto;
  selectedSku: SkuApiDto | undefined;
  selectedVariant?: ProductApiDto;
}

export type ProductStickyFooterVariants = 'A' | 'B' | 'C';

const ProductStickyFooterController = (props: PropsWithCustomComponent<ProductStickyFooterProps>) => (
  <VariantsController<ProductStickyFooterProps, ProductStickyFooterVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ProductStickyFooterA, B: ProductStickyFooterB, C: ProductStickyFooterC },
      name: 'ProductStickyFooter',
    }}
  />
);
export { ProductStickyFooterController as ProductStickyFooter };
