import React from 'react';

import { useModalContextStrict } from './ModalContext';
import { createContext } from './util';

export interface DrawersContextInterface {
  activeDrawersId: Array<string>;
  addActiveDrawerId(id: string): void;
  closeActiveDrawer(): void;
  drawerClassName?: string;
  itemsClassName?: string;
  removeDrawerId(id: string): void;
}

export const [DrawersContextProvider, useDrawersContext] = createContext<DrawersContextInterface>('DrawersContext');

interface UseDrawersContextInitializerProps {
  drawerClassName?: string;
  itemsClassName?: string;
}

const useDrawersContextInitializer = ({ drawerClassName, itemsClassName }: UseDrawersContextInitializerProps) => {
  const [activeDrawersId, setActiveDrawersId] = React.useState<Array<string>>([]);

  const addActiveDrawerId = React.useCallback((drawerId: string) => {
    setActiveDrawersId((prevState) => [...prevState, drawerId]);
  }, []);

  const removeDrawerId = React.useCallback((drawerId: string) => {
    setActiveDrawersId((prevState) => prevState.filter((id) => id !== drawerId));
  }, []);

  const closeActiveDrawer = React.useCallback(() => {
    setActiveDrawersId((prevState) => prevState.slice(0, -1));
  }, []);

  const modalContext = useModalContextStrict();
  const { addEventListener } = modalContext ?? {};

  const handleClose = React.useCallback(() => {
    setActiveDrawersId((prevstate) => [prevstate[0]]);
  }, []);

  React.useEffect(() => {
    addEventListener && addEventListener('hidden', 'offcanvas', handleClose);
  }, [addEventListener, handleClose]);

  const drawersContext: DrawersContextInterface = React.useMemo(
    () => ({
      activeDrawersId,
      addActiveDrawerId,
      closeActiveDrawer,
      drawerClassName,
      itemsClassName,
      removeDrawerId,
    }),
    [activeDrawersId, addActiveDrawerId, closeActiveDrawer, drawerClassName, itemsClassName, removeDrawerId]
  );

  return {
    DrawersContextProvider,
    drawersContext,
  };
};

export interface DrawersContextProps extends UseDrawersContextInitializerProps {
  children: React.ReactNode | ((drawersContext: DrawersContextInterface) => React.ReactNode);
}

export const DrawersContext = ({ children, ...otherProps }: DrawersContextProps) => {
  const drawersContextInitializer = useDrawersContextInitializer(otherProps);

  return (
    <drawersContextInitializer.DrawersContextProvider value={drawersContextInitializer.drawersContext}>
      {typeof children === 'function' ? children(drawersContextInitializer.drawersContext) : children}
    </drawersContextInitializer.DrawersContextProvider>
  );
};
