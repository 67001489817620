import './CircleProgressBar.structure.scss';

import classnames from 'classnames';
import React from 'react';

import { Div } from './HTMLElement';
import { uiClassName } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface CircleProgressBarProps {
  // backgroungVariant?: ThemeColor;
  // barVariant?: {
  //   completed?: ThemeColor;
  //   progress?: ThemeColor;
  // };
  label?: string;
  size?: number;
  strokeLineCap?: 'square' | 'round';
  strokeWidth?: number;
  valueCurrent: number;
  valueFrom: number;
  valueTo: number;
}

const CircleProgressBar = (props: CircleProgressBarProps) => {
  const {
    // backgroungVariant,
    // barVariant,
    label,
    size = 250,
    strokeLineCap = 'round',
    strokeWidth = 10,
    valueCurrent,
    valueFrom,
    valueTo,
  } = props;

  // const backgroungColor = React.useMemo(
  //   () => (backgroungVariant && getThemeColorHex(backgroungVariant)) || 'grey',
  //   [backgroungVariant]
  // );
  // const completedBarColor = React.useMemo(
  //   () => (barVariant?.completed && getThemeColorHex(barVariant.completed)) || 'green',
  //   [barVariant?.completed]
  // );
  // const progressBarColor = React.useMemo(
  //   () => (barVariant?.progress && getThemeColorHex(barVariant.progress)) || 'red',
  //   [barVariant?.progress]
  // );

  const valuePathBar = React.useRef<SVGPathElement>(null);
  const progress = React.useMemo(() => (valueCurrent * 100) / valueTo / 100, [valueCurrent, valueTo]);

  React.useEffect(() => {
    const length = 360;
    const dashoffset = length * (1 - (progress > 0.01 ? progress : 0.01));
    if (valuePathBar.current) {
      valuePathBar.current.style.strokeDashoffset = `${dashoffset}`;
      valuePathBar.current.style.strokeDasharray = `${length}`;
    }
  }, [progress, valueCurrent, valueTo]);

  const completed = progress === 1;

  return (
    <Div
      className={classnames('circle-progress-bar', { completed: completed })}
      style={{ position: 'relative', width: size }}
    >
      <div>
        <svg viewBox="0 0 196.4 166.9" xmlns="http://www.w3.org/2000/svg">
          <path
            className="background"
            d="M39.1,147.1c-27.3-32.5-23.1-81.1,9.5-108.5,32.5-27.3,81.1-23.1,108.4,9.5,24,28.6,24,70.4,0,99"
            fill="none"
            stroke="grey"
            strokeLinecap={strokeLineCap}
            strokeWidth={strokeWidth}
          />
          <path
            className="bar"
            d="M39.1,147.1c-27.3-32.5-23.1-81.1,9.5-108.5,32.5-27.3,81.1-23.1,108.4,9.5,24,28.6,24,70.4,0,99"
            fill="none"
            ref={valuePathBar}
            stroke="green"
            strokeLinecap={strokeLineCap}
            strokeWidth={strokeWidth}
          />
        </svg>
      </div>
      <div
        className={uiClassName({
          bs5: 'position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center pt-4',
        })}
      >
        <Div textAlign="center">
          <div className={uiClassName({ all: 'current-value', bs5: 'lh-sm' })}>{valueCurrent}</div>
          <div className={uiClassName({ bs5: 'lh-sm' })}>
            <div className="label">{label}</div>
            <div className="from-to">
              ({valueFrom}/{valueTo})
            </div>
          </div>
        </Div>
      </div>
    </Div>
  );
};

export type CircleProgressBarVariants = '';

const CircleProgressBarController = (props: PropsWithCustomComponent<CircleProgressBarProps>) => (
  <VariantsController<CircleProgressBarProps, CircleProgressBarVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CircleProgressBar,
      name: 'CircleProgressBar',
    }}
  />
);
export { CircleProgressBarController as CircleProgressBar };
