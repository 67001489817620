import { b2x } from '@b2x/react/src';

import { FooterIconsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface FooterIconsProps {
  className?: string;
}

export const FooterIcons = ({ className }: FooterIconsProps) => {
  const footerIconsContent = b2x.useContent<FooterIconsContentType>('FOOTER_ICONS_CONTENT');
  return (
    <>
      {footerIconsContent?.body.iconsList && (
        <div className="row">
          {footerIconsContent.body.iconsList.map((item) => {
            return (
              item.name &&
              isIconName(item.name) && (
                <div className="col-lg-3 text-center" key={item.iconTitle}>
                  <div className="mb-3">
                    <Icon fontSize={56} name={item.name as IconName} />
                    <div>{item.title} </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
};
