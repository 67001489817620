import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { Logo } from './Header';
import { Icon } from './Icon';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';
import { useMobileSimpleSearchOffcanvas } from './MobileSimpleSearchOffcanvas';

export interface MobileHeaderProps {
  visibleUntil: b2x.Breakpoint;
}
export const MobileHeader = ({ visibleUntil }: MobileHeaderProps) => {
  const { session } = b2x.useAppContext();
  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();
  const [MobileSimpleSearchOffcanvas, showMobileSimpleSearchOffcanvas] = useMobileSimpleSearchOffcanvas();
  const { showCartOffcanvas } = useAppStaticContext();

  const iconSize = 30;

  return (
    <>
      {MobileNavigationOffcanvas}
      {MobileSimpleSearchOffcanvas}
      <b2x.Sticky>
        {({ isSticky }) => (
          <div
            className={classnames(
              `sticky-top shadow-sm MobileHeader d-block d-${visibleUntil}-none border-bottom bg-white`
            )}
          >
            <Container>
              <b2x.Row className={classnames('align-items-center py-2')}>
                <b2x.Col className="d-flex">
                  <Button
                    className="pe-3"
                    iconEnd={{ name: 'menu', size: iconSize }}
                    onClick={showMobileNavigationOffcanvas}
                    variant="blank"
                  />
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-center">
                  <Logo small />
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-end">
                  <div className="hstack gap-2">
                    <Button
                      iconEnd={{ name: 'search', size: iconSize }}
                      onClick={showMobileSimpleSearchOffcanvas}
                      variant="blank"
                    />
                    {/*                     {session?.userLogged ? (
                      <b2x.router.Link className="lh-1" to="/account/area/wishlist">
                        <Button className="position-relative" variant="blank">
                          <Icon name={'wishlist'} size={iconSize} />
                          {((session?.wishlist?.products && session.wishlist.products.length > 0) ||
                            (session?.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary text-primary">
                              {(session.wishlist.products ? session.wishlist.products.length : 0) +
                                (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                            </span>
                          )}
                        </Button>
                      </b2x.router.Link>
                    ) : (
                      <Button
                        iconEnd={{ name: 'wishlist', size: iconSize }}
                        //                     onClick={showAccountOffcanvas}
                        variant="blank"
                      />
                    )}
 */}
                    <Button className="position-relative" onClick={showCartOffcanvas} variant="blank">
                      <Icon
                        name={
                          session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0
                            ? 'cart-full'
                            : 'cart'
                        }
                        size={iconSize}
                      />
                      {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                        <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge fw-normal bg-primary text-white">
                          {session.cart.itemsNumber}
                        </span>
                      )}
                    </Button>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  );
};
