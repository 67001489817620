// eslint-disable-next-line no-restricted-imports
import { api, CreateReviewRequestApiDto } from '@b2x/storefront-api-js-client/src';
import { GetProductOptions, GetSkuOptions, SearchProductsOptions } from '@b2x/storefront-api-js-client/src/products';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useProductsApi = () => {
  const { apiRequest } = useApiRequest();

  const searchProducts = React.useCallback(
    (key?: Array<unknown>, options?: SearchProductsOptions, config?: ApiRequestConfig) =>
      apiRequest(api.products.search(key, options), { silent: false, ...config }),
    [apiRequest]
  );

  const getProduct = React.useCallback(
    <T>(id: string, options?: GetProductOptions, config?: ApiRequestConfig) =>
      apiRequest(api.products.get<T>(id, options), { ...config }),
    [apiRequest]
  );

  const getProductByPath = React.useCallback(
    <T>(path: string, options?: GetProductOptions, config?: ApiRequestConfig) =>
      apiRequest(api.products.getByPath<T>(path, options), { ...config }),
    [apiRequest]
  );

  const getSku = React.useCallback(
    (id: string, options?: GetSkuOptions, config?: ApiRequestConfig) =>
      apiRequest(api.products.getSku(id, options), { ...config }),
    [apiRequest]
  );

  const getUrlInAnotherLocaleByPath = React.useCallback(
    (path: string, locale: string, config?: ApiRequestConfig) =>
      apiRequest(api.products.getUrlInAnotherLocaleByPath(path, locale), { ...config }),
    [apiRequest]
  );

  const getUrlsInAllLocalesByPath = React.useCallback(
    (path: string, config?: ApiRequestConfig) =>
      apiRequest(api.products.getUrlsInAllLocalesByPath(path), { ...config }),
    [apiRequest]
  );

  const writeReview = React.useCallback(
    (id: string, data: CreateReviewRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.products.writeReview(id, data), { ...config }),
    [apiRequest]
  );

  return {
    getProduct,
    getProductByPath,
    getSku,
    getUrlInAnotherLocaleByPath,
    getUrlsInAllLocalesByPath,
    searchProducts,
    writeReview,
  };
};
