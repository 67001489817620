import React from 'react';

import { Div } from '../../../HTMLElement';
import { usePageWithSubpageContext } from '../../../PageWithSubpageContext';
import { useMenu } from '../../../useMenu';
import { ListC } from './ListC';

export interface ServiceIndexSubpageAProps {}

export const ServiceIndexSubpageA = (props: ServiceIndexSubpageAProps) => {
  const {
    setMobileBackLink,
    setPageTitle,
    setShowMobileBackLink,
    setShowPageTitle,
    setShowSubpageTitle,
    setSubpageTitle,
  } = usePageWithSubpageContext();

  const customerCareMenu = useMenu('MENU_CUSTOMER_CARE');
  const legalAreaMenu = useMenu('MENU_LEGAL_AREA');

  React.useEffect(() => {
    setShowPageTitle(false);
    setShowSubpageTitle(false);
    setSubpageTitle(undefined);
    setPageTitle(undefined);
    setShowMobileBackLink(false);
    setMobileBackLink(undefined);
  }, [setMobileBackLink, setPageTitle, setShowMobileBackLink, setShowSubpageTitle, setSubpageTitle, setShowPageTitle]);

  return (
    <>
      <Div display={{ lg: 'none' }}>
        {customerCareMenu?.children && (
          <Div marginY={3}>
            {customerCareMenu.label && (
              <ListC
                items={customerCareMenu.children.map((item) => ({
                  label: item.label,
                  link: item.link,
                }))}
                title={customerCareMenu.label}
              />
            )}
          </Div>
        )}

        {legalAreaMenu?.children && legalAreaMenu.label && (
          <ListC
            items={legalAreaMenu.children.map((item) => ({
              label: item.label,
              link: item.link,
              onClick: () => {
                setPageTitle(legalAreaMenu.label);
              },
            }))}
            title={legalAreaMenu.label}
          />
        )}
      </Div>
    </>
  );
};
