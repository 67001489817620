import { b2x } from '@b2x/react/src';

import loading from './images/loading.svg';

export type FetchingProps<T> = Omit<b2x.FetchingProps<T>, 'placeholder'>;

export const Fetching = <T,>(props: FetchingProps<T>) => <b2x.Fetching<T> placeholder={<PlaceHolder />} {...props} />;

const PlaceHolder = () => (
  <div className="text-center">
    {/* eslint-disable-next-line react/forbid-elements */}
    <img alt="loading..." src={loading} />
  </div>
);
