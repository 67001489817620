import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { ServicePageA } from './ServicePageA';

export interface ServicePageProps {}

export type ServicePageVariants = 'A';

const ServicePageController = (props: PropsWithCustomComponent<ServicePageProps>) => (
  <VariantsController<ServicePageProps, ServicePageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServicePageA },
      name: 'ServicePage',
    }}
  />
);
export { ServicePageController as ServicePage };
