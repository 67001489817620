import { PageApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { useAppContext } from '../../AppContext';
import { Fetching } from '../../Fetching';
import { t } from '../../i18n/i18n';
import { usePageWithSubpageContext } from '../../PageWithSubpageContext';
import { usePage } from '../../usePage';

export interface AccountSubpageProps<T> {
  children: ((page: PageApiDto<T>) => React.ReactNode) | React.ReactNode;
}

export const AccountSubpage = <T,>({ children }: AccountSubpageProps<T>) => {
  const { setMobileBackLink, setShowMobileBackLink, setShowSubpageTitle, setSubpageTitle } =
    usePageWithSubpageContext();

  const page = usePage<T>({ populate: { breadcrumb: true } });
  const { session } = useAppContext();

  React.useEffect(() => {
    setShowSubpageTitle(true);
    setSubpageTitle(page?.name);
    setShowMobileBackLink(true);
    page?.breadcrumb &&
      setMobileBackLink({
        children: t('account.welcomeMessage', { name: session?.customer?.name }),
        to: page.breadcrumb[1].fullPath,
      });
  }, [
    page?.breadcrumb,
    page?.name,
    session?.customer?.name,
    setMobileBackLink,
    setShowMobileBackLink,
    setShowSubpageTitle,
    setSubpageTitle,
  ]);

  return <Fetching item={page}>{page && typeof children === 'function' ? children(page) : children}</Fetching>;
};
