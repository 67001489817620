import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { useAppContext, useAppStaticContext } from './AppContext';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import { t } from './i18n/i18n';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import { MobileHeader } from './MobileHeader';
import { SliderVertical } from './slider/SliderVertical';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const { session, shippingCountry } = b2x.useAppContext();
  const { headerCheckout } = useAppContext();

  return (
    <>
      {!headerCheckout ? (
        <>
          {content && (
            <>
              <TopBar content={content} />
            </>
          )}
          <DesktopHeader visibleFrom="lg" />
          <MobileHeader visibleUntil="lg" />
        </>
      ) : (
        <div className={classnames('HeaderCheckoutDesktop sticky-top', 'py-3 shadow-sm bg-white')}>
          <div className="container-xxl">
            <div className="d-flex justify-content-between align-items-center">
              <div className="logo" style={{ flex: '1 1 0' }}>
                <Logo className="d-none d-lg-block" />
                <Logo className="d-lg-none" small />
              </div>
              <div className="text-end text-lg-center h5 m-0" style={{ flex: '1 1 0' }}>
                {session?.cart?.itemsNumber && (
                  <b2x.Fetching item={session.cart.itemsNumber}>
                    {(count) => t('header.cartSummary', { count: count })}
                  </b2x.Fetching>
                )}
              </div>
              <div
                className="text-end d-none d-lg-block extra-small text-dark text-uppercase"
                style={{ flex: '1 1 0' }}
              >
                {session?.cart?.shippingProfile?.freeThreshold &&
                  t('header.freeShippingFrom', {
                    from: b2x.formatCurrency(session.cart.shippingProfile.freeThreshold),
                    locale: shippingCountry?.name,
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface LogoProps {
  className?: string;
  small?: boolean;
}

export const Logo = ({ className, small }: LogoProps) => {
  return (
    <b2x.router.Link className={className} to="/">
      <b2x.Image className="logo" src={small ? logoSmall : logo} />
    </b2x.router.Link>
  );
};

interface TopBarProps {
  content: b2x.ContentApiDto<HeaderContentType>;
}

const TopBar = ({ content }: TopBarProps) => {
  const { setTopBarRowRef } = useAppStaticContext();

  return (
    <>
      <div
        className="position-relative"
        ref={setTopBarRowRef}
        style={{ backgroundColor: content.body.topBar?.backgroundColor }}
      >
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              {content.body.topBar?.centerItem?.itemList && (
                <SliderVertical
                  items={content.body.topBar.centerItem.itemList.map((item) => ({
                    href: item.action,
                    icon: item.icon,
                    label: item.label ?? '',
                  }))}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
