// import { Locale } from './interfaces';

import Cookies, { CookieAttributes } from 'js-cookie';

import { appConfig } from './config';
import { env } from './env';

/**
 * Funzioni interne
 */
const getItem = (key: string): string | undefined => {
  const prefixedKey = getPrefixedKey(key);
  return Cookies.get(prefixedKey);
};
const getItemNotUndefined = (key: string): string => {
  const item = getItem(key);
  if (item === undefined) throw new Error(`${key} not found in cookies`);
  return item;
};
const setItem = (key: string, value: string, options?: CookieAttributes) => {
  const prefixedKey = getPrefixedKey(key);
  Cookies.set(prefixedKey, value, options);
};
const removeItem = (key: string) => {
  const prefixedKey = getPrefixedKey(key);
  Cookies.remove(prefixedKey);
};

export const getPrefixedKey = (key: string) => {
  const cookiePrefix = appConfig.cookiePrefix;
  if (cookiePrefix === '') {
    throw new Error(`Attempting to get/set/remove the key '${key}' before obtaining the cookiePrefix`);
  }
  const apiProxy = env.REACT_APP_API_PROXY;
  const apiProxyPrefix = apiProxy ? `${apiProxy}_` : '';
  return `${cookiePrefix.toUpperCase()}_${apiProxyPrefix}${key}`;
};

/**
 * String
 */

const getString = (key: string): string | undefined => {
  return getItem(key);
};

const getStringNotUndefined = (key: string): string => {
  return getItemNotUndefined(key);
};

const setString = (key: string, value: string, options?: CookieAttributes) => {
  return setItem(key, value, options);
};

/**
 * Number
 */

const getNumber = (key: string): number | undefined => {
  const value = getItem(key);
  if (value) {
    return parseInt(value);
  } else {
    return undefined;
  }
};

const getNumberNotUndefined = (key: string): number => {
  const value = getItemNotUndefined(key);
  return parseInt(value);
};

const setNumber = (key: string, value: number, options?: CookieAttributes) => {
  return setItem(key, value.toString(), options);
};

/**
 * Enum
 */

const getEnum = <T extends string>(key: string): T | undefined => {
  const value = getItem(key);
  if (value) {
    return value as T;
  } else {
    return undefined;
  }
};

const getEnumNotUndefined = <T extends string>(key: string): T => {
  const value = getItemNotUndefined(key);
  return value as T;
};

const setEnum = <T extends string>(key: string, value: T, options?: CookieAttributes) => {
  return setItem(key, value, options);
};

/**
 * Boolean
 */

const getBoolean = (key: string): boolean => {
  const value = getItem(key);
  if (value && JSON.parse(value)) {
    return true;
  } else {
    return false;
  }
};

const setBoolean = (key: string, value: boolean, options?: CookieAttributes) => {
  return setItem(key, JSON.stringify(value), options);
};

/**
 * Object
 */

const getObject = <T>(key: string): T | undefined => {
  const value = getItem(key);
  if (value) {
    return JSON.parse(value);
  } else {
    return undefined;
  }
};

const getObjectNotUndefined = <T>(key: string): T => {
  const value = getItemNotUndefined(key);
  return JSON.parse(value);
};

const setObject = <T>(key: string, value: T, options?: CookieAttributes) => {
  return setItem(key, JSON.stringify(value), options);
};

/**
 * Da qui iniziano i metodi dedicati da esportare
 */

export const cookies = {
  getBoolean,
  getEnum,
  getEnumNotUndefined,
  getNumber,
  getNumberNotUndefined,
  getObject,
  getObjectNotUndefined,
  getString,
  getStringNotUndefined,
  removeItem,
  setBoolean,
  setEnum,
  setNumber,
  setObject,
  setString,
};
