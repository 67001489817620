import { t } from './i18n/i18n';
import { renderUI } from './util';

export interface OrderStateProps {
  showOnlyText?: boolean;
  state: string;
}

export const OrderState = ({ showOnlyText, state }: OrderStateProps) => {
  let stateClass = '';
  let stateMessage = '';

  switch (state) {
    case 'WAIT_PAYMENT':
      stateClass = 'text-warning';
      stateMessage = t('account.orders.status.waitPayment');
      break;
    case 'PREPARING':
      stateClass = '';
      stateMessage = t('account.orders.status.preparing');
      break;
    case 'READY_TO_SHIPPING':
      stateClass = '';
      stateMessage = t('account.orders.status.readyToShipping');
      break;
    case 'SHIPPING':
      stateClass = '';
      stateMessage = t('account.orders.status.shipping');
      break;
    case 'ARRIVED':
      stateClass = 'text-success';
      stateMessage = t('account.orders.status.arrived');
      break;
    case 'CANCELLED':
      stateClass = 'text-danger';
      stateMessage = t('account.orders.status.cancelled');
      break;
    case 'WAITING_ACCEPTANCE':
      stateClass = 'text-warning';
      stateMessage = t('account.orders.status.waitingAcceptance');
      break;
    default:
      stateClass = '';
      stateMessage = '';
  }

  return renderUI({
    bs5: <>{showOnlyText ? stateMessage : <span className={stateClass}>{stateMessage}</span>}</>,
  });
};
