import { ErrorResponseApiDto } from '@b2x/storefront-api-js-client/src';
import { CancelOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';

import { analytics } from '../analytics/analytics';
import { useAppContext, useAppStaticContext } from '../AppContext';
import { appConfig } from '../config';
import { ResourceKeys, t } from '../i18n/i18n';
import { useNavigate } from '../router/useNavigate';
// import { useNavigate } from '../router/router';
import { storage } from '../storage';
import { useModals } from '../useModals';
import { wait } from '../util';

export interface ApiRequestConfig {
  silent?: boolean;
  suppressErrorLog?: boolean;
  suppressErrorModal?: boolean | Array<string>;
}

export const useApiRequest = () => {
  const { showModal } = useModals();
  const { session } = useAppContext();
  const { setFetching } = useAppStaticContext();
  const navigate = useNavigate();

  const apiRequest = React.useCallback(
    <T>(promise: Promise<T>, config?: ApiRequestConfig) => {
      const { silent = true, suppressErrorLog, suppressErrorModal } = config ?? {};
      if (session?.userLogged) {
        /*
          Faccio questo if a vuoto solo per avere "session?.userLogged" nell'array di dipendenze,
          in modo che le chiamate vengano refreshate dopo la login/login (cambiano i prezzi).
        */
      }

      !silent && setFetching((prevState) => prevState + 1);
      return promise
        .then((response) => {
          return response;
        })
        .catch((error: AxiosError<ErrorResponseApiDto> | CancelOptions) => {
          if (isApiRequestCancelled(error)) {
            // Suppressing error per cancelled request
            // FIXME non ci sono riuscito... da ripensare...
            throw error;
          } else {
            const apiError = error as AxiosError<ErrorResponseApiDto>;

            if (apiError.response?.data.key) {
              const errorTitle = t('api.error.title');
              const errorMessage = t(apiError.response.data.key as ResourceKeys, {
                replace: apiError.response.data.args,
              });
              if (
                suppressErrorModal === undefined ||
                suppressErrorModal === false ||
                (typeof suppressErrorModal === 'object' &&
                  error.response?.data.key &&
                  !suppressErrorModal.includes(error.response.data.key))
              ) {
                showModal({
                  children: errorMessage,
                  // Commento in quanto agganciarmi a "useNavigate" trigghera un rerender a ogni cambio path.
                  // Renderebbe useApiRequest poco stabile, e causerebbe un rerender di tutta l'app anche solo se cambio un sottopath di una Route index
                  // UPDATE: Abbiamo reso stabile la useNavigate(). Fino che a non da problemi evidenti, riabilito il redirect.
                  onClose: apiError.response.data.key === 'api.error.usernotlogged' ? () => navigate('/') : undefined,
                  title: errorTitle,
                });
              }
              if (!suppressErrorLog) {
                console.error(`API error: ${apiError.response.data.key}`, apiError.response.data.message, {
                  ...apiError.response.data,
                  i18nMessage: errorMessage,
                });
              }
              if (apiError.response.data.key === 'api.error.TokenMalformedDataException') {
                storage.removeItem('sessionToken', appConfig.persistentSession);
              }
            }
            analytics.events.exception('EVENT_ID', apiError);

            throw apiError;
          }
        })
        .finally(() => {
          !silent &&
            //Aggiungo questo piccolo delay per evitare che tra una chiamata e l'altra scompaia e ricompaia l'overlay per un microsecondo.
            wait(100).then(() => {
              setFetching((prevState) => prevState - 1);
            });
        });
    },
    [session?.userLogged, setFetching, showModal, navigate]
  );

  // const apiRequest2 = React.useCallback(
  //   <Props extends Record<string, unknown>, Response extends unknown>(
  //     apiCall: (props: Props) => Promise<AxiosResponse<Response>>,
  //     apiProps: Props,
  //     options?: ApiRequestOptions
  //   ): Promise<AxiosResponse<Response>> => {
  //     const { silent } = { ...defaultOptions, ...options };

  //     !silent && setFetching(true);

  //     return apiCall({ headers: { locale: locale, sessionToken: '', shippingCountry: '' } })
  //       .then((response) => {
  //         return response;
  //       })
  //       .catch((error: AxiosError<ErrorResponseApiDto>) => {
  //         if (error.response?.data.key) {
  //           showModal({
  //             children: t(error.response.data.key as ResourceKeys, { replace: error.response.data.args }),
  //             title: 'Errore API',
  //           });
  //         } else {
  //           // showModal({ children: t(''), title: 'Errore API' });
  //         }
  //         throw error;
  //       })
  //       .finally(() => {
  //         !silent && setFetching(false);
  //       });
  //   },
  //   [language, setFetching, showModal]
  // );

  return { apiRequest };
};

const isApiRequestCancelled = (error: AxiosError<ErrorResponseApiDto> | CancelOptions): error is CancelOptions =>
  (error as CancelOptions).revert !== undefined;
