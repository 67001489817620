import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { useCustomerApi } from './api/useCustomerApi';
import { useAppContext, useAppStaticContext } from './AppContext';
import { appConfig } from './config';
import { t } from './i18n/i18n';
import { useNavigate } from './router/useNavigate';
import { useModals } from './useModals';

export interface UseWishlistButtonHelperProps {
  product: ProductApiDto;
  sku?: SkuApiDto;
}

export interface UseWishlistButtonHelperResult {
  handleWishlistButtonClick(): void;
  inWishlist: boolean;
}

export const useWishlistButtonHelper = ({
  product,
  sku,
}: UseWishlistButtonHelperProps): UseWishlistButtonHelperResult => {
  const [inWishlist, setInWishlist] = React.useState<boolean>(false);

  const { session } = useAppContext();
  const { addProductToWishlist, removeProductFromWishlist } = useCustomerApi();

  React.useEffect(() => {
    if (session?.wishlist) {
      if (
        session.wishlist.products?.findIndex((_product) => _product.id === product.id) !== -1 ||
        session.wishlist.skus?.some((_sku) => _sku.product?.id === product.id)
      ) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [product.id, session?.wishlist]);

  const { onAddToWishlistAsGuestButtonClick } = useAppStaticContext();

  const { showModal } = useModals();

  const navigate = useNavigate();

  const handleWishlistButtonClick = React.useCallback(() => {
    if (session?.userLogged) {
      if (!inWishlist) {
        if (
          appConfig.enableProductAvailabilityEmailNotification &&
          (sku?.state === 'NOT_AVAILABLE' || sku?.state === 'AVAILABLE_SOON')
        ) {
          showModal({
            buttons: [
              {
                close: true,
                label: t('wishlist.modal.buttons.goToWishlist.label'),
                onClick: () => navigate('/account/area/wishlist'),
              },
              { close: true, label: t('wishlist.modal.buttons.continueShopping.label'), variant: 'outline-primary' },
            ],
            children: (
              <>
                {t('wishlist.modal.availabilityNotificationEnabled')}
                <br />
                {t('wishlist.modal.productAdded')}
              </>
            ),
            className: 'wishlist-modal',
            size: 'default',
            title: t('wishlist.modal.title'),
          });
        }

        addProductToWishlist({ product: product, sku: sku });
      } else {
        removeProductFromWishlist({ productId: product.id });
      }
    } else {
      onAddToWishlistAsGuestButtonClick && onAddToWishlistAsGuestButtonClick();
    }
  }, [
    addProductToWishlist,
    inWishlist,
    navigate,
    onAddToWishlistAsGuestButtonClick,
    product,
    removeProductFromWishlist,
    session?.userLogged,
    showModal,
    sku,
  ]);

  return {
    handleWishlistButtonClick,
    inWishlist,
  };
};
