import classnames from 'classnames';
import React from 'react';

import { Display } from './interfaces';
import { createUUID } from './util';

export interface FadeProps {
  children?: React.ReactNode;
  className?: string;
  delayIn?: number;
  delayOut?: number;
  display?: Display;
  duration?: number;
  show: boolean;
}

export const Fade = ({
  children,
  className,
  delayIn = 0,
  delayOut = 0,
  display = 'block',
  duration = 200,
  show,
}: FadeProps) => {
  const id = React.useMemo(() => createUUID(), []);
  const ref = React.useRef<HTMLDivElement>(null);

  const [zIndex, setZIndex] = React.useState<string>();

  React.useEffect(() => {
    const firstElementChild = ref.current?.firstElementChild;
    if (firstElementChild !== null && firstElementChild !== undefined) {
      setZIndex(window.getComputedStyle(firstElementChild).zIndex);
    }
  }, []);

  const style = `
  #fade-${id} {
    display: ${display};
    visibility: hidden;
    opacity: 0;
    transition: opacity ${duration}ms ease-in ${delayOut}ms, visibility 0ms ease-in ${duration + delayOut}ms;
    z-index: ${zIndex}
  }
  
  #fade-${id}.show {
    display: ${display};
    visibility: visible;
    opacity: 1;
    transition: opacity ${duration}ms ease-in ${delayIn}ms, visibility 0ms ease-in 0ms;
  }`;

  return (
    <div className={classnames(className, { show: show })} id={`fade-${id}`} ref={ref}>
      {children}
      <style>{style}</style>
    </div>
  );
};
