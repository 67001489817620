import { ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { createContext } from './util';

interface ListingContextInterface {
  getProductIndex(productId: string): number;
  // id: string;
  name: string;
}

export const [ListingContextProvider, useListingContext, useListingContextStrict] =
  createContext<ListingContextInterface>('ListingContext');

interface UseListingContextInitializerProps {
  name: string;
  products: Array<ProductApiDto>;
}

const useListingContextInitializer = ({ name, products }: UseListingContextInitializerProps) => {
  const map = React.useMemo<Record<string, number>>(
    () => Object.fromEntries(products.map((product, index) => [product.id, index])),
    [products]
  );

  // const id = React.useMemo(() => slugify(name), [name]);

  const getProductIndex = React.useCallback((productId: string) => map[productId], [map]);

  const listingContext: ListingContextInterface = React.useMemo(
    () => ({
      getProductIndex,
      // id,
      name,
    }),
    [getProductIndex, name]
  );

  return {
    ListingContextProvider,
    listingContext,
  };
};

export interface ListingContextProps extends UseListingContextInitializerProps {
  children: React.ReactNode | ((listingContext: ListingContextInterface) => React.ReactNode);
}

export const ListingContext = ({ children, ...otherProps }: ListingContextProps) => {
  const listingContextInitializer = useListingContextInitializer(otherProps);
  return (
    <listingContextInitializer.ListingContextProvider value={listingContextInitializer.listingContext}>
      {typeof children === 'function' ? children(listingContextInitializer.listingContext) : children}
    </listingContextInitializer.ListingContextProvider>
  );
};
