import { AlertProps } from '../Alert';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { CheckoutPageA } from './CheckoutPageA';

export interface CheckoutPageProps {
  additionaContent?: {
    billingAddress?: AdditionalCheckoutStepContentProps;
    email?: AdditionalCheckoutStepContentProps;
    paymentMethod?: AdditionalCheckoutStepContentProps;
    shippingAddress?: AdditionalCheckoutStepContentProps;
    shippingProfile?: AdditionalCheckoutStepContentProps;
    summary?: Omit<AdditionalCheckoutStepContentProps, 'onClosed'>;
  };
}

export interface AdditionalCheckoutStepContentProps {
  onClosed?: {
    alerts?: Array<AlertProps>;
  };
  onEdit?: {
    alerts?: Array<AlertProps>;
  };
}

export type CheckoutPageVariants = 'A';

const CheckoutPageController = (props: PropsWithCustomComponent<CheckoutPageProps>) => (
  <VariantsController<CheckoutPageProps, CheckoutPageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CheckoutPageA },
      name: 'CheckoutPage',
    }}
  />
);
export { CheckoutPageController as CheckoutPage };
