import classnames from 'classnames';
import React from 'react';

export interface IconProps<IconName, IconSize> extends Omit<React.SVGProps<SVGSVGElement>, 'name'> {
  name: IconName;
  size?: IconSize;
}

export const Icon = <IconName extends string, IconSize extends number>({
  className,
  name,
  size,
  style,
  ...otherProps
}: IconProps<IconName, IconSize>) => {
  return (
    <svg
      {...otherProps}
      className={classnames(`Icon icon-${name}`, className)}
      style={{
        ...style,
        fontSize: size,
        width: size,
      }}
    >
      <use xlinkHref={`#icon-${name}`}></use>
    </svg>
  );
};

export const isIconName = <IconName extends string>(
  names: Readonly<Array<IconName>>,
  name?: string
): name is IconName => {
  const isValid: boolean = names.includes(name as IconName);
  if (!isValid) {
    //console.log(`Icon name '${name}' not valid`);
  }
  return isValid;
};
