import {
  PayPalButtons,
  PayPalCardFieldsProvider,
  PayPalCVVField,
  PayPalExpiryField,
  PayPalNameField,
  PayPalNumberField,
  PayPalScriptProvider,
} from '@paypal/react-paypal-js';

import { Button } from './Button';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { usePayPalCheckout, UsePayPalCheckoutProps } from './usePayPalCheckout';
import { usePayPalCheckoutSubmitButton } from './usePayPalCheckoutSubmitButton';
import { formatHtml } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface PayPalCheckoutProps extends UsePayPalCheckoutProps {}

const PayPalCheckout = ({ ...usePayPalCheckoutProps }: PayPalCheckoutProps) => {
  const {
    buttonsComponentProps,
    cardFieldsProviderProps,
    // cardFieldsState,
    cvvFieldProps,
    expiryFieldProps,
    handleCancel,
    nameFieldProps,
    numberFieldProps,
    scriptProviderOptions,
  } = usePayPalCheckout(usePayPalCheckoutProps);

  return (
    <PayPalScriptProvider options={scriptProviderOptions}>
      <PayPalCardFieldsProvider {...cardFieldsProviderProps}>
        <PayPalButtons {...buttonsComponentProps} />
        <hr />
        <PayPalNameField {...nameFieldProps} />
        <PayPalNumberField {...numberFieldProps} />
        {/* {cardFieldsState?.errors.includes('INVALID_NUMBER') && <small>!!!</small>} */}
        <Div display="flex" gap={3}>
          <div style={{ width: '100%' }}>
            <PayPalExpiryField {...expiryFieldProps} />
          </div>
          <div style={{ width: '100%' }}>
            <PayPalCVVField {...cvvFieldProps} />
          </div>
        </Div>
        <Div marginBottom={3}>
          <small>{formatHtml(t('misc.payPalCheckout.advanced.privacyNotice'))}</small>
        </Div>
        <Div display="flex" gap={3} justifyContent={'end'}>
          <Button label={t('misc.payPalCheckout.form.buttons.cancel')} onClick={handleCancel} variant="secondary" />
          <SubmitPayment />
        </Div>
      </PayPalCardFieldsProvider>
    </PayPalScriptProvider>
  );
};

const SubmitPayment = () => {
  const { handleClick, isPaying } = usePayPalCheckoutSubmitButton({});

  return (
    <Button disabled={isPaying} onClick={handleClick} variant="primary">
      {t('misc.payPalCheckout.form.buttons.submit')}
    </Button>
  );
};

export type PayPalCheckoutVariants = '';

const PayPalCheckoutController = (props: PropsWithCustomComponent<PayPalCheckoutProps>) => (
  <VariantsController<PayPalCheckoutProps, PayPalCheckoutVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: PayPalCheckout,
      name: 'PayPalCheckout',
    }}
  />
);
export { PayPalCheckoutController as PayPalCheckout };
