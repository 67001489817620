// export type ModalFullScreen = Exclude<Breakpoint, 'xs'> | 'always';

import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { appConfig } from './config';
import { ModalContext, ModalContextInterface, useModalContext } from './ModalContext';
import { useModals } from './useModals';
import { createUUID, renderUI, wait } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';
export type OffcanvasPlacement = 'left' | 'right' | 'top' | 'bottom';

export interface OffcanvasProps {
  autoClose?: number; // in seconds
  backdrop?: boolean;
  children?: React.ReactNode | ((modalContext: ModalContextInterface) => React.ReactNode);
  className?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  onClose?(): void;
  placement?: OffcanvasPlacement;
  scroll?: boolean;
}

export const Offcanvas = (props: OffcanvasProps) => {
  const id = React.useMemo(() => createUUID(), []);
  return <OffcanvasWithId {...props} id={id} />;
};

export interface OffcanvasWithIdProps extends OffcanvasProps {
  id: string;
}

export const OffcanvasWithId = ({
  autoClose,
  backdrop = true,
  children,
  className,
  id,
  innerRef,
  onClose,
  placement = 'left',
  scroll,
}: OffcanvasWithIdProps) => {
  const { addEventListener, close } = useModals();

  React.useEffect(() => {
    onClose && addEventListener(id, 'hidden', 'offcanvas', onClose);
  }, [addEventListener, id, onClose]);

  React.useEffect(() => {
    autoClose &&
      addEventListener(id, 'shown', 'offcanvas', () => {
        wait(autoClose * 1000).then(() => {
          close(id);
        });
      });
  }, [addEventListener, autoClose, close, id, onClose]);

  return (
    <ModalContext id={id}>
      {(modalContext) =>
        renderUI({
          bs5: (
            <div
              aria-labelledby="offcanvasLabel"
              className={classnames(
                'offcanvas',
                { 'offcanvas-start': placement === 'left' },
                { 'offcanvas-end': placement === 'right' },
                { 'offcanvas-top': placement === 'top' },
                { 'offcanvas-bottom': placement === 'bottom' },
                className
              )}
              data-bs-backdrop={backdrop}
              data-bs-scroll={scroll}
              id={id}
              ref={innerRef}
              tabIndex={-1}
            >
              {typeof children === 'function' ? children(modalContext) : children}
            </div>
          ),
        })
      }
    </ModalContext>
  );
};

export interface PackedOffcanvasProps extends OffcanvasWithIdProps {
  title: string;
}

export interface OffcanvasHeaderProps {
  children?: React.ReactNode;
  className?: string;
  smallPadding?: boolean;
  title?: string;
}

const OffcanvasHeader = ({ children, className, title }: OffcanvasHeaderProps) => {
  const { id } = useModalContext();
  return renderUI({
    bs5: (
      <div className={classnames('offcanvas-header', className)}>
        {(title || children) && (
          <h5 className="offcanvas-title" id={`${id}-label`}>
            {title}
            {children}
          </h5>
        )}
        <Button
          aria-label="Close"
          data-bs-dismiss="offcanvas"
          iconEnd={appConfig.icons ? { name: appConfig.icons.close } : undefined}
          type="button"
          variant="blank"
        />
      </div>
    ),
  });
};

export interface OffcanvasBodyProps {
  children?: React.ReactNode;
  className?: string;
}

export const OffcanvasBody = ({ children, className }: OffcanvasBodyProps) => {
  return renderUI({
    bs5: <div className={classnames('offcanvas-body', className)}>{children}</div>,
  });
};

export type OffcanvasHeaderVariants = '';

const OffcanvasHeaderController = (props: PropsWithCustomComponent<OffcanvasHeaderProps>) => (
  <VariantsController<OffcanvasHeaderProps, OffcanvasHeaderVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: OffcanvasHeader,
      name: 'OffcanvasHeader',
    }}
  />
);
export { OffcanvasHeaderController as OffcanvasHeader };
