import { usePayPalCardFields } from '@paypal/react-paypal-js';
import React from 'react';

import { t } from './i18n/i18n';

export interface UsePayPalCheckoutSubmitButtonProps {}

export interface UsePayPalCheckoutSubmitButtonResult {
  handleClick(): Promise<void>;
  isPaying: boolean;
}

export const usePayPalCheckoutSubmitButton = (
  props: UsePayPalCheckoutSubmitButtonProps
): UsePayPalCheckoutSubmitButtonResult => {
  const [isPaying, setIsPaying] = React.useState(false);

  const { cardFieldsForm } = usePayPalCardFields();

  const handleClick = React.useCallback(async () => {
    if (!cardFieldsForm) {
      const childErrorMessage = 'Unable to find any child components in the <PayPalCardFieldsProvider />';
      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    console.log(formState);

    if (!formState.isFormValid) {
      return alert(t('misc.payPalCheckout.form.state.invalid'));
    }
    setIsPaying(true);

    // cardFieldsForm.submit({ billingAddress }).catch((err) => {
    //   setIsPaying(false);
    // });
    cardFieldsForm
      .submit()
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPaying(false);
      });
  }, [cardFieldsForm, setIsPaying]);

  return { handleClick, isPaying };
};
