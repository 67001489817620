import {
  OrderingApiType,
  ReviewVoteApiType,
  SearchReviewOrderApiType,
  SearchReviewsApiDto,
  SearchReviewTypeApiType,
  SexApiType,
  SummaryReviewsApiDto,
} from './dto';
import { ProductPopulate } from './products';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

const rootPath = '/v2/reviews';

export interface ReviewPopulate {
  product?: Populate<ProductPopulate>;
}

export interface SearchReviewsPopulate {
  items?: Populate<ReviewPopulate>;
}

export interface SearchReviewsOptions extends BaseOptions {
  allLanguages?: boolean;
  id?: string;
  orderBy?: SearchReviewOrderApiType;
  orderingType?: OrderingApiType;
  pageNum?: number;
  pageSize?: number;
  populate?: Populate<SearchReviewsPopulate>;
  ratingType?: ReviewVoteApiType;
  ratingValue?: number;
  readStatus?: boolean;
  reviewerSex?: SexApiType;
  reviews?: Array<string>;
  simpleSearch?: string;
  type: SearchReviewTypeApiType;
}

export const search = async (options?: SearchReviewsOptions) =>
  apiRequest<SearchReviewsApiDto, void, SearchReviewsPopulate>({
    ...getBaseOptions(options),
    params: {
      allLanguages: options?.allLanguages,
      id: options?.id,
      orderBy: options?.orderBy,
      orderingType: options?.orderingType,
      pageNum: options?.pageNum,
      pageSize: options?.pageSize,
      ratingType: options?.ratingType,
      ratingValue: options?.ratingValue,
      readStatus: options?.readStatus,
      reviewerSex: options?.reviewerSex,
      reviews: options?.reviews,
      simpleSearch: options?.simpleSearch,
      type: options?.type,
    },
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}`,
  });

export const getSummary = async (id: string, options?: BaseOptions) =>
  apiRequest<SummaryReviewsApiDto, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/product/${id}/summary`,
  });
