import React from 'react';

import { renderUI } from '../util';

export interface InputGroupTextProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const InputGroupText = ({ children, ...otherProps }: InputGroupTextProps) => {
  return renderUI({
    bs5: (
      <span className="input-group-text" {...otherProps}>
        {children}
      </span>
    ),
  });
};
