import classnames from 'classnames';
import React from 'react';

export interface BoxProps {
  children?: React.ReactNode;
  fullHeight?: boolean;
}
export const Box = ({ children, fullHeight }: BoxProps) => {
  return (
    <div className={classnames('shadow-sm bg-white px-3 px-xl-5 py-3 py-xl-5', { 'h-100': fullHeight })}>
      {children}
    </div>
  );
};
