import React from 'react';

import { createContext } from '../util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface MarkerClustererContextInterface {
  addMarker(marker: google.maps.marker.AdvancedMarkerElement): void;
}

export const [MarkerClustererContextProvider, useMarkerClustererContext, useMarkerClustererContextStrict] =
  createContext<MarkerClustererContextInterface>('MarkerClustererContext');

// Proprietà necessarie per creare il contesto
interface UseMarkerClustererContextInitializerProps {
  addMarker(marker: google.maps.marker.AdvancedMarkerElement): void;
}

// Funzione per inizializzare il contesto
const useMarkerClustererContextInitializer = ({ addMarker }: UseMarkerClustererContextInitializerProps) => {
  // Crea l'oggetto markerClustererContext
  const markerClustererContext: MarkerClustererContextInterface = React.useMemo(() => ({ addMarker }), [addMarker]);

  return {
    MarkerClustererContextProvider,
    markerClustererContext,
  };
};

// Interfaccia componente contesto
export interface MarkerClustererContextProps extends UseMarkerClustererContextInitializerProps {
  children: React.ReactNode | ((markerClustererContext: MarkerClustererContextInterface) => React.ReactNode);
}

// Componente Contesto
export const MarkerClustererContext = ({ children, ...otherProps }: MarkerClustererContextProps) => {
  const markerClustererContextInitializer = useMarkerClustererContextInitializer(otherProps);
  return (
    <markerClustererContextInitializer.MarkerClustererContextProvider
      value={markerClustererContextInitializer.markerClustererContext}
    >
      {typeof children === 'function' ? children(markerClustererContextInitializer.markerClustererContext) : children}
    </markerClustererContextInitializer.MarkerClustererContextProvider>
  );
};
