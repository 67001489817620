import { renderUI } from './util';

export interface DropdownHeaderProps {
  children?: React.ReactNode;
}

export const DropdownHeader = ({ children }: DropdownHeaderProps) => {
  return renderUI({
    bs5: (
      <li>
        <h6 className="dropdown-header">{children}</h6>
      </li>
    ),
  });
};
