import 'photoswipe/style.css';

import React from 'react';

import { useFileApi } from './api/useFileApi';
import { useLightboxContext } from './LightboxContext';

export interface LightboxItemProps {
  bcomImageId?: string;
  children?: React.ReactNode;
  className?: string;
  height?: number | string;
  src: string;
  width?: number | string;
}

export const LightboxItem = ({ bcomImageId, children, className, height, src, width }: LightboxItemProps) => {
  const { allDimensions, cursor, id } = useLightboxContext();
  const [dimensions, setDimensions] = React.useState<{ height: number; width: number }>();

  const { getImage } = useFileApi();

  const getDimensionsFromOnLoad = React.useCallback(() => {
    const img = new Image();
    img.onload = () => {
      setDimensions({ height: img.height, width: img.width });
    };
    img.src = src;
  }, [src]);

  React.useEffect(() => {
    if (dimensions === undefined) {
      if (width !== undefined && height !== undefined) {
        setDimensions({ height: parseInt(height.toString()), width: parseInt(width.toString()) });
      } else {
        const dimensionsFromContext = allDimensions[src];
        if (dimensionsFromContext !== undefined) {
          setDimensions({ height: dimensionsFromContext.height, width: dimensionsFromContext.width });
        } else {
          if (bcomImageId !== undefined) {
            getImage(bcomImageId, { suppressErrorModal: true })
              .then((response) => {
                if (response.data.height && response.data.width) {
                  setDimensions({ height: response.data.height, width: response.data.width });
                } else {
                  getDimensionsFromOnLoad();
                }
              })
              .catch(() => {
                getDimensionsFromOnLoad();
              });
          } else {
            getDimensionsFromOnLoad();
          }
        }
      }
    }
  }, [allDimensions, bcomImageId, dimensions, getDimensionsFromOnLoad, getImage, height, src, width]);

  return (
    // eslint-disable-next-line react/forbid-elements
    <a
      className={className}
      data-pswp-height={dimensions?.height}
      data-pswp-width={dimensions?.width}
      href={src}
      key={`${id}-${src}`}
      rel="noreferrer"
      style={{ cursor: cursor }}
      target="_blank"
    >
      {children}
    </a>
  );
};
