import { Button } from '../Button';
import { Div } from '../HTMLElement';
import { AddressFieldset } from './AddressFieldset';
import { addressValidationSchemaSelector } from './AddressFieldsetA'; //FIXME
import { CustomerAddressFormHelper, CustomerAddressFormProps } from './CustomerAddressForm';
import { Checkbox } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';

export const CustomerAddressFormA = (props: CustomerAddressFormProps) => {
  return (
    <CustomerAddressFormHelper validationSchemaSelector={{ address: addressValidationSchemaSelector }} {...props}>
      {({ fieldsHelper }) => (
        <>
          <AddressFieldset addressFieldsHelper={fieldsHelper.address} />
          <FormGroup {...fieldsHelper.defaultShipping.formGroup}>
            <Checkbox {...fieldsHelper.defaultShipping.checkbox} />
          </FormGroup>
          <FormGroup {...fieldsHelper.defaultBilling.formGroup}>
            <Checkbox {...fieldsHelper.defaultBilling.checkbox} />
          </FormGroup>
          <Div
            className="actions-container"
            display="flex"
            gap={3}
            // eslint-disable-next-line react/destructuring-assignment
            justifyContent={props.duringCheckout ? 'start' : 'center'}
          >
            {fieldsHelper.buttons.cancel && <Button {...fieldsHelper.buttons.cancel} />}
            <Button {...fieldsHelper.buttons.submit} />
          </Div>
        </>
      )}
    </CustomerAddressFormHelper>
  );
};
