import { OrderApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { ServiceSubpageContentType } from '../../../contentTypes';
import { OrderGuestForm } from '../../../form/OrderGuestForm';
import { Div } from '../../../HTMLElement';
import { OrderDetails } from '../../../OrderDetails';
import { formatHtml, renderUI } from '../../../util';
import { ServiceSubpage } from '../ServiceSubpage';

export interface ServiceSearchOrderSubpageAProps {}

export const ServiceSearchOrderSubpageA = (props: ServiceSearchOrderSubpageAProps) => {
  const [order, setOrder] = React.useState<OrderApiDto>();

  return renderUI({
    bs5: (
      <ServiceSubpage<ServiceSubpageContentType>>
        {(page) => (
          <>
            <Div marginBottom={5}>{formatHtml(page.content?.body.content)}</Div>
            <OrderGuestForm
              className="mb-3"
              // eslint-disable-next-line react/jsx-no-bind
              onSuccess={(foundedOrder) => {
                setOrder(foundedOrder);
              }}
            />
            {order && <OrderDetails order={order} />}
          </>
        )}
      </ServiceSubpage>
    ),
  });
};
