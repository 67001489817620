import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { ServiceGenericSubpageA } from './serviceSubpagesA/ServiceGenericSubpageA';

export interface ServiceGenericSubpageProps {}

export type ServiceGenericSubpageVariants = 'A';

const ServiceGenericSubpageController = (props: PropsWithCustomComponent<ServiceGenericSubpageProps>) => (
  <VariantsController<ServiceGenericSubpageProps, ServiceGenericSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServiceGenericSubpageA },
      name: 'ServiceGenericSubpage',
    }}
  />
);
export { ServiceGenericSubpageController as ServiceGenericSubpage };
