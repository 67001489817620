import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { ServiceFaqSubpageA } from './serviceSubpagesA/ServiceFaqSubpageA';

export interface ServiceFaqSubpageProps {}

export type ServiceFaqSubpageVariants = 'A';

const ServiceFaqSubpageController = (props: PropsWithCustomComponent<ServiceFaqSubpageProps>) => (
  <VariantsController<ServiceFaqSubpageProps, ServiceFaqSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServiceFaqSubpageA },
      name: 'ServiceFaqSubpage',
    }}
  />
);
export { ServiceFaqSubpageController as ServiceFaqSubpage };
