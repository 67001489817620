import { EditorContextHelper } from '@magnolia/template-annotations';
import React from 'react';
import { useLocation } from 'react-router-dom-5';

import { appConfig } from '../config';
import { env } from '../env';

export const getBase = (): string => {
  // const host = env.getMgnl().REACT_APP_MGNL_HOST;
  // if (host === undefined) {
  //   throw new Error('Missing host (REACT_APP_MGNL_HOST)');
  // }
  const baseDev = env.getMgnl().REACT_APP_MGNL_BASE_DEV;
  // if (baseDev === undefined) {
  //   throw new Error('Missing base dev (REACT_APP_MGNL_BASE_DEV)');
  // }
  const baseAuthor = env.getMgnl().REACT_APP_MGNL_BASE_AUTHOR;
  // if (baseAuthor === undefined) {
  //   throw new Error('Missing base author (REACT_APP_MGNL_BASE_AUTHOR)');
  // }
  const basePublic = env.getMgnl().REACT_APP_MGNL_BASE_PUBLIC;
  // if (basePublic === undefined) {
  //   throw new Error('Missing base public (REACT_APP_MGNL_BASE_PUBLIC)');
  // }
  let base;
  const pathName = window.location.pathname;
  if (pathName.startsWith(baseDev)) {
    base = baseDev;
  } else if (pathName.startsWith(baseAuthor)) {
    base = baseAuthor;
  } else if (pathName.startsWith(basePublic)) {
    base = basePublic;
  } else {
    base = '';
  }
  // return host + base;
  return base;
};

export const isWithContextPath = () => getBase() !== '';

export const getLanguages = (): Array<string> => {
  // return env.getMgnl().REACT_APP_MGNL_LANGUAGES;
  return Object.keys(appConfig.i18n);
};

export const getDefaultLanguage = (): string => {
  return getLanguages()[0];
};

export const removeCurrentLanguage = (string: string, currentLanguage: string): string => {
  return string.replace(new RegExp(`/${currentLanguage}($|/)`), '/');
};

export const getCurrentLanguage = (): string => {
  const languages = getLanguages();
  for (const language of languages) {
    if (new RegExp(`/${language}($|/)`).test(window.location.pathname)) {
      return language;
    }
  }
  return getDefaultLanguage();
};

export const getUrlInOtherLanguage = (newLanguage: string): string => {
  const base = getBase();
  const appBase = env.getMgnl().REACT_APP_MGNL_APP_BASE;
  let pathNameWithoutBase = getPathNameWithoutBase();
  const currentLanguage = getCurrentLanguage();
  pathNameWithoutBase = removeCurrentLanguage(pathNameWithoutBase, currentLanguage);
  const defaultLanguage = getDefaultLanguage();

  if (newLanguage !== defaultLanguage) {
    if (appBase && pathNameWithoutBase.indexOf(appBase) > -1) {
      pathNameWithoutBase = pathNameWithoutBase.replace(new RegExp(appBase), `/${newLanguage}${appBase}`);
    } else {
      pathNameWithoutBase = `/${newLanguage}${pathNameWithoutBase}`;
    }
  }

  return window.location.origin + base + pathNameWithoutBase + window.location.search;
};

export const changeLanguage = (newLanguage: string): void => {
  window.location.href = getUrlInOtherLanguage(newLanguage);
};

export const getPathNameWithoutBase = () => {
  const base = getBase();
  const pathname = window.location.pathname;
  return pathname.replace(base, '');
};

export const getRouterBasename = (): string => {
  const nodeName = env.getMgnl().REACT_APP_MGNL_APP_BASE;
  const pathname = window.location.pathname;

  if (nodeName && pathname.indexOf(nodeName) > -1) {
    return pathname.replace(new RegExp(`${nodeName}.*`), '') + nodeName;
  }

  const currentLanguage = getCurrentLanguage();
  const defaultLanguage = getDefaultLanguage();

  return defaultLanguage === currentLanguage ? '/' : `/${currentLanguage}`;
};

export const getVersion = (path: string): string | null => {
  return new URLSearchParams(path).get('mgnlVersion');
};

export const getPagePath = (): string => {
  // const languages = getLanguages();
  const base = getBase();
  const magnoliaAppBase = env.getMgnl().REACT_APP_MGNL_APP_BASE;

  let path = window.location.pathname.replace(new RegExp('(.html)', 'g'), '');

  const stringToRemove = base + magnoliaAppBase;

  if (stringToRemove !== '') {
    path = path.replace(new RegExp(`(.*${stringToRemove})`, 'g'), '');
  }

  const currentLanguage = getCurrentLanguage();

  // if (currentLanguage !== languages[0]) {
  path = removeCurrentLanguage(path, currentLanguage);
  path += `?lang=${currentLanguage}`;
  // }

  return path;
};

export const inEditorPreview = (): boolean => {
  const url = window.location.href;
  const inPreview = url.indexOf('mgnlPreview=true') > 0;
  console.log(`inEditorPreview:${inPreview}`);
  return EditorContextHelper.inEditor() && inPreview;
};

export interface PageState {
  content?: unknown;
  init?: boolean;
  pathname?: string;
  templateAnnotations?: unknown;
}

export const usePageLoader = () => {
  const [state, setState] = React.useState<PageState>({});

  const location = useLocation();

  const loadPage = React.useCallback(async (): Promise<void> => {
    // Bail out if already loaded content.
    if (state.pathname === location.pathname) return;

    const apiBase = getBase();

    const pagePath = getPagePath();
    const fullContentPath = apiBase + env.getMgnl().REACT_APP_MGNL_API_PAGES + pagePath;

    const pageResponse = await fetch(fullContentPath);
    const pageJson = await pageResponse.json();

    // const templateId = pageJson['mgnl:template'];

    let templateJson = null;
    if (EditorContextHelper.inEditor()) {
      const templateResponse = await fetch(apiBase + env.getMgnl().REACT_APP_MGNL_API_TEMPLATES + pagePath);
      templateJson = await templateResponse.json();
    }

    setState({
      content: pageJson,
      init: true,
      pathname: location.pathname,
      templateAnnotations: templateJson,
    });
  }, [location.pathname, state.pathname]);

  React.useEffect(() => {
    loadPage();
  }, [loadPage]);

  return { ...state };
};
