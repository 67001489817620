import { Col } from '../Col';
import { Container } from '../Container';
import { Row } from '../Row';
import { renderUI } from '../util';

export interface SpacingProps {}

export const Spacing = (props: SpacingProps) => {
  return renderUI({
    bs5: (
      <Container>
        <Row gap={3}>
          {[0, 1, 2, 3, 4, 5].map((size) => (
            <Col key={size}>
              <div className={' py-3'} style={{ background: '#EFEFEF' }}>
                <div className={`bg-primary px-${size}`}>
                  <div className="bg-white text-center py-3">px-{size}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    ),
  });
};
