import classnames from 'classnames';
import React from 'react';

import { Container } from '../Container';
import { Link } from '../router/router';
import { useWindowSize } from '../useWindowSize';
import { renderUI } from '../util';

export interface TypographyProps {
  hideClassesColumn?: boolean;
  variants: Array<{
    className: string;
    name: string;
  }>;
}

export const Typography = ({ hideClassesColumn, variants }: TypographyProps) => {
  return renderUI({
    bs5: (
      <Container>
        <div className="table-responsive">
          <table className={classnames('table table-sm align-middle')}>
            <thead>
              <tr>
                <th className="px-3 text-nowrap"></th>
                <th className="px-3 text-center text-nowrap">font-size</th>
                <th className="px-3 text-center text-nowrap">font-weight</th>
                <th className="px-3 text-center text-nowrap">font-family</th>
                {!hideClassesColumn && <th className="px-3 text-center text-nowrap">classes</th>}
              </tr>
            </thead>
            <tbody>
              {variants.map(({ className, name }) => (
                <TableRow className={className} hideClassesColumn={hideClassesColumn} key={`${className}_${name}`}>
                  <span className={className}>{name}</span>
                </TableRow>
              ))}
              <TableRow>
                <Link to="#">Link</Link>
              </TableRow>
            </tbody>
          </table>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.
        </p>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
          aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
          dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit
          amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam
          aliquam quaerat voluptatem.
        </p>
      </Container>
    ),
  });
};

interface TableRowProps {
  children?: React.ReactNode;
  className?: string;
  hideClassesColumn?: boolean;
}

const TableRow = ({ children, className, hideClassesColumn }: TableRowProps) => {
  const firstTdRef = React.useRef<HTMLTableCellElement>(null);

  const [fontSize, setFontSize] = React.useState<string>();
  const [fontWeight, setFontWeight] = React.useState<string>();
  const [fontFamily, setFontFamily] = React.useState<string>();

  const { width } = useWindowSize();

  React.useEffect(() => {
    const span = firstTdRef.current?.firstElementChild;
    if (span) {
      const computedStyle = window.getComputedStyle(span);
      setFontSize(computedStyle.fontSize);
      setFontWeight(computedStyle.fontWeight);
      setFontFamily(computedStyle.fontFamily);
    }
  }, [width]);

  return renderUI({
    bs5: (
      <tr>
        <td className="ps-0 pe-3 text-nowrap" ref={firstTdRef}>
          {children}
        </td>
        <td className="px-3 text-center text-nowrap">{fontSize}</td>
        <td className="px-3 text-center text-nowrap">{fontWeight}</td>
        <td className="px-3 text-center text-nowrap">{fontFamily}</td>
        {!hideClassesColumn && (
          <td className="px-3 text-center text-nowrap">
            <span style={{ wordSpacing: 10 }}>{className}</span>
          </td>
        )}
      </tr>
    ),
  });
};
