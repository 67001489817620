import { EqualHeightElement as _EqualHeightElement } from 'react-equal-height';

import { Breakpoint } from './interfaces';
import { useBreakpoint } from './useBreakpoint';

export interface EqualHeightElementProps {
  children?: React.ReactNode;
  disable?: boolean | Array<Breakpoint>;
  name: string;
  placeholder?: boolean;
  tag?: string;
}

export const EqualHeightElement = ({ disable, ...otherProps }: EqualHeightElementProps) => {
  const breakpoint = useBreakpoint();

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_EqualHeightElement
      {...otherProps}
      disable={typeof disable === 'boolean' ? disable : disable?.includes(breakpoint) ? true : false}
    />
  );
};
