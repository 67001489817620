import React from 'react';

import {
  useWishlistButtonHelper,
  UseWishlistButtonHelperProps,
  UseWishlistButtonHelperResult,
} from './useWishlistButtonHelper';

export interface WishlistButtonHelperProps extends UseWishlistButtonHelperProps {
  children(props: UseWishlistButtonHelperResult): React.ReactNode;
}

export const WishlistButtonHelper = ({ children, product, sku }: WishlistButtonHelperProps) => {
  const useWishlistButtonHelperResult = useWishlistButtonHelper({ product, sku });

  return <>{children(useWishlistButtonHelperResult)}</>;
};
