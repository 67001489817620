import { AddressApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { t } from './i18n/i18n';

export interface InvoiceSummaryProps {
  address: AddressApiDto;
}

export const InvoiceSummary = ({ address }: InvoiceSummaryProps) => {
  return (
    <>
      {address.company && <div>{address.company}</div>}
      {address.vatNumber && <div>{`${t('misc.invoice.vatNumber')}: ${address.vatNumber}`}</div>}
      {address.taxCode && <div>{`${t('misc.invoice.taxCode')}: ${address.taxCode}`}</div>}
      {address.uniqueCode && <div>{`${t('misc.invoice.uniqueCode')}: ${address.uniqueCode}`}</div>}
      {address.pec && <div>{`${t('misc.invoice.pec')}: ${address.pec}`}</div>}
    </>
  );
};
