import classnames from 'classnames';

import { AlertApiDto } from '../../b2x-react/node_modules/@b2x/storefront-api-js-client/src/dto';
import { Alert } from './Alert';
import { ResourceKeys, t } from './i18n/i18n';
import { Icon, IconProps } from './Icon';
import { renderUI } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface AlertFromDtoProps<IconName, IconSize> {
  alert: AlertApiDto;
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  className?: string;
  iconEnd?: IconProps<IconName, IconSize>;
  iconStart?: IconProps<IconName, IconSize>;
  justifyContent?: 'between' | 'start' | 'end' | 'center' | 'around' | 'evenly';
  light?: boolean;
  noMargin?: boolean;
  small?: boolean;
}

const AlertFromDto = <IconName extends string, IconSize extends number>({
  alert,
  alignItems,
  className,
  iconEnd,
  iconStart,
  justifyContent,
  light,
  noMargin,
  small,
}: AlertFromDtoProps<IconName, IconSize>) => {
  return renderUI({
    bs5: (
      <Alert
        className={classnames(
          className,
          { 'text-danger': light && alert.severity === 'ERROR' },
          { 'text-warning': light && alert.severity === 'WARNING' },
          { 'text-info': light && alert.severity === 'INFO' },
          { 'border-0 p-0': light },
          { 'mb-0': noMargin },
          { 'd-flex': iconStart || iconEnd },
          { [`justify-content-${justifyContent}`]: (iconStart || iconEnd) && justifyContent },
          { [`align-items-${alignItems}`]: (iconStart || iconEnd) && alignItems },
          { small: small }
        )}
        variant={
          !light && alert.severity === 'ERROR'
            ? 'error'
            : !light && alert.severity === 'WARNING'
            ? 'warning'
            : !light && alert.severity === 'INFO'
            ? 'info'
            : undefined
        }
      >
        {iconStart && <Icon {...iconStart} className={classnames('me-2', iconStart.className)} />}
        {t(`alert.${alert.code}` as ResourceKeys, alert.data)}
        {iconEnd && <Icon {...iconEnd} className={classnames('ms-2', iconEnd.className)} />}
      </Alert>
    ),
  });
};

export type AlertFromDtoVariants = '';

const AlertFromDtoController = <IconName extends string, IconSize extends number>(
  props: PropsWithCustomComponent<AlertFromDtoProps<IconName, IconSize>>
) => (
  <VariantsController<AlertFromDtoProps<IconName, IconSize>, AlertFromDtoVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: AlertFromDto,
      name: 'AlertFromDto',
    }}
  />
);
export { AlertFromDtoController as AlertFromDto };
