import React from 'react';

import { Button } from '../Button';
import { Col } from '../Col';
import { useExampleModal } from '../ExampleModal';
import { useExampleFormModal } from '../form/ExampleForm';
import { I18nExample } from '../i18n/I18nExample';
import { ModalFullScreen, ModalSize } from '../Modal';
import { Row } from '../Row';
import { useModals } from '../useModals';
import { uiClassName } from '../util';
import { RecursiveModal } from './RecursiveModal';

export interface ModalsProps {}

export const Modals = () => {
  const { showModal } = useModals();
  const [ExampleModal, showExampleModal] = useExampleModal({ stringToAlert: 'Hello World!' });

  const showExampleFormModal = useExampleFormModal();

  const handleNonClosableModalButtonClick = React.useCallback(() => {
    showModal({
      buttons: [{ close: true, label: 'Only way to close' }],
      children: 'Lorem Ipsum',
      closable: false,
      title: 'Non closable modal',
    });
  }, [showModal]);

  const handleAutoCloseModalButtonClick = React.useCallback(() => {
    showModal({
      autoClose: 2,
      children: 'Lorem Ipsum',
      closable: false,
      title: 'Auto close modal',
    });
  }, [showModal]);

  const handleDynamicModalWithTextButtonClick = React.useCallback(() => {
    showModal({
      buttons: [{ close: true, label: 'Close' }],
      children: 'Lorem Ipsum',
      title: 'Dynamic modal',
    });
  }, [showModal]);

  const handleDynamicModalWithComponentButtonClick = React.useCallback(() => {
    showModal({
      buttons: [{ close: true, label: 'Close' }],
      children: <I18nExample />,
      size: 'large',
      title: 'Example form',
    });
  }, [showModal]);

  return (
    <>
      <h5>Different sizes</h5>
      <Row>
        {(['small', 'default', 'large', 'extra-large'] as Array<ModalSize>).map((size) => (
          <Col display="grid" key={size}>
            <Button
              className={uiClassName({ bs5: 'mb-3' })}
              label={`Show ${size} modal`}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                showModal({
                  buttons: [{ close: true, label: 'Close' }],
                  children:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                  size: size,
                  title: 'Modal title',
                });
              }}
            />
          </Col>
        ))}
      </Row>
      <h5>Fullscreen</h5>
      <Row>
        {(['sm', 'md', 'lg', 'xl', 'xxl', 'always'] as Array<ModalFullScreen>).map((breakpoint) => (
          <Col display="grid" key={breakpoint}>
            <Button
              className={uiClassName({ bs5: 'mb-3' })}
              label={`Show full-screen modal up to ${breakpoint}`}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                showModal({
                  buttons: [{ close: true, label: 'Close' }],
                  children:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                  fullscreen: breakpoint,
                  title: 'Modal title',
                });
              }}
            />
          </Col>
        ))}
      </Row>
      <h5>Other options</h5>
      <Row>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Non closable Modal"
            onClick={handleNonClosableModalButtonClick}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Auto close Modal (2 seconds)"
            onClick={handleAutoCloseModalButtonClick}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Show simple text in Modal"
            onClick={handleDynamicModalWithTextButtonClick}
          />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Show a Component in Modal"
            onClick={handleDynamicModalWithComponentButtonClick}
          />
        </Col>
        <Col display="grid">
          <RecursiveModal className={uiClassName({ bs5: 'mb-3' })} />
        </Col>
        <Col display="grid">
          <Button
            className={uiClassName({ bs5: 'mb-3' })}
            label="Show Example Form Modal"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => showExampleFormModal({})}
          />
        </Col>
        {ExampleModal}
        <Col display="grid">
          <Button className={uiClassName({ bs5: 'mb-3' })} label="Show Modal by useModal" onClick={showExampleModal} />
        </Col>
      </Row>
    </>
  );
};
