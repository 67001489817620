import classnames from 'classnames';
import React from 'react';

import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { CommonFieldProps, useField } from '../Form';
import { useFormContext } from '../FormContext';

interface CommonProps<V> extends CommonFieldProps<HTMLInputElement, V> {
  children?: React.ReactNode;
  id: string;
  inline?: boolean;
  inputClassName?: string;
  label?: string | React.ReactElement;
  labelClassName?: string;
  noMargin?: boolean;
  showFeedback?: boolean;
  value?: string;
}

interface RadioCheckProps<V> extends CommonProps<V> {
  type: 'checkbox' | 'radio';
}

const RadioCheck = <V,>(props: RadioCheckProps<V>) => {
  const {
    children,
    className,
    id,
    inhibitFormikOnChange,
    inline,
    innerRef,
    inputClassName,
    label,
    labelClassName,
    noMargin,
    onFormikChange,
    showFeedback = false,
    size,
    title,
    ...orherProps
  } = props;
  const { b2xHelpers, field } = useField(props, orherProps);
  const formContext = useFormContext();
  const isCustom = children !== undefined;

  return renderUI({
    bs5: (
      <div
        className={classnames(
          { 'form-check': label !== undefined && !isCustom },
          { 'form-check-inline': inline && !isCustom },
          { 'd-inline-block': isCustom },
          { 'me-2': isCustom && inline && !noMargin },
          className
        )}
        title={isCustom && typeof label === 'string' ? label : undefined}
      >
        <input
          {...field}
          {...orherProps}
          autoComplete={isCustom ? 'off' : undefined}
          className={classnames(
            'form-check-input',
            { 'is-valid': b2xHelpers.isValid },
            { 'is-invalid': b2xHelpers.isInvalid },
            { 'btn-check': isCustom },
            inputClassName
          )}
          id={`${formContext.id}-${field.name}-${id}`}
          onChange={b2xHelpers.handleChange}
          ref={innerRef}
          title={title}
        />
        {(label || isCustom) && (
          <label
            className={classnames({ 'form-check-label': !isCustom }, { 'custom d-block': isCustom }, labelClassName)}
            htmlFor={`${formContext.id}-${field.name}-${id}`}
            title={title}
          >
            {children ?? label}
          </label>
        )}
        {showFeedback && <Feedback name={props.name} />}
      </div>
    ),
  });
};

export interface CheckboxProps<V = boolean> extends CommonProps<V> {}
export const Checkbox = (props: CheckboxProps): React.ReactElement => <RadioCheck {...props} type="checkbox" />;

export interface CheckboxesProps {
  checkboxes: Array<CheckboxProps>;
}
export const Checkboxes = ({ checkboxes }: CheckboxesProps): React.ReactElement => (
  <>
    {checkboxes.map((checkbox) => (
      <Checkbox key={checkbox.id} {...checkbox} />
    ))}
  </>
);

export interface RadioProps<V = string> extends CommonProps<V> {
  value: string;
}
export const Radio = (props: RadioProps): React.ReactElement => <RadioCheck {...props} type="radio" />;

export interface RadiosProps {
  radios: Array<RadioProps>;
}
export const Radios = ({ radios }: RadiosProps): React.ReactElement => (
  <>
    {radios.map((radio) => (
      <Radio key={radio.id} {...radio} />
    ))}
  </>
);
