import { Area, ComponentProps } from '@magnolia/react-editor';
import React from 'react';

import { createContext } from '../util';

interface AreaContextInterface {
  getComponentIndex(componentProps: ComponentProps): number;
  nodes: Array<string>;
}

export const [AreaContextProvider, useAreaContext] = createContext<AreaContextInterface>('AreaContext');

interface UseAreaContextInitializerProps {
  content: Area;
}

const useAreaContextInitializer = ({ content }: UseAreaContextInitializerProps) => {
  const getComponentIndex = React.useCallback(
    (componentProps: ComponentProps) => {
      const name = componentProps.metadata['@name'];
      return content['@nodes'].indexOf(name);
    },
    [content]
  );

  const areaContext: AreaContextInterface = {
    getComponentIndex: getComponentIndex,
    nodes: content['@nodes'],
  };

  return { AreaContextProvider, areaContext };
};

export interface AreaContextProps extends UseAreaContextInitializerProps {
  children: React.ReactNode | ((areaContext: AreaContextInterface) => React.ReactNode);
}

export const AreaContext = ({ children, ...otherProps }: AreaContextProps) => {
  const areaContextInitializer = useAreaContextInitializer(otherProps);
  return (
    <areaContextInitializer.AreaContextProvider value={areaContextInitializer.areaContext}>
      {typeof children === 'function' ? children(areaContextInitializer.areaContext) : children}
    </areaContextInitializer.AreaContextProvider>
  );
};
