import classnames from 'classnames';
import React from 'react';

import { Button, ButtonSize } from '../Button';
import { Col } from '../Col';
import { Container } from '../Container';
import { Row } from '../Row';
import { renderUI } from '../util';

export interface ButtonsProps {
  backgrounds: Array<string>;
  buttonClassName?: string;
  disableOutline?: boolean;
  iconsOrLabels: Array<
    'onlyLabel' | 'onlyIcon' | 'IconLeftAndLabel' | 'IconRightAndLabel' | 'IconLeftAndRightAndLabel'
  >;
  sizes: Array<ButtonSize>;
  variants: Array<string>;
}

export const Buttons = ({
  backgrounds,
  buttonClassName,
  disableOutline,
  iconsOrLabels,
  sizes,
  variants,
}: ButtonsProps) => {
  return renderUI({
    bs5: (
      <Container>
        {iconsOrLabels.map((iconOrLabel) =>
          backgrounds.map((background) => (
            <div className={`p-3 bg-${background}`} key={`${iconOrLabel}-${background}`}>
              <Row gap={3}>
                {['', 'outline-'].map(
                  (prefix) =>
                    (prefix !== 'outline-' || !disableOutline) && (
                      <React.Fragment key={`${iconOrLabel}-${background}-${prefix}`}>
                        {sizes.map((size) => (
                          <React.Fragment key={`${iconOrLabel}-${background}-${prefix}-${size}`}>
                            {variants.map((variant) => (
                              <Col key={`${iconOrLabel}-${background}-${prefix}-${size}-${variant}`} size={'auto'}>
                                <Button
                                  className={classnames('', buttonClassName)}
                                  iconEnd={
                                    iconOrLabel === 'IconRightAndLabel' ||
                                    iconOrLabel === 'IconLeftAndRightAndLabel' ||
                                    iconOrLabel === 'onlyIcon'
                                      ? { name: 'menu' }
                                      : undefined
                                  }
                                  iconStart={
                                    iconOrLabel === 'IconLeftAndLabel' || iconOrLabel === 'IconLeftAndRightAndLabel'
                                      ? { name: 'menu' }
                                      : undefined
                                  }
                                  label={iconOrLabel !== 'onlyIcon' ? variant : undefined}
                                  size={size}
                                  title={`${prefix}${variant}`}
                                  variant={`${prefix}${variant}`}
                                />
                              </Col>
                            ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    )
                )}
              </Row>
            </div>
          ))
        )}
      </Container>
    ),
  });
};
