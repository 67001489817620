import './Icon.scss';

import { b2x } from '@b2x/react/src';

export const iconsNames = [
  'visible',
  'hidden',
  'chef',
  'timing',
  'cooking',
  'italian',
  'bio',
  'salt',
  'gluten-free',
  'oil',
  'vegan',
  'phone',
  'settings',
  'cart',
  'cart-full',
  'check',
  'close',
  'coupon',
  'customer',
  'delete',
  'delivery',
  'exit',
  'facebook',
  'info',
  'instagram',
  'language',
  'large-arrow-left',
  'large-arrow-right',
  'mail',
  'menu',
  'minus',
  'payment',
  'plus',
  'profile',
  'profile-full',
  'search',
  'small-arrow-down',
  'small-arrow-left',
  'small-arrow-right',
  'small-arrow-up',
  'wishlist',
  'wishlist-full',
  'privacy',
  'credit-card',
] as const;
export type IconName = typeof iconsNames[number];

export type IconSize = 12 | 14 | 16 | 20 | 25 | 30 | 35 | 48 | 56 | 72 | 120;

export interface IconProps extends b2x.IconProps<IconName, IconSize> {}

export const Icon = (props: IconProps) => <b2x.Icon {...props} />;

export const isIconName = (name?: string): name is IconName => b2x.isIconName(iconsNames, name);
