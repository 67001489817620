import React from 'react';

import { CartOffcanvasA } from './CartOffcanvasA';
import { ToggleableFreeShippingThresholdContentType } from './contentTypes';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface CartOffcanvasProps {
  close(): void;
  footerMessage?: string;
  freeShippingThreshold?: ToggleableFreeShippingThresholdContentType;
  title?: string;
}

export type CartOffcanvasVariants = 'A';

const CartOffcanvasController = React.memo((props: PropsWithCustomComponent<CartOffcanvasProps>) => (
  <VariantsController<CartOffcanvasProps, CartOffcanvasVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CartOffcanvasA },
      name: 'CartOffcanvas',
    }}
  />
));
CartOffcanvasController.displayName = 'CartOffcanvasController';

export { CartOffcanvasController as CartOffcanvas };
