import React from 'react';

import { useSessionApi } from './api/useSessionApi';
import { Container } from './Container';
import { Div } from './HTMLElement';
import { Page } from './Page';
import { useSearchParams } from './router/router';

export interface BcomAutologinProps {}

export const BcomAutologin = (props: BcomAutologinProps) => {
  const { autologin } = useSessionApi();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('auth') ?? undefined;

  React.useEffect(() => {
    if (token) {
      autologin({ token: token });
    } else {
      throw new Error('b.com autologin stopped, missing token');
    }
  }, [autologin, token]);

  return (
    <Page>
      <Container>
        <Div marginY={5} textAlign="center">
          bcom autologin in progress...
        </Div>
      </Container>
    </Page>
  );
};
