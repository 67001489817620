import { useProduct } from './useProduct';

export interface ProductTypeSwitchProps {
  assembled?: JSX.Element;
  default: JSX.Element;
  giftCard?: JSX.Element;
  kit?: JSX.Element;
}

export const ProductTypeSwitch = ({ assembled, default: _default, giftCard, kit }: ProductTypeSwitchProps) => {
  const product = useProduct({ sendEvent: false });
  return (
    <>
      {product ? (
        product.giftCard && giftCard !== undefined ? (
          giftCard
        ) : product.assembled && assembled !== undefined && !product.kit ? (
          assembled
        ) : product.kit && kit !== undefined ? (
          kit
        ) : (
          _default
        )
      ) : (
        <></>
      )}
    </>
  );
};
