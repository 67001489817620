import classNames from 'classnames';

import { renderUI } from './util';

export interface TextMutedProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {}

export const TextMuted = ({ className, ...otherProps }: TextMutedProps) => {
  return renderUI({ bs5: <span className={classNames('text-muted', className)} {...otherProps} /> });
};
