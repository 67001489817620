import { ProductApiDto } from '@b2x/storefront-api-js-client/src/';
import { GetProductOptions } from '@b2x/storefront-api-js-client/src/products';
import React from 'react';

import { analytics } from './analytics/analytics';
import { ApiRequestConfig } from './api/useApiRequest';
import { useProductsApi } from './api/useProductsApi';
import { useAppContext, useAppStaticContext } from './AppContext';
import { useStable } from './util';

export interface UseProductOptions extends GetProductOptions {
  sendEvent?: boolean;
}

export const useProduct = <ContentType,>(options?: UseProductOptions, config?: ApiRequestConfig) => {
  const [product, setProduct] = React.useState<ProductApiDto<ContentType>>();

  const { getProductByPath } = useProductsApi();

  const { routeInfo } = useAppContext();

  const { populate, sendEvent = true, ...otherOptions } = options ?? {};

  const otherStableOptions = useStable(otherOptions);
  const stablePopulate = useStable(populate);
  const stableConfig = useStable(config);

  const { setDynamicSegment404 } = useAppStaticContext();

  React.useEffect(() => {
    routeInfo?.pathParam &&
      getProductByPath<ContentType>(
        routeInfo.pathParam,
        { populate: stablePopulate, ...otherStableOptions },
        { suppressErrorModal: true, ...stableConfig }
      )
        .then((response) => {
          setProduct(response.data);
          sendEvent && analytics.events.viewProduct(response.eventId, response.data);
        })
        .catch(() => {
          // nascondo qualsiasi modale di errore e simulo un 404
          setDynamicSegment404(true);
        });
  }, [
    getProductByPath,
    otherStableOptions,
    routeInfo?.pathParam,
    sendEvent,
    setDynamicSegment404,
    stableConfig,
    stablePopulate,
  ]);

  return product;
};
