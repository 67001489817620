import { TopSuggestionsApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions } from './utils/util';

const rootPath = '/v2/suggestions';

export interface GetTopSuggestionsOptions extends BaseOptions {
  directory?: string;
  maxDirectories?: number;
  maxPopularSearches?: number;
  maxProducts?: number;
  simpleSearch?: string;
}

export const getTopSuggestions = async (options: GetTopSuggestionsOptions) =>
  apiRequest<TopSuggestionsApiDto, void, void>({
    ...getBaseOptions(options),
    params: {
      directory: options.directory,
      maxDirectories: options.maxDirectories,
      maxPopularSearches: options.maxPopularSearches,
      maxProducts: options.maxProducts,
      simpleSearch: options.simpleSearch,
    },
    staleTime: Infinity,
    url: `${rootPath}/top`,
  });
