import classNames from 'classnames';

import { BackgroundOpacity } from './interfaces';
import { buildBackgroundOpacityClassNames, renderUI } from './util';

export interface BadgeProps<ThemeColor> {
  backgroundColor?: string;
  backgroundOpacity?: BackgroundOpacity;
  backgroundVariant?: ThemeColor;
  children?: React.ReactNode;
  className?: string;
  pill?: boolean;
  textColor?: string;
  textVariant?: ThemeColor;
}

export const Badge = <ThemeColor extends string>({
  backgroundColor,
  backgroundOpacity,
  backgroundVariant,
  children,
  className,
  pill,
  textColor,
  textVariant,
}: BadgeProps<ThemeColor>) => {
  return renderUI({
    bs5: (
      <span
        className={classNames(
          'badge',
          className,
          { [`bg-${backgroundVariant}`]: backgroundVariant },
          { [`text-${textVariant}`]: textVariant },
          { 'rounded-pill': pill },
          backgroundOpacity && buildBackgroundOpacityClassNames(backgroundOpacity)
        )}
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        <span className="children-wrapper">{children}</span>
      </span>
    ),
  });
};
