import { CountryApiDto, LocaleApiDto } from '@b2x/storefront-api-js-client/src';

export type UI = 'bs5';

interface StaticVariables {
  locale?: LocaleApiDto;
  shippingCountry?: CountryApiDto;
}

export let staticVariables: StaticVariables;

export const updateStaticVariables = (_staticVariables: StaticVariables) => {
  staticVariables = {
    ...staticVariables,
    ..._staticVariables,
  };
};
