import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { TextInput } from './fields/Input';
import { Checkbox, Radios } from './fields/RadioCheck';
import { FormGroup } from './FormGroup';
import { InvoiceFieldsetA } from './InvoiceFieldsetA';
import { InvoiceFieldsHelper } from './useAddressForm';

export interface InvoiceFieldsetProps {
  invoiceFieldsHelper: InvoiceFieldsHelper;
}

const InvoiceFieldset = ({ invoiceFieldsHelper }: InvoiceFieldsetProps) => {
  return (
    <>
      <FormGroup {...invoiceFieldsHelper.request.formGroup}>
        <Checkbox {...invoiceFieldsHelper.request.checkbox} />
      </FormGroup>

      {invoiceFieldsHelper.type && (
        <>
          <FormGroup {...invoiceFieldsHelper.type.formGroup}>
            <Radios {...invoiceFieldsHelper.type.radios} />
          </FormGroup>
        </>
      )}

      {invoiceFieldsHelper.data && (
        <>
          {invoiceFieldsHelper.data.taxCode && (
            <FormGroup {...invoiceFieldsHelper.data.taxCode.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.taxCode.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.vatNumber && (
            <FormGroup {...invoiceFieldsHelper.data.vatNumber.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.vatNumber.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.company && (
            <FormGroup {...invoiceFieldsHelper.data.company.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.company.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.pec && (
            <FormGroup {...invoiceFieldsHelper.data.pec.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.pec.textInput} />
            </FormGroup>
          )}
          {invoiceFieldsHelper.data.uniqueCode && (
            <FormGroup {...invoiceFieldsHelper.data.uniqueCode.formGroup}>
              <TextInput {...invoiceFieldsHelper.data.uniqueCode.textInput} />
            </FormGroup>
          )}
        </>
      )}
    </>
  );
};

export type InvoiceFieldsetVariants = 'A';

const InvoiceFieldsetController = (props: PropsWithCustomComponent<InvoiceFieldsetProps>) => (
  <VariantsController<InvoiceFieldsetProps, InvoiceFieldsetVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: {
        A: InvoiceFieldsetA,
      },
      defaultComponent: InvoiceFieldset,
      name: 'InvoiceFieldset',
    }}
  />
);
export { InvoiceFieldsetController as InvoiceFieldset };
