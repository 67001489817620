import React from 'react';

import { createContext } from './util';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface ExampleContextInterface {
  counter: number;
  decrementCounter(): void;
  incrementCounter(): void;
}

export const [ExampleContextProvider, useExampleContext] = createContext<ExampleContextInterface>('ExampleContext');

// Proprietà necessarie per creare il contesto
interface UseExampleContextInitializerProps {
  initialValue?: number;
  max?: number;
  min?: number;
}

// Funzione per inizializzare il contesto
const useExampleContextInitializer = ({ initialValue = 0, max, min }: UseExampleContextInitializerProps) => {
  const [counter, setCounter] = React.useState<number>(initialValue);

  const incrementCounter = React.useCallback(() => {
    if (max === undefined || counter < max) {
      setCounter((previousValue) => previousValue + 1);
    }
  }, [counter, max]);

  const decrementCounter = React.useCallback(() => {
    if (min === undefined || counter > min) {
      setCounter((previousValue) => previousValue - 1);
    }
  }, [counter, min]);

  // Crea l'oggetto exampleContext
  const exampleContext: ExampleContextInterface = React.useMemo(
    () => ({
      counter,
      decrementCounter,
      incrementCounter,
    }),
    [counter, decrementCounter, incrementCounter]
  );

  return {
    ExampleContextProvider,
    exampleContext,
  };
};

// Interfaccia componente contesto
export interface ExampleContextProps extends UseExampleContextInitializerProps {
  children: React.ReactNode | ((exampleContext: ExampleContextInterface) => React.ReactNode);
}

// Componente Contesto
export const ExampleContext = ({ children, ...otherProps }: ExampleContextProps) => {
  const exampleContextInitializer = useExampleContextInitializer(otherProps);
  return (
    <exampleContextInitializer.ExampleContextProvider value={exampleContextInitializer.exampleContext}>
      {typeof children === 'function' ? children(exampleContextInitializer.exampleContext) : children}
    </exampleContextInitializer.ExampleContextProvider>
  );
};
