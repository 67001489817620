import { ContentApiDto, OrderApiDto } from '@b2x/storefront-api-js-client/src';
import { OrderPopulate } from '@b2x/storefront-api-js-client/src/orders';
import React from 'react';

import { useCustomerApi } from './api/useCustomerApi';
import { useOrdersApi } from './api/useOrdersApi';
import { useAppContext } from './AppContext';
import { ContactUsContentType } from './contentTypes';
import { CustomerCareForm } from './form/CustomerCareForm';
import { Radio } from './form/fields/RadioCheck';
import { Select } from './form/fields/Select';
import {
  Form,
  formikBooleanAsString,
  formikString,
  getFormikBooleanAsStringValue,
  getInitialBooleanAsString,
  getInitialString,
} from './form/Form';
import { FormGroup } from './form/FormGroup';
import { OrderGuestForm } from './form/OrderGuestForm';
import { Div, P } from './HTMLElement';
import { t } from './i18n/i18n';
import { OrderDetails } from './OrderDetails';
import { useContent } from './useContent';
import { formatHtml } from './util';

export interface ContactUsProps {
  disableSatisfiedForm?: boolean;
  showSatisfiedForm?: boolean;
}

export const ContactUs = ({ disableSatisfiedForm, showSatisfiedForm }: ContactUsProps) => {
  const content = useContent<ContactUsContentType>('CONTACT_US_CONTENT');

  const [selectedSubjectIndex, setSelectedSubjectIndex] = React.useState<number>();
  const [customerOrders, setCustomerOrders] = React.useState<Array<OrderApiDto>>();
  const [order, setOrder] = React.useState<OrderApiDto>();
  const [satisfied, setSatisfied] = React.useState<boolean>(true);

  const { session } = useAppContext();
  const { getOrders } = useCustomerApi();
  const { getOrderByCodeAndEmail } = useOrdersApi();

  const handleSubjectFormChange = React.useCallback((values: SubjectFormValues) => {
    if (values.subject === '') {
      setSelectedSubjectIndex(undefined);
    } else {
      setSelectedSubjectIndex(Number(values.subject));
    }
    setOrder(undefined);
    setSatisfied(true);
  }, []);

  const selectedSubject = React.useMemo(
    () =>
      content?.body.subjects && selectedSubjectIndex !== undefined
        ? content.body.subjects[selectedSubjectIndex]
        : undefined,
    [content?.body.subjects, selectedSubjectIndex]
  );

  const handleOrderGuestFormSucess = React.useCallback((foundedOrder: OrderApiDto) => {
    setOrder(foundedOrder);
    setSatisfied(true);
  }, []);

  const handleCustomerOrderFormChange = React.useCallback(
    (values: CustomerOrderFormValues) => {
      if (values.id === '') {
        setOrder(undefined);
      } else {
        const customerOrder = customerOrders?.find((_customerOrder) => _customerOrder.id === values.id);
        customerOrder &&
          getOrderByCodeAndEmail(
            customerOrder.code,
            customerOrder.email,
            {
              populate: orderPopulate,
            },
            { silent: false }
          ).then((response) => {
            setOrder(response.data);
          });
      }
      setSatisfied(true);
    },
    [customerOrders, getOrderByCodeAndEmail]
  );

  const handleSatisfiedFormChange = React.useCallback((values: SatisfiedFormValues) => {
    setSatisfied(getFormikBooleanAsStringValue(values.satisfied));
  }, []);

  // Se a navigare è un customer loggato, prendo la lista dei suoi ordini
  React.useEffect(() => {
    if (session?.userLogged) {
      getOrders().then((response) => {
        setCustomerOrders(response.data);
      });
    }
  }, [getOrders, session?.userLogged]);

  return (
    <Div marginBottom={-4}>
      <Div marginBottom={4}>
        <SubjectForm content={content} onChange={handleSubjectFormChange} />
      </Div>
      {selectedSubject && (
        <>
          <Div marginBottom={4}>{formatHtml(selectedSubject.message)}</Div>
          {selectedSubject.orderRelated === true && (
            <Div marginBottom={4}>
              {session?.userLogged ? (
                <CustomerOrderForm customerOrders={customerOrders} onChange={handleCustomerOrderFormChange} />
              ) : (
                <OrderGuestForm onSuccess={handleOrderGuestFormSucess} orderPopulate={orderPopulate} />
              )}
            </Div>
          )}
          {order && (
            <Div marginBottom={4}>
              <OrderDetails order={order} />
            </Div>
          )}
          {!disableSatisfiedForm && (
            <>
              {showSatisfiedForm ? (
                <P>{t('contactUs.notSatisfied.label')}</P>
              ) : (
                <Div marginBottom={4}>
                  <SatisfiedForm onChange={handleSatisfiedFormChange} />
                </Div>
              )}
              {(satisfied === false || showSatisfiedForm) && (
                <Div marginBottom={4}>
                  <CustomerCareForm subject={selectedSubject.subject} />
                </Div>
              )}
            </>
          )}
        </>
      )}
    </Div>
  );
};

interface SubjectFormProps {
  content?: ContentApiDto<ContactUsContentType>;
  onChange(values: SubjectFormValues): void;
}

interface SubjectFormValues {
  subject: formikString;
}

const SubjectForm = ({ content, onChange }: SubjectFormProps) => {
  return (
    <Form<SubjectFormValues> initialValues={{ subject: getInitialString() }} onChange={onChange}>
      <FormGroup label={t('contactUs.subject.label')} names={['subject']}>
        <Select includeEmptyOption={false} name="subject" placeholder={t('contactUs.subject.placeholder')}>
          {content?.body.subjects &&
            content.body.subjects.map(({ contentSectionId, subject }, index) => (
              <option key={contentSectionId} value={index}>
                {subject}
              </option>
            ))}
        </Select>
      </FormGroup>
    </Form>
  );
};

interface CustomerOrderFormProps {
  customerOrders?: Array<OrderApiDto>;
  onChange(values: CustomerOrderFormValues): void;
}

interface CustomerOrderFormValues {
  id: formikString;
}

const CustomerOrderForm = ({ customerOrders, onChange }: CustomerOrderFormProps) => {
  return (
    <Form<CustomerOrderFormValues> initialValues={{ id: getInitialString() }} onChange={onChange}>
      <FormGroup label={t('contactUs.customerOrder.label')} names={['id']}>
        <Select includeEmptyOption={false} name="id" placeholder={t('contactUs.customerOrder.placeholder')}>
          {customerOrders &&
            customerOrders.map((customerOrder) => (
              <option key={customerOrder.id} value={customerOrder.id}>
                {customerOrder.code}
              </option>
            ))}
        </Select>
      </FormGroup>
    </Form>
  );
};

interface SatisfiedFormProps {
  onChange(values: SatisfiedFormValues): void;
}

interface SatisfiedFormValues {
  satisfied: formikBooleanAsString;
}

const SatisfiedForm = ({ onChange }: SatisfiedFormProps) => {
  return (
    <Form<SatisfiedFormValues> initialValues={{ satisfied: getInitialBooleanAsString(true) }} onChange={onChange}>
      <FormGroup label={t('contactUs.satisfied.label')} names={['satisfied']} omitForAttribute>
        <Radio id="yes" inline label={t('contactUs.satisfied.yes')} name="satisfied" value="true" />
        <Radio id="no" inline label={t('contactUs.satisfied.no')} name="satisfied" value="false" />
      </FormGroup>
    </Form>
  );
};

const orderPopulate: OrderPopulate = {
  billingAddress: true,
  paymentMethod: true,
  shippingAddress: true,
  warehouseOrders: { carrierTracking: true, orderedSkus: { sku: { product: true } } },
};
