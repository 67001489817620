import './PriceBlock.scss';

import { b2x } from '@b2x/react/src';

export type PriceBlockProps = b2x.PriceBlockProps;

export const PriceBlock = ({ classNames, ...otherProps }: PriceBlockProps) => {
  return (
    <b2x.PriceBlock
      {...otherProps}
      alignItems={'center'}
      classNames={{
        crossedOutPrice: `pt-1 fw-light text-gray-300 ${classNames?.crossedOutPrice}`,
        discountPercentage: `d-flex align-items-center bg-promo px-3 pt-1 text-white d-flex align-items-center ${classNames?.discountPercentage}`,
        price: `pt-1 fw-bold text-black ${classNames?.price}`,
        specialPrice: `pt-1 fw-bold text-promo ${classNames?.specialPrice}`,
      }}
    />
  );
};
