import React from 'react';

import { appConfig } from './config';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
interface VariantsContextInterface {
  setVariants: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  variants: Record<string, string>;
}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [VariantsContextProvider, useVariantsContext] = createContext<VariantsContextInterface>('VariantsContext');

// Proprietà necessarie per creare il contesto
interface UseVariantsContextInitializerProps {}

// Funzione per inizializzare il contesto
const useVariantsContextInitializer = (props: UseVariantsContextInitializerProps) => {
  const [variants, setVariants] = React.useState<Record<string, string>>(
    Object.entries(appConfig.variants).reduce<Record<string, string>>((acc, [key, value]) => {
      acc[key] = typeof value === 'string' ? value : 'custom';
      return acc;
    }, {})
  );

  // Crea l'oggetto variantsContext
  const variantsContext: VariantsContextInterface = React.useMemo(
    () => ({
      setVariants,
      variants,
    }),
    [variants]
  );

  return {
    VariantsContextProvider,
    variantsContext,
  };
};

// Interfaccia componente contesto
export interface VariantsContextProps extends UseVariantsContextInitializerProps {
  children: React.ReactNode | ((variantsContext: VariantsContextInterface) => React.ReactNode);
}

// Componente Contesto
export const VariantsContext = ({ children, ...otherProps }: VariantsContextProps) => {
  const variantsContextInitializer = useVariantsContextInitializer(otherProps);
  return (
    <variantsContextInitializer.VariantsContextProvider value={variantsContextInitializer.variantsContext}>
      {typeof children === 'function' ? children(variantsContextInitializer.variantsContext) : children}
    </variantsContextInitializer.VariantsContextProvider>
  );
};
