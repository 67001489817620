import _hash from "hash.js";
import Cookies from "js-cookie";

const COOKIE_PREFIX = "baim_";

export const getCookie = (name: string) => Cookies.get(COOKIE_PREFIX + name);

export const setCookie = (
  name: string,
  value: string,
  expires: number | undefined,
  hash: boolean
) =>
  Cookies.set(
    COOKIE_PREFIX + name,
    hash ? _hash.sha256().update(value).digest("hex") : value,
    { expires: expires }
  );

export const removeCookie = (name: string) =>
  Cookies.remove(COOKIE_PREFIX + name);

export const checkCookie = (
  name: string,
  value: string,
  expires: number | undefined,
  hash: boolean
) => {
  if (!getCookie(name)) {
    setCookie(name, value, expires, hash);
  }
};
