import { b2x } from '@b2x/react/src';

import { MiscellaneousContentType } from './contentTypes';
import { NewsletterForm } from './forms/NewsletterForm';

export interface NewsletterProps {
  subTitle?: string;
  title?: string;
}

export const Newsletter = ({ subTitle, title }: NewsletterProps) => {
  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');

  return (
    <div
      className="Newsletter d-none d-lg-block"
      style={
        content?.body.backgroundImageBoxNewsletter
          ? {
              backgroundImage: content.body.backgroundImageBoxNewsletter
                ? `url(${content.body.backgroundImageBoxNewsletter})`
                : 'none',
              backgroundPosition: 'center bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }
          : undefined
      }
    >
      <div className="py-4 text-center">
        <div className="container-xxl">
          <div className="display-1 mb-3">{b2x.formatHtml(title)}</div>
          <div className="mb-3 h4 fw-normal">{b2x.formatHtml(subTitle)}</div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-7 text-start">
              <NewsletterForm emailFieldStyle="inputGroup" source={'website-footer'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
