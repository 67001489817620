import classnames from 'classnames';

import { Col } from '../Col';
import { Container } from '../Container';
import { Icon } from '../Icon';
import { renderUI } from '../util';

export interface IconsProps {
  names: Readonly<Array<string>>;
  size?: number;
}

export const Icons = ({ names, size = 35 }: IconsProps) => {
  return renderUI({
    bs5: (
      <Container>
        (tot: {names.length})
        {['bg-white', 'bg-black'].map((backgroundColor) => (
          <div className={classnames('p-3', backgroundColor)} key={backgroundColor}>
            <div className="row gy-4 gy-lg-5">
              {names.map((name) => (
                <Col key={name} size={{ lg: 1, sm: 2, xs: 3 }}>
                  <div
                    className={classnames('text-center', {
                      'text-white': backgroundColor === 'bg-black',
                    })}
                  >
                    <p className="mb-2">
                      <Icon name={name} size={size} />
                    </p>
                    <span className="extra-small d-inline-block text-truncate w-100" title={name}>
                      {name}
                    </span>
                  </div>
                </Col>
              ))}
            </div>
          </div>
        ))}
      </Container>
    ),
  });
};
