import classnames from 'classnames';

import { Div, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { PriceBlockProps } from './PriceBlock';
import { formatCurrency, formatFloatPercentage } from './util';

export const PriceBlockA = ({
  alignItems,
  classNames,
  direction,
  gap,
  hiddenDiscountLabel,
  hiddenDiscountPercentage,
  priceHelper,
}: PriceBlockProps) => {
  return (
    <Div className="price-block">
      <Div alignItems={alignItems} display={'flex'} flexDirection={direction} gap={gap}>
        {priceHelper.crossedOutPrice && (
          <Span className={classnames('crossed-out-price', classNames?.crossedOutPrice)}>
            <del>
              {t(priceHelper.isPriceInRange ? 'misc.fromPrice' : 'misc.price', {
                value: formatCurrency(priceHelper.crossedOutPrice),
              })}
            </del>
          </Span>
        )}
        <Span
          className={classnames(
            'price',
            { 'special-price': priceHelper.crossedOutPrice },
            classNames?.price,
            priceHelper.crossedOutPrice && classNames?.specialPrice
          )}
        >
          {t(priceHelper.isPriceInRange ? 'misc.fromPrice' : 'misc.price', {
            value: formatCurrency(priceHelper.price),
          })}
        </Span>
        {!hiddenDiscountLabel && priceHelper.discountPercentage && (
          <Span className={classnames('discount-percentage', classNames?.discountPercentage)}>
            {t('product.promo')}
            {!hiddenDiscountPercentage &&
              priceHelper.discountPercentage &&
              ` -${formatFloatPercentage(priceHelper.discountPercentage)}%`}
          </Span>
        )}
      </Div>
    </Div>
  );
};
