import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Organization as OrganizationSchema } from 'schema-dts';

import { useAppContext } from '../AppContext';

export type OrganizationProps = Omit<OrganizationSchema, '@context' | '@type'>;

export const Organization = (props: OrganizationProps) => {
  const { structuredDataContent } = useAppContext();

  const organization = structuredDataContent?.body.organization;

  return (
    <Helmet
      script={[
        helmetJsonLdProp<OrganizationSchema>(
          {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            address: {
              '@type': 'PostalAddress',
              addressCountry: {
                '@type': 'Country',
                name: organization?.address?.addressCountry,
              },
              addressLocality: organization?.address?.addressLocality,
              addressRegion: organization?.address?.addressRegion,
              postalCode: organization?.address?.postalCode,
              streetAddress: organization?.address?.streetAddress,
            },
            email: organization?.email,
            founder: {
              '@type': 'Person',
              name: organization?.founder?.name,
            },
            foundingDate: organization?.foundingDate,
            foundingLocation: organization?.foundingLocation,
            image: organization?.image?.url,
            logo: organization?.logo?.url,
            name: organization?.name,
            sameAs: organization?.sameAs
              ?.filter((item) => item.url !== undefined && item.url !== '')
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              .map((item) => item.url!),
            taxID: organization?.taxID,
            telephone: organization?.telephone,
            url: organization?.url,
            vatID: organization?.vatID,
          },
          {
            space: 1,
          }
        ),
      ]}
    />
  );
};
