import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { BrowserRouter as _BrowserRouter, BrowserRouterProps as _BrowserRouterProps } from 'react-router-dom-6';

import { BrowserRouterContext } from './BrowserRouterContext';

export const BrowserRouter = (props: _BrowserRouterProps) => {
  const [basename, setBasename] = React.useState<string>();

  return (
    <BrowserRouterContext setBasename={setBasename}>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_BrowserRouter basename={basename} {...props} />
    </BrowserRouterContext>
  );
};
