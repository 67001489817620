import { AccountOffcanvasA } from './AccountOffcanvasA';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface AccountOffcanvasProps {
  close(): void;
  title?: string;
}

export type AccountOffcanvasVariants = 'A';

const AccountOffcanvasController = (props: PropsWithCustomComponent<AccountOffcanvasProps>) => (
  <VariantsController<AccountOffcanvasProps, AccountOffcanvasVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountOffcanvasA },
      name: 'AccountOffcanvas',
    }}
  />
);
export { AccountOffcanvasController as AccountOffcanvas };
