import React from 'react';
import * as yup from 'yup';

import { Button } from '../Button';
import { Col } from '../Col';
import { Div } from '../HTMLElement';
import { Row } from '../Row';
import { CustomerFormHelper, CustomerFormProps } from './CustomerForm';
import { FieldArray } from './FieldArray';
import { DateInput, PasswordInput, TextInput } from './fields/Input';
import { Checkbox } from './fields/RadioCheck';
import { Select } from './fields/Select';
import { getInitialString } from './Form';
import { FormGroup } from './FormGroup';

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesValidationSchema = yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesValidationSchema = yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesChildrenFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesChildrenValidationSchema = yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesChildrenFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesChildrenValidationSchema = yup.ObjectSchema<{}>;

export type CustomerFormAProps = Omit<
  CustomerFormProps<
    AdditionalPropertiesFormValues,
    AdditionalPropertiesValidationSchema,
    CustomPropertiesFormValues,
    CustomPropertiesValidationSchema,
    AdditionalPropertiesChildrenFormValues,
    AdditionalPropertiesChildrenValidationSchema,
    CustomPropertiesChildrenFormValues,
    CustomPropertiesChildrenValidationSchema
  >,
  | 'additionalPropertiesInitialValues'
  | 'additionalProperties'
  | 'additionalPropertiesValidationSchema'
  | 'customPropertiesInitialValues'
  | 'customPropertiesValidationSchema'
  | 'validationSchemaSelector'
  | 'additionalPropertiesChildrenInitialValues'
  | 'additionalPropertiesChildren'
  | 'additionalPropertiesChildrenValidationSchema'
  | 'customPropertiesChildrenInitialValues'
  | 'customPropertiesChildrenValidationSchema'
  | 'validationSchemaChildrenSelector'
>;

export const CustomerFormA = (props: CustomerFormAProps) => {
  return (
    <CustomerFormHelper<
      AdditionalPropertiesFormValues,
      AdditionalPropertiesValidationSchema,
      CustomPropertiesFormValues,
      CustomPropertiesValidationSchema,
      AdditionalPropertiesChildrenFormValues,
      AdditionalPropertiesChildrenValidationSchema,
      CustomPropertiesChildrenFormValues,
      CustomPropertiesChildrenValidationSchema
    >
      {...props}
      maxChildren={0}
      maxPhoneNumbers={1}
      minChildren={0}
      minPhoneNumbers={1}
      validationSchemaSelector={{
        birthdate: false,
        children: { birthdate: false, name: false, sex: false, surname: false },
        name: true,
        phoneNumber: false,
        profile: false,
        sex: false,
        surname: true,
      }}
    >
      {({ content, fieldsHelper, formik, mode }) => (
        <>
          {mode === 'registration' && (
            <>
              <FormGroup {...fieldsHelper.name.formGroup}>
                <TextInput {...fieldsHelper.name.textInput} placeholder={undefined} />
              </FormGroup>
              <FormGroup {...fieldsHelper.surname.formGroup}>
                <TextInput {...fieldsHelper.surname.textInput} placeholder={undefined} />
              </FormGroup>
              <FormGroup {...fieldsHelper.email.formGroup}>
                <TextInput {...fieldsHelper.email.textInput} placeholder={undefined} />
              </FormGroup>
              <FormGroup {...fieldsHelper.password.formGroup}>
                <PasswordInput {...fieldsHelper.password.passwordInput} placeholder={undefined} />
              </FormGroup>
              <FormGroup {...fieldsHelper.privacyConsent.formGroup}>
                <Checkbox labelClassName="extra-small" {...fieldsHelper.privacyConsent.checkbox} />
              </FormGroup>
              {content?.body.newsletter?.enabled && (
                <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
                  <Checkbox labelClassName="extra-small" {...fieldsHelper.newsletterConsent.checkbox} />
                </FormGroup>
              )}
              {content?.body.profiling?.enabled && (
                <FormGroup {...fieldsHelper.profilingConsent.formGroup}>
                  <Checkbox labelClassName="extra-small" {...fieldsHelper.profilingConsent.checkbox} />
                </FormGroup>
              )}
              {content?.body.marketing?.enabled && (
                <FormGroup {...fieldsHelper.marketingConsent.formGroup}>
                  <Checkbox labelClassName="extra-small" {...fieldsHelper.marketingConsent.checkbox} />
                </FormGroup>
              )}
              {fieldsHelper.buttons.cancel ? (
                <Row>
                  <Col size={6}>
                    <Div display={'grid'}>
                      <Button {...fieldsHelper.buttons.cancel} />
                    </Div>
                  </Col>
                  <Col size={6}>
                    <Div display={'grid'}>
                      <Button {...fieldsHelper.buttons.submit} />
                    </Div>
                  </Col>
                </Row>
              ) : (
                <Div display={'grid'}>
                  <Button {...fieldsHelper.buttons.submit} />
                </Div>
              )}
            </>
          )}
          {mode === 'update' && (
            <>
              <Row>
                <Col size={{ sm: 6, xs: 12 }}>
                  <FormGroup {...fieldsHelper.name.formGroup}>
                    <TextInput {...fieldsHelper.name.textInput} placeholder={undefined} />
                  </FormGroup>
                </Col>
                <Col size={{ sm: 6, xs: 12 }}>
                  <FormGroup {...fieldsHelper.surname.formGroup}>
                    <TextInput {...fieldsHelper.surname.textInput} placeholder={undefined} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col size={{ sm: 6, xs: 12 }}>
                  <FormGroup {...fieldsHelper.sex.formGroup}>
                    <Select {...fieldsHelper.sex.select} />
                  </FormGroup>
                </Col>
                <Col size={{ sm: 6, xs: 12 }}>
                  <FormGroup {...fieldsHelper.birthdate.formGroup}>
                    <DateInput {...fieldsHelper.birthdate.dateInput} placeholder={undefined} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col size={{ sm: 6, xs: 12 }}>
                  <FieldArray<string> {...fieldsHelper.phoneNumbers.fieldArray}>
                    {(arrayHelpers) => (
                      <FormGroup {...fieldsHelper.phoneNumbers.formGroup}>
                        {fieldsHelper.phoneNumbers.fields.map(({ formGroup, removeButton, textInput }, index) => (
                          <React.Fragment key={textInput.name}>
                            <FormGroup {...formGroup}>
                              <TextInput {...textInput} />
                            </FormGroup>
                            {typeof fieldsHelper.phoneNumbers.fieldArray.min === 'number' &&
                              index >= fieldsHelper.phoneNumbers.fieldArray.min && (
                                <p>
                                  <Button
                                    {...removeButton}
                                    disabled={arrayHelpers.removeDisabled}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                    variant="blank"
                                  />
                                </p>
                              )}
                          </React.Fragment>
                        ))}
                        {fieldsHelper.phoneNumbers.fieldArray.max &&
                          formik.values.phoneNumbers.length < fieldsHelper.phoneNumbers.fieldArray.max && (
                            <p>
                              <Button
                                {...fieldsHelper.phoneNumbers.addButton}
                                disabled={arrayHelpers.pushDisabled}
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => {
                                  arrayHelpers.push(getInitialString());
                                }}
                                variant="blank"
                              />
                            </p>
                          )}
                      </FormGroup>
                    )}
                  </FieldArray>
                </Col>
                {/* <Col size={{ sm: 6, xs: 12 }}>
                  <FormGroup {...fieldsHelper.email.formGroup}>
                    <TextInput {...fieldsHelper.email.textInput} disabled />
                  </FormGroup>
                </Col> */}
              </Row>
              <Div display={'flex'} gap={3} justifyContent={'center'}>
                <Button {...fieldsHelper.buttons.submit} />
              </Div>
            </>
          )}
        </>
      )}
    </CustomerFormHelper>
  );
};
