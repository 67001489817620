import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { NavLink as _NavLink, NavLinkProps as _NavLinkProps, To, useLocation } from 'react-router-dom-6';

import { useAppStaticContext } from '../AppContext';
import { scrollTop as _scrollTop, wait } from '../util';

export interface NavLinkProps extends Omit<_NavLinkProps, 'to'> {
  preferFrom?: boolean;
  scrollTop?: boolean;
  silent?: boolean;
  to?: To;
}

export const NavLink = ({ onClick, preferFrom, scrollTop = true, silent, to, ...otherProps }: NavLinkProps) => {
  const { setPageReady } = useAppStaticContext();
  const { pathname, search, state } = useLocation();
  // const { getPageByPath } = usePagesApi();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (pathname !== to) {
        onClick && onClick(event);
        !silent && setPageReady(false);
        scrollTop && wait(150).then(() => _scrollTop());
      }
    },
    [onClick, pathname, scrollTop, setPageReady, silent, to]
  );

  // React.useEffect(() => {
  //   to && queryClient.prefetchQuery({ queryFn: () => getPageByPath(to.toString()), queryKey: [to.toString()] });
  // }, [getPageByPath, to]);

  const navigateTo = preferFrom && state?.from ? state.from : to;
  return (
    <>
      {navigateTo && (
        // eslint-disable-next-line react/jsx-pascal-case
        <_NavLink
          state={{ from: pathname + search, ...otherProps.state }}
          {...otherProps}
          onClick={handleClick}
          to={navigateTo}
        />
      )}
    </>
  );
};
