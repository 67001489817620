// import './ListA.scss';

import { b2x } from '@b2x/react/src';

import { ListA, ListAProps } from './ListA';

export interface ListAFromMenuProps extends Omit<ListAProps, 'items'> {
  menu: b2x.MenuApiDto;
}

export const ListAFromMenu = ({ menu, ...otherProps }: ListAFromMenuProps) => {
  return (
    <ListA {...otherProps} items={menu.children.map((item) => ({ cta: item.link ?? '#', label: item.label ?? '' }))} />
  );
};
