import React from 'react';

import { useCustomerApi } from './api/useCustomerApi';
import { useAppStaticContext } from './AppContext';

export const useReferralCode = () => {
  const { getReferralCode } = useCustomerApi();
  const { getFixedPagePath } = useAppStaticContext();

  const [referralCode, setReferralCode] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    getReferralCode().then((response) => setReferralCode(response.data));
  }, [getReferralCode]);

  const referralLink = React.useMemo(() => {
    if (!referralCode) {
      return undefined;
    }

    const url = new URL(getFixedPagePath('register'), window.location.origin);
    url.searchParams.append('referralCode', referralCode);
    return url.toString();
  }, [referralCode, getFixedPagePath]);

  return { referralCode, referralLink };
};
