/* eslint-disable react/forbid-elements */
import { Div } from './HTMLElement';
import loading from './images/loading.svg';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface LoadingProps {}

const Loading = (props: LoadingProps) => (
  <Div textAlign={'center'}>
    <img alt="loading..." height={100} src={loading} width={100} />
  </Div>
);

export type LoadingVariants = '';

const LoadingController = (props: PropsWithCustomComponent<LoadingProps>) => (
  <VariantsController<LoadingProps, LoadingVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: Loading,
      name: 'Loading',
    }}
  />
);
export { LoadingController as Loading };
