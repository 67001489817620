import classnames from 'classnames';
import React from 'react';
import * as yup from 'yup';

import { createUUID, renderUI } from '../util';
import { useFormContextStrict } from './FormContext';
import { FormGroupContext } from './FormGroupContext';

export interface FormGroupProps {
  children?: React.ReactNode;
  className?: string;
  help?: string;
  label?: string | React.ReactElement;
  labelClassName?: string;
  names: Array<string>;
  noMarginBottom?: boolean;
  omitForAttribute?: boolean;
  required?: boolean | 'auto';
}

export const FormGroup = ({
  children,
  className,
  help,
  label,
  labelClassName,
  names,
  noMarginBottom = false,
  omitForAttribute,
  required = 'auto',
}: FormGroupProps): React.ReactElement => {
  const id = createUUID();

  const formContext = useFormContextStrict();

  const { validationSchema, values } = formContext || {};

  const isRequired = React.useMemo<boolean>(() => {
    if (required === 'auto') {
      if (validationSchema !== undefined) {
        if (names.length === 1) {
          const name = names[0];
          try {
            const fieldValidationSchema = yup.reach(validationSchema, name, values) as yup.BaseSchema;
            return fieldValidationSchema.describe().tests.findIndex((test) => test.name === 'required') !== -1;
          } catch (error) {
            console.error('Nome del FormGroup non trovato nel validationSchema.', name, validationSchema);
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return required;
    }
  }, [names, required, validationSchema, values]);

  return (
    <FormGroupContext id={omitForAttribute ? undefined : id} required={isRequired} withLabel={label !== undefined}>
      {renderUI({
        bs5: (
          <div className={classnames('form-group', { 'mb-3': !noMarginBottom }, className)}>
            {label &&
              (!omitForAttribute ? (
                <label
                  className={classnames('d-block form-label', { required: isRequired }, labelClassName)}
                  htmlFor={id}
                >
                  {label}
                </label>
              ) : (
                <span className={classnames('d-block form-label', { required: isRequired }, labelClassName)}>
                  {label}
                </span>
              ))}
            {children}
            {help && <div className="form-text">{help}</div>}
          </div>
        ),
      })}
    </FormGroupContext>
  );
};

export interface FakeFormGroupForVerticalSpacingProps {
  children: React.ReactNode;
  className?: string;
}

export const FakeFormGroupForVerticalSpacing = ({ children, className }: FakeFormGroupForVerticalSpacingProps) =>
  renderUI({
    bs5: (
      <div className="mb-3">
        <span className={classnames(className, 'd-block form-label')}>&nbsp;</span>
        {children}
      </div>
    ),
  });
