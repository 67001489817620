import React from 'react';

export interface WindowSize {
  height: number;
  width: number;
}

export const useWindowSize = (timeout = 200): WindowSize => {
  const getSize = (): WindowSize => {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  };

  const [size, setSize] = React.useState<WindowSize>(getSize());
  const timeoutId = React.useRef<number>();

  const resizeListener = React.useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = window.setTimeout(() => {
      setSize(getSize());
    }, timeout);
  }, [timeout]);

  React.useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [resizeListener]);

  return size;
};
