import { CartApiDto, ShippingProfileApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { analytics } from '../analytics/analytics';
import { useCartApi } from '../api/useCartApi';
import { useAppContext } from '../AppContext';
import { useCheckoutContextStrict } from '../CheckoutContext';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Radio, RadioProps } from './fields/RadioCheck';
import { formikString, getInitialString } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface CartShippingProfilesFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  cart: CartApiDto;
}

interface FormValues {
  id: formikString;
}

type ValidationSchema = {
  id: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  id: {
    formFields: Array<{ radio: RadioProps; shippingProfile: ShippingProfileApiDto }>;
    formGroup: FormGroupProps;
  };
}

export const CartShippingProfilesFormHelper = ({
  cart,
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: CartShippingProfilesFormProps) => {
  const [shippingProfiles, setShippingProfiles] = React.useState<Array<ShippingProfileApiDto>>([]);

  const { getShippingProfiles, setShippingProfile } = useCartApi();
  const { session } = useAppContext();

  // Prendo gli shippingProfiles al primo caricamento e quando cambia il carrello in sessione (potrebbero cambiare le soglie)
  React.useEffect(() => {
    getShippingProfiles().then((response) => {
      setShippingProfiles(response.data);
    });
  }, [getShippingProfiles, session?.cart]);

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      id: getInitialString(cart.shippingProfile?.id),
      ...initialValues,
    }),
    [cart.shippingProfile?.id, initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      id: yup.string(),
    }),
    []
  );

  const checkoutContext = useCheckoutContextStrict();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      setShippingProfile({ shippingProfileCost: 0, shippingProfileId: values.id }).then((response) => {
        const shippingProfile = shippingProfiles.find((_shippingProfile) => _shippingProfile.id === values.id);
        if (shippingProfile?.name) {
          analytics.events.addShippingInfo(response.eventId, cart, shippingProfile.name);
        }
        if (checkoutContext) {
          checkoutContext.deleteForcedStep();
        }
        onSuccess && onSuccess();
      }),
    [cart, checkoutContext, onSuccess, setShippingProfile, shippingProfiles]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('CartShippingProfilesForm', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      submitOnChange
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          id: {
            formFields: shippingProfiles.map((shippingProfile) => ({
              radio: {
                id: shippingProfile.id,
                label: shippingProfile.name,
                name: 'id',
                value: shippingProfile.id,
              },
              shippingProfile: shippingProfile,
            })),
            formGroup: { label: 'Shipping profiles', names: ['id'], omitForAttribute: true },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <FormGroup {...fieldsHelper.id.formGroup}>
            {fieldsHelper.id.formFields.map(({ radio, shippingProfile }) => (
              <Radio key={radio.id} {...radio} />
            ))}
          </FormGroup>
        );
      }}
    </HelpedForm>
  );
};

export type CartShippingProfilesFormVariants = '';

const CartShippingProfilesFormController = (
  props: PropsWithCustomComponentWithoutChildren<CartShippingProfilesFormProps>
) => (
  <VariantsController<CartShippingProfilesFormProps, CartShippingProfilesFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CartShippingProfilesFormHelper,
      name: 'CartShippingProfilesForm',
    }}
  />
);
export { CartShippingProfilesFormController as CartShippingProfilesForm };
