import { CommonFieldProps } from '../Form';
import {
  DateInput,
  DateInputProps,
  NumberInput,
  NumberInputProps,
  PasswordInput,
  PasswordInputProps,
  TextInput,
  TextInputProps,
  TimeInput,
  TimeInputProps,
} from './Input';
import { Checkbox, CheckboxProps, Radio, RadioProps } from './RadioCheck';
import { Select, SelectProps } from './Select';
import { TextArea, TextAreaProps } from './TextArea';

export interface DynamicFieldProps extends Partial<CommonFieldProps<unknown, unknown>> {
  fieldProps: FieldProps;
  type: Type;
}

type FieldProps =
  | TextInputProps
  | PasswordInputProps
  | NumberInputProps
  | DateInputProps
  | TimeInputProps
  | CheckboxProps
  | RadioProps
  | SelectProps
  | TextAreaProps;
type Type =
  | 'textInput'
  | 'passwordInput'
  | 'numberInput'
  | 'dateInput'
  | 'timeInput'
  | 'checkbox'
  | 'radio'
  | 'select'
  | 'textArea';

export const DynamicField = ({ fieldProps, type, ...otherProps }: DynamicFieldProps) => {
  return (
    <>
      {isTextInput(fieldProps, type) ? (
        <TextInput {...fieldProps} {...(otherProps as CommonFieldProps<HTMLInputElement, string>)} />
      ) : isPasswordInput(fieldProps, type) ? (
        <PasswordInput {...fieldProps} {...(otherProps as CommonFieldProps<HTMLInputElement, string>)} />
      ) : isNumberInput(fieldProps, type) ? (
        <NumberInput {...fieldProps} {...(otherProps as CommonFieldProps<HTMLInputElement, number>)} />
      ) : isDateInput(fieldProps, type) ? (
        <DateInput {...fieldProps} {...(otherProps as CommonFieldProps<HTMLInputElement, string>)} />
      ) : isTimeInput(fieldProps, type) ? (
        <TimeInput {...fieldProps} {...(otherProps as CommonFieldProps<HTMLInputElement, string>)} />
      ) : isCheckbox(fieldProps, type) ? (
        <Checkbox {...fieldProps} {...(otherProps as CommonFieldProps<HTMLInputElement, boolean>)} />
      ) : isRadio(fieldProps, type) ? (
        <Radio {...fieldProps} {...(otherProps as CommonFieldProps<HTMLInputElement, string>)} />
      ) : isSelect(fieldProps, type) ? (
        <Select {...fieldProps} {...(otherProps as CommonFieldProps<HTMLSelectElement, string>)} />
      ) : isTextArea(fieldProps, type) ? (
        <TextArea {...fieldProps} {...(otherProps as CommonFieldProps<HTMLTextAreaElement, string>)} />
      ) : (
        <></>
      )}
    </>
  );
};

const isTextInput = (fieldProps: FieldProps, type: Type): fieldProps is TextInputProps => type === 'textInput';
const isPasswordInput = (fieldProps: FieldProps, type: Type): fieldProps is PasswordInputProps =>
  type === 'passwordInput';
const isNumberInput = (fieldProps: FieldProps, type: Type): fieldProps is NumberInputProps => type === 'numberInput';
const isDateInput = (fieldProps: FieldProps, type: Type): fieldProps is DateInputProps => type === 'dateInput';
const isTimeInput = (fieldProps: FieldProps, type: Type): fieldProps is TimeInputProps => type === 'timeInput';
const isCheckbox = (fieldProps: FieldProps, type: Type): fieldProps is CheckboxProps => type === 'checkbox';
const isRadio = (fieldProps: FieldProps, type: Type): fieldProps is RadioProps => type === 'radio';
const isSelect = (fieldProps: FieldProps, type: Type): fieldProps is SelectProps => type === 'select';
const isTextArea = (fieldProps: FieldProps, type: Type): fieldProps is TextAreaProps => type === 'textArea';
