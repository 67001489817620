import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { PageHeaderContentSection } from './contentTypes';

export interface PageHeaderProps extends PageHeaderContentSection {
  className?: string;
}

export const PageHeader = ({ className, content, img, textColor, title }: PageHeaderProps) => {
  return (
    <section className={classnames(className, 'text-center')}>
      {img && (
        <div className="position-relative">
          <b2x.ImageFromContentV1 {...img} fluid />
          <div className="position-absolute top-50 start-50 translate-middle w-100">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10 mb-3 mb-xl-5 text-center">
                  {title && (
                    <h1 className={classnames(`text-${textColor}`, 'h1 text-uppercase')}>{b2x.formatHtml(title)}</h1>
                  )}
                  {content && (
                    <div className={classnames(`text-${textColor}`, 'mt-3 h3 d-none d-lg-block')}>
                      {b2x.formatHtml(content)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
