import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { ServiceSearchOrderSubpageA } from './serviceSubpagesA/ServiceSearchOrderSubpageA';

export interface ServiceSearchOrderSubpageProps {}

export type ServiceSearchOrderSubpageVariants = 'A';

const ServiceSearchOrderSubpageController = (props: PropsWithCustomComponent<ServiceSearchOrderSubpageProps>) => (
  <VariantsController<ServiceSearchOrderSubpageProps, ServiceSearchOrderSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: ServiceSearchOrderSubpageA },
      name: 'ServiceSearchOrderSubpage',
    }}
  />
);
export { ServiceSearchOrderSubpageController as ServiceSearchOrderSubpage };
