import { AddressApiDto } from '@b2x/storefront-api-js-client/src';

import { Button } from '../../../Button';
import { Col } from '../../../Col';
import { useChangePasswordFormModal } from '../../../form/ChangePasswordForm';
import { CustomerAddressFormProps, useCustomerAddressFormModal } from '../../../form/CustomerAddressForm';
import { useCustomerFormModal } from '../../../form/CustomerForm';
import { Div } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { Row } from '../../../Row';
import { useCustomer } from '../../../useCustomer';
import { formatAddress, formatDate, renderUI } from '../../../util';
import { AccountInfoSubpageProps } from '.././AccountInfoSubpage';
import { AccountSubpage } from '../AccountSubpage';

export const AccountInfoSubpageA = (props: AccountInfoSubpageProps) => {
  const customer = useCustomer({
    populate: {
      additionalPropertiesExt: true,
      addresses: true,
      children: {
        additionalProperties: true,
      },
      defaultBillingAddress: true,
      defaultShippingAddress: true,
      documents: true,
      qualifiers: true,
    },
  });

  // const showPersonalDataFormModal = usePersonalDataFormModal();
  const showCustomerFormModal = useCustomerFormModal();
  const showCustomerAddressFormModal = useCustomerAddressFormModal();
  const showChangePasswordFormModal = useChangePasswordFormModal();

  return renderUI({
    bs5: (
      <AccountSubpage>
        <Div marginBottom={4}>
          <Row cols={{ xl: 2, xs: 1 }} gap={4}>
            <Col>
              <InnerBox
                cta={
                  customer && {
                    label: t('account.edit'),
                    onClick: () => showCustomerFormModal({ customer }),
                  }
                }
                title={t('account.info.personalData')}
              >
                <ul className="list-group list-unstyled">
                  <InnerBoxListItem label={t('account.info.name')} value={`${customer?.name} ${customer?.surname}`} />
                  {/* <InnerBoxListItem label={t('account.info.surname')} value={} /> */}
                  {customer?.sex && customer.sex !== 'O' && (
                    <InnerBoxListItem
                      label={t('account.info.sex.title')}
                      value={t(`account.info.sex.options.${customer.sex}`)}
                    />
                  )}
                  {customer?.birthdate && (
                    <InnerBoxListItem label={t('account.info.birthdate')} value={formatDate(customer.birthdate)} />
                  )}
                  {customer?.phoneNumbers &&
                    customer.phoneNumbers.map((phone, index) => (
                      <InnerBoxListItem
                        key={phone}
                        label={index > 0 ? t('account.info.secondaryPhone') : t('account.info.phone')}
                        value={phone}
                      />
                    ))}
                  {customer?.profile && customer.profile === 'BUSINESS' && (
                    <InnerBoxListItem
                      label={t('account.info.profile.label')}
                      value={t(`account.info.profile.options.${customer.profile}`)}
                    />
                  )}
                  {customer?.businessType && (
                    <InnerBoxListItem
                      label={t('account.info.businessType.label')}
                      value={t(`account.info.businessType.options.${customer.businessType}`)}
                    />
                  )}
                  {customer?.company && <InnerBoxListItem label={t('account.info.company')} value={customer.company} />}
                  {customer?.vatNumber && (
                    <InnerBoxListItem label={t('account.info.vatNumber')} value={customer.vatNumber} />
                  )}
                  {customer?.taxCode && <InnerBoxListItem label={t('account.info.taxCode')} value={customer.taxCode} />}
                  {customer?.uniqueCode && (
                    <InnerBoxListItem label={t('account.info.uniqueCode')} value={customer.uniqueCode} />
                  )}
                  {customer?.pec && <InnerBoxListItem label={t('account.info.pec')} value={customer.pec} />}
                </ul>
              </InnerBox>
            </Col>
            <Col>
              <InnerBox
                cta={
                  customer && {
                    label: t('account.info.editPassword'),
                    onClick: () => showChangePasswordFormModal({}),
                  }
                }
                title={t('account.info.loginData')}
              >
                <ul className="list-group list-unstyled">
                  <InnerBoxListItem label={t('account.info.email')} value={customer?.email} />
                  <InnerBoxListItem label="Password" value="***" />
                </ul>
              </InnerBox>
            </Col>
            {/* {(customer?.profile ||
              customer?.businessType ||
              customer?.company ||
              customer?.vatNumber ||
              customer?.taxCode ||
              customer?.uniqueCode ||
              customer?.pec) && (
              <Col>
                <InnerBox
                  cta={{
                    label: t('account.edit'),
                    onClick: () => alert('account.info.invoiceData'),
                  }}
                  title={t('account.info.invoiceData.title')}
                >
                  <ul className="list-group list-unstyled">
                    {customer.profile && (
                      <InnerBoxListItem
                        label={t('account.info.invoiceData.profile.label')}
                        value={t(`account.info.invoiceData.profile.options.${customer.profile}`)}
                      />
                    )}
                    {customer.businessType && (
                      <InnerBoxListItem
                        label={t('account.info.invoiceData.businessType.label')}
                        value={t(`account.info.invoiceData.businessType.options.${customer.businessType}`)}
                      />
                    )}
                    <InnerBoxListItem label={t('account.info.invoiceData.company')} value={customer.company} />
                    <InnerBoxListItem label={t('account.info.invoiceData.vatNumber')} value={customer.vatNumber} />
                    <InnerBoxListItem label={t('account.info.invoiceData.taxCode')} value={customer.taxCode} />
                    <InnerBoxListItem label={t('account.info.invoiceData.uniqueCode')} value={customer.uniqueCode} />
                    <InnerBoxListItem label={t('account.info.invoiceData.pec')} value={customer.pec} />
                  </ul>
                </InnerBox>
              </Col>
            )} */}
          </Row>
        </Div>
        {/* {(customer?.additionalProperties ||
          customer?.company ||
          customer?.vatNumber ||
          customer?.taxCode ||
          customer?.pec ||
          customer?.uniqueCode ||
          customer?.document ||
          customer?.children) && (
          <Row className="mb-4">
            <Col size={12}>
              <InnerBox
                cta={{
                  label: t('account.edit'),
                  onClick: () => showPersonalDataFormModal({ customer }),
                }}
                title={t('account.info.additionalInformation.title')}
              >
                <Row gap={4}>
                  <Col size={{ xl: 6, xs: 12 }}>
                    <InnerBox title={'Altro'}>
                      <ul className="list-group list-unstyled">
                        {customer.additionalProperties &&
                          Object.entries(customer.additionalProperties).map(([key, additionalProperty]) => (
                            <InnerBoxListItem
                              key={additionalProperty?.id}
                              label={
                                additionalProperty?.name
                                  ? t(`misc.additionalProperties.${additionalProperty.name}` as ResourceKeys)
                                  : ''
                              }
                              value={additionalProperty?.value}
                            />
                          ))}
                      </ul>
                    </InnerBox>
                  </Col>
                  {(customer.company ||
                    customer.vatNumber ||
                    customer.taxCode ||
                    customer.pec ||
                    customer.uniqueCode) && (
                    <Col size={{ xl: 6, xs: 12 }}>
                      <InnerBox title={t('account.info.additionalInformation.invoiceData.title')}>
                        {customer.company && (
                          <InnerBoxListItem
                            label={t('account.info.additionalInformation.invoiceData.company')}
                            value={customer.company}
                          />
                        )}
                        {customer.vatNumber && (
                          <InnerBoxListItem
                            label={t('account.info.additionalInformation.invoiceData.vatNumber')}
                            value={customer.vatNumber}
                          />
                        )}
                        {customer.taxCode && (
                          <InnerBoxListItem
                            label={t('account.info.additionalInformation.invoiceData.taxCode')}
                            value={customer.taxCode}
                          />
                        )}
                        {customer.pec && (
                          <InnerBoxListItem
                            label={t('account.info.additionalInformation.invoiceData.pec')}
                            value={customer.pec}
                          />
                        )}
                        {customer.uniqueCode && (
                          <InnerBoxListItem
                            label={t('account.info.additionalInformation.invoiceData.uniqueCode')}
                            value={customer.uniqueCode}
                          />
                        )}
                      </InnerBox>
                    </Col>
                  )}
                  {customer.document && (
                    <Col size={{ xl: 6, xs: 12 }}>
                      <InnerBox title={t('account.info.additionalInformation.document.title')}>
                        <ul className="list-group list-unstyled">
                          {customer.document.authorityDescription && (
                            <InnerBoxListItem
                              label={t('account.info.additionalInformation.document.authorityDescription')}
                              value={customer.document.authorityDescription}
                            />
                          )}
                          <InnerBoxListItem
                            label={t('account.info.additionalInformation.document.authorityType')}
                            value={customer.document.authorityType}
                          />
                          {customer.document.districtName && (
                            <InnerBoxListItem
                              label={t('account.info.additionalInformation.document.districtName')}
                              value={customer.document.districtName}
                            />
                          )}
                          {customer.document.districtType && (
                            <InnerBoxListItem
                              label={t('account.info.additionalInformation.document.districtType')}
                              value={customer.document.districtType}
                            />
                          )}
                          {customer.document.documentNumber && (
                            <InnerBoxListItem
                              label={t('account.info.additionalInformation.document.documentNumber')}
                              value={customer.document.documentNumber}
                            />
                          )}
                          <InnerBoxListItem
                            label={t('account.info.additionalInformation.document.documentType')}
                            value={customer.document.documentType}
                          />
                          {customer.document.releaseDate && (
                            <InnerBoxListItem
                              label={t('account.info.additionalInformation.document.releaseDate')}
                              value={formatDate(customer.document.releaseDate)}
                            />
                          )}
                        </ul>
                      </InnerBox>
                    </Col>
                  )}
                  {customer.children &&
                    customer.children.map((child) => (
                      <Col key={child.id} size={{ xl: 6, xs: 12 }}>
                        <InnerBox title={t('account.info.additionalInformation.children.title')}>
                          <ul className="list-group list-unstyled">
                            {child.name && (
                              <InnerBoxListItem
                                label={t('account.info.additionalInformation.children.name')}
                                value={child.name}
                              />
                            )}
                            {child.surname && (
                              <InnerBoxListItem
                                label={t('account.info.additionalInformation.children.surname')}
                                value={child.surname}
                              />
                            )}
                            {child.birthdate && (
                              <InnerBoxListItem
                                label={t('account.info.additionalInformation.children.birthdate')}
                                value={formatDate(child.birthdate)}
                              />
                            )}
                            {child.sex && (
                              <InnerBoxListItem
                                label={t('account.info.additionalInformation.children.sex')}
                                value={child.sex}
                              />
                            )}
                            {child.additionalProperties &&
                              Object.entries(child.additionalProperties).map(([key, value]) => (
                                <InnerBoxListItem key={key} label={key} value={value} />
                              ))}
                          </ul>
                        </InnerBox>
                      </Col>
                    ))}
                </Row>
              </InnerBox>
            </Col>
          </Row>
        )} */}
        <Row>
          <Col size={12}>
            <InnerBox title={t('account.info.personalAddresses')}>
              <Row>
                {customer?.addresses?.map((address) => (
                  <Col className="mb-4" key={address.id} size={{ lg: 6, xs: 12 }}>
                    <InnerAddressBox
                      address={address}
                      cta={t('account.edit')}
                      showAddressFormModal={showCustomerAddressFormModal}
                    />
                  </Col>
                ))}
              </Row>
              <div className="d-flex justify-content-center">
                <Button
                  label={t('misc.addAddress')}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => showCustomerAddressFormModal({})}
                  variant="outline-primary"
                />
              </div>
            </InnerBox>
          </Col>
        </Row>
      </AccountSubpage>
    ),
  });
};

interface InnerBoxProps {
  children?: React.ReactNode;
  cta?: {
    label: string;
    onClick(): void;
  };
  title?: string;
}

const InnerBox = ({ children, cta, title }: InnerBoxProps) => {
  return renderUI({
    bs5: (
      <div className="inner-box bg-white shadow-sm p-4">
        <div className="pb-1 d-flex justify-content-between border-bottom mb-4">
          <div className="inner-box-title fw-bold">{title}</div>
          {cta && (
            <Button
              className="cta-title extra-small text-decoration-underline"
              label={cta.label}
              onClick={cta.onClick}
              variant="blank"
            />
          )}
        </div>
        {children}
      </div>
    ),
  });
};

interface InnerBoxListItemProps {
  label: string;
  value?: string | number;
}

const InnerBoxListItem = ({ label, value }: InnerBoxListItemProps) => {
  return renderUI({
    bs5: (
      <li className="d-flex justify-content-between">
        <div className="pe-3">{label}</div>
        <div className="text-end">{value}</div>
      </li>
    ),
  });
};

interface InnerAddressBoxProps {
  address: AddressApiDto;
  cta?: string;
  showAddressFormModal(props: CustomerAddressFormProps): void;
}
const InnerAddressBox = ({ address, cta, showAddressFormModal }: InnerAddressBoxProps) => {
  return renderUI({
    bs5: (
      <div className="inner-address-box bg-white border p-3">
        <div className="text-end">
          <Button
            className="text-reset extra-small text-decoration-underline"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              showAddressFormModal({ address: address });
            }}
            variant="blank"
          >
            {cta}
          </Button>
        </div>
        <div>
          {address.name} {address.surname}
        </div>
        <div>{formatAddress(address, { withCountry: true })}</div>
        <div>{address.phone}</div>
      </div>
    ),
  });
};
