import React from 'react';

export interface FormStaticContextInterface {
  id: string;
  //   showValidFeedback?: boolean;
  //   validationSchema?: yup.AnyObjectSchema;
}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [FormStaticContextProvider, useFormStaticContext, useFormStaticContextStrict] =
  createContext<FormStaticContextInterface>('FormStaticContext');

interface UseFormContextInitializerProps {
  id: string;
  //   showValidFeedback?: boolean;
  //   validationSchema?: yup.AnyObjectSchema;
}

const useFormContextInitializer = ({
  id,
}: //   showValidFeedback,
//   validationSchema,
UseFormContextInitializerProps) => {
  const formStaticContext: FormStaticContextInterface = React.useMemo(
    () => ({
      id,
      //   showValidFeedback,
      //   validationSchema,
    }),
    [id]
  );

  return {
    FormStaticContextProvider,
    formStaticContext,
  };
};

export interface FormContextProps extends UseFormContextInitializerProps {
  children: React.ReactNode;
}

export const FormStaticContext = ({ children, ...otherProps }: FormContextProps) => {
  const formContextInitializer = useFormContextInitializer(otherProps);
  return (
    <formContextInitializer.FormStaticContextProvider value={formContextInitializer.formStaticContext}>
      {children}
    </formContextInitializer.FormStaticContextProvider>
  );
};
