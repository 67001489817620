import './DeprecatedVideo.scss';

import classnames from 'classnames';
import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { useEqualHeightContextStrict } from './EqualHeightContext';
import { useWindowSize } from './useWindowSize';

export interface DeprecatedVideoAsBackgroundProps extends Omit<ReactPlayerProps, 'playing' | 'controls'> {
  src: Array<{
    height: number;
    preview?: string;
    video: string;
    width: number;
  }>;
}

export const DeprecatedVideoAsBackground = ({ src, ...otherProps }: DeprecatedVideoAsBackgroundProps) => {
  const videoContainer = React.useRef<HTMLDivElement>(null);
  const [aspectRatio, setAspectRatio] = React.useState<number>();
  const [videoIndex, setVideoIndex] = React.useState<number>();
  const windowSize = useWindowSize();

  const equalHeightContext = useEqualHeightContextStrict();

  React.useEffect(() => {
    if (videoContainer.current && videoContainer.current.offsetHeight !== 0) {
      setAspectRatio(videoContainer.current.offsetWidth / videoContainer.current.offsetHeight);
    }
  }, [windowSize, equalHeightContext?.updateChecker]);

  React.useEffect(() => {
    if (aspectRatio) {
      // Predispongo una variabile temporanea
      const temp = { accuracy: 0, index: 0 };

      src.forEach((video, index) => {
        if (index === 0) {
          // Salvo il risultato della differenza tra l'aspect ratio del container e del video ed il relativo indice
          temp.accuracy = Math.abs(aspectRatio - video.width / video.height);
          temp.index = index;
        } else if (Math.abs(aspectRatio - video.width / video.height) <= temp.accuracy) {
          // Se il risultato della differenza tra l'aspect ratio del container e del video
          // è minore dell'accuracy salvata, il video corrente è più preciso del precedente
          // Aggiorno il nuovo valore di accuracy ed il relativo indice
          temp.accuracy = Math.abs(aspectRatio - video.width / video.height);
          temp.index = index;
        }
      });

      // Salvo in una variabile di stato l'indice del video migliore nella lista
      setVideoIndex(temp.index);
    }
  }, [aspectRatio, src, windowSize]);

  return (
    <div
      className={classnames(
        'Video background',
        { landscape: aspectRatio && aspectRatio > 1 },
        { portrait: aspectRatio && aspectRatio < 1 }
      )}
      ref={videoContainer}
    >
      {videoIndex !== undefined && src[videoIndex] && (
        <ReactPlayer
          {...otherProps}
          config={{
            file: { attributes: { poster: src[videoIndex].preview } },
            youtube: {
              playerVars: { modestbranding: 1, showinfo: 0 },
            },
          }}
          controls={false}
          height={'100%'}
          loop
          muted
          playing
          playsinline
          url={src[videoIndex].video}
          width={'100%'}
        />
      )}
    </div>
  );
};
