import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams as _useSearchParams } from 'react-router-dom-6';

/*
  Non posso usare la setSearchParams di react-router, in quanto è fatta male, non è stabile:
  https://github.com/remix-run/react-router/issues/9991
  In alternativa invento un accrocco.
*/
export const useSearchParams = () => {
  const [searchParams, setSearchParams] = _useSearchParams();
  // Store a reference to the setter
  const setSearchParamsRef = React.useRef(setSearchParams);
  // Update the reference when the setter changes
  setSearchParamsRef.current = setSearchParams;
  // Return a stable setter (which has no dep on searchParams)
  const setSearchParamsStable = React.useCallback(
    (...args: Parameters<typeof setSearchParams>) => setSearchParamsRef.current(...args),
    []
  );
  return [searchParams, setSearchParamsStable] as const;
};
