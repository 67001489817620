import React from 'react';

import { Button } from './Button';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasProps } from './Offcanvas';
import { useStaticModal } from './useModal';

interface ExampleOffcanvasProps extends OffcanvasProps {
  stringToAlert: string;
}

const ExampleOffcanvas = ({ stringToAlert, ...offcanvasProps }: ExampleOffcanvasProps) => {
  const handleButtonClick = React.useCallback(() => {
    alert(stringToAlert);
  }, [stringToAlert]);

  return (
    <Offcanvas {...offcanvasProps}>
      {({ close }) => (
        <>
          <OffcanvasHeader title="Title" />
          <OffcanvasBody>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <p>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
              id est laborum.
            </p>
            <Button label="Alert" onClick={handleButtonClick} />
            <Button label="Close" onClick={close} />
          </OffcanvasBody>
        </>
      )}
    </Offcanvas>
  );
};

export const useExampleOffcanvas = (props?: ExampleOffcanvasProps) =>
  useStaticModal(ExampleOffcanvas, 'offcanvas', props);
