import { Button } from './Button';
import { Col } from './Col';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import clipboardImage from './images/share/clipboard.svg';
import facebookImage from './images/share/facebook.svg';
import whatsAppImage from './images/share/whatsApp.svg';
import xImage from './images/share/x.svg';
import { Modal, ModalBody, ModalHeader, ModalProps } from './Modal';
import { Row } from './Row';
import { useStaticModal } from './useModal';
import { useModals } from './useModals';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface SharingModalProps<ButtonVariant> extends ModalProps {
  buttonVariant?: ButtonVariant;
  sharingMethods?: Array<SharingMethod>;
}

type SharingMethod = 'clipboard' | 'whatsApp' | 'facebook' | 'x';

const SharingModal = <ButtonVariant extends string>({
  buttonVariant,
  sharingMethods = ['clipboard', 'whatsApp', 'facebook', 'x'],
  ...modalProps
}: SharingModalProps<ButtonVariant>) => {
  const { showModal } = useModals();

  const url = window.location.href;

  return (
    <Modal {...modalProps}>
      {({ close }) => (
        <>
          <ModalHeader title={t('sharingModal.title')} />
          <ModalBody>
            <Row cols={{ sm: 2, xs: 1 }} gap={3}>
              {sharingMethods.map((sharingMethod) => (
                <Col key={sharingMethod}>
                  <Div display="grid">
                    <Button
                      iconStart={{ name: 'pet' }}
                      justifyContent="start"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        switch (sharingMethod) {
                          case 'clipboard':
                            navigator.clipboard.writeText(url);
                            close();
                            showModal({
                              autoClose: 1.5,
                              children: t('sharingModal.methods.clipboard.success'),
                              closable: false,
                              title: t('sharingModal.title'),
                            });
                            break;
                          case 'facebook':
                            window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
                            break;
                          case 'whatsApp':
                            window.open(`https://api.whatsapp.com/send?text=%0a${url}`, '_blank');
                            break;
                          case 'x':
                            window.open(`https://twitter.com/intent/tweet?text=${url}`, '_blank');
                            break;
                          default:
                            break;
                        }
                      }}
                      variant={buttonVariant}
                    >
                      <Image
                        alt={t(`sharingModal.methods.${sharingMethod}.label`)}
                        src={
                          sharingMethod === 'clipboard'
                            ? clipboardImage
                            : sharingMethod === 'facebook'
                            ? facebookImage
                            : sharingMethod === 'whatsApp'
                            ? whatsAppImage
                            : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            sharingMethod === 'x'
                            ? xImage
                            : undefined
                        }
                      />
                      <span>{t(`sharingModal.methods.${sharingMethod}.label`)}</span>
                    </Button>
                  </Div>
                </Col>
              ))}
            </Row>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export const useSharingModal = <ButtonVariant extends string>(props?: SharingModalProps<ButtonVariant>) =>
  useStaticModal(SharingModalController, 'modal', props);

///

export type SharingModalVariants = '';

const SharingModalController = <BurronVariant extends string>(
  props: PropsWithCustomComponent<SharingModalProps<BurronVariant>>
) => (
  <VariantsController<SharingModalProps<BurronVariant>, SharingModalVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: SharingModal,
      name: 'SharingModal',
    }}
  />
);
export { SharingModalController as SharingModal };
