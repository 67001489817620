import { BankAccountDetailsApiDto, OrderApiDto, SessionApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { analytics } from './analytics/analytics';
import { useInfoApi } from './api/useInfoApi';
import { useOrdersApi } from './api/useOrdersApi';
import { useAppContext } from './AppContext';
import { useSearchParams } from './router/useSearchParams';
import { storage } from './storage';

export interface UseThankYouPageProps {}

export interface UseThankYouPageResult {
  bankAccountDetails?: BankAccountDetailsApiDto;
  order?: OrderApiDto;
  session?: SessionApiDto;
}

export const useThankYouPage = ({ ...otherProps }: UseThankYouPageProps): UseThankYouPageResult => {
  const [searchParams] = useSearchParams();

  const { info } = useAppContext();

  const { session } = useAppContext();

  const orderCode = searchParams.get('orderCode') ?? undefined;
  const email = searchParams.get('email') ?? undefined;

  const token = searchParams.get('token') ?? undefined;

  const { getOrderByCodeAndEmail, getOrderByToken } = useOrdersApi();
  const [order, setOrder] = React.useState<OrderApiDto>();
  const [bankAccountDetails, setBankAccountDetails] = React.useState<BankAccountDetailsApiDto | undefined>(
    info?.bankAccountDetails
  );

  React.useEffect(() => {
    orderCode && email
      ? getOrderByCodeAndEmail(orderCode, email, {
          populate: {
            paymentMethod: true,
            warehouseOrders: {
              carrierTracking: true,
              orderedSkus: {
                sku: {
                  price: true,
                  product: true,
                },
              },
            },
          },
        }).then((response) => {
          setOrder(response.data);
        })
      : token &&
        getOrderByToken(token, {
          populate: {
            paymentMethod: true,
            warehouseOrders: {
              carrierTracking: true,
              orderedSkus: {
                sku: {
                  price: true,
                  product: true,
                },
              },
            },
          },
        }).then((response) => {
          setOrder(response.data);
        });
  }, [getOrderByCodeAndEmail, orderCode, email, token, getOrderByToken]);

  React.useEffect(() => {
    if (order) {
      const storageKey = `order-${order.code}-purchase-event-sent`;
      const purchaseEventAlreadySent = storage.getBoolean(storageKey, true);
      if (!purchaseEventAlreadySent) {
        analytics.events.purchase('EVENT_ID', order);
        storage.setBoolean(storageKey, true, true);
      }
    }
  }, [order]);

  const { getBankAccountDetails } = useInfoApi();

  // Se ho pagato con bonifico e non ho i dettagli della banca, li chiedo
  React.useEffect(() => {
    if (order !== undefined && order.paymentMethod?.type === 'BANK_TRANSFER' && bankAccountDetails === undefined) {
      getBankAccountDetails().then((response) => {
        setBankAccountDetails(response.data);
      });
    }
  }, [bankAccountDetails, getBankAccountDetails, order]);

  return { bankAccountDetails, order, session };
};
