import { appConfig } from '../../../config';
import { AccountTaxBenefitsContentType } from '../../../contentTypes';
import { TaxBenefitsForm } from '../../../form/TaxBenefitsForm';
import { Div } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { useCustomer } from '../../../useCustomer';
import { formatHtml, renderUI } from '../../../util';
import { AccountSubpage } from '../AccountSubpage';
import { AccountTaxBenefitsSubpageProps } from '../AccountTaxBenefitsSubpage';
import { Box } from './Box';

export const AccountTaxBenefitsSubpageA = (props: AccountTaxBenefitsSubpageProps) => {
  const customer = useCustomer({
    populate: {
      additionalPropertiesExt: true,
      qualifiers: true,
    },
  });

  return (
    <AccountSubpage<AccountTaxBenefitsContentType>>
      {(page) => (
        <>
          {renderUI({
            bs5: (
              <Box>
                {appConfig.enableTaxBenefits && page.content && customer ? (
                  <>
                    <Div marginBottom={5}>{formatHtml(page.content.body.content)}</Div>
                    {customer.qualifiers?.includes('IVA_4') ? (
                      <div className="alert alert-success text-center m-0" role="alert">
                        {t('account.taxBenefits.alerts.success')}
                      </div>
                    ) : (
                      <TaxBenefitsForm content={page.content} customer={customer} />
                    )}
                  </>
                ) : (
                  <p className="m-0">{t('account.sectionDisabled')}</p>
                )}
              </Box>
            ),
          })}
        </>
      )}
    </AccountSubpage>
  );
};
