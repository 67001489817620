import { OrderApiDto } from '@b2x/storefront-api-js-client/src';

import { OrderDetailsA } from './OrderDetailsA';
import { OrderDetailsB } from './OrderDetailsB';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface OrderDetailsProps {
  order: OrderApiDto;
}

export type OrderDetailsVariants = 'A' | 'B';

const OrderDetailsController = (props: PropsWithCustomComponent<OrderDetailsProps>) => (
  <VariantsController<OrderDetailsProps, OrderDetailsVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: OrderDetailsA, B: OrderDetailsB },
      name: 'OrderDetails',
    }}
  />
);
export { OrderDetailsController as OrderDetails };
