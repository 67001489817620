// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { GetPageOptions } from '@b2x/storefront-api-js-client/src/pages';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const usePagesApi = () => {
  const { apiRequest } = useApiRequest();

  const searchPages = React.useCallback(
    <T>(ids: Array<string>, options?: GetPageOptions, config?: ApiRequestConfig) =>
      apiRequest(api.pages.search<T>(ids, options), { ...config }),
    [apiRequest]
  );

  const getPage = React.useCallback(
    <T>(id: string, options?: GetPageOptions, config?: ApiRequestConfig) =>
      apiRequest(api.pages.get<T>(id, options), { ...config }),
    [apiRequest]
  );

  const getPageByCode = React.useCallback(
    <T>(code: string, options?: GetPageOptions, config?: ApiRequestConfig) =>
      apiRequest(api.pages.getByCode<T>(code, options), { ...config }),
    [apiRequest]
  );

  const getPageByPath = React.useCallback(
    <T>(path: string, options?: GetPageOptions, config?: ApiRequestConfig) =>
      apiRequest(api.pages.getByPath<T>(path, options), { ...config }),
    [apiRequest]
  );

  const getPathInAnotherLocaleByPath = React.useCallback(
    (path: string, locale: string, config?: ApiRequestConfig) =>
      apiRequest(api.pages.getPathInAnotherLocaleByPath(path, locale), { ...config }),
    [apiRequest]
  );

  const getPathsInAllLocalesByPath = React.useCallback(
    (path: string, config?: ApiRequestConfig) => apiRequest(api.pages.getPathsInAllLocalesByPath(path), { ...config }),
    [apiRequest]
  );

  const getPageMetaByPath = React.useCallback(
    (path: string, config?: ApiRequestConfig) =>
      apiRequest(api.pages.getMetaByPath(path), { suppressErrorModal: true, ...config }),
    [apiRequest]
  );

  return {
    getPage,
    getPageByCode,
    getPageByPath,
    getPageMetaByPath,
    getPathInAnotherLocaleByPath,
    getPathsInAllLocalesByPath,
    searchPages,
  };
};
