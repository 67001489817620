import './AccountLoyaltySubpageB.scss';

import classnames from 'classnames';

import { AccountLoyaltySubpageBContentType } from '../../../contentTypes';
import { CtaFromContent } from '../../../CtaFromContent';
import { Div } from '../../../HTMLElement';
import { Icon } from '../../../Icon';
import { useCustomer } from '../../../useCustomer';
import { formatHtml, uiClassName } from '../../../util';
import { AccountLoyaltySubpageProps } from '../AccountLoyaltySubpage';
import { AccountSubpage } from '../AccountSubpage';
import { Box } from '../accountSubpagesB/Box';

export const AccountLoyaltySubpageB = (props: AccountLoyaltySubpageProps) => {
  const customer = useCustomer({
    populate: {
      fidelityCards: true,
    },
  });

  const amount =
    customer?.fidelityCards && customer.fidelityCards.length >= 1 ? customer.fidelityCards[0].creditAmount : 0;

  return (
    <AccountSubpage<AccountLoyaltySubpageBContentType>>
      {(page) => {
        const lastItemPoints = page.content?.body.pointsSection?.levels?.at(-1)?.fromPoints;
        const activeLevelName = page.content?.body.pointsSection?.levels?.find(
          (item) => item.fromPoints && item.toPoints && item.fromPoints <= amount && item.toPoints >= amount
        )?.name;

        return (
          <div className="account-loyalty-page">
            {page.content && customer && (
              <>
                <Box>
                  <Div uiClassName={{ bs5: 'border-bottom pb-5' }}>
                    <h5
                      className={uiClassName({
                        bs5: 'fw-bold',
                      })}
                    >
                      {formatHtml(page.content.body.pointsSection?.title)} {activeLevelName}
                    </h5>
                    <div>{formatHtml(page.content.body.pointsSection?.subtitle)}</div>

                    {page.content.body.pointsSection &&
                      page.content.body.pointsSection.levels &&
                      page.content.body.pointsSection.levels.length > 0 &&
                      lastItemPoints && (
                        <Div
                          className="point-bar"
                          style={{
                            backgroundColor: page.content.body.pointsSection.defaultColor,
                          }}
                        >
                          {page.content.body.pointsSection.levels.map(
                            (item, index) =>
                              item.fromPoints &&
                              item.toPoints && (
                                <Div
                                  className="bar"
                                  key={item.contentSectionId}
                                  style={{
                                    width: `${(100 * item.fromPoints) / lastItemPoints}%`,
                                  }}
                                >
                                  <Div
                                    className="point-wrapper"
                                    style={{
                                      backgroundColor:
                                        item.name === activeLevelName || item.alwaysOn
                                          ? page.content?.body.pointsSection?.activeColor
                                          : 'transparent',
                                    }}
                                  >
                                    <Div className="point" />
                                    <Div className="point-name">{item.name}</Div>
                                  </Div>
                                </Div>
                              )
                          )}
                        </Div>
                      )}
                  </Div>

                  <Div paddingY={{ md: 4, xs: 3 }}>
                    {page.content.body.pointsSection?.levels && (
                      <Div>
                        {page.content.body.pointsSection.levels.map((item) => (
                          <Div
                            alignItems={{ sm: 'center', xs: 'start' }}
                            className="rule-item"
                            display={'flex'}
                            flexDirection={{ xs: 'row' }}
                            key={item.contentSectionId}
                            paddingBottom={{ xs: 2 }}
                          >
                            {item.name && (
                              <>
                                {item.iconName && item.fromPoints && item.toPoints && (
                                  <Icon
                                    className={classnames(uiClassName({ bs5: 'me-2 mt-sm-0 mt-1' }), 'item-rule-icon')}
                                    name={item.iconName}
                                    size={12}
                                    style={{
                                      color:
                                        !activeLevelName && item.alwaysOn
                                          ? page.content?.body.pointsSection?.activeColor
                                          : item.name === activeLevelName
                                          ? page.content?.body.pointsSection?.activeColor
                                          : page.content?.body.pointsSection?.defaultColor,
                                    }}
                                  />
                                )}
                                {item.name && (
                                  <Div
                                    className={'item-rule-name'}
                                    fontWeight={700}
                                    uiClassName={{ bs5: 'small me-2' }}
                                  >
                                    {formatHtml(item.name)}
                                  </Div>
                                )}
                                {item.description && (
                                  <Div className={'item-rule-description'} uiClassName={{ bs5: 'small' }}>
                                    {formatHtml(item.description)}
                                  </Div>
                                )}
                              </>
                            )}
                          </Div>
                        ))}
                      </Div>
                    )}
                  </Div>
                  {page.content.body.pointsSection?.rules && (
                    <Div alignItems={'start'} display={'flex'}>
                      {page.content.body.pointsSection.iconNameRules && (
                        <Icon
                          className={uiClassName({ bs5: 'me-2' })}
                          name={page.content.body.pointsSection.iconNameRules}
                          size={25}
                          style={{
                            color: page.content.body.pointsSection.activeColor,
                          }}
                        />
                      )}
                      <Div>{formatHtml(page.content.body.pointsSection.rules)}</Div>
                    </Div>
                  )}
                </Box>
                <Box>
                  <Div className="inner-box">
                    <Div className={uiClassName({ bs5: 'pb-1 border-bottom mb-4' })}>
                      {page.content.body.howItWorksSection?.title && (
                        <h6
                          className={uiClassName({
                            bs5: 'text-center text-md-start fw-bold',
                          })}
                        >
                          {formatHtml(page.content.body.howItWorksSection.title)}
                        </h6>
                      )}
                    </Div>
                    <div>{formatHtml(page.content.body.howItWorksSection?.description)}</div>

                    {page.content.body.howItWorksSection?.cta && page.content.body.howItWorksSection.cta.to && (
                      <Div paddingTop={4} textAlign={{ xs: 'center' }}>
                        <CtaFromContent {...page.content.body.howItWorksSection.cta} />
                      </Div>
                    )}
                  </Div>
                </Box>
              </>
            )}
          </div>
        );
      }}
    </AccountSubpage>
  );
};
