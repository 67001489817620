import { MenuApiDto, RouteApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface MenuPopulate {
  additionalContents?: boolean;
  content?: boolean;
}

const rootPath = '/v2/menus';

export interface GetMenuOptions extends BaseOptions {
  allProducts?: boolean;
  populate?: Populate<MenuPopulate>;
}

export const getByCode = async (code: string, options?: GetMenuOptions) =>
  apiRequest<MenuApiDto, void, MenuPopulate>({
    ...getBaseOptions(options),
    params: {
      allProducts: options?.allProducts,
    },
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/code/${code}`,
  });

export const getRouter = async (options?: BaseOptions) =>
  apiRequest<RouteApiDto, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/router`,
  });
