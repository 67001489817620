import { ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import React from 'react';

import { BlankButton } from '../BlankButton';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { GoogleMapInstance } from '../useGoogleMap';
import { usePreLoadedScrollingPagination } from '../usePreLoadedScrollingPagination';
import { uiClassName } from '../util';
import { SearchForm } from './SearchForm';
import { ShopTile } from './ShopTile';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface ListingPanelProps {
  activateShop(shop: ShopApiDto | undefined): void;
  activeShopId: string | undefined;
  currentPosition: GeolocationPosition | undefined;
  googleMap: GoogleMapInstance;
  listingPanelVisibility: boolean;
  markerHoveredShopId: string | undefined;
  previewShopId: string | undefined;
  setTileHoveredShopId: React.Dispatch<React.SetStateAction<string | undefined>>;
  shops?: Array<ShopApiDto>;
  tileHoveredShopId: string | undefined;
  toggleListingPanel(): void;
}

export const ListingPanel = ({
  activateShop,
  activeShopId,
  currentPosition,
  googleMap,
  listingPanelVisibility,
  markerHoveredShopId,
  previewShopId,
  setTileHoveredShopId,
  shops,
  tileHoveredShopId,
  toggleListingPanel,
}: ListingPanelProps) => {
  const scrollingPagination = usePreLoadedScrollingPagination(shops);
  const { hideShopsInListingPanel } = useStoreLocatorContext();

  const listingPanelMobileHeaderRef = React.useRef<HTMLSpanElement>(null);

  // const { addOffset, removeOffset } = useFloatingActionButtonContext();

  // React.useEffect(() => {
  //   if (listingPanelMobileHeaderRef.current?.offsetHeight) {
  //     addOffset('listingPanelMobileHeader', listingPanelMobileHeaderRef.current.offsetHeight);
  //   } else {
  //     removeOffset('listingPanelMobileHeader');
  //   }
  //   return () => {
  //     removeOffset('listingPanelMobileHeader');
  //   };
  // }, [addOffset, removeOffset]);

  return (
    <Div
      className={classnames(uiClassName({ bs5: 'shadow' }), { in: listingPanelVisibility })}
      display="flex"
      flexDirection="column"
      id="listing-panel"
    >
      <BlankButton
        className={uiClassName({ bs5: 'fw-bold pt-2' })}
        id="listing-panel-mobile-header"
        innerRef={listingPanelMobileHeaderRef}
        onClick={toggleListingPanel}
      >
        {shops
          ? t('storeLocator.listingPanel.shopsCount', { count: shops.length })
          : t('storeLocator.listingPanel.loading')}
      </BlankButton>
      <header id="listing-panel-desktop-header">
        <Div marginBottom={3}>
          <SearchForm googleMap={googleMap} />
        </Div>
        <Div className="shops-count">
          <strong>
            {shops
              ? t('storeLocator.listingPanel.shopsCount', { count: shops.length })
              : t('storeLocator.listingPanel.loading')}
          </strong>
        </Div>
      </header>
      {!hideShopsInListingPanel && (
        <Div className="shops-container">
          <Div className="shops">
            <Div marginBottom={-3}>
              {scrollingPagination.items?.map((shop) => (
                <Div key={shop.id} marginBottom={3}>
                  <ShopTile
                    activateShop={activateShop}
                    activeShopId={activeShopId}
                    className="shadow-sm"
                    currentPosition={currentPosition}
                    googleMap={googleMap}
                    markerHoveredShopId={markerHoveredShopId}
                    previewShopId={previewShopId}
                    setTileHoveredShopId={setTileHoveredShopId}
                    shop={shop}
                    tileHoveredShopId={tileHoveredShopId}
                  />
                </Div>
              ))}
              {scrollingPagination.hasMoreItemsToShow && (
                <Div innerRef={scrollingPagination.triggerRef} marginBottom={3} textAlign="center">
                  {t('storeLocator.listingPanel.loading')}
                </Div>
              )}
            </Div>
          </Div>
        </Div>
      )}
    </Div>
  );
};
