import classnames from 'classnames';

import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { CommonFieldProps, HTMLAutocomplete, useField } from '../Form';
import { useFormContextStrict } from '../FormContext';
import { useFormGroupContext } from '../FormGroupContext';
import { Option, OptionProps } from './Option';

export interface SelectProps<V = string> extends CommonFieldProps<HTMLSelectElement, V> {
  autoComplete?: HTMLAutocomplete;
  children?: React.ReactNode;
  defaultValue?: string;
  includeEmptyOption?: boolean;
  options?: Array<OptionProps>;
  placeholder?: string;
}

export const Select = ({ defaultValue, ...otherProps }: SelectProps): React.ReactElement => {
  const formContext = useFormContextStrict();
  return (
    <>
      {formContext !== undefined ? (
        <InsideFormSelect {...otherProps} />
      ) : (
        <OutsideFormSelect {...otherProps} defaultValue={defaultValue} />
      )}
    </>
  );
};

const InsideFormSelect = (props: SelectProps): React.ReactElement => {
  const {
    children,
    className,
    includeEmptyOption = true,
    innerRef,
    onFormikChange,
    options,
    placeholder,
    size,
    ...otherProps
  } = props;
  const { b2xHelpers, field } = useField(props, otherProps);
  const { id, required, withLabel } = useFormGroupContext();
  return renderUI({
    bs5: (
      <>
        <select
          {...field}
          {...otherProps}
          className={classnames(
            'form-select',
            { 'form-select-sm': size === 'small' },
            { 'form-select-lg': size === 'large' },
            { 'is-valid': b2xHelpers.isValid },
            { 'is-invalid': b2xHelpers.isInvalid },
            className
          )}
          id={id}
          onChange={b2xHelpers.handleChange}
          ref={innerRef}
        >
          {placeholder && <option value="">{!withLabel && required ? `${placeholder} *` : placeholder}</option>}
          {includeEmptyOption && <option value=""></option>}
          {options?.map((option) => (
            <Option key={option.value} {...option} />
          ))}
          {children}
        </select>
        <Feedback name={props.name} />
      </>
    ),
  });
};

const OutsideFormSelect = (props: SelectProps): React.ReactElement => {
  const { children, className, includeEmptyOption = true, options, placeholder, size, ...otherProps } = props;
  const { id, required, withLabel } = useFormGroupContext();
  return renderUI({
    bs5: (
      <>
        <select
          {...otherProps}
          className={classnames(
            'form-select',
            { 'form-select-sm': size === 'small' },
            { 'form-select-lg': size === 'large' },
            className
          )}
          id={id}
        >
          {placeholder && <option value="">{!withLabel && required ? `${placeholder} *` : placeholder}</option>}
          {includeEmptyOption && <option value=""></option>}
          {options?.map((option) => (
            <Option key={option.value} {...option} />
          ))}
          {children}
        </select>
      </>
    ),
  });
};
