import React from 'react';

import { useModalContextStrict } from './ModalContext';

export const useModalCloser = () => {
  const modalContext = useModalContextStrict();

  const closeModal = React.useCallback(() => {
    modalContext && modalContext.close();
  }, [modalContext]);

  return closeModal;
};
