import { FieldArray as FormikFieldArray, FieldArrayConfig } from 'formik';

export interface ArrayHelpers<T> {
  push(obj: T): void;
  pushDisabled: boolean;
  remove(index: number): void;
  removeDisabled: boolean;
}

export interface FieldArrayProps<T> extends Omit<FieldArrayConfig, 'children'> {
  children(arrayHelpers: ArrayHelpers<T>): React.ReactNode;
  items: Array<T>;
  max?: number;
  min?: number;
}

export const FieldArray = <T,>({ children, items, max, min, ...formikFieldArrayProps }: FieldArrayProps<T>) => {
  return (
    <FormikFieldArray {...formikFieldArrayProps}>
      {(formikArrayHelpers) => {
        const push = (object: T) => {
          formikArrayHelpers.push(object);
        };

        const remove = (index: number) => {
          formikArrayHelpers.remove(index);
        };

        const pushDisabled = max === undefined || items.length >= max;
        const removeDisabled = min === undefined || items.length <= min;

        const arrayHelpers: ArrayHelpers<T> = { push, pushDisabled, remove, removeDisabled };
        return children(arrayHelpers);
      }}
    </FormikFieldArray>
  );
};
