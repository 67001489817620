import { ProductApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { analytics } from './analytics/analytics';
import { ListingContext } from './ListingContext';

export interface ListingProps {
  children?: React.ReactNode;
  name: string;
  products: Array<ProductApiDto> | undefined;
  sendEvent?: boolean;
}

export const Listing = ({ children, name, products, sendEvent = true }: ListingProps) => {
  const analyticsEventFired = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (products?.length === 1) {
      console.error('Si sta usando un <Listing /> con dentro un solo prodotto, qualcosa non torna.');
    }
  }, [products?.length]);

  React.useEffect(() => {
    if (!analyticsEventFired.current && products && sendEvent) {
      analytics.events.viewProducts('EVENT_ID', products, { name });
      analyticsEventFired.current = true;
    }
  }, [name, products, sendEvent]);

  return (
    <>
      {products ? (
        <ListingContext name={name} products={products}>
          {children}
        </ListingContext>
      ) : (
        children
      )}
    </>
  );
};
