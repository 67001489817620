import './ReferralBoxUI.scss';

import classNames from 'classnames';

import { ReferralBoxTextContentSection } from './contentTypes';
import { EqualHeight } from './EqualHeight';
import { EqualHeightElement } from './EqualHeightElement';
import { Div, H3, P } from './HTMLElement';
import { Icon } from './Icon';
import { ResponsivePaddingSize } from './interfaces';
import { ShareMetaData } from './ShareMetaData';
import { Swiper, SwiperSlide } from './Swiper';
import { SwiperContext } from './SwiperContext';
import { useBreakpoint } from './useBreakpoint';
import { formatHtml, untilBreakpoint } from './util';

export interface ReferralBoxTextProps extends ReferralBoxTextContentSection {
  children?: React.ReactNode;
  hideBorder?: boolean;
  padding?: ResponsivePaddingSize;
}

export const ReferralBoxUI = ({ children, hideBorder, padding, steps, subTitle, title }: ReferralBoxTextProps) => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = untilBreakpoint('md', currentBreakpoint);

  return (
    <>
      <Div
        className={classNames('referral-friend-box', {
          border: !isMobile && !hideBorder,
        })}
        marginX="auto"
        padding={padding ?? { lg: 5, xs: 3 }}
        style={{ maxWidth: 1000 }}
        textAlign="center"
      >
        {title && <H3 fontWeight={700}>{title}</H3>}
        {subTitle && <p>{subTitle}</p>}
        <EqualHeight>
          <Div className="slider-container" marginTop={5}>
            <SwiperContext>
              <Swiper
                autoHeight
                breakpoints={{
                  lg: {
                    slidesPerView: 3,
                  },
                }}
                pagination
                slidesPerView={1}
                spaceBetween={20}
              >
                {steps?.map((step) => (
                  <SwiperSlide key={step.contentSectionId}>
                    <EqualHeightElement name="inviteFriendBoxSlide">
                      <Div alignItems="center" display="flex" flexDirection="column" gap={3}>
                        {step.icon?.name && (
                          <Div
                            background={step.icon.bgColor}
                            padding={3}
                            style={{ borderRadius: '100%' }}
                            textVariant={step.icon.color}
                          >
                            <Icon name={step.icon.name} size={48} />
                          </Div>
                        )}
                        <EqualHeightElement name="inviteFriendBoxSlideTitle">
                          <P fontWeight={700}>{formatHtml(step.title)}</P>
                        </EqualHeightElement>
                        <p>{formatHtml(step.description)}</p>
                      </Div>
                    </EqualHeightElement>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperContext>
          </Div>
        </EqualHeight>
        {children && <Div marginTop={4}>{children}</Div>}
      </Div>
      <ShareMetaData contentCode={'REFERRAL_SHARE_CONTENT'} />
    </>
  );
};
