import React from 'react';

import { b2x } from '..';
import { Col } from '../Col';
import { Container } from '../Container';
import { Div } from '../HTMLElement';
import { Row } from '../Row';
import { renderUI } from '../util';

export interface ColorsProps {
  colors: Array<{
    background: string;
    text: 'white' | 'black';
  }>;
}

export const Colors = ({ colors }: ColorsProps) => {
  return (
    <Div marginBottom={3}>
      <Container>
        <Row gap={3}>
          {colors.map(({ background, text }) => (
            <Col key={background} size={{ lg: 3, md: 4, sm: 6, xs: 12 }}>
              <Block background={background} text={text} />
            </Col>
          ))}
        </Row>
      </Container>
    </Div>
  );
};

interface BlockProps {
  background: string;
  text: 'white' | 'black';
}

const Block = ({ background, text }: BlockProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [colorRGBCode, setColorRGBCode] = React.useState<string>();
  const [colorHexCode, setColorHexCode] = React.useState<string>();

  React.useEffect(() => {
    if (ref.current) {
      const computedStyle = window.getComputedStyle(ref.current);
      setColorRGBCode(computedStyle.backgroundColor);
      setColorHexCode(b2x.toHex(computedStyle.backgroundColor));
    }
  }, []);

  return renderUI({
    bs5: (
      <div className={`p-3 border bg-${background} text-${text} text-center`} ref={ref}>
        <strong>{background}</strong>
        <br />
        <small>{colorHexCode?.toUpperCase()}</small>
        <br />
        <small>{colorRGBCode}</small>
      </div>
    ),
  });
};
