import React from 'react';
import * as yup from 'yup';

import { appConfig, Variants } from './config';
import { VariantsSelectorProps } from './Configurator';
import { useVariantsContext } from './VariantsContext';

export type VariantsControllerProps<ComponentProps, VariantChoices extends string> = ComponentProps & {
  variantsControllerConfig: {
    // componentVariants?: Record<VariantChoices, React.FunctionComponent<ComponentProps>>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentVariants?: Record<VariantChoices, React.FunctionComponent<any>>; // FIXME Metto any in attesa di capire come gestirlo
    defaultComponent?: React.FunctionComponent<ComponentProps>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: keyof Variants<any, yup.AnyObjectSchema, any, yup.AnyObjectSchema, string, string, string, number>;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const VariantsController = <ComponentProps extends object, VariantChoices extends string>({
  customComponent,
  loopBreaker,
  variantsControllerConfig: { componentVariants, defaultComponent, name },
  ...componentProps
}: VariantsControllerProps<ComponentProps, VariantChoices> & {
  customComponent?: React.FunctionComponent<ComponentProps>;
  loopBreaker?: boolean;
}) => {
  const { variants } = useVariantsContext();
  const variant = variants[name];

  React.useEffect(() => {
    if (!allVariantsSelectors.hasOwnProperty(name)) {
      allVariantsSelectors[name] = {
        componentVariants: componentVariants ? Object.keys(componentVariants) : [],
        customComponent: customComponent !== undefined,
        defaultComponent: defaultComponent !== undefined,
        name: name,
      };
    }
  }, [componentVariants, customComponent, defaultComponent, name]);

  React.useEffect(() => {
    if (customComponent !== undefined && appConfig.variants[name] === undefined) {
      throw new Error(`Missing '${name}' in variant config inside index.tsx`);
    }
  }, [customComponent, name]);

  const Component = React.useMemo<React.FunctionComponent<ComponentProps>>(() => {
    return !loopBreaker && typeof appConfig.variants[name] === 'function'
      ? (appConfig.variants[name] as React.FunctionComponent<ComponentProps>)
      : variant === 'custom' && customComponent
      ? customComponent
      : variant === 'default' && defaultComponent
      ? defaultComponent
      : !loopBreaker && variant !== 'default' && variant !== 'custom' && variants.hasOwnProperty(name)
      ? (componentVariants as Record<string, React.FunctionComponent<ComponentProps>>)[variant]
      : defaultComponent
      ? defaultComponent
      : (componentVariants as Record<'A', React.FunctionComponent<ComponentProps>>)['A'];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  return !loopBreaker ? (
    <Component {...(componentProps as ComponentProps)} loopBreaker />
  ) : (
    <Component {...(componentProps as ComponentProps)} />
  );
};

export type PropsWithCustomComponent<P> = P & {
  customComponent?: React.FunctionComponent<P>;
};

export type PropsWithCustomComponentWithoutChildren<P> = Omit<P, 'children'> & {
  customComponent?: React.FunctionComponent<P>;
};

export const allVariantsSelectors: Record<string, VariantsSelectorProps> = {};
