import { Helmet } from 'react-helmet-async';

import { ReferralShareContent } from './contentTypes';
import { useContent } from './useContent';

interface ShareMetaDataProps {
  contentCode: string;
}

export const ShareMetaData = ({ contentCode }: ShareMetaDataProps) => {
  const shareContent = useContent<ReferralShareContent>(contentCode);

  const { canonicalUrl, description, image, title, twitter, type } = shareContent?.body ?? {};

  const twitterImage = twitter?.image?.url ?? image?.url;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta content={description} name="description" />}
      {canonicalUrl && <link href={canonicalUrl} rel="canonical" />}

      {type && <meta content={type} property="og:type" />}
      {title && <meta content={title} property="og:title" />}
      {description && <meta content={description} property="og:description" />}
      {image?.url && <meta content={image.url} property="og:image" />}
      {canonicalUrl && <meta content={canonicalUrl} property="og:url" />}

      {twitter?.cardType && <meta content={twitter.cardType} name="twitter:card" />}
      {twitter?.handle && <meta content={twitter.handle} name="twitter:site" />}
      {title && <meta content={title} name="twitter:title" />}
      {description && <meta content={description} name="twitter:description" />}
      {twitterImage && <meta content={twitterImage} name="twitter:image" />}
    </Helmet>
  );
};
