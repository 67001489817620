// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { GetContentOptions, SearchContentsOptions } from '@b2x/storefront-api-js-client/src/contents';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useContentsApi = () => {
  const { apiRequest } = useApiRequest();

  const searchContents = React.useCallback(
    <T>(key?: Array<unknown>, options?: SearchContentsOptions, config?: ApiRequestConfig) =>
      apiRequest(api.contents.search<T>(key, options), { silent: false, ...config }),
    [apiRequest]
  );

  const getContentByCode = React.useCallback(
    <T>(code: string, options?: GetContentOptions, config?: ApiRequestConfig) =>
      apiRequest(api.contents.getByCode<T>(code, options), { suppressErrorModal: true, ...config }),
    [apiRequest]
  );

  const getContentByPath = React.useCallback(
    <T>(path: string, options?: GetContentOptions, config?: ApiRequestConfig) =>
      apiRequest(api.contents.getByPath<T>(path, options), { suppressErrorModal: true, ...config }),
    [apiRequest]
  );

  return { getContentByCode, getContentByPath, searchContents };
};
