import { CustomerApiDto } from '@b2x/storefront-api-js-client/src/';
import { GetCustomerOptions } from '@b2x/storefront-api-js-client/src/customer';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useCustomerApi } from './api/useCustomerApi';
import { useAppContext } from './AppContext';
import { useStable } from './util';

export interface UseCustomerOptions extends GetCustomerOptions {}

export const useCustomer = (options?: UseCustomerOptions, config?: ApiRequestConfig) => {
  const [customer, setCustomer] = React.useState<CustomerApiDto>();

  const { getCustomer } = useCustomerApi();
  const { session } = useAppContext();

  options = useStable(options);
  config = useStable(config);

  /*
  Prendo il customer al primo caricamento e ogni volta che cambia quello in sessione, per garantirne la coerenza.
  L'iter sarà quindi:
   - sto nella pagina account
   - modifico qualcosa
   - alla onSuccess verrà aggiornato il customer in sessione
   - la modifica del customer in sessione scatenerà questo aggiornamento del customer in pagina
  Ricapitolado, qualsiasi modifica al customer deve prima passare per quello in sessione, che a cascata scatenerà l'aggiornamento di questo.
  */
  React.useEffect(() => {
    getCustomer(options, config).then((response) => {
      setCustomer(response.data);
    });
  }, [config, getCustomer, options, session?.customer]);

  return customer;
};
