import { renderUI } from './util';

export const DropdownDivider = () => {
  return renderUI({
    bs5: (
      <li>
        <hr className="dropdown-divider" />
      </li>
    ),
  });
};
