import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { LinkAsButton, LinkAsButtonProps } from './LinkAsButton';

/*
  Una cta è un link che graficamente appare come un bottone (<LinkAsButton />) e che fa una di queste 3 cose:
   - rimanda a un sito esterno (es: <a class="btn" href="www.b2x.it">link</a>)
   - rimanda a una pagina interna, tramite url (<a class="btn" href="./{url}>link</a>")
   - rimanda a una pagina interna, tramite il codidce della pagina (<a class="btn" href="./{paginaTrovataInBaseAlCodice}">link</a>)
*/
export interface CtaProps<ButtonVariant, IconName, IconSize>
  extends LinkAsButtonProps<ButtonVariant, IconName, IconSize> {
  action?: string;
  code?: string;
  href?: string;
}

interface ActionToProps {
  onClick?(): void;
  to?: string;
}

const useActionToProps = (action?: string): ActionToProps | undefined => {
  const { session } = useAppContext();
  const { getFixedPagePath, getPagePath, onLoginActionCallback } = useAppStaticContext();
  if (!action) {
    return undefined;
  }

  const isLoggedIn = !!session?.customer;

  let onClick;
  let to;
  switch (action) {
    case 'login':
      if (isLoggedIn) {
        to = getPagePath('SITE_ACCOUNT');
      } else {
        onClick = onLoginActionCallback;
      }
      break;
    case 'register':
      to = isLoggedIn ? getPagePath('SITE_ACCOUNT') : getFixedPagePath('register');
      break;
    case 'cart':
      to = getFixedPagePath('cart');
      break;
    case 'checkout':
      to = getFixedPagePath('checkout');
      break;
    case 'newsletter':
      to = getFixedPagePath('newsletter');
      break;
  }

  return { onClick, to };
};

export const Cta = <ButtonVariant extends string, IconName extends string, IconSize extends number>({
  action,
  code,
  href,
  ...otherProps
}: CtaProps<ButtonVariant, IconName, IconSize>) => {
  const { getPagePath } = useAppStaticContext();
  const actionProps = useActionToProps(action?.trim());

  if (!!actionProps) {
    return (
      <Button onClick={actionProps.onClick} style={{ all: 'unset' }}>
        <LinkAsButton {...otherProps} link={{ ...otherProps.link, to: actionProps.to }} />
      </Button>
    );
  }

  return <LinkAsButton {...otherProps} link={{ ...otherProps.link, to: href ? href : code && getPagePath(code) }} />;
};
