import { Button } from '../Button';
import { Icon } from '../Icon';
import { uiClassName } from '../util';
import { TextInput } from './fields/Input';
import { FormGroup } from './FormGroup';
import { SimpleSearchFormHelper, SimpleSearchFormProps } from './SimpleSearchForm';

export const SimpleSearchFormA = (props: SimpleSearchFormProps) => {
  const { submitIconName } = props;

  return (
    <SimpleSearchFormHelper {...props}>
      {({ fieldsHelper, formik }) => (
        <FormGroup
          {...fieldsHelper.text.formGroup}
          className="d-flex align-items-center"
          label={undefined}
          noMarginBottom
        >
          <TextInput {...fieldsHelper.text.textInput} autoFocus className={uiClassName({ bs5: 'border-0' })} />
          {submitIconName && (
            <Button
              className={uiClassName({ bs5: 'px-3 align-self-stretch' })}
              onClick={formik.submitForm}
              variant="blank"
            >
              <Icon name={submitIconName} />
            </Button>
          )}
        </FormGroup>
      )}
    </SimpleSearchFormHelper>
  );
};
