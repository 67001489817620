import React from 'react';

import { createContext } from '../util';
import { StoreLocatorProps } from './StoreLocator';

// Intefaccia contiene tutte le informazioni da passare ai consumer del contesto
type StoreLocatorContextInterface = StoreLocatorProps<string, string, number>;

export const [StoreLocatorContextProvider, useStoreLocatorContext] =
  createContext<StoreLocatorContextInterface>('StoreLocatorContext');

// Proprietà necessarie per creare il contesto
type UseStoreLocatorContextInitializerProps = StoreLocatorProps<string, string, number>;

// Funzione per inizializzare il contesto
const useStoreLocatorContextInitializer = (props: UseStoreLocatorContextInitializerProps) => {
  // Crea l'oggetto storeLocatorContext
  const storeLocatorContext: StoreLocatorContextInterface = React.useMemo(() => props, [props]);

  return {
    StoreLocatorContextProvider,
    storeLocatorContext,
  };
};

// Interfaccia componente contesto
export interface StoreLocatorContextProps extends UseStoreLocatorContextInitializerProps {
  children: React.ReactNode | ((storeLocatorContext: StoreLocatorContextInterface) => React.ReactNode);
}

// Componente Contesto
export const StoreLocatorContext = ({ children, ...otherProps }: StoreLocatorContextProps) => {
  const storeLocatorContextInitializer = useStoreLocatorContextInitializer(otherProps);
  return (
    <storeLocatorContextInitializer.StoreLocatorContextProvider
      value={storeLocatorContextInitializer.storeLocatorContext}
    >
      {typeof children === 'function' ? children(storeLocatorContextInitializer.storeLocatorContext) : children}
    </storeLocatorContextInitializer.StoreLocatorContextProvider>
  );
};
