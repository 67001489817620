import { Alert } from '../../../Alert';
import { AlertFromDto } from '../../../AlertFromDto';
import { Button } from '../../../Button';
import { useCheckoutContext } from '../../../CheckoutContext';
import { ConsentsContentType } from '../../../contentTypes';
import { Checkbox } from '../../../form/fields/RadioCheck';
import { FormGroup } from '../../../form/FormGroup';
import { StartPaymentFormHelper } from '../../../form/StartPaymentForm';
import { t } from '../../../i18n/i18n';
import { useContent } from '../../../useContent';
import { formatHtml, renderUI } from '../../../util';
import { CheckoutSummaryStepProps } from '../CheckoutSummaryStep';

export const CheckoutSummaryStepA = ({ additionalContent, content, onEdit }: CheckoutSummaryStepProps) => {
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const { canStartPayment, cart } = useCheckoutContext();

  return renderUI({
    bs5: (
      <>
        {onEdit ? (
          <>
            {content?.onEdit?.alerts?.map((alert) => (
              <Alert
                className="extra-small py-2"
                key={alert.contentSectionId}
                {...alert}
                variant={
                  alert.type === 'info'
                    ? 'info'
                    : alert.type === 'success'
                    ? 'success'
                    : alert.type === 'warning'
                    ? 'warning'
                    : alert.type === 'error'
                    ? 'error'
                    : undefined
                }
              >
                {formatHtml(alert.message)}
              </Alert>
            ))}
            {additionalContent?.onEdit?.alerts?.map((alert, index) => (
              <Alert
                className="extra-small py-2"
                // eslint-disable-next-line react/no-array-index-key
                key={`checkout-summary-alert-on-edit-${index}`}
                {...alert}
              />
            ))}
            {cart?.alerts?.map((alert) => (
              <AlertFromDto alert={alert} key={alert.code} light small />
            ))}
            {!canStartPayment && <p className="text-danger small">{t('checkout.cannotStartPayment')}</p>}
            {cart?.email && (
              <StartPaymentFormHelper cart={cart}>
                {({ fieldsHelper }) => (
                  <>
                    <FormGroup {...fieldsHelper.checkbox.formGroup}>
                      <Checkbox
                        labelClassName="extra-small fw-light text-dark"
                        {...fieldsHelper.checkbox.checkbox}
                        label={formatHtml(consentsContent?.body.purchase)}
                      />
                    </FormGroup>
                    <div className="col-12 col-md-5 d-grid">
                      <Button {...fieldsHelper.buttons.submit} />
                    </div>
                  </>
                )}
              </StartPaymentFormHelper>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    ),
  });
};
