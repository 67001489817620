import { useSpeechRecognition, UseSpeechRecognitionOptions, UseSpeechRecognitionResult } from './useSpeechRecognition';

export interface SpeechRecognitionProps extends UseSpeechRecognitionOptions {
  children(useSpeechRecognitionResult: UseSpeechRecognitionResult): React.ReactNode;
}

export const SpeechRecognition = ({ children, ...otherProps }: SpeechRecognitionProps) => {
  const speechRecognitionResult = useSpeechRecognition(otherProps);

  return <>{children(speechRecognitionResult)}</>;
};
