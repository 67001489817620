import classnames from 'classnames';
import React from 'react';

import { Button, ButtonProps } from './Button';
import { ConditionalWrapper } from './ConditionalWrapper';
import { DropdownItem, DropdownItemProps } from './DropdownItem';
import { useInputGroupContextStrict } from './form/InputGroupContext';
import { createUUID, renderUI } from './util';

export type DropdownDirection = 'up' | 'down' | 'left' | 'right';
export type DropdownAlignment = 'left' | 'right';
export type DropdownAutoclose = true | false | 'inside' | 'outside';

export interface DropdownProps<ButtonVariant, IconName, IconSize>
  extends Omit<ButtonProps<ButtonVariant, IconName, IconSize>, 'type'> {
  alignment?: DropdownAlignment;
  autoClose?: DropdownAutoclose;
  buttonClassname?: string;
  children?: React.ReactNode;
  dark?: boolean;
  direction?: DropdownDirection;
  items?: Array<DropdownItemProps>;
  offset?: {
    x: number;
    y: number;
  };
}

export const Dropdown = <ButtonVariant extends string, IconName extends string, IconSize extends number>({
  alignment = 'left',
  autoClose = true,
  buttonClassname,
  children,
  className,
  dark,
  direction = 'down',
  id = createUUID(),
  items,
  offset,
  ...buttonProps
}: DropdownProps<ButtonVariant, IconName, IconSize>) => {
  const inputGroupContext = useInputGroupContextStrict();
  const isInsideInputGroup = inputGroupContext !== undefined;

  return renderUI({
    bs5: (
      <ConditionalWrapper
        condition={!isInsideInputGroup}
        wrapper={
          <div
            className={classnames(
              { dropdown: direction === 'down' },
              { dropup: direction === 'up' },
              { dropend: direction === 'right' },
              { dropstart: direction === 'left' },
              className
            )}
          />
        }
      >
        <Button
          {...buttonProps}
          aria-expanded="false"
          className={classnames('dropdown-toggle', buttonClassname)}
          data-bs-auto-close={autoClose}
          data-bs-offset={offset ? `${offset.x},${offset.y}` : undefined}
          data-bs-toggle="dropdown"
          id={id}
          type="button"
        />
        <ul
          aria-labelledby={id}
          className={classnames(
            'dropdown-menu',
            { 'dropdown-menu-dark': dark },
            { 'dropdown-menu-end': alignment === 'right' }
          )}
        >
          {items?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <DropdownItem key={index} {...item} />
          ))}
          {children}
        </ul>
      </ConditionalWrapper>
    ),
  });
};
