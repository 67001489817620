import { EqualHeight as _EqualHeight } from 'react-equal-height';

import { EqualHeightContext, EqualHeightContextInterface } from './EqualHeightContext';

export interface EqualHeightProps {
  animationSpeed?: number;
  children: React.ReactNode | ((equalHeightContext: EqualHeightContextInterface) => React.ReactNode);
  timeout?: number;
}

export const EqualHeight = ({ animationSpeed = 0, children, ...otherProps }: EqualHeightProps) => {
  return (
    <EqualHeightContext>
      {(context) => (
        // eslint-disable-next-line react/jsx-pascal-case
        <_EqualHeight animationSpeed={animationSpeed} updateOnChange={context.updateChecker} {...otherProps}>
          {typeof children === 'function' ? children(context) : children}
        </_EqualHeight>
      )}
    </EqualHeightContext>
  );
};
