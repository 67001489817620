import React from 'react';

export interface UseShareButtonClickHandlerOptions {
  data: ShareData;
  fallback(): void;
}

export const useShareButtonClickHandler = ({ data, fallback }: UseShareButtonClickHandlerOptions) => {
  const handleClick = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.canShare !== undefined && navigator.canShare(data)) {
      navigator.share(data);
    } else {
      fallback();
    }
  }, [data, fallback]);

  return handleClick;
};
