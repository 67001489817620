import React from 'react';

import { Button } from './Button';
import { Div } from './HTMLElement';

export const RerenderTest = () => {
  log('RerenderTest');
  const [count, setCount] = React.useState<number>(0);

  const incrementCounter = React.useCallback(() => {
    setCount((prevState) => prevState + 1);
  }, []);

  const person = React.useMemo<Person>(
    () => ({
      name: 'Simone',
      surname: 'Conti',
    }),
    []
  );

  const hookWithParams = useHookWithParams('Simone', person);
  const hookWithProps = useHookWithProps({ name: 'Simone', person: person });
  return (
    <>
      <p>Count: {count}</p>
      <Div marginBottom={3}>
        <Button label="Rerender trigger" onClick={incrementCounter} />
      </Div>
      <p>{hookWithParams}</p>
      <p>{hookWithProps}</p>
    </>
  );
};

interface Person {
  name: string;
  surname: string;
}

const useHookWithParams = (name: string, person: Person) => {
  log('useHookWithParams', name, person);

  React.useEffect(() => {
    log('useHookWithParams', 'useEffect', 'name', name);
  }, [name]);

  React.useEffect(() => {
    log('useHookWithParams', 'useEffect', 'person', person);
  }, [person]);

  return name;
};

const useHookWithProps = ({ name, person }: { name: string; person: Person }) => {
  log('useHookWithProps', name, person);

  React.useEffect(() => {
    log('useHookWithProps', 'useEffect', 'name', name);
  }, [name]);

  React.useEffect(() => {
    log('useHookWithProps', 'useEffect', 'person', person);
  }, [person]);

  return name;
};

export const log = (...messages: Array<unknown>) => {
  console.log('RerenderTest', messages);
};
