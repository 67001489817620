import { MetaDataApiDto, PageApiDto } from '.';
import { ContentPopulate } from './contents';
import { ProductPopulate } from './products';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface PagePopulate {
  additionalContents?: Populate<ContentPopulate>;
  associatedPages?: Populate<PagePopulate>;
  breadcrumb?: Populate<PagePopulate>;
  children?: Populate<PagePopulate>;
  content?: boolean;
  products?: Populate<ProductPopulate>;
}

const rootPath = '/v2/pages';

export interface GetPageOptions extends BaseOptions {
  populate?: Populate<PagePopulate>;
}

export const search = async <T>(ids: Array<string>, options?: GetPageOptions) =>
  apiRequest<Array<PageApiDto<T>>, void, PagePopulate>({
    ...getBaseOptions(options),
    params: {
      ids: ids,
    },
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/search`,
  });

export const get = async <T>(id: string, options?: GetPageOptions) =>
  apiRequest<PageApiDto<T>, void, PagePopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/${id}`,
  });

export const getByCode = async <T>(code: string, options?: GetPageOptions) =>
  apiRequest<PageApiDto<T>, void, PagePopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/code/${code}`,
  });

export const getByPath = async <T>(path: string, options?: GetPageOptions) =>
  apiRequest<PageApiDto<T>, void, PagePopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}/path/${path}`,
  });

export const getPathInAnotherLocaleByPath = async (path: string, locale: string, options?: BaseOptions) =>
  apiRequest<string, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/locale/${locale}/path/${path}`,
  });

export const getPathsInAllLocalesByPath = async (path: string, options?: BaseOptions) =>
  apiRequest<Record<string, string>, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/locale/all/path/${path}`,
  });

export const getMetaByPath = async (path: string, options?: BaseOptions) =>
  apiRequest<MetaDataApiDto | undefined, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/meta/path/${path}`,
  });
