import classnames from 'classnames';

import { Alert, AlertProps } from './Alert';
import { renderUI } from './util';

export interface DevAlertProps extends Omit<AlertProps, 'heading'> {
  heading: string;
}

export const DevAlert = ({ className, dismissable = true, heading, ...otherOptions }: DevAlertProps) => {
  // const { environment } = useAppContext();

  return (
    <>
      {/* {environment === 'LOCAL' && */}
      {renderUI({
        bs5: (
          <Alert
            {...otherOptions}
            className={classnames(className, 'small')}
            dismissable
            heading={{ className: 'fw-bold', size: 6, text: heading }}
            variant="error"
          />
        ),
      })}
      {/* } */}
    </>
  );
};
