import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountReferralSubpageA } from './accountSubpagesA/AccountReferralSubpageA';

export interface AccountReferralSubpageProps {}

export type AccountReferralSubpageVariants = 'A';

const AccountReferralSubpageController = (props: PropsWithCustomComponent<AccountReferralSubpageProps>) => (
  <VariantsController<AccountReferralSubpageProps, AccountReferralSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountReferralSubpageA },
      name: 'AccountReferralSubpage',
    }}
  />
);
export { AccountReferralSubpageController as AccountReferralSubpage };
