import { ServiceSubpageContentType } from '../../../contentTypes';
import { formatHtml } from '../../../util';
import { ServiceSubpage } from '../ServiceSubpage';

export interface ServiceGenericSubpageAProps {}

export const ServiceGenericSubpageA = (props: ServiceGenericSubpageAProps) => {
  return (
    <ServiceSubpage<ServiceSubpageContentType>>
      {(page) => <>{formatHtml(page.content?.body.content)}</>}
    </ServiceSubpage>
  );
};
