import React from 'react';

import { Breakpoint } from './interfaces';
import { useWindowSize } from './useWindowSize';
import { getCurrentBreakpoint } from './util';

export const useBreakpoint = (timeout = 200): Breakpoint => {
  const [breakpoint, setBreakpoint] = React.useState<Breakpoint>(getCurrentBreakpoint());
  const { width } = useWindowSize(timeout);

  React.useEffect(() => {
    setBreakpoint(getCurrentBreakpoint());
  }, [width]);

  return breakpoint;
};
