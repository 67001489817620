import React from 'react';

import { Button } from '../Button';
import { useModalContext } from '../ModalContext';
import { useModals } from '../useModals';

export interface RecursiveOffcanvasProps {
  className?: string;
}

export const RecursiveOffcanvas = ({ className }: RecursiveOffcanvasProps) => {
  const { showOffcanvas } = useModals();

  const handleButton1Click = React.useCallback(() => {
    showOffcanvas({
      children: <RecursiveOffcanvasInternal />,
      title: 'Title Offcanvas 1',
    });
  }, [showOffcanvas]);

  return (
    <>
      <Button className={className} label={'Show Recursive Offcanvas'} onClick={handleButton1Click} />
    </>
  );
};

interface RecursiveOffcanvasInternalProps {
  level?: number;
}

const RecursiveOffcanvasInternal = ({ level = 1 }: RecursiveOffcanvasInternalProps) => {
  const { showModal, showOffcanvas } = useModals();
  const { close } = useModalContext();

  const handleButton1Click = React.useCallback(() => {
    close();
    showOffcanvas({
      children: <RecursiveOffcanvasInternal level={level + 1} />,
      title: `Title Offcanvas ${level + 1}`,
    });
  }, [close, level, showOffcanvas]);

  const handleButton2Click = React.useCallback(() => {
    showOffcanvas({
      children: <RecursiveOffcanvasInternal level={level + 1} />,
      title: `Title Offcanvas ${level + 1}`,
    });
  }, [level, showOffcanvas]);

  const handleButton3Click = React.useCallback(() => {
    close();
    showModal({
      children: <RecursiveOffcanvasInternal level={level + 1} />,
      title: `Title Offcanvas ${level + 1}`,
    });
  }, [close, level, showModal]);

  const handleButton4Click = React.useCallback(() => {
    showModal({
      children: <RecursiveOffcanvasInternal level={level + 1} />,
      title: `Title Offcanvas ${level + 1}`,
    });
  }, [level, showModal]);

  return (
    <>
      <p>
        <Button label={`Show Offcanvas ${level + 1} closing this one`} onClick={handleButton1Click} />
      </p>
      <p>
        <Button label={`Show Offcanvas ${level + 1} leaving this one open for later`} onClick={handleButton2Click} />
      </p>
      <p>
        <Button label={`Show Modal ${level + 1} closing this one`} onClick={handleButton3Click} />
      </p>
      <p>
        <Button label={`Show Modal ${level + 1} leaving this one open for later`} onClick={handleButton4Click} />
      </p>
    </>
  );
};
