import './DesktopHeaderSticky.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { Logo } from './Header';
import { t } from './i18n/i18n';
import { Icon } from './Icon';

export interface DesktopHeaderProps {
  visibleFrom: b2x.Breakpoint;
}
export const DesktopHeader = ({ visibleFrom }: DesktopHeaderProps) => {
  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Quando apro una delle due box, chiudo il sottomenu delle categorie.
    if (activeCategory !== undefined) {
      setSearchBoxVisible(false);
      setLocaleBoxVisible(false);
    }
  }, [activeCategory]);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, []);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      {/* <b2x.Sticky className="DesktopHeaderSticky"> */}
      <div className={`bg-white sticky-top shadow-sm py-1 d-none d-${visibleFrom}-block`}>
        <FirstRow
          localeBoxVisible={localeBoxVisible}
          searchBoxVisible={searchBoxVisible}
          toggleLocaleBoxVisible={toggleLocaleBoxVisible}
          toggleSearchBoxVisible={toggleSearchBoxVisible}
          visibleFrom={visibleFrom}
        />
        <MenuBar visibleFrom={visibleFrom} />
      </div>
      {/* </b2x.Sticky> */}
    </>
  );
};

interface FirstRowProps {
  activeCategory?: b2x.MenuApiDto;
  localeBoxVisible: boolean;
  searchBoxVisible: boolean;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const FirstRow = ({
  activeCategory,
  localeBoxVisible,
  searchBoxVisible,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: FirstRowProps) => {
  return (
    <div className={classnames('header-first-row', `d-none d-${visibleFrom}-block`)}>
      <Container>
        <b2x.Row className="align-items-start pt-2">
          <b2x.Col className="d-flex">
            <LocaleToggle toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          </b2x.Col>
          <b2x.Col className="d-flex justify-content-center">
            <Logo />
          </b2x.Col>
          <b2x.Col className="d-flex justify-content-end">
            <IconsBox toggleSearchBoxVisible={toggleSearchBoxVisible} />
          </b2x.Col>
        </b2x.Row>
      </Container>
      {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
      {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
    </div>
  );
};

interface MenuBarProps {
  visibleFrom: b2x.Breakpoint;
}
const MenuBar = ({ visibleFrom }: MenuBarProps) => {
  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });
  return (
    <div>
      <div className="container-xxl">
        <div className="row">
          <div className="col">
            <Menu activeCategory={undefined} menu={menu} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <div className="row justify-content-center text-center">
        <div className="col-5">
          <b2x.LocaleForm />
        </div>
      </div>
    </Box>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory?: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}
const Menu = ({ activeCategory, menu, setActiveCategory }: MenuProps) => {
  return (
    <div className="menu d-flex pt-3 pb-2 justify-content-center">
      {menu?.children.map((firstLevelCategory) => {
        const firstLevelCategoryTextColor = firstLevelCategory.tags?.includes('primary')
          ? 'text-primary'
          : 'text-black';
        return (
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) && (
            <div
              className={classnames('menu-item mx-2 ', {
                active: activeCategory && activeCategory.id === firstLevelCategory.id,
              })}
              key={firstLevelCategory.id}
            >
              {firstLevelCategory.children.length > 0 ? (
                <Button
                  className={classnames('text-decoration-none p-0 text-nowrap fw-bold', firstLevelCategoryTextColor, {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  })}
                  label={firstLevelCategory.label}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => {
                    //setActiveCategory(firstLevelCategory);
                  }}
                  size="small"
                  variant="blank"
                />
              ) : (
                <b2x.router.Link
                  className={classnames(
                    'Button btn btn-link text-decoration-none p-0 text-nowrap fw-bold',
                    firstLevelCategoryTextColor,
                    {
                      active: activeCategory && activeCategory.id === firstLevelCategory.id,
                    }
                  )}
                  to={firstLevelCategory.link}
                >
                  {firstLevelCategory.label}
                </b2x.router.Link>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};
interface IconsBoxProps {
  toggleSearchBoxVisible(): void;
}

const IconsBox = ({ toggleSearchBoxVisible }: IconsBoxProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();
  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();
  const iconSize = 30;

  return (
    <>
      <div className="hstack gap-2">
        <Button iconEnd={{ name: 'search', size: iconSize }} onClick={toggleSearchBoxVisible} variant="blank" />
        {session?.userLogged ? (
          <b2x.router.Link className="lh-1 text-dark" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="profile-full" size={iconSize} />
          </b2x.router.Link>
        ) : (
          <Button iconEnd={{ name: 'profile', size: iconSize }} onClick={showAccountOffcanvas} variant="blank" />
        )}

        {/*         {session?.userLogged ? (
          <b2x.router.Link className="lh-1" to="/account/area/wishlist">
            <Button className="position-relative" variant="blank">
              <Icon name={'wishlist'} size={iconSize} />
              {((session?.wishlist?.products && session.wishlist.products.length > 0) ||
                (session?.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-secondary text-primary">
                  {(session.wishlist.products ? session.wishlist.products.length : 0) +
                    (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                </span>
              )}
            </Button>
          </b2x.router.Link>
        ) : (
          <Button iconEnd={{ name: 'wishlist', size: iconSize }} onClick={showAccountOffcanvas} variant="blank" />
        )}
 */}
        <Button className="position-relative" onClick={showCartOffcanvas} variant="blank">
          <Icon
            name={session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 ? 'cart-full' : 'cart'}
            size={iconSize}
          />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge fw-normal bg-primary text-white">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleSearchBoxVisible}>
      <div className="row justify-content-center">
        <div className="col-8">
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </div>
      </div>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="box py-2 bg-white position-absolute w-100 zindex-sticky" ref={ref}>
      <Container>
        <>
          <div className="d-flex justify-content-end">
            <Button iconEnd={{ name: 'close', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
          </div>
          {children}
        </>
      </Container>
    </div>
  );
};

interface LocaleToggleProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleToggle = ({ toggleLocaleBoxVisible }: LocaleToggleProps) => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <Button
      alignItems="center"
      className="small text-initial"
      iconStart={{ name: 'language', size: 20 }}
      label={
        locale &&
        shippingCountry && (
          <span>{t('header.sendTo', { country: shippingCountry.name, locale: locale.languageDescriptionLocal })}</span>
        )
      }
      onClick={toggleLocaleBoxVisible}
      size="small"
      variant="blank"
    />
  );
};
