import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountPrivacySubpageA } from './accountSubpagesA/AccountPrivacySubpageA';

export interface AccountPrivacySubpageProps {}

export type AccountPrivacySubpageVariants = 'A';

const AccountPrivacySubpageController = (props: PropsWithCustomComponent<AccountPrivacySubpageProps>) => (
  <VariantsController<AccountPrivacySubpageProps, AccountPrivacySubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountPrivacySubpageA },
      name: 'AccountPrivacySubpage',
    }}
  />
);
export { AccountPrivacySubpageController as AccountPrivacySubpage };
