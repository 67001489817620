import { PropsWithCustomComponent, VariantsController } from '../../VariantsController';
import { AccountCreditCardsSubpageA } from './accountSubpagesA/AccountCreditCardsSubpageA';

export interface AccountCreditCardsSubpageProps {}

export type AccountCreditCardsSubpageVariants = 'A';

const AccountCreditCardsSubpageController = (props: PropsWithCustomComponent<AccountCreditCardsSubpageProps>) => (
  <VariantsController<AccountCreditCardsSubpageProps, AccountCreditCardsSubpageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: AccountCreditCardsSubpageA },
      name: 'AccountCreditCardsSubpage',
    }}
  />
);
export { AccountCreditCardsSubpageController as AccountCreditCardsSubpage };
