import {
  CartApiDto,
  CreateSmartOrderRequestApiDto,
  OrderApiDto,
  PaymentResponseApiDto,
  ResponseApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { analytics } from './analytics/analytics';
import { useAppContext, useAppStaticContext } from './AppContext';
import { PayPalCheckoutModalProps, usePayPalCheckoutModal } from './PayPalCheckoutModal';
import { useNavigate } from './router/useNavigate';
import { addScript } from './util';

export const usePaymentHandler = () => {
  const [PayPalCheckoutModal, showPayPalCheckoutModal] = usePayPalCheckoutModal();
  const navigate = useNavigate();
  const { getFixedPagePath } = useAppStaticContext();
  const { shippingCountry } = useAppContext();

  const PaymentModal = React.useMemo(() => {
    return <>{PayPalCheckoutModal}</>;
  }, [PayPalCheckoutModal]);

  const handlePaymentCallback = React.useCallback(
    (
      response: ResponseApiDto<PaymentResponseApiDto>,
      analyticsFn: () => void,
      paypalProps: Pick<PayPalCheckoutModalProps, 'currency' | 'mode'>
    ) => {
      if (response.data.ok) {
        analyticsFn();
        if (!response.data.direct) {
          if (response.data.paymentURL) {
            window.location.replace(response.data.paymentURL);
          } else {
            if (response.data.paymentMethodType === 'WORLDLINE_EASY_CHECKOUT') {
              const endpoint = response.data.properties['endpoint'] as string | undefined;
              const licenseKey = response.data.properties['licenseKey'] as string | undefined;
              const paymentId = response.data.properties['paymentId'] as string | undefined;
              if (endpoint && licenseKey && paymentId) {
                addScript({
                  id: 'axepta',
                  src: `${endpoint}/sdk/axepta-pg-redirect.js`,
                }).then(() => {
                  const axeptaClient = new AxeptaSDKClient(endpoint, licenseKey);
                  axeptaClient.proceedToPayment(paymentId);
                });
              }
            } else if (response.data.paymentMethodType === 'PAYPAL_V2') {
              showPayPalCheckoutModal({ ...paypalProps, payment: response.data });
            } else {
              alert(`payment method type: ${response.data.paymentMethodType} not supported`);
            }
          }
        } else {
          if (
            response.data.paymentMethodType === 'BANK_TRANSFER' ||
            response.data.paymentMethodType === 'CASH_ON_DELIVERY' ||
            response.data.paymentMethodType === 'GIFT_CARD' ||
            response.data.paymentMethodType === 'NOT_NECESSARY_PAYMENT'
          ) {
            if (response.data.token) {
              navigate(`${getFixedPagePath('order-ok')}?token=${response.data.token}`);
            }
          }
        }
      } else {
        alert('error');
      }
    },
    [getFixedPagePath, navigate, showPayPalCheckoutModal]
  );

  const handleSmartPaymentCallback = React.useCallback(
    async (response: ResponseApiDto<PaymentResponseApiDto>, order: CreateSmartOrderRequestApiDto) => {
      handlePaymentCallback(response, () => analytics.events.smartOrderPayment(response.eventId, order), {
        currency: shippingCountry?.currencyCode ?? 'EUR',
        mode: 'start',
      });
    },
    [handlePaymentCallback, shippingCountry?.currencyCode]
  );

  const handleCartPaymentCallback = React.useCallback(
    async (response: ResponseApiDto<PaymentResponseApiDto>, cart: CartApiDto) => {
      handlePaymentCallback(response, () => analytics.events.startPayment(response.eventId, cart), {
        currency: cart.currency,
        mode: 'start',
      });
    },
    [handlePaymentCallback]
  );

  const handleOrderPaymentCallback = React.useCallback(
    (response: ResponseApiDto<PaymentResponseApiDto>, order: OrderApiDto) => {
      handlePaymentCallback(response, () => analytics.events.orderPayment(response.eventId, order), {
        currency: order.currency,
        mode: 'restart',
      });
    },
    [handlePaymentCallback]
  );

  return {
    PaymentModal,
    handleCartPaymentCallback,
    handleOrderPaymentCallback,
    handleSmartPaymentCallback,
  };
};
