import { b2x } from '@b2x/react/src';

import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Container } from '../Container';
import { BannerSearchContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { PageHeader } from '../PageHeader';
import { Error404Page } from './Error404Page';
import { Page } from './Page';

export interface SearchPageProps {}

export const SearchPage = (props: SearchPageProps) => {
  const { page, searchResult } = b2x.useSearch<BannerSearchContentType>({
    defaultPageSize: 18,
    pageOptions: {
      populate: {
        content: true,
      },
    },
  });

  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    searchResult: searchResult,
  });

  return (
    <>
      {searchResult?.itemsCount === 0 ? (
        <Error404Page />
      ) : (
        <Page className="bg-secondary" noPaddingTop>
          {MobileSearchFiltersOffcanvas}
          <PageHeader {...page?.content?.body.header} />
          <Container>
            <b2x.Div display="flex" marginY={2}>
              <Breadcrumb classNameObject={{ ol: 'mb-0' }} />
            </b2x.Div>
            <b2x.Div display={{ lg: 'flex', xs: 'none' }} justifyContent={'end'} marginY={2}>
              <SortingOptionsDropdown searchResult={searchResult} />
            </b2x.Div>
            <b2x.Div display={{ lg: 'none', xs: 'flex' }} justifyContent="center" marginY={3}>
              <Button
                label={t('misc.filterAndOrderBy')}
                onClick={showMobileSearchFiltersOffcanvas}
                type="button"
                variant="link"
              />
            </b2x.Div>
            <b2x.Row>
              <b2x.Col size={{ lg: 3, xs: 12 }}>
                <b2x.SearchFormHelper searchResult={searchResult} submitOnChange>
                  {({ fieldsHelper, formik }) => <Filters fieldsHelper={fieldsHelper} />}
                </b2x.SearchFormHelper>
              </b2x.Col>
              <b2x.Col size={{ lg: 9, xs: 12 }}>
                {searchResult && searchResult.items && (
                  <b2x.Listing name="Search page" products={searchResult.items}>
                    <b2x.ProductsTiles
                      className="g-2 g-md-4 mb-5"
                      enableExtraInfo
                      products={searchResult.items.map((product) => ({ product: product }))}
                      productsPerRow={{ lg: 3, md: 3, sm: 2, xl: 3, xs: 2, xxl: 3 }}
                    />
                  </b2x.Listing>
                )}
              </b2x.Col>
            </b2x.Row>
            <Pagination searchResult={searchResult} />
          </Container>
        </Page>
      )}
    </>
  );
};

interface SortingOptionsDropdownProps {
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown className="" label={t('misc.orderBy')} variant="blank">
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};

interface FiltersProps {
  fieldsHelper: b2x.SearchFieldsHelper;
}

const Filters = ({ fieldsHelper }: FiltersProps) => {
  return (
    <ul className="list-unstyled">
      {fieldsHelper.activeFilters.map(
        (activeFilter) =>
          activeFilter.fromSimpleSearch && (
            <li key={activeFilter.filter.code}>
              <div className="d-none d-lg-block display-3 my-4">{t('misc.youSearchedFor')}</div>
              <ul className="list-unstyled">
                <li>
                  <span className="d-inline-block d-lg-none me-3">{t('misc.youSearchedFor')}:</span>
                  <Button
                    className="btn-sm extra-small"
                    iconEnd={{ name: 'close', size: 12 }}
                    label={activeFilter.filter.name}
                    onClick={activeFilter.handleClick}
                    variant="outline-primary"
                  />
                </li>
              </ul>
              <hr />
            </li>
          )
      )}
      {fieldsHelper.filters.map((lvl1Filter) => (
        <li className="d-none d-lg-block" key={lvl1Filter.checkbox.id}>
          <div className="display-3 my-4">{lvl1Filter.filter.name}</div>
          <ul className="list-unstyled">
            {lvl1Filter.children.map((lvl2Filter) => (
              <li key={lvl2Filter.checkbox.id}>
                <b2x.Checkbox {...lvl2Filter.checkbox} />
                <ul className="list-unstyled ms-4">
                  {lvl2Filter.children.map((lvl3Filter) => (
                    <li key={lvl3Filter.checkbox.id}>
                      <b2x.Checkbox {...lvl3Filter.checkbox} />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <hr />
        </li>
      ))}
    </ul>
  );
};

interface PaginationProps {
  searchResult?: b2x.SearchProductsApiDto;
}

const Pagination = ({ searchResult }: PaginationProps) => {
  return (
    <>
      {searchResult && (
        <b2x.Div display="flex" justifyContent="end">
          <b2x.Pagination
            currentPage={searchResult.pageNumber}
            pageOffset={2}
            showDots
            singleStepNavigation
            totalPages={searchResult.pagesCount}
          />
        </b2x.Div>
      )}
    </>
  );
};
