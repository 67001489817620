import { BankAccountDetailsApiDto, CountryApiDto, InfoApiDto, ProvinceApiDto } from './dto';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface InfoPopulate {
  bankAccountDetails?: boolean;
  defaultLocale?: boolean;
  defaultShippingCountry?: boolean;
  shippingCountries?: boolean;
  shippingCountriesLocales?: boolean;
  supportedLocales?: boolean;
}

const rootPath = '/v2/info';

export interface GetInfoOptions extends BaseOptions {
  populate?: Populate<InfoPopulate>;
}

export const get = async (options?: GetInfoOptions) =>
  apiRequest<InfoApiDto, void, InfoPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    staleTime: Infinity,
    url: `${rootPath}`,
  });

export const getSampleShippingCountries = async (options?: BaseOptions) =>
  apiRequest<Array<CountryApiDto>, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/shippingCountries`,
  });

export const getCountryProvinces = async (countryCode: string, options?: BaseOptions) =>
  apiRequest<Array<ProvinceApiDto>, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/provinces/${countryCode}`,
  });

export const getProvinceZipCodes = async (provinceCode: string, options?: BaseOptions) =>
  apiRequest<Array<string>, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/zipCodes/${provinceCode}`,
  });

export const getBankAccountDetails = async (options?: BaseOptions) =>
  apiRequest<BankAccountDetailsApiDto, void, void>({
    ...getBaseOptions(options),
    staleTime: Infinity,
    url: `${rootPath}/bankAccountDetails`,
  });

export const checkComingSoon = async (previewCode: string, options?: BaseOptions) =>
  apiRequest<boolean, void, void>({
    ...getBaseOptions(options),
    params: {
      previewCode,
    },
    staleTime: Infinity,
    url: `${rootPath}/comingSoon/check`,
  });
