import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import {
  MerchantReturnEnumeration,
  OfferItemCondition,
  Product as ProductSchema,
  ReturnFeesEnumeration,
  ReturnMethodEnumeration,
} from 'schema-dts';

import { useAppContext, useAppStaticContext } from '../AppContext';
import { useShippingProfiles } from '../useShippingProfiles';
import { formatDate } from '../util';

export interface ProductProps {
  product: ProductApiDto;
  schema?: Omit<ProductSchema, '@context' | '@type'>;
  selectedSku?: SkuApiDto;
}

export const Product = ({ product, schema, selectedSku }: ProductProps) => {
  const { shippingCountry, structuredDataContent } = useAppContext();
  const { getPagePath } = useAppStaticContext();
  const merchantReturnPolicy = structuredDataContent?.body.merchantReturnPolicy;

  const shippingProfiles = useShippingProfiles();

  const productSku = selectedSku ?? product.skus?.find((sku) => sku.preferred === true) ?? product.skus?.at(0);

  return (
    <Helmet
      script={[
        helmetJsonLdProp<ProductSchema>({
          '@context': 'https://schema.org',
          '@type': 'Product',
          aggregateRating:
            product.summaryReviews?.average && product.summaryReviews.totCount
              ? {
                  '@type': 'AggregateRating',
                  bestRating: product.summaryReviews.rangeTo,
                  ratingValue: product.summaryReviews.average,
                  reviewCount: product.summaryReviews.totCount,
                  worstRating: product.summaryReviews.rangeFrom,
                }
              : undefined,
          brand: product.brand?.name
            ? {
                '@type': 'Brand',
                image: product.brand.image?.src,
                name: product.brand.name,
              }
            : undefined,
          description: product.description,
          image: product.image?.src,
          name: product.name,
          offers: productSku
            ? {
                '@type': 'Offer',
                availability: productSku.state === 'AVAILABLE' ? 'InStock' : 'OutOfStock',
                hasMerchantReturnPolicy: {
                  '@type': 'MerchantReturnPolicy',
                  customerRemorseReturnFees: merchantReturnPolicy?.customerRemorseReturnFees
                    ? (`https://schema.org/${merchantReturnPolicy.customerRemorseReturnFees}` as ReturnFeesEnumeration)
                    : undefined,
                  itemCondition: merchantReturnPolicy?.itemCondition
                    ? (`https://schema.org/${merchantReturnPolicy.itemCondition}` as OfferItemCondition)
                    : undefined,
                  merchantReturnDays: merchantReturnPolicy?.merchantReturnDays,
                  name: merchantReturnPolicy?.name,
                  returnFees: merchantReturnPolicy?.returnFees
                    ? (`https://schema.org/${merchantReturnPolicy.returnFees}` as ReturnFeesEnumeration)
                    : undefined,
                  returnMethod: merchantReturnPolicy?.returnMethods?.map(
                    (item) => `https://schema.org/${item.returnMethod}` as ReturnMethodEnumeration
                  ),
                  returnPolicyCategory: merchantReturnPolicy?.returnPolicyCategory
                    ? (`https://schema.org/${merchantReturnPolicy.returnPolicyCategory}` as MerchantReturnEnumeration)
                    : undefined,
                  url: merchantReturnPolicy?.url ? getPagePath(merchantReturnPolicy.url) : undefined,
                },
                price: productSku.price?.value,
                priceCurrency: productSku.price?.currencyCode,
                shippingDetails: shippingProfiles?.map((shipping) => ({
                  '@type': 'OfferShippingDetails',
                  deliveryTime: {
                    '@type': 'ShippingDeliveryTime',
                    // handlingTime: {
                    //   '@type': 'QuantitativeValue',
                    //   maxValue: shipping.minDeliveryDays,
                    //   minValue: 1,
                    //   unitCode: 'd',
                    // },
                    transitTime: {
                      '@type': 'QuantitativeValue',
                      maxValue: shipping.maxDeliveryDays,
                      minValue: 1, // Da aggiungere il dato mancante (attualmente l'1 è una forzatura)
                      unitCode: 'd',
                    },
                  },
                  shippingDestination: {
                    '@type': 'DefinedRegion',
                    addressCountry: shippingCountry?.code,
                  },
                  shippingRate: {
                    '@type': 'MonetaryAmount',
                    currency: shippingCountry?.currencyCode,
                    value: shipping.cost,
                  },
                  transitTimeLabel: shipping.name,
                })),
                sku: productSku.code,
              }
            : undefined,
          review: product.reviews
            ? product.reviews.map((review) => ({
                '@type': 'Review',
                author: {
                  '@type': 'Person',
                  name: review.nickname ?? 'Guest',
                },
                datePublished: review.date ? formatDate(review.date) : undefined,
                reviewBody: review.message,
                reviewRating: {
                  '@type': 'Rating',
                  bestRating: review.rangeTo,
                  ratingValue: review.rating,
                  worstRating: review.rangeFrom,
                },
              }))
            : undefined,
          sku: product.code,
          url: product.url,
          ...schema,
        }),
      ]}
    />
  );
};
