import { PaymentMethodApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { analytics } from '../analytics/analytics';
import { useCartApi } from '../api/useCartApi';
import { Button } from '../Button';
import { useCheckoutContextStrict } from '../CheckoutContext';
import { appConfig } from '../config';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Image } from '../Image';
import { TextMuted } from '../TextMuted';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PaymentMethod, usePaymentMethods } from '../usePaymentMethods';
import { formatCurrency } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Radio, RadioProps } from './fields/RadioCheck';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface CartPaymentMethodsFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  appliedPaymentMethod: PaymentMethodApiDto | undefined;
}

interface FormValues {
  id: formikString;
}

type ValidationSchema = {
  id: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  id: {
    formFields: Array<{ paymentMethod: PaymentMethod; radio: RadioProps }>;
    formGroup: FormGroupProps;
  };
}

export const CartPaymentMethodsFormHelper = ({
  appliedPaymentMethod,
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: CartPaymentMethodsFormProps) => {
  const { setPaymentMethod } = useCartApi();

  const { paymentMethods } = usePaymentMethods({});

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      id: getInitialString(appliedPaymentMethod?.id),
      ...initialValues,
    }),
    [appliedPaymentMethod?.id, initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      id: yup.string().required(),
    }),
    []
  );

  const checkoutContext = useCheckoutContextStrict();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      setPaymentMethod({ paymentMethodId: values.id, preferred: false }).then((response) => {
        if (checkoutContext) {
          const paymentMethod = paymentMethods.find((_paymentMethod) => _paymentMethod.id === values.id);
          if (checkoutContext.cart && paymentMethod) {
            analytics.events.addPaymentInfo(response.eventId, checkoutContext.cart, paymentMethod.type);
          }
          checkoutContext.deleteForcedStep();
          checkoutContext.setStepAlreadyDone('paymentMethod');
        }
        onSuccess && onSuccess();
      }),
    [checkoutContext, onSuccess, paymentMethods, setPaymentMethod]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('CartPaymentMethodsForm', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.cartPaymentMethodsForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.cartPaymentMethodsForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          id: {
            formFields: paymentMethods.map((paymentMethod) => ({
              paymentMethod: paymentMethod,
              radio: {
                className: 'small',
                id: paymentMethod.id,
                label: `${paymentMethod.name}${
                  paymentMethod.extraCost !== undefined && paymentMethod.extraCost > 0
                    ? ` (${formatCurrency(paymentMethod.extraCost)})`
                    : ''
                }`,
                name: 'id',
                value: paymentMethod.id,
              },
            })),
            formGroup: {
              label: t('checkout.step.paymentMethod.subTitle'),
              names: ['id'],
              omitForAttribute: true,
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.id.formGroup}>
              {fieldsHelper.id.formFields.map(({ paymentMethod, radio }) => (
                <Radio
                  key={radio.id}
                  {...radio}
                  label={
                    <div>
                      <Div alignItems="center" display="flex" gap={3}>
                        <div>
                          {`${paymentMethod.name}${
                            paymentMethod.extraCost !== undefined && paymentMethod.extraCost > 0
                              ? ` (${formatCurrency(paymentMethod.extraCost)})`
                              : ''
                          }`}
                        </div>
                        {paymentMethod.images && (
                          <Div alignItems="center" display="flex" gap={1}>
                            {paymentMethod.images.map((image) => (
                              <Image key={image} src={image} />
                            ))}
                          </Div>
                        )}
                      </Div>
                      {paymentMethod.description && (
                        <TextMuted>
                          <small>{paymentMethod.description}</small>
                        </TextMuted>
                      )}
                    </div>
                  }
                />
              ))}
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type CartPaymentMethodsFormVariants = '';

const CartPaymentMethodsFormController = (
  props: PropsWithCustomComponentWithoutChildren<CartPaymentMethodsFormProps>
) => (
  <VariantsController<CartPaymentMethodsFormProps, CartPaymentMethodsFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CartPaymentMethodsFormHelper,
      name: 'CartPaymentMethodsForm',
    }}
  />
);
export { CartPaymentMethodsFormController as CartPaymentMethodsForm };
