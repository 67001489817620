import React from 'react';

import { Col, ColSize } from '../Col';
import { Container } from '../Container';
import { Row } from '../Row';
import { useWindowSize } from '../useWindowSize';
import { renderUI } from '../util';

export interface GridSystemProps {
  showActualBreakpoint?: boolean;
}
export const GridSystem = (props: GridSystemProps) => {
  return (
    <>
      <Container className="mb-3">
        <GridSystemInternal {...props} />
      </Container>
      {/*       <Container fluid="always">
        <GridSystemInternal {...props} />
      </Container>
 */}{' '}
    </>
  );
};

const GridSystemInternal = ({ showActualBreakpoint = true }: GridSystemProps) => (
  <>
    {showActualBreakpoint &&
      renderUI({
        bs5: (
          <div className="mb-1">
            Breakpoint attuale: <strong className="d-inline d-sm-none">xs</strong>
            <strong className="d-none d-sm-inline d-md-none">sm</strong>
            <strong className="d-none d-md-inline d-lg-none">md</strong>
            <strong className="d-none d-lg-inline d-xl-none">lg</strong>
            <strong className="d-none d-xl-inline d-xxl-none">xl</strong>
            <strong className="d-none d-xxl-inline">xxl</strong>
          </div>
        ),
      })}
    <div>
      {[12, 6, 4, 3, 2, 1].map((columnSize) => (
        <Row key={columnSize}>
          {[...Array(12 / columnSize)].map((col, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col key={index} size={columnSize as ColSize}>
              <Block columnSize={columnSize} />
            </Col>
          ))}
        </Row>
      ))}
      {([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as Array<Exclude<ColSize, 'auto' | ''>>).map((col) => (
        <Row key={col}>
          <Col size={col}>
            <Block columnSize={col} />
          </Col>
          <Col size={(12 - col) as ColSize}>
            <Block columnSize={12 - col} />
          </Col>
        </Row>
      ))}
    </div>
  </>
);

interface BlockProps {
  columnSize: number;
}

const Block = ({ columnSize }: BlockProps) => {
  const [width, setWidth] = React.useState<number>();

  const ref = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  React.useEffect(() => {
    ref.current && setWidth(ref.current.offsetWidth);
  }, [windowSize]);

  return renderUI({
    bs5: (
      <div className="bg-light text-center mb-2 py-1 text-nowrap" ref={ref}>
        <small>{width}</small> <small className="text-muted d-none d-lg-inline">({columnSize})</small>
      </div>
    ),
  });
};
