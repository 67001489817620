// eslint-disable-next-line no-restricted-imports
import { api, SendCustomerCareMessagesRequestApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useMessagesApi = () => {
  const { apiRequest } = useApiRequest();

  const sendCustomerCareMessage = React.useCallback(
    (data: SendCustomerCareMessagesRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.messages.sendCustomerCareMessage(data), { silent: false, ...config }),
    [apiRequest]
  );

  return { sendCustomerCareMessage };
};
