import { OrderApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from '../../../Button';
import { Div, P } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { OrderDetails } from '../../../OrderDetails';
import { OrderState } from '../../../OrderState';
import { Pagination } from '../../../Pagination';
import { TableBody } from '../../../TableBody';
import { TableHead } from '../../../TableHead';
import { useOrdersSearch } from '../../../useOrdersSearch';
import { formatCurrency, formatDate, renderUI } from '../../../util';
import { AccountOrdersSubpageProps } from '../AccountOrdersSubpage';
import { AccountSubpage } from '../AccountSubpage';
import { AccountTable } from './AccountTable';
import { Box } from './Box';

export const AccountOrdersSubpageA = ({ enableReceiverColumn }: AccountOrdersSubpageProps) => {
  const { searchResult } = useOrdersSearch({
    populate: {
      billingAddress: true,
      paymentMethod: true,
      shippingAddress: true,
      warehouseOrders: {
        carrierTracking: true,
        orderedSkus: {
          sku: {
            product: { attributes: true },
          },
        },
      },
    },
  });

  return (
    <AccountSubpage>
      <Box>
        {searchResult &&
          renderUI({
            bs5: (
              <div className="account-order-table">
                <Div
                  display={{
                    md: 'block',
                    xs: 'none',
                  }}
                  marginBottom={3}
                >
                  <AccountTable className="border" colNumber={12}>
                    <TableHead variant="light">
                      <tr>
                        <th className="small fw-bold text-uppercase" colSpan={enableReceiverColumn ? 2 : 3}>
                          {t('account.orders.details.code')}
                        </th>
                        {enableReceiverColumn && (
                          <th className="small fw-bold text-uppercase" colSpan={2}>
                            {t('account.orders.details.receiverShipping')}
                          </th>
                        )}
                        <th className="small fw-bold text-uppercase" colSpan={2}>
                          {t('account.orders.details.date')}
                        </th>
                        <th className="small fw-bold text-uppercase" colSpan={enableReceiverColumn ? 2 : 3}>
                          {t('account.orders.details.status')}
                        </th>
                        <th className="small fw-bold text-uppercase" colSpan={2}>
                          {t('account.orders.details.total')}
                        </th>
                        <th className="small fw-bold text-uppercase" colSpan={2}></th>
                      </tr>
                    </TableHead>
                    <TableBody className="border-light">
                      {searchResult.items?.map((order) => (
                        <AccountOrderTr
                          enableReceiverColumn={enableReceiverColumn}
                          key={order.code}
                          order={order}
                          pagesCount={searchResult.pagesCount}
                        />
                      ))}
                    </TableBody>
                  </AccountTable>
                </Div>
                <Div
                  display={{
                    md: 'none',
                  }}
                >
                  <AccountTable colNumber={12}>
                    <TableBody>
                      {searchResult.items?.map((order, index) => (
                        <AccountOrderTr
                          enableReceiverColumn={enableReceiverColumn}
                          key={order.code}
                          lastOrder={index === (searchResult.items && searchResult.items.length - 1)}
                          order={order}
                          pagesCount={searchResult.pagesCount}
                        />
                      ))}
                    </TableBody>
                  </AccountTable>
                </Div>
              </div>
            ),
          })}
        {searchResult && searchResult.pagesCount > 1 && (
          <Div className="mt-3" display="flex" justifyContent={{ lg: 'end', xs: 'center' }}>
            <Pagination
              currentPage={searchResult.pageNumber}
              pageOffset={2}
              showDots
              singleStepNavigation
              totalPages={searchResult.pagesCount}
            />
          </Div>
        )}
      </Box>
    </AccountSubpage>
  );
};

interface AccountOrderTrProps {
  enableReceiverColumn?: boolean;
  lastOrder?: boolean;
  order: OrderApiDto;
  pagesCount: number;
}

const AccountOrderTr = ({ enableReceiverColumn, lastOrder, order, pagesCount }: AccountOrderTrProps) => {
  const [showMore, setShowMore] = React.useState<boolean>();
  const handleShowMore = React.useCallback(() => {
    showMore ? setShowMore(false) : setShowMore(true);
  }, [showMore]);

  return renderUI({
    bs5: (
      <>
        <tr className={classnames('d-none d-md-table-row', showMore && 'order-selected')}>
          <td
            className="mobile-data-title"
            colSpan={enableReceiverColumn ? 2 : 3}
            data-title={t('account.orders.details.code')}
          >
            {order.code}
          </td>
          {enableReceiverColumn && (
            <td className="mobile-data-title" colSpan={2} data-title={t('account.orders.details.receiverShipping')}>
              {`${order.shippingAddress?.name} ${order.shippingAddress?.surname}`}
            </td>
          )}
          <td className="mobile-data-title" colSpan={2} data-title={t('account.orders.details.date')}>
            {formatDate(order.creationDate, 'DD/MM/YYYY')}
          </td>
          <td
            className="mobile-data-title"
            colSpan={enableReceiverColumn ? 2 : 3}
            data-title={t('account.orders.details.status')}
          >
            <OrderState state={order.state} />
          </td>
          <td className="mobile-data-title fw-bold small" colSpan={2} data-title={t('account.orders.details.total')}>
            {formatCurrency(order.totalCost)}
          </td>
          <td className="text-end" colSpan={2}>
            <Button
              iconStart={{ name: !showMore ? 'plus' : 'minus', size: 16 }}
              onClick={handleShowMore}
              variant={'blank'}
            />
          </td>
        </tr>
        <>
          <tr className="d-md-none">
            <td className="border-0 pt-3 pb-0 px-0" colSpan={4}>
              <P className="small" fontWeight={700} margin={0}>
                {t('account.orders.details.code')}
              </P>
            </td>
            <td className="border-0 pt-3 pb-0 px-0" colSpan={8}>
              <P className="small" margin={0}>
                {order.code}
              </P>
            </td>
          </tr>
          {enableReceiverColumn && (
            <tr className="d-md-none">
              <td className="border-0 py-0 px-0" colSpan={4}>
                <P className="small" fontWeight={700} margin={0}>
                  {t('account.orders.details.receiverShipping')}
                </P>
              </td>

              <td className="border-0 py-0 px-0" colSpan={8}>
                <P className="small" margin={0}>
                  {`${order.shippingAddress?.name} ${order.shippingAddress?.surname}`}
                </P>
              </td>
            </tr>
          )}
          <tr className="d-md-none">
            <td className="border-0 py-0 px-0" colSpan={4}>
              <P className="small" fontWeight={700} margin={0}>
                {t('account.orders.details.date')}
              </P>
            </td>
            <td className="border-0 py-0 px-0" colSpan={8}>
              <P className="small" margin={0}>
                {formatDate(order.creationDate, 'DD/MM/YYYY')}
              </P>
            </td>
          </tr>
          <tr className="d-md-none">
            <td className="border-0 py-0 px-0" colSpan={4}>
              <P className="small" fontWeight={700} margin={0}>
                {t('account.orders.details.status')}
              </P>
            </td>
            <td className="border-0 py-0 px-0" colSpan={8}>
              <P className="small" margin={0}>
                <OrderState state={order.state} />
              </P>
            </td>
          </tr>
          <tr className="d-md-none">
            <td className="border-0 py-0 px-0" colSpan={4}>
              <P className="small" fontWeight={700} margin={0}>
                {t('account.orders.details.total')}
              </P>
            </td>
            <td className="border-0 py-0 px-0" colSpan={8}>
              <P className="small" fontWeight={700} margin={0}>
                {formatCurrency(order.totalCost)}
              </P>
            </td>
          </tr>
          <tr className="d-md-none">
            <td className="border-0 py-0 px-0" colSpan={12}>
              <Button
                className={classnames('p-0 m-0 fw-bold btn-sm text-reset mt-3')}
                label={
                  !showMore
                    ? t('account.orders.details.showOrderDetails')
                    : t('account.orders.details.closeOrderDetails')
                }
                onClick={handleShowMore}
                variant={'link'}
              />
            </td>
          </tr>
        </>
        {showMore && (
          <tr className="order-details-row">
            <td className="p-0 border-0" colSpan={12}>
              <OrderDetails order={order} />
            </td>
          </tr>
        )}
        <tr className={classnames('d-md-none', { 'd-none': lastOrder && pagesCount === 1 })}>
          <td className="border-0 py-0 px-0" colSpan={12}>
            <Div marginX={-3} paddingTop={3}>
              <Div className="border-bottom border-5" />
            </Div>
          </td>
        </tr>
      </>
    ),
  });
};
