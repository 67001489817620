import { b2x } from '@b2x/react/src';

import { Page } from './Page';

export interface AccountPageProps extends b2x.AccountPageProps {}

export const AccountPage = (props: AccountPageProps) => {
  return (
    <Page className="account-page" greyBackground noPaddingTop>
      <b2x.AccountPage {...props} />
    </Page>
  );
};
