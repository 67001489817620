import React from 'react';

import { wait } from './util';

export interface UseRemounterProps {
  deps: React.DependencyList;
}

export type UseRemounterResult = boolean;

export const useRemounter = ({ deps }: UseRemounterProps): UseRemounterResult => {
  const [mount, setMount] = React.useState<boolean>(true);

  React.useEffect(() => {
    setMount(false);
    wait(100).then(() => {
      setMount(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return mount;
};
