import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface FetchingProps<T> {
  children: ((item: T) => React.ReactNode) | React.ReactNode;
  item: T | undefined;
  placeholder?: string | React.ReactElement;
}

const Fetching = <T,>({ children, item, placeholder = 'loading...' }: FetchingProps<T>) => {
  return <>{item === undefined ? placeholder : typeof children === 'function' ? children(item) : children}</>;
};

export type FetchingVariants = '';

const FetchingController = <T,>(props: PropsWithCustomComponent<FetchingProps<T>>) => (
  <VariantsController<FetchingProps<T>, FetchingVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: Fetching,
      name: 'Fetching',
    }}
  />
);
export { FetchingController as Fetching };
