import React from 'react';

type Event = MouseEvent | TouchEvent;

interface Options {
  classesToExclude?: Array<string>;
}

export const useOutsideClickHandler = <T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  callback: () => void,
  options?: Options
) => {
  const { classesToExclude = ['LoadingOverlay'] } = options ?? {};
  const listener = React.useCallback(
    (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        const targetClassName = (event.target as Element).classList.value;
        if (targetClassName && !classesToExclude.some((classToExclude) => targetClassName.includes(classToExclude))) {
          callback();
        }
      }
    },
    [callback, classesToExclude, ref]
  );

  React.useEffect(() => {
    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);
    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [listener]);
};
