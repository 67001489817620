import './Configurator.scss';

import classNames from 'classnames';
import JsZip from 'jszip';
import React from 'react';
import * as yup from 'yup';

import { Button } from './Button';
import { appConfig, Variants } from './config';
import { env } from './env';
import { Select } from './form/fields/Select';
import { FormGroup } from './form/FormGroup';
import { en } from './i18n/locales/en';
import { it } from './i18n/locales/it';
import { useModals } from './useModals';
import { downloadFileFromBlob, environment, objectToBlob, renderUI } from './util';
import { useVariantsContext } from './VariantsContext';
import { allVariantsSelectors } from './VariantsController';

export interface ConfiguratorProps {}

export const Configurator = (props: ConfiguratorProps) => {
  const { showOffcanvas } = useModals();

  const handleConfiguratorButtonClick = React.useCallback(() => {
    showOffcanvas({
      children: environment !== 'PRODUCTION' ? <ConfiguratorOffcanvas /> : undefined,
      placement: 'right',
      title: 'Configuratore',
    });
  }, [showOffcanvas]);

  return renderUI({
    bs5: (
      <>
        {environment !== 'PRODUCTION' && (
          <Button
            className="btn-configurator position-fixed d-flex justify-contente-center align-items-center border-0"
            iconStart={appConfig.icons ? { name: appConfig.icons.configurator, size: 25 } : undefined}
            onClick={handleConfiguratorButtonClick}
            style={{ color: '#FFFFFF' }}
          />
        )}
      </>
    ),
  });
};

interface ConfiguratorOffcanvasProps {}

const ConfiguratorOffcanvas = (props: ConfiguratorOffcanvasProps) => {
  React.useEffect(() => {
    console.log(appConfig.variants);
  });

  const handlei18nButtonClick = React.useCallback(() => {
    const zip = JsZip();
    zip.file('en_common.json', objectToBlob(en));
    zip.file('it_common.json', objectToBlob(it));
    Object.entries(appConfig.i18n).forEach(([key, value]) => {
      zip.file(`${key}.json`, objectToBlob(value));
    });
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      downloadFileFromBlob(zipFile, `${appConfig.projectName}.zip`, 'application/zip');
    });
  }, []);

  return renderUI({
    bs5: (
      <div className="Configurator">
        <div className="border-bottom pb-3 mb-3 fw-bold">API Proxy: {env.REACT_APP_API_PROXY}</div>
        <div className="border-bottom pb-3 mb-3 fw-bold">
          <Button label="i18n download" onClick={handlei18nButtonClick} />
        </div>
        <div className="settings border-bottom pb-3 mb-3 fw-bold">
          <div className={classNames(appConfig.enableWishlist ? 'enabled' : 'disabled', 'd-flex align-items-center')}>
            Wishlist
          </div>
        </div>
        {Object.values(allVariantsSelectors).map((variantsSelector) => (
          <VariantsSelector key={variantsSelector.name} {...variantsSelector} />
        ))}
      </div>
    ),
  });
};

///

export interface VariantsSelectorProps {
  componentVariants: Array<string>;
  customComponent: boolean;
  defaultComponent: boolean;
  name: keyof Variants<never, yup.AnyObjectSchema, never, yup.AnyObjectSchema, string, string, string, number>;
}

const VariantsSelector = ({ componentVariants, customComponent, defaultComponent, name }: VariantsSelectorProps) => {
  const { setVariants, variants } = useVariantsContext();

  const handleVariantChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setVariants((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    },
    [name, setVariants]
  );

  const options = React.useMemo<Array<React.OptionHTMLAttributes<HTMLOptionElement>>>(() => {
    const _options: Array<React.OptionHTMLAttributes<HTMLOptionElement>> = [];
    if (defaultComponent) {
      _options.push({ label: 'default', value: 'default' });
    }
    componentVariants.forEach((componentVariant) => {
      _options.push({
        label: componentVariant,
        value: componentVariant,
      });
    });
    if (customComponent) {
      _options.push({ label: 'custom', value: 'custom' });
    }
    return _options;
  }, [componentVariants, customComponent, defaultComponent]);

  return (
    <>
      {options.length > 1 && (
        <FormGroup label={name} labelClassName="mb-0" names={[name]}>
          <Select
            defaultValue={variants[name]}
            includeEmptyOption={false}
            name={name}
            onChange={handleVariantChange}
            size="small"
          >
            {options.map((option) => (
              <option key={option.label} {...option} />
            ))}
          </Select>
        </FormGroup>
      )}
    </>
  );
};

///

// interface VariantsSelectorProps<ComponentProps, VariantChoices extends string>
//   extends VariantsControllerProps<ComponentProps, VariantChoices> {}

// // eslint-disable-next-line @typescript-eslint/ban-types
// const VariantsSelector = <ComponentProps extends object, VariantChoices extends string = ''>({
//   componentVariants,
//   defaultComponent,
//   name,
// }: VariantsSelectorProps<ComponentProps, VariantChoices>) => {
//   const { setVariants, variants } = useAppContext();

//   const handleVariantChange = React.useCallback(
//     (e: React.ChangeEvent<HTMLSelectElement>) => {
//       setVariants((prevState) => ({
//         ...prevState,
//         [name]:
//           e.target.value === 'default'
//             ? undefined
//             : e.target.value === 'custom'
//             ? appConfig.variants[name]
//             : e.target.value,
//       }));
//     },
//     [name, setVariants]
//   );

//   const options = React.useMemo<Array<React.OptionHTMLAttributes<HTMLOptionElement>>>(() => {
//     const _options: Array<React.OptionHTMLAttributes<HTMLOptionElement>> = [];
//     if (defaultComponent) {
//       _options.push({ label: 'default', selected: variants[name] === undefined, value: 'default' });
//     }
//     if (componentVariants) {
//       Object.keys(componentVariants).forEach((key) => {
//         _options.push({ label: key, selected: variants[name] === key, value: key });
//       });
//     }
//     if (typeof appConfig.variants[name] === 'function') {
//       _options.push({ label: 'custom', selected: typeof variants[name] === 'function', value: 'custom' });
//     }
//     return _options;
//   }, [defaultComponent, componentVariants, name, variants]);

//   return (
//     <>
//       {options.length > 1 && (
//         <FormGroup label={name} labelClassName="mb-0" names={[name]}>
//           <Select includeEmptyOption={false} name={name} onChange={handleVariantChange} size="small">
//             {options.map((option) => (
//               <option key={option.label} {...option} />
//             ))}
//           </Select>
//         </FormGroup>
//       )}
//     </>
//   );
// };
