import classnames from 'classnames';

import { PaymentsContentType } from './contentTypes';
import { NotResponsiveImageFromContent } from './NotResponsiveImageFromContent';
import { useContent } from './useContent';

interface PaymentIconsProps {
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  className?: string;
  gap?: 0 | 1 | 2 | 3 | 4 | 5;
  justifyContent?: 'between' | 'start' | 'end' | 'center' | 'around' | 'evenly';
  width?: number;
}

export const PaymentIcons = ({
  alignItems = 'center',
  className,
  gap = 3,
  justifyContent = 'start',
  width,
}: PaymentIconsProps) => {
  const paymentIcons = useContent<PaymentsContentType>('PAYMENTS_CONTENT');

  return (
    <div
      className={classnames(
        'd-flex',
        className,
        { [`justify-content-${justifyContent}`]: justifyContent },
        { [`align-items-${alignItems}`]: alignItems },
        { [`gap-${gap}`]: gap }
      )}
    >
      {paymentIcons?.body.iconsList?.map(({ contentSectionId, image, link, name }) => (
        <div key={contentSectionId}>{<NotResponsiveImageFromContent {...image} alt={name} fluid width={width} />}</div>
      ))}
    </div>
  );
};
