import { SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';

import { Button, ButtonProps } from './Button';
import { appConfig } from './config';
import { AddToCartFieldsHelper } from './form/AddToCartForm';

export interface AddToCartFormButtonProps<ButtonVariant, IconName, IconSize> {
  availabilityEmailNotificationButton?: ButtonProps<ButtonVariant, IconName, IconSize>;
  fieldsHelper?: AddToCartFieldsHelper;
  selectedSku?: SkuApiDto;
  submitButton?: ButtonProps<ButtonVariant, IconName, IconSize>;
}

export const AddToCartFormButton = <ButtonVariant extends string, IconName extends string, IconSize extends number>({
  availabilityEmailNotificationButton,
  fieldsHelper,
  selectedSku,
  submitButton,
}: AddToCartFormButtonProps<ButtonVariant, IconName, IconSize>) => {
  const { enableProductAvailabilityEmailNotification } = appConfig;

  const iconStartProps = appConfig.icons?.email ? { name: appConfig.icons.email } : undefined;

  return (
    <React.Fragment>
      {enableProductAvailabilityEmailNotification ? (
        <>
          {selectedSku?.state === undefined || selectedSku.state === 'AVAILABLE' ? (
            <Button {...fieldsHelper?.buttons.submit} {...submitButton} />
          ) : (
            <Button
              {...fieldsHelper?.buttons.availabilityEmailNotification}
              iconStart={iconStartProps}
              variant="outline-primary"
              {...availabilityEmailNotificationButton}
            />
          )}
        </>
      ) : (
        <Button {...fieldsHelper?.buttons.submit} {...submitButton} />
      )}
    </React.Fragment>
  );
};
