import { ContactUs } from '../../../ContactUs';
import { AccountSubpageContentType } from '../../../contentTypes';
import { Div } from '../../../HTMLElement';
import { formatHtml } from '../../../util';
import { AccountHelpSubpageProps } from '../AccountHelpSubpage';
import { AccountSubpage } from '../AccountSubpage';
import { Box } from './Box';

export const AccountHelpSubpageA = ({ contactUs }: AccountHelpSubpageProps) => {
  return (
    <AccountSubpage<AccountSubpageContentType>>
      {(page) => (
        <Box>
          <Div marginBottom={3}>{formatHtml(page.content?.body.content)}</Div>
          <ContactUs {...contactUs} />
        </Box>
      )}
    </AccountSubpage>
  );
};
