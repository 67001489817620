import classNames from 'classnames';

import { Table } from '../../../Table';

export interface AccountTableProps {
  borderless?: boolean;
  children?: React.ReactNode;
  className?: string;
  colNumber?: number;
}

export const AccountTable = ({ borderless, children, className, colNumber }: AccountTableProps): React.ReactElement => {
  return (
    <div className="account-table">
      <Table borderless={borderless} className={classNames(className, 'mb-0')} colNumber={colNumber}>
        {children}
      </Table>
    </div>
  );
};
